import { Row } from '@tanstack/react-table'

import { DispatchRowType } from '../ReconPayrollInstaller/components/PaystubStatusCell'
type InstallerDispatchType = Row<DispatchRowType>['original']

type ReturnType = {
  payoutNumber: number
  bonusNumber: number | null
  weekDrivePayoutNumber: number | null
}

export const NEUTRAL_700 = '#666666' // text subtlest
export const NEUTRAL_400 = '#BBBBBB' // disabled text
export const NEUTRAL_300 = '#DDDDDD' // border
export const NEUTRAL_200 = '#EEEEEE' // background subtlest
export const NEUTRAL_100 = '#F6F6F6' // background
export const NEUTRAL_1000 = '#111111' // text

export function getPieceRateAddonPayouts(installerDispatch: InstallerDispatchType): ReturnType {
  if (!installerDispatch) return { payoutNumber: 0, bonusNumber: null, weekDrivePayoutNumber: null }
  const {
    isDavisBacon,
    isHourlyPayout,
    isHourlyInstaller,
    payoutAddons,
    payoutDavisBacon,
    payoutDrive,
    payoutHourly,
    payoutPieceRate,
    payoutPieceRateDavisBaconGap,
    payoutTotal,
  } = installerDispatch
  const payoutAddonsNumber: number = payoutAddons === null ? 0 : Number(payoutAddons)
  const payoutDavisBaconNumber: number = payoutDavisBacon === null ? 0 : Number(payoutDavisBacon)
  const payoutDriveNumber: number = payoutDrive === null ? 0 : Number(payoutDrive)
  const payoutHourlyNumber: number = payoutHourly === null ? 0 : Number(payoutHourly)
  const payoutPieceRateNumber: number = payoutPieceRate === null ? 0 : Number(payoutPieceRate)
  const payoutPieceRateDavisBaconGapNumber: number = payoutPieceRateDavisBaconGap === null ? 0 : Number(payoutPieceRateDavisBaconGap)

  const payoutTotalNumber: number = payoutTotal === null ? 0 : Number(payoutTotal)

  const isPieceRateInstaller = !isHourlyInstaller
  const isPieceRatePayout = !isHourlyPayout && !isDavisBacon
  const isHourlyJob = isHourlyPayout && !isDavisBacon
  let payoutNumber: number = 0
  let weekDrivePayoutNumber: number | null = 0
  let bonusNumber: number | null = 0
  let remainderNumber: number = 0

  if (isHourlyInstaller && isDavisBacon) {
    payoutNumber = payoutDavisBaconNumber
    bonusNumber = payoutAddonsNumber
    weekDrivePayoutNumber += payoutDriveNumber
    remainderNumber = payoutHourlyNumber + payoutPieceRateNumber + payoutPieceRateDavisBaconGapNumber
  } else if (isHourlyInstaller && isPieceRatePayout) {
    payoutNumber = payoutPieceRateNumber + payoutAddonsNumber + payoutDriveNumber
    remainderNumber = payoutDavisBaconNumber + payoutPieceRateDavisBaconGapNumber + payoutHourlyNumber
  } else if (isHourlyInstaller && isHourlyJob) {
    payoutNumber = payoutHourlyNumber
    bonusNumber = payoutAddonsNumber
    weekDrivePayoutNumber += payoutDriveNumber
    remainderNumber = payoutDavisBaconNumber + payoutPieceRateDavisBaconGapNumber + payoutPieceRateNumber
  } else if (isPieceRateInstaller && isDavisBacon) {
    payoutNumber = payoutDavisBaconNumber
    bonusNumber = payoutAddonsNumber + payoutDriveNumber + payoutPieceRateDavisBaconGapNumber
    remainderNumber = payoutPieceRateNumber + payoutHourlyNumber
  } else if (isPieceRateInstaller && isPieceRatePayout) {
    payoutNumber = payoutPieceRateNumber + payoutAddonsNumber + payoutDriveNumber
    remainderNumber = payoutDavisBaconNumber + payoutPieceRateDavisBaconGapNumber + payoutHourlyNumber
  } else if (isPieceRateInstaller && isHourlyJob) {
    payoutNumber = payoutHourlyNumber + payoutAddonsNumber + payoutDriveNumber
    remainderNumber = payoutDavisBaconNumber + payoutPieceRateDavisBaconGapNumber + payoutPieceRateNumber
  }
  if (remainderNumber > 0) console.warn(`remainderNumber ${remainderNumber}`)

  const subtotal = bonusNumber + payoutNumber + weekDrivePayoutNumber
  if (subtotal !== payoutTotalNumber) console.log(`payoutNumber ${payoutNumber} !== subtotal ${subtotal}`, installerDispatch)
  if (bonusNumber === 0) bonusNumber = null
  if (weekDrivePayoutNumber === 0) weekDrivePayoutNumber = null
  return { payoutNumber, bonusNumber, weekDrivePayoutNumber }
}
