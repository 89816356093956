import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { GetVehiclesQuery } from '@/gql/graphql'

export type Vehicle = GetVehiclesQuery['vehiclesByDate'][0]

export const useVehiclesQuery = (date: string | null = null) => {
  const graphQLClient = useGraphQLClient()

  const { data, error, status, refetch } = useQuery({
    queryKey: [GET_VEHICLES_QUERY_KEY, date],
    queryFn: () => graphQLClient.request(GET_VEHICLES, { date }),
    refetchInterval: 1000 * 60 * 60, // 60 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  })

  const { vehicleMap, vehicles } = useMemo(() => {
    const list = (data?.vehiclesByDate ?? []) as Vehicle[]
    const idMap = list.reduce(
      (acc, vehicle) => {
        acc[vehicle.id] = vehicle
        return acc
      },
      {} as Record<string, Vehicle>
    )
    return { vehicleMap: idMap, vehicles: list }
  }, [data])

  return { vehicles, vehicleMap, error, status, refetch }
}

export const GET_VEHICLES_QUERY_KEY = 'vehicles'
export const GET_VEHICLES = graphql(/* GraphQL */ `
  query GetVehicles($date: Date) {
    vehiclesByDate(date: $date) {
      id
      name
      licensePlate
      make
      model
      year
      color
      isGhost
      date
      sortedVehicleDispatchIds
    }
  }
`)
