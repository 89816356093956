import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { SubmitDispatchPieceRateMutationVariables } from '@/gql/graphql'

import TableCellNumberTextField from '@/components/TableCellNumberTextField'

interface DispatchPieceRateTextFieldProps {
  databaseValue: number | null
  dispatchId: string
  isDisabled?: boolean
}

const DispatchPieceRateTextField = ({ databaseValue, dispatchId, isDisabled }: DispatchPieceRateTextFieldProps) => {
  const formattedDatabaseValue = databaseValue === null || databaseValue === undefined ? '' : Number(databaseValue).toFixed(2)
  const [value, setValue] = useState(formattedDatabaseValue)

  useEffect(() => {
    setValue(formattedDatabaseValue)
  }, [formattedDatabaseValue])

  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: SubmitDispatchPieceRateMutationVariables) => {
      const response = await graphQLClient.request(SUBMIT_DISPATCH_PIECE_RATE, variables)
      if (!response.submitDispatchPieceRate?.success)
        throw new Error(response.submitDispatchPieceRate?.message ?? 'Failed to submit dispatch piece rate')
      return response.submitDispatchPieceRate
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GetDispatchPayoutDetails'] })
    },
  })

  return (
    <TableCellNumberTextField
      prefix={'$'}
      isCompact
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={blurOnEnter}
      isDisabled={isPending || isDisabled}
    />
  )

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value)
  }

  function onBlur(e: React.FocusEvent<HTMLInputElement>) {
    const enteredPieceRate = Number(e.target.value).toFixed(2)
    setValue(enteredPieceRate)
    mutate({ dispatchId, enteredPieceRate })
  }

  function blurOnEnter(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      ;(e.target as HTMLInputElement).blur()
    }
  }
}

export default DispatchPieceRateTextField

const SUBMIT_DISPATCH_PIECE_RATE = graphql(/* GraphQL */ `
  mutation SubmitDispatchPieceRate($dispatchId: ID!, $enteredPieceRate: String!) {
    submitDispatchPieceRate(dispatchId: $dispatchId, enteredPieceRate: $enteredPieceRate) {
      success
      message
    }
  }
`)
