import LinkIcon from '@atlaskit/icon/glyph/link'
import LocationIcon from '@atlaskit/icon/glyph/location'
import StarIcon from '@atlaskit/icon/glyph/star'
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled'
import TrashIcon from '@atlaskit/icon/glyph/trash'

import React from 'react'
import styled from 'styled-components'

import Badge from '@atlaskit/badge'
import Button from '@atlaskit/button/new'

import { ExtendedPlaceType } from '@/types/project-site'

interface PlacesListProps {
  places: ExtendedPlaceType[]
  primaryPlaceId: string | null
  onSetPrimary: (placeId: string) => void
  onDelete: (placeId: string) => void
}

const PlacesList: React.FC<PlacesListProps> = ({ places, primaryPlaceId, onSetPrimary, onDelete }) => {
  return (
    <PlacesContainer>
      {places.map(place => (
        <PlaceItem key={place.id}>
          <PlaceContent>
            <PlaceIcon>
              <LocationIcon label='Location' size='medium' />
            </PlaceIcon>
            <PlaceDetails>
              <PlaceHeader>
                <PlaceName>
                  {place.displayName || 'Unknown Location'}
                  {place.isNew && <Badge appearance='added'>New</Badge>}
                </PlaceName>
                <PlaceActions>
                  {place.id === primaryPlaceId ? (
                    <Button
                      appearance='subtle'
                      spacing='compact'
                      iconBefore={<StarFilledIcon label='Primary' primaryColor='#FFAB00' />}
                      isDisabled
                    >
                      Primary
                    </Button>
                  ) : (
                    <Button
                      appearance='subtle'
                      spacing='compact'
                      onClick={() => onSetPrimary(place.id)}
                      iconBefore={<StarIcon label='Set as primary' />}
                    >
                      Set as Primary
                    </Button>
                  )}
                  <Button
                    appearance='subtle'
                    spacing='compact'
                    onClick={() => onDelete(place.googlePlaceId ?? place.id)}
                    iconBefore={<TrashIcon label='Delete' />}
                  >
                    Remove
                  </Button>
                </PlaceActions>
              </PlaceHeader>

              <PlaceAddress>{place.formattedAddress}</PlaceAddress>

              <PlaceLinks>
                {place.googleMapsUri && (
                  <a href={place.googleMapsUri} target='_blank' rel='noopener noreferrer'>
                    <LinkIcon label='Google Maps' size='small' /> View on Google Maps
                  </a>
                )}
                {place.websiteUri && (
                  <a href={place.websiteUri} target='_blank' rel='noopener noreferrer'>
                    <LinkIcon label='Website' size='small' /> Visit Website
                  </a>
                )}
              </PlaceLinks>

              <PlaceAddressComponents>
                {place.streetNumber && (
                  <span>
                    <strong>Street #:</strong> {place.streetNumber}
                  </span>
                )}
                {place.route && (
                  <span>
                    <strong>Route:</strong> {place.route}
                  </span>
                )}
                {place.city && (
                  <span>
                    <strong>City:</strong> {place.city}
                  </span>
                )}
                {place.state && (
                  <span>
                    <strong>State:</strong> {place.state}
                  </span>
                )}
                {place.zip && (
                  <span>
                    <strong>Zip:</strong> {place.zip}
                  </span>
                )}
                {place.country && (
                  <span>
                    <strong>Country:</strong> {place.country}
                  </span>
                )}
              </PlaceAddressComponents>
            </PlaceDetails>
          </PlaceContent>
        </PlaceItem>
      ))}
    </PlacesContainer>
  )
}

const PlacesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const PlaceItem = styled.div`
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  overflow: hidden;
`

const PlaceContent = styled.div`
  display: flex;
  padding: 16px;
`

const PlaceIcon = styled.div`
  margin-right: 16px;
  color: #0052cc;
`

const PlaceDetails = styled.div`
  flex: 1;
`

const PlaceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
  gap: 8px;
`

const PlaceName = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`

const PlaceActions = styled.div`
  display: flex;
  gap: 8px;
`

const PlaceAddress = styled.div`
  margin-bottom: 8px;
  color: #172b4d;
`

const PlaceLinks = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 8px;

  a {
    display: flex;
    align-items: center;
    color: #0052cc;
    text-decoration: none;
    font-size: 14px;
    gap: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
`

const PlaceAddressComponents = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  font-size: 12px;
  color: #6b778c;
  margin-top: 8px;
`

export default PlacesList
