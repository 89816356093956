import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { itemMouseOverAtom } from '../../dispatchTypes'

export const useHighlightedInstallerId = (assignedInstallerIds: string[]): string | null => {
  const installerIdAtom = useMemo(
    () =>
      selectAtom(itemMouseOverAtom, mouseOver => {
        if (mouseOver.itemIdentifiedVariant === 'INSTALLER' && mouseOver?.installerId) {
          const installerId = mouseOver.installerId
          if (assignedInstallerIds.includes(installerId)) return installerId
        }
        return null
      }),
    [assignedInstallerIds]
  )
  return useAtomValue(installerIdAtom)
}
