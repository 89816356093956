import { useMemo } from 'react'

import { DispatchPayoutType } from '../useDispatchPayoutQuery'

import {
  INSTALLER_COL_GROUPS,
  INSTALLER_COL_GROUPS_ORDER,
  INSTALLER_COL_ORDER,
  INSTALLER_COLUMNS,
  InstallerColumn,
  InstallerColumnKey,
} from './constants'

interface UseColumnDefsProps {
  dispatch?: DispatchPayoutType
  isDavisBacon: boolean
}

export interface ColumnOrderItem extends InstallerColumn {
  key: keyof typeof INSTALLER_COLUMNS
}
export const useInstallerColumnDefs = ({ dispatch, isDavisBacon }: UseColumnDefsProps) => {
  const installerDispatches = useMemo(() => dispatch?.installerDispatches ?? [], [dispatch])

  const totalPrGap = installerDispatches.reduce((acc, assignment) => acc + Number(assignment.payoutPieceRateDavisBaconGap ?? 0), 0)

  const columnKeysToHide: InstallerColumnKey[] = useMemo(() => {
    const colsToHide: InstallerColumnKey[] = []
    if (!dispatch?.isAddonsSeparated) colsToHide.push('isPercentAddonsLocked', 'percentAddons')
    if (totalPrGap === 0) colsToHide.push('payoutPieceRateDavisBaconGap')
    if (isDavisBacon) colsToHide.push('payoutHourly')
    else colsToHide.push('payoutDavisBacon', 'payoutPieceRateDavisBaconGap')
    return colsToHide
  }, [totalPrGap, isDavisBacon, dispatch?.isAddonsSeparated])

  const orderedColumns: ColumnOrderItem[] = INSTALLER_COL_ORDER.filter(col => !columnKeysToHide.includes(col)).map(col => ({
    key: col,
    ...INSTALLER_COLUMNS[col],
  }))
  const columnGroups = INSTALLER_COL_GROUPS_ORDER.filter(group => {
    if (isDavisBacon && group === 'hourlyPayout') return false
    if (!isDavisBacon && group === 'davisBacon') return false
    return true
  }).map(group => {
    const defaultGroup = INSTALLER_COL_GROUPS[group]
    return {
      ...defaultGroup,
      columns: defaultGroup.columns.filter(col => !columnKeysToHide.includes(col)),
    }
  })

  return {
    orderedColumns,
    columnGroups,
  }
}
