import { useMemo } from 'react'

import { useInstallerColumnDefs } from './useInstallerColumnDefs'

import type { DispatchPayoutType, InstallerDispatchPayoutType } from '../useDispatchPayoutQuery'

interface UseInstallerTableProps {
  dispatch?: DispatchPayoutType
  isDavisBacon: boolean
}
export const useInstallersTableData = ({ dispatch, isDavisBacon }: UseInstallerTableProps) => {
  const installerDispatches: InstallerDispatchPayoutType[] = useMemo(() => {
    if (!dispatch?.installerDispatches) return []
    const dispatches = dispatch?.installerDispatches.sort((a, b) => {
      // First sort by vehicle name
      const vehicleCompare = (a.vehicleName ?? '').localeCompare(b.vehicleName ?? '')
      if (vehicleCompare !== 0) return vehicleCompare

      // Then sort by installer name
      return a.installer.fullName.localeCompare(b.installer.fullName)
    })
    return dispatches
  }, [dispatch])

  const shouldLockPieceRate: boolean = useMemo(() => {
    if (!dispatch?.installerDispatches) return false
    if (!dispatch.percentPieceRateAllocated || Number(dispatch.percentPieceRateAllocated) < 99) return false
    // Return true if there are less than two installerDispatches where isPercentPieceRateLocked is false since they have
    // be able to adjust the other to total 100%
    return dispatch.installerDispatches.filter(installerDispatch => installerDispatch.isPercentPieceRateLocked === false).length < 2
  }, [dispatch])

  const shouldLockAddons: boolean = useMemo(() => {
    if (!dispatch?.isAddonsSeparated === true) return true
    if (!dispatch?.installerDispatches) return false
    if (!dispatch.percentAddonsAllocated || Number(dispatch.percentAddonsAllocated) < 99) return false

    return dispatch.installerDispatches.filter(installerDispatch => installerDispatch.isPercentAddonsLocked === false).length === 0
  }, [dispatch])

  const { orderedColumns, columnGroups } = useInstallerColumnDefs({ dispatch, isDavisBacon })

  const workOrderId: string | undefined = dispatch?.workOrderId ?? undefined
  const tbodyHeight: number = installerDispatches.length * 32
  const dispatchEnteredPieceRate: string = dispatch?.enteredPieceRate ?? '0.00'
  const dispatchEnteredAddons: string = dispatch?.enteredAddons ?? '0.00'

  return {
    installerDispatches,
    shouldLockPieceRate,
    shouldLockAddons,
    orderedColumns,
    columnGroups,
    workOrderId,
    tbodyHeight,
    dispatchEnteredPieceRate,
    dispatchEnteredAddons,
  }
}
