/// <reference types="@types/google.maps" />

import { FormEvent, useEffect, useState } from 'react'

import { PlaceInputType, parsePlaceResult } from '@/types/project-site'

import { useJobAPI } from './useJobAPI'
import { SelectOption } from './useJobForm'

interface UseCreateJobFormProps {
  closeModal: () => void
  preselectedCustomer?: SelectOption
}

export function useCreateJobForm({ closeModal, preselectedCustomer }: UseCreateJobFormProps) {
  // Form state
  const [title, setTitle] = useState('')
  const [projectSiteName, setProjectSiteName] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState<SelectOption | undefined>(preselectedCustomer)
  const [projectSiteCoordinates, setProjectSiteCoordinates] = useState<{ lat: number; lng: number } | undefined>(undefined)
  const [placesData, setPlacesData] = useState<PlaceInputType[]>([])
  const [additionalAddresses, setAdditionalAddresses] = useState<string[]>([])

  // Validation state
  const [errors, setErrors] = useState<Record<string, { type: string }>>({})

  // Places management state
  const [autocompleteRefs, setAutocompleteRefs] = useState<(google.maps.places.Autocomplete | null)[]>([null])
  const [isMapPinModalOpen, setIsMapPinModalOpen] = useState(false)
  const [hasPinLocation, setHasPinLocation] = useState(false)

  const { customersOptions, isPendingCustomersList, submitJob, isPending, prepareSubmission } = useJobAPI(closeModal)

  // Validation logic
  useEffect(() => {
    const newErrors: Record<string, { type: string }> = {}

    if (title && title.length < 3) newErrors.title = { type: 'minLength' }
    if (!title) newErrors.title = { type: 'required' }
    if (projectSiteName && projectSiteName.length < 3) newErrors.projectSiteName = { type: 'minLength' }
    if (!projectSiteName) newErrors.projectSiteName = { type: 'required' }
    if (!selectedCustomer?.value) newErrors.selectedCustomer = { type: 'required' }

    setErrors(newErrors)
  }, [title, projectSiteName, selectedCustomer])

  // Form validation status
  const titleValid = title && title.length >= 3
  const projectSiteNameValid = projectSiteName && projectSiteName.length >= 3
  const selectedCustomerValid = selectedCustomer?.value
  const formIsInvalid = !titleValid || !projectSiteNameValid || !selectedCustomerValid || Object.keys(errors).length > 0

  // Places management functions
  const handlePlaceSelect = (place: google.maps.places.PlaceResult, index: number) => {
    if (place.formatted_address) {
      // Set project site name from the first place only
      if (index === 0) {
        setProjectSiteName(place.formatted_address)
        setHasPinLocation(false)
      }

      if (place.geometry?.location) {
        const coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }

        // Set coordinates from the first place only
        if (index === 0) {
          setProjectSiteCoordinates(coordinates)
        }

        // Create new place data object
        const newPlaceData = parsePlaceResult(place)

        // Update placesData array with the new place at the correct index
        const updatedPlacesData = [...placesData]
        updatedPlacesData[index] = newPlaceData
        setPlacesData(updatedPlacesData)
      }
    }
  }

  const handleMapPinSelect = (coordinates: { lat: number; lng: number }) => {
    setProjectSiteCoordinates(coordinates)
    setProjectSiteName(`${coordinates.lat.toFixed(6)}, ${coordinates.lng.toFixed(6)}`)
    setPlacesData([]) // Clear places data when using pin mode
    setHasPinLocation(true)
  }

  const clearCoordinates = () => {
    setProjectSiteCoordinates(undefined)
    setProjectSiteName('')
    setPlacesData([])
    setHasPinLocation(false)
  }

  const addAddressField = () => {
    setAutocompleteRefs(prev => [...prev, null])
  }

  const removeAddressField = (index: number) => {
    if (autocompleteRefs.length > 1) {
      setAutocompleteRefs(prev => prev.filter((_, i) => i !== index))

      // Remove the place data at this index and reindex the remaining places
      const updatedPlacesData = [...placesData].filter((_, i) => i !== index)
      setPlacesData(updatedPlacesData)

      // Also update the additionalAddresses field to keep it in sync
      setAdditionalAddresses(additionalAddresses.filter((_, i) => i !== index - 1))
    }
  }

  // Form submission handler
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const formData = {
      title,
      projectSiteName,
      selectedCustomer: selectedCustomer!,
      projectSiteCoordinates,
      placesData,
      additionalAddresses,
    }

    const variables = prepareSubmission(formData, hasPinLocation)
    submitJob(variables)
  }

  return {
    // Form state
    title,
    setTitle,
    projectSiteName,
    setProjectSiteName,
    selectedCustomer,
    setSelectedCustomer,
    projectSiteCoordinates,
    placesData,
    additionalAddresses,

    // Validation
    errors,
    formIsInvalid,

    // Places management
    autocompleteRefs,
    setAutocompleteRefs,
    isMapPinModalOpen,
    setIsMapPinModalOpen,
    hasPinLocation,

    // Functions
    handlePlaceSelect,
    handleMapPinSelect,
    clearCoordinates,
    addAddressField,
    removeAddressField,
    handleSubmit,

    // API related
    customersOptions,
    isPendingCustomersList,
    isPending,
  }
}
