import { useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useParams } from 'react-router'

import RefreshIcon from '@atlaskit/icon/glyph/refresh'

import ButtonGroup from '@atlaskit/button/button-group'
import Button, { IconButton } from '@atlaskit/button/new'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { DeleteWorkItemsMutationVariables } from '@/gql/graphql'

import SalesPersonDropdown from './components/SalesPersonDropdown'
import { editorSelectionStateAtom } from './editorAtoms'
import { useEditorData } from './hooks/useEditorData'
import { useEstimateAttribute } from './hooks/useEstimateAttribute'
import { useSubmitEstimateValues } from './hooks/useSubmitEstimateValues'

const EstimateEditorPageActions = () => {
  const params = useParams()
  const estimateId = params.estimateId as string
  const [selectedCellsState, setSelectedCellsState] = useAtom(editorSelectionStateAtom)
  const workItemIds = Object.keys(selectedCellsState)
  const selectedCellsCount = workItemIds.length
  const { refetch, isPending: isRefetchPending } = useEditorData(estimateId)

  const graphQLClient = useGraphQLClient()
  const { mutate: deleteWorkItems, isPending } = useMutation({
    mutationFn: async (variables: DeleteWorkItemsMutationVariables) => {
      const res = await graphQLClient.request(DELETE_WORK_ITEMS, variables)
      if (!res.deleteWorkItems.success) {
        throw new Error(res.deleteWorkItems.message)
      }
      return res.deleteWorkItems
    },
    onSuccess: () => {
      refetch()
      setSelectedCellsState({})
    },
  })

  const handleDeleteWorkItems = () => deleteWorkItems({ workItemIds })

  return (
    <div style={{ marginBottom: -8 }}>
      <ButtonGroup label='Editor Actions'>
        <DavisBaconToggle estimateId={estimateId} />
        <SalesPersonDropdown estimateId={estimateId} />
        {selectedCellsCount === 0 ? null : (
          <Button appearance='danger' onClick={handleDeleteWorkItems} isLoading={isPending}>
            Delete {selectedCellsCount} Work Items
          </Button>
        )}
        <IconButton icon={RefreshIcon} onClick={refetch} label='Refresh' isLoading={isRefetchPending} />
      </ButtonGroup>
    </div>
  )
}

export default EstimateEditorPageActions

const DELETE_WORK_ITEMS = graphql(/* GraphQL */ `
  mutation DeleteWorkItems($workItemIds: [UUID!]!) {
    deleteWorkItems(workItemIds: $workItemIds) {
      success
      message
    }
  }
`)

const DavisBaconToggle = ({ estimateId }: { estimateId: string }) => {
  const isDavisBacon = useEstimateAttribute('isDavisBacon')
  const isReconLocked = useEstimateAttribute('isReconLocked')

  const mutate = useSubmitEstimateValues()

  return (
    <Button
      isSelected={isDavisBacon}
      // @ts-expect-error known json issue
      onClick={() => mutate({ estimateId, values: [{ attribute: 'isDavisBacon', value: !isDavisBacon }] })}
      isDisabled={isReconLocked}
    >
      {isDavisBacon ? 'Davis Bacon' : 'Standard'}
    </Button>
  )
}
