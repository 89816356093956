import { QueriedEstimate } from '../editorTypes'
import { useEstimateAttribute } from '../hooks/useEstimateAttribute'

import NumberCell from './NumberCell'

interface SummaryNumberFooterProps {
  estimateAttribute: keyof Omit<QueriedEstimate, 'workItems'>
  prefix?: string
  suffix?: string
}
const SummaryNumberFooter = ({ estimateAttribute, prefix = '$', suffix = '' }: SummaryNumberFooterProps) => {
  const value = useEstimateAttribute(estimateAttribute)
  return <NumberCell value={value} prefix={prefix} suffix={suffix} readOnly />
}

export default SummaryNumberFooter
