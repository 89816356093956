import { useQuery } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'

import { installerPropertiesByIdAtom } from './InstallersTable/useInstallerProps'

export const useDispatchPayoutQuery = ({ workOrderId }: { workOrderId: string | undefined }) => {
  const graphQLClient = useGraphQLClient()
  const setInstallerProps = useSetAtom(installerPropertiesByIdAtom)

  return useQuery({
    queryKey: [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY, workOrderId],
    queryFn: async () => {
      if (!workOrderId) throw new Error('Work Order ID is required')
      const response = await graphQLClient.request(GET_DISPATCH_PAYOUT_DETAILS, {
        workOrderId,
      })
      if (!response.workOrder) throw new Error('Work Order not found')
      console.log('useDispatchPayoutQuery() success: Clearing installerPropsAtom')
      setInstallerProps({})
      return response
    },
    enabled: !!workOrderId,
  })
}

export type DispatchPayoutWorkOrder = NonNullable<GetDispatchPayoutDetailsQuery['workOrder']>
export type DispatchPayoutType = NonNullable<DispatchPayoutWorkOrder['dispatches']>[number]
export type InstallerDispatchPayoutType = NonNullable<DispatchPayoutType['installerDispatches']>[number]

export const GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY = 'GetDispatchPayoutDetails'
const GET_DISPATCH_PAYOUT_DETAILS = graphql(/* GraphQL */ `
  query GetDispatchPayoutDetails($workOrderId: ID!) {
    workOrder(pk: $workOrderId) {
      id
      name
      tripName
      tripId
      isDavisBacon

      totalMinutesWorked

      totalPaidAddons
      totalPaidPieceRate
      totalPaidLaborTotal
      totalPaidDavisBacon
      totalPaidPieceRateDavisBaconGap
      totalPaidDrivePay

      totalEnteredPieceRate
      totalEnteredDrivePay
      totalEnteredAddons

      remainingAddons
      remainingDrivePay
      remainingPieceRate
      remainingLaborTotal

      projectedAddons
      projectedDrivePay
      projectedPieceRate
      projectedLaborTotal

      estimateTitle
      estimateId
      jobId
      jobTitle
      projectSiteAddress
      customerName
      marginPercent

      dispatches {
        id
        sid
        date

        totalMinutesWorked
        percentPieceRateAllocated
        percentAddonsAllocated
        isAddonsSeparated

        enteredPieceRate
        enteredAddons
        enteredPayTotal

        totalPaidHourly
        totalPaidAddons
        totalPaidDrivePay
        totalPaidPieceRate
        totalPaidPieceRateDavisBaconGap
        totalPaidDavisBacon
        totalPaidLaborTotal

        workOrderId

        vehicleDispatches {
          id
          sid
          vehicleDateId
        }

        payoutAddons {
          id
          reason
          payoutAmount
          note
        }

        installerDispatches {
          id
          sid
          isPayrollLocked

          vehicleName
          vehicleColor

          isHourlyInstaller

          isHourlyPayout
          isDavisBacon

          calculatedMinutesOnSite
          minutesWorked

          hourlyRate
          hourlyRatePieceRateAddons

          payoutHourly
          payoutDavisBacon

          minutesDriveActual
          calculatedMinutesDriveBuffer
          minutesDrivePayable
          minutesDrivePayableOverride
          hourlyRateDrive
          payoutDrive

          percentPieceRate
          isPercentPieceRateLocked
          calculatedPayoutPieceRate
          payoutPieceRate
          payoutPieceRateDavisBaconGap

          isPercentAddonsLocked
          percentAddons
          calculatedPayoutAddons
          payoutAddons

          minutesActual
          payoutTotal

          isCompleted
          isApproved

          installerId
          installer {
            id
            sid
            isDriver
            isHourly
            hourlyRate
            fullName
          }
        }
      }
      workItems {
        id
        quantity
        containersNeededFinal
        isVisibleOnWorkOrder
        workAreaName
        tripName
        materialName
        materialNote
        itemCode
        containerLabel
        containersNeededFinal
        unitOfMeasure
        laborCostFinal
      }
    }
  }
`)
