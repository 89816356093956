import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { useVehiclesQuery } from '@/utils/queryHooks/useVehiclesQuery'

import { sortedVehicleDispatchIdsByVehicleIdAtom } from '../../dispatchTypes'

export const useVisibleDateVehicles = (dateString: string) => {
  const sortedVehicleDispatchIdsAtom = useMemo(
    () =>
      selectAtom(sortedVehicleDispatchIdsByVehicleIdAtom, state => {
        const stateDate = state?.dateString ?? null
        if (!stateDate || !dateString || dateString !== stateDate) return null
        return state
      }),
    [dateString]
  )
  const sortedVehicleDispatchIds = useAtomValue(sortedVehicleDispatchIdsAtom)
  const { vehicles } = useVehiclesQuery()
  const visibleVehicles = vehicles.filter(vehicle => {
    if (sortedVehicleDispatchIds === null) return !vehicle.isGhost
    if (sortedVehicleDispatchIds[vehicle.id] !== null) return true
    return !vehicle.isGhost
  })

  return { vehicles: visibleVehicles }
}
