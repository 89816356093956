import styled from 'styled-components'

import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'

import { NEUTRAL_300 } from './paystubUtils'
import PrintTableDispatches from './PrintPaystubTableDispatches'
import { usePrintPaystubData } from './usePrintPaystubData'

const PrintPaystub = () => {
  const {
    title,
    paystubAttributes,
    isHourlyInstaller,
    installerDates,
    installerPayroll,
    datesOfWeek,
    minutesManual,
    minutesDispatch,
    minutesNonDispatchPayable,
    minutesAdjustment,
    minutesTotal,
    payOvertime,
    payNonDispatch,
    payAdjustments,
    payoutDriveWeek,
    payoutActual,
    payoutDispatches,
    dispatches,
    dbDispatches,
  } = usePrintPaystubData()

  return (
    <PageWrapper>
      <title>{title}</title>
      <PrintableWrapper>
        <div className='main-row-header'>
          <div className='logo-title-column'>
            <div className='title-info-column'>
              <div className='work-order-category'>Weekly Paystub</div>

              <h2 className='work-order-title'>{title}</h2>
            </div>
          </div>

          <div className='sheet-properties-column'>
            <div className='attribute-column'>
              {paystubAttributes.map(pa => (
                <div className='attribute'>{pa.label}</div>
              ))}
            </div>

            <div className='values-column'>
              {paystubAttributes.map(pa => (
                <div className='value'>{pa.value}</div>
              ))}
            </div>
          </div>
        </div>

        {dbDispatches.length > 0 ? (
          <>
            <h4 style={{ margin: '8px 0 4px 0' }}>Davis Bacon Dispatches</h4>
            <PrintTableDispatches installerPayroll={installerPayroll} dispatches={dbDispatches} isDavisBacon={true} />
          </>
        ) : null}

        {!installerPayroll ? null : (
          <PrintTableDispatches
            payoutDispatches={payoutDispatches}
            installerPayroll={installerPayroll}
            dispatches={dispatches}
            isDavisBacon={false}
          />
        )}

        <div className='totals-section'>
          <div className='totals-minutes-column'>
            <table>
              <thead>
                <tr>
                  <th colSpan={2} style={{ padding: '4px 8px' }}>
                    Hours Totals
                  </th>
                </tr>
              </thead>
              <tbody>
                {datesOfWeek.map(date => {
                  const installerDate = installerDates?.find(id => id.date === date?.toString())
                  if ((date?.dayOfWeek === 6 || date?.dayOfWeek === 7) && !installerDate?.minutesPayable) {
                    return null
                  }
                  return (
                    <tr key={date?.toString()}>
                      <td>{date?.toLocaleString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })}</td>
                      <td>
                        <HrMinCell padding={TOTALS_SECTION_PADDING} value={installerDate?.minutesPayable ?? 0} />
                      </td>
                    </tr>
                  )
                })}

                {!isHourlyInstaller ? null : (
                  <>
                    <tr className='dispatch-hours-row'>
                      <td>Dispatch Hours</td>
                      <td>
                        <HrMinCell padding={TOTALS_SECTION_PADDING} value={minutesDispatch} />
                      </td>
                    </tr>

                    <tr>
                      <td>Warehouse Hours</td>
                      <td>
                        <HrMinCell padding={TOTALS_SECTION_PADDING} value={minutesNonDispatchPayable} />
                      </td>
                    </tr>
                  </>
                )}

                {minutesManual === 0 ? null : (
                  <tr>
                    <td>Adjustment</td>
                    <td>
                      <HrMinCell padding={TOTALS_SECTION_PADDING} value={minutesAdjustment} />
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td>Total Hours</td>
                  <td>
                    <HrMinCell padding={TOTALS_SECTION_PADDING} value={minutesTotal} />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          {/* Payout Totals */}
          <div className='totals-payout-column'>
            <table>
              <thead>
                <tr>
                  <th colSpan={2} style={{ padding: '4px 8px' }}>
                    Payout Totals
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dispatches</td>
                  <td>
                    <NumberCell padding={TOTALS_SECTION_PADDING} value={payoutDispatches} prefix='$' readOnly />
                  </td>
                </tr>

                {payoutDriveWeek === 0 ? null : (
                  <tr>
                    <td>Drive</td>
                    <td>
                      <NumberCell padding={TOTALS_SECTION_PADDING} value={payoutDriveWeek} prefix='$' readOnly />
                    </td>
                  </tr>
                )}
                {payNonDispatch === 0 ? null : (
                  <tr>
                    <td>Warehouse Hrs</td>
                    <td>
                      <NumberCell padding={TOTALS_SECTION_PADDING} value={payNonDispatch} prefix='$' readOnly />
                    </td>
                  </tr>
                )}

                {payOvertime === 0 ? null : (
                  <tr>
                    <td>Overtime</td>
                    <td>
                      <NumberCell padding={TOTALS_SECTION_PADDING} value={payOvertime} prefix='$' readOnly />
                    </td>
                  </tr>
                )}

                {payAdjustments === 0 ? null : (
                  <tr>
                    <td>Adjustment</td>
                    <td>
                      <NumberCell padding={TOTALS_SECTION_PADDING} value={payAdjustments} prefix='$' readOnly />
                    </td>
                  </tr>
                )}
              </tbody>

              <tfoot>
                <tr>
                  <td>Total</td>
                  <td>
                    <NumberCell padding={TOTALS_SECTION_PADDING} value={payoutActual} prefix='$' readOnly />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </PrintableWrapper>
    </PageWrapper>
  )
}

const TOTALS_SECTION_PADDING = '2px 8px'
export default PrintPaystub

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  color: #000;
  background-color: #fff;
  overflow: auto;
  /* Reserve space for scrollbars so that sticky footer stays flush */
  scrollbar-gutter: stable;

  .num-cell-value {
    color: #111 !important;
  }
  .num-cell-prefix {
    color: #111 !important;
  }
  .num-cell-suffix {
    color: #111 !important;
  }
`
const PrintableWrapper = styled.div`
  flex: 1;
  /* border: 3px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: stretch;

  position: relative;
  padding: 24px;
  max-width: 800px;
  background-color: #fff;

  .totals-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;
    margin-bottom: 32px;

    table {
      border: 1px solid ${NEUTRAL_300};
      border-radius: 4px;
      box-sizing: border-box;

      * {
        box-sizing: border-box;
      }

      tr.dispatch-hours-row {
        td {
          border-top: 1px solid ${NEUTRAL_300};
        }
      }

      tr > td {
        &:first-child {
          padding: 2px 8px;
        }
      }

      tfoot {
        td {
          font-weight: 600;
        }
      }
    }
  }

  .material-note-column {
    min-width: 140px;
    max-width: 200px;
    white-space: normal;
    word-wrap: break-word;
  }

  .work-order-category {
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 4px;
    text-transform: uppercase;
    color: #999;
  }

  .logo-title-column {
    display: flex;
    flex: 1 1 auto;
  }

  .logo-wrapper {
    flex: 0 0 auto;
    width: 72px;
    height: 72px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-info-column {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .work-order-title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #111;
  }

  .installers-section {
    margin-bottom: 4px;
    font-size: 13px;
  }

  .sheet-properties-column {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 0 0 auto;
    width: 180px;
    font-size: 12px;
  }

  .attribute-column,
  .values-column {
    position: relative;
    display: flex;
    flex-direction: column;
    /* gap: 4px; */
    margin-left: 8px;
    flex: 0 0 auto;
    overflow: hidden;
  }

  .attribute {
    font-weight: 600;
    text-align: right;
    margin-bottom: 4px;
  }

  .value {
    text-align: left;
    margin-bottom: 4px;
  }
  .main-row-header {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .main-row-vehicles {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 0;
    margin-bottom: 8px;
  }

  .vehicle-column {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 6px 8px;
    border: 1px solid #999;
    border-radius: 4px;
  }

  .vehicle-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .vehicle-text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
  }

  .main-row-subheader {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 0 4px;
    margin-bottom: 4px;
  }

  .subheader-column {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: 200px;
    margin-right: 8px;
  }

  .subheader-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .subheader-text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  .main-row-notes {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: 0 4px;
    margin-bottom: 8px;
  }

  .notes-text {
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    min-width: 100%;
  }
`
