import { atom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useEffect, useMemo } from 'react'

import { useInstallerDispatchSelector } from './useInstallerDispatchSelector'

import type { InstallerDispatchPayoutType } from '../useDispatchPayoutQuery'

export interface InstallerProperties {
  percentPieceRate?: string
  percentAddons?: string
  minutesWorked?: number | null
  minutesDrivePayableOverride?: number | null
  isPercentPieceRateLocked?: boolean
  isPercentAddonsLocked?: boolean
  isPayrollLocked?: boolean
  isChanged?: boolean
  isHourlyPayout?: boolean
  hourlyRate?: string
  minutesDrivePayable?: string
  calculatedPayoutPieceRate?: string
  calculatedPayoutAddons?: string
  hourlyRateDrive?: string
  isCompleted?: boolean
  isApproved?: boolean
}

type InstallerPropertiesById = Record<string, InstallerProperties>
export const installerPropertiesByIdAtom = atom<InstallerPropertiesById>({})

export interface InstallerPropertiesSetterArgs {
  key: keyof InstallerProperties
  value: InstallerProperties[keyof InstallerProperties]
}

interface UseInstallerPropertiesProps {
  installerDispatchId: string
  workOrderId?: string
}

export const useInstallerProperties = ({ installerDispatchId, workOrderId }: UseInstallerPropertiesProps) => {
  const setInstallerProps = useSetAtom(installerPropertiesByIdAtom)
  const installerDispatchDatabase = useInstallerDispatchSelector({ installerDispatchId, workOrderId })
  const installerPropsForInstallerDispatchAtom = useMemo(
    () => selectAtom(installerPropertiesByIdAtom, props => props?.[installerDispatchId] ?? {}),
    [installerDispatchId]
  )

  const setInstallerAmounts = useCallback(
    ({ key, value }: InstallerPropertiesSetterArgs) => {
      setInstallerProps(prev => {
        const prevProps = prev?.[installerDispatchId] ?? {}

        if (value === undefined) {
          // Create a new object without the key if value is undefined
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [key]: _, ...restProps } = prevProps
          return { ...prev, [installerDispatchId]: restProps }
        }

        return { ...prev, [installerDispatchId]: { ...prevProps, [key]: value } }
      })
    },
    [installerDispatchId, setInstallerProps]
  )

  const installerLocalProps = useAtomValue(installerPropsForInstallerDispatchAtom)

  useEffect(() => {
    if (installerLocalProps?.percentPieceRate && installerDispatchDatabase?.percentPieceRate === installerLocalProps.percentPieceRate)
      setInstallerAmounts({ key: 'percentPieceRate', value: undefined })
  }, [installerLocalProps?.percentPieceRate, installerDispatchDatabase?.percentPieceRate, setInstallerAmounts])

  const installerDispatch: InstallerDispatchPayoutType = useMemo(
    () => ({ ...installerDispatchDatabase, ...installerLocalProps }) as InstallerDispatchPayoutType,
    [installerDispatchDatabase, installerLocalProps]
  )

  return { installerDispatch, setInstallerAmounts }
}
