import TableCellNumberTextField from '@/components/TableCellNumberTextField'

import { InstallersTableRowRenderArgs } from './columnRenderDefs'

const CellHourlyRate = ({ setInstallerAmounts, mutate, handleReturn, handleFocus, installerDispatch }: InstallersTableRowRenderArgs) => {
  const {
    hourlyRate,
    isCompleted,
    isDavisBacon,
    hourlyRatePieceRateAddons,
    isHourlyPayout,
    isApproved,
    id: installerDispatchId,
    isPayrollLocked,
  } = installerDispatch

  let isDisabled = false
  let disabledReason = ''
  let value = hourlyRate
  if (!isDavisBacon && !isHourlyPayout) {
    value = hourlyRatePieceRateAddons
    isDisabled = true
    disabledReason = 'Hourly rate is not used for Piece Rate payout mode'
    if (value && Number(value) > 0) {
      disabledReason += '. Showing calculated rate based on Piece Rate and Addons.'
    }
  } else if (isPayrollLocked) {
    isDisabled = true
    disabledReason = 'Payroll has been completed for the week.'
  } else if (isCompleted || isApproved) {
    isDisabled = true
    disabledReason = 'This line has been ' + (isCompleted ? 'completed.' : 'approved.')
  }

  return (
    <TableCellNumberTextField
      isCompact
      isDisabled={isDisabled}
      value={value ?? undefined}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInstallerAmounts({ key: 'hourlyRate', value: e.target.value })}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value).toFixed(2)
        setInstallerAmounts({ key: 'hourlyRate', value })
        mutate({ installerDispatchId, attribute: 'hourlyRate', value })
      }}
      onKeyDown={handleReturn}
      onFocus={handleFocus}
      tooltip={disabledReason}
      suffix='/ hr'
      prefix='$'
    />
  )
}

export default CellHourlyRate
