import { useQuery } from '@tanstack/react-query'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { CoordinatesType, PinDropType } from '@/types/project-site'

import { GetProjectSiteForJobQuery } from '@/gql/graphql'

import { FormDataType, PlaceType } from '../types'

// Query to get the project site data for a job
const GET_PROJECT_SITE_QUERY = graphql(/* GraphQL */ `
  query GetProjectSiteForJob($jobId: ID!) {
    job(pk: $jobId) {
      id
      projectSite {
        id
        name
        streetAddress
        city
        state
        zipCode
        cityStateZip

        pinDrop {
          id
          latitude
          longitude
          createdAt
        }

        primaryPlace {
          id
          isPrimary
          googlePlaceId
          displayName
          formattedAddress
          googleMapsUri
          websiteUri
          latitude
          longitude
          formattedPhoneNumber
          streetNumber
          route
          state
          county
          city
          zip
          country
        }

        places {
          id
          isPrimary
          googlePlaceId
          displayName
          formattedAddress
          googleMapsUri
          websiteUri
          latitude
          longitude
          formattedPhoneNumber
          streetNumber
          route
          state
          county
          city
          zip
          country
        }
      }
    }
  }
`)

export const PROJECT_SITE_QUERY_KEY = 'projectSite'

// Format the project site data for the form
const formatProjectSiteData = (data: GetProjectSiteForJobQuery | undefined): FormDataType => {
  const projectSite = data?.job?.projectSite
  const places = projectSite?.places || []

  // Extract the primary place ID
  const primaryPlace = places.find(place => place.isPrimary) || null
  const primaryPlaceId = primaryPlace?.id || null

  // Check if there's pin drop data
  const pinDrop = projectSite?.pinDrop || null

  // Format places for the form
  const formattedPlaces = places.map(
    (place): PlaceType => ({
      ...place,
      // Convert latitude/longitude to numbers for the map component
      lat: place.latitude ? parseFloat(place.latitude) : null,
      lng: place.longitude ? parseFloat(place.longitude) : null,
    })
  )

  // If we have a pin drop, extract coordinates
  const coordinates: CoordinatesType | null = pinDrop
    ? {
        lat: parseFloat(String(pinDrop.latitude)),
        lng: parseFloat(String(pinDrop.longitude)),
      }
    : null

  return {
    projectSite: {
      name: projectSite?.name || '',
      streetAddress: projectSite?.streetAddress || '',
      city: projectSite?.city || '',
      state: projectSite?.state || '',
      zipCode: projectSite?.zipCode || '',
      pinDrop: pinDrop as PinDropType | null,
    },
    places: formattedPlaces,
    primaryPlaceId,
    deletedPlaceIds: [],
    // If we have pin drop coordinates, we're in pin mode
    coordinates,
    isPinMode: !!coordinates,
    pinDrop: pinDrop as PinDropType | null,
  }
}

export const useProjectSiteData = (jobId: string) => {
  const graphQLClient = useGraphQLClient()

  const query = useQuery({
    queryKey: [PROJECT_SITE_QUERY_KEY, jobId],
    queryFn: async () => {
      const response = await graphQLClient.request(GET_PROJECT_SITE_QUERY, { jobId })
      return response as GetProjectSiteForJobQuery
    },
    enabled: !!jobId,
  })

  const data = formatProjectSiteData(query.data)

  return {
    data,
    projectSite: data.projectSite,
    places: data.places,
    primaryPlaceId: data.primaryPlaceId,
    coordinates: data.coordinates,
    isPinMode: data.isPinMode,
    pinDrop: data.pinDrop,
    isLoading: query.isLoading,
    error: query.error,
    refetch: query.refetch,
  }
}
