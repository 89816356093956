import { useState } from 'react'

import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down'

import { IconButton } from '@atlaskit/button/new'
import { token } from '@atlaskit/tokens'

import { getNegativeCellStyle } from '@/utils/utilities'

import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'

import NumberCell from '@/components/NumberCell'

import { useReconSearchParams } from '../../useReconSearchParams'
import { CELL_PAD } from '../constants'

import WorkOrderCell from './WorkOrderCell'
import WorkOrderDispatchRow from './WorkOrderDispatchRow'

interface DispatchTableTopHalfProps {
  workOrder?: GetDispatchPayoutDetailsQuery['workOrder']
  dateString: string
  isDavisBacon: boolean
}

const DispatchTableTopHalf = ({ workOrder, dateString, isDavisBacon }: DispatchTableTopHalfProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatches = (workOrder?.dispatches ?? []).sort((a, b) => a.date.localeCompare(b.date))
  const { paramValues } = useReconSearchParams()

  const projectedDrivePay = workOrder?.projectedDrivePay ?? 0
  const projectedPieceRate = workOrder?.projectedPieceRate ?? 0
  const projectedAddons = workOrder?.projectedAddons ?? 0
  const projectedTotal = workOrder?.projectedLaborTotal ?? 0

  const totalEnteredPieceRate = workOrder?.totalEnteredPieceRate ?? 0
  const totalEnteredDrivePay = workOrder?.totalEnteredDrivePay ?? 0
  const totalEnteredAddons = workOrder?.totalEnteredAddons ?? 0
  const totalEnteredLaborTotal = (Number(totalEnteredDrivePay) + Number(totalEnteredPieceRate) + Number(totalEnteredAddons)).toFixed(2)

  const remainingDrivePay = Number(projectedDrivePay) - Number(totalEnteredDrivePay)
  const remainingPieceRate = Number(projectedPieceRate) - Number(totalEnteredPieceRate)
  const remainingAddons = Number(projectedAddons) - Number(totalEnteredAddons)
  const remainingLaborTotal = Number(projectedTotal) - Number(totalEnteredLaborTotal)

  const multiDispatch = dispatches.length > 1
  return (
    <>
      <tr>
        <td rowSpan={isOpen ? 3 + dispatches.length : 3} className='col0 workOrderCell'>
          <WorkOrderCell workOrder={workOrder} />
        </td>

        <td className='col-row-labels'>Projected</td>

        {isDavisBacon ? <td className='dispatch-col-hourly' /> : <td className='dispatch-col-hourly' />}

        {isDavisBacon ? <td className='dispatch-col-pr-gap' /> : null}

        <td className='dispatch-col-drive'>
          <NumberCell padding={CELL_PAD} readOnly value={projectedDrivePay} prefix='$' />
        </td>

        <td className='dispatch-col-piece-rate'>
          <NumberCell padding={CELL_PAD} readOnly value={projectedPieceRate} prefix='$' />
        </td>

        <td className='dispatch-col-addons'>
          <NumberCell padding={CELL_PAD} readOnly value={projectedAddons} prefix='$' />
        </td>

        <td className='dispatch-col-total'>
          <NumberCell padding={CELL_PAD} readOnly value={projectedTotal} prefix='$' />
        </td>
      </tr>

      <tr>
        <td className='col-row-labels'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 2 }}>Paid Out</span>
            <div
              className='paid-out-reveal-button'
              style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)', transition: 'transform 0.25s ease-in-out' }}
            >
              {multiDispatch && (
                <IconButton
                  icon={iconProps => <ChevronDownIcon {...iconProps} color={token('color.icon.subtlest')} />}
                  label='Show All Dispatches'
                  onClick={() => setIsOpen(prev => !prev)}
                  appearance='subtle'
                  isTooltipDisabled={isOpen}
                  shape='circle'
                />
              )}
            </div>
          </div>
        </td>

        {isDavisBacon ? <td className='dispatch-col-hourly' /> : <td className='dispatch-col-hourly' />}

        {isDavisBacon ? <td className='dispatch-col-pr-gap' /> : null}

        <td className='dispatch-col-drive'>
          <NumberCell padding={CELL_PAD} readOnly value={totalEnteredDrivePay} prefix='$' />
        </td>

        <td className='dispatch-col-piece-rate'>
          <NumberCell padding={CELL_PAD} readOnly value={totalEnteredPieceRate} prefix='$' />
        </td>

        <td className='dispatch-col-addons'>
          <NumberCell padding={CELL_PAD} readOnly value={totalEnteredAddons} prefix='$' />
        </td>

        <td className='dispatch-col-total'>
          <NumberCell padding={CELL_PAD} readOnly value={totalEnteredLaborTotal} prefix='$' />
        </td>
      </tr>

      {!isOpen
        ? null
        : dispatches.map((dispatch, ind) => (
            <WorkOrderDispatchRow
              key={dispatch.date}
              dispatch={dispatch}
              dateString={dateString}
              paramValues={paramValues}
              isDavisBacon={isDavisBacon}
              isLast={ind === dispatches.length - 1}
            />
          ))}

      <tr>
        <td className='col-row-labels'>Remaining</td>

        {isDavisBacon ? <td className='dispatch-col-hourly' /> : <td className='dispatch-col-hourly' />}

        {isDavisBacon ? <td className='dispatch-col-pr-gap' /> : null}

        <td className='dispatch-col-drive' style={getNegativeCellStyle(remainingDrivePay)}>
          <NumberCell padding={CELL_PAD} readOnly value={remainingDrivePay} prefix='$' />
        </td>

        <td className='dispatch-col-piece-rate' style={getNegativeCellStyle(remainingPieceRate)}>
          <NumberCell padding={CELL_PAD} readOnly value={remainingPieceRate} prefix='$' />
        </td>

        <td className='dispatch-col-addons' style={getNegativeCellStyle(remainingAddons)}>
          <NumberCell padding={CELL_PAD} readOnly value={remainingAddons} prefix='$' />
        </td>

        <td className='dispatch-col-total' style={getNegativeCellStyle(remainingLaborTotal)}>
          <NumberCell padding={CELL_PAD} readOnly value={remainingLaborTotal} prefix='$' />
        </td>
      </tr>
    </>
  )
}

export default DispatchTableTopHalf
