import { useEffect, useState } from 'react'

// Custom hook to detect when the component becomes visible
export const useIsVisible = (ref: React.RefObject<HTMLElement>) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const currentRef = ref.current
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting)
    })

    if (ref.current) observer.observe(ref.current)

    return () => {
      if (currentRef) observer.unobserve(currentRef)
    }
  }, [ref])

  return isVisible
}
