import { Component, ErrorInfo, ReactNode } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'
import { error } from 'console'

interface ErrorBoundaryProps {
  children: ReactNode
  customDisplayMessage?: string
  customContainer?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  errorMessage: string
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, errorMessage: '' }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI.
    return { hasError: true, errorMessage: error.message }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can log the error to an error reporting service here
    console.error('ErrorBoundary caught an error', error, errorInfo)
    // Update state with error message
    this.setState({
      hasError: true,
      errorMessage: error.message,
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI here
      const message = (
        <ErrorMessageWrapper>
          <h4>{this.props.customDisplayMessage ?? 'Something went wrong.'}</h4>
          <p>Message: {this.state.errorMessage}</p>
        </ErrorMessageWrapper>
      )
      if (this.props.customContainer) {
        // Check if customContainer is a valid React component
        if (
          typeof this.props.customContainer === 'function' ||
          (typeof this.props.customContainer === 'object' && this.props.customContainer !== null)
        ) {
          const CustomContainer = this.props.customContainer as React.ElementType
          return <CustomContainer>{message}</CustomContainer>
        }
      }
      return message
    }

    return this.props.children
  }
}

export default ErrorBoundary

const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  margin: 0;
  border: 1px solid ${token('color.border.danger')};
  background: ${token('color.background.danger')};
  color: ${token('color.text.danger')};
  min-height: 24px;
  min-width: 60px;
  overflow: auto;
  width: 100%;
  /* height: 100%; */
  padding: 8px 16px;
  flex: 0 0 auto;

  > p {
    font-size: 10px;
    padding-bottom: 8px;
  }
`
