import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { assignedVehicleDispatchIdsByInstallerIdAtom as assignmentIdsAtom } from '../../dispatchTypes'

export const useSelectedInstallerDispatches = (installerId: string) => {
  const installerDispatchesAtom = useMemo(() => selectAtom(assignmentIdsAtom, byInstallerIds => byInstallerIds[installerId]), [installerId])
  return useAtomValue(installerDispatchesAtom)
}
