import HoursMinutesInput from '@/components/HoursMinutesInput'

import { useParamsWeek } from '../hooks/useParamsWeek'
import { useInstallerDate } from '../hooks/useReconPayrollSelectors'
import { useSubmitInstallerPayroll } from '../hooks/useSubmitInstallerPayroll'
import { InstallerDatesLookupKey } from '../reconPayrollTypes'

interface EditTimecardCellProps {
  dayIndex: InstallerDatesLookupKey
  installerId: string
  isDisabled?: boolean
  fieldName: string
}

const EditTimecardCell = ({ dayIndex, installerId, fieldName, isDisabled }: EditTimecardCellProps) => {
  const { weekOf: weekOfDate } = useParamsWeek()

  const installerDate = useInstallerDate(installerId, dayIndex)
  const databaseValue = installerDate?.[fieldName as keyof typeof installerDate] as number | null
  const date = weekOfDate.add({ days: parseInt(dayIndex) - 1 }).toString()

  const minutes = databaseValue && !isNaN(databaseValue) ? databaseValue : null
  const { mutate, isPending } = useSubmitInstallerPayroll()

  return <HoursMinutesInput minutes={minutes} onChangeCommit={onChangeCommit} isDisabled={isPending || isDisabled} usedWithReadOnly />

  function onChangeCommit(newMinutes: number | null) {
    const weekOf = weekOfDate.toString()
    const dateString = date.toString()
    console.log('newMinutes', { newMinutes, weekOf, dateString })
    mutate({
      weekOf,
      installerId,
      attribute: fieldName,
      value: newMinutes ? newMinutes.toString() : null,
      date: dateString,
    })
  }
}

export default EditTimecardCell
