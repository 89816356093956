import { Temporal } from '@js-temporal/polyfill'
import { AnimatePresence, motion, Reorder } from 'framer-motion'
import { useSetAtom } from 'jotai'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'

import { dateStringHoveredAtom } from './atoms'
import PrintDayDispatchesModal from './components/PrintDayDispatchesModal'
import SchedulerCard from './components/SchedulerCard'
import { useSelectedDateDispatches } from './hooks/useSelectedDateDispatches'

interface RightPanelSelectedDateProps {
  dateString: string
}

const RightPanelSelectedDate = ({ dateString }: RightPanelSelectedDateProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const itemsContainerRef = useRef<HTMLDivElement | null>(null)

  const setHoveredDateString = useSetAtom(dateStringHoveredAtom)

  const [isOverflowing, setIsOverflowing] = useState(false)

  const date = Temporal.PlainDate.from(dateString)
  const dispatches = useSelectedDateDispatches(date)

  useEffect(() => {
    const el = ref.current
    if (!el) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: el,
      getData: () => ({ dateString }),
      onDragEnter: () => setHoveredDateString(dateString),
      onDragLeave: () => setHoveredDateString(prevValue => (prevValue === dateString ? null : prevValue)),
    })
  }, [dateString, setHoveredDateString])

  useEffect(() => {
    const checkOverflow = () =>
      itemsContainerRef.current &&
      setIsOverflowing(
        itemsContainerRef.current.scrollHeight > itemsContainerRef.current.clientHeight && itemsContainerRef.current.clientHeight > 360
      )

    checkOverflow() // Initial check
    window.addEventListener('resize', checkOverflow) // Re-check on window resize

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [dispatches, setIsOverflowing])

  return (
    <SideDateWrapper ref={ref}>
      <PrintDayDispatchesModal dateString={dateString} />

      <ItemsContainer ref={itemsContainerRef}>
        <Reorder.Group values={dispatches} onReorder={() => {}} as='div'>
          {dispatches.map(calendarDispatch => {
            const key = calendarDispatch?.workOrderId ?? '' + (calendarDispatch?.dispatchId ?? '')
            if (!calendarDispatch) return null
            return (
              <Reorder.Item key={key} value={calendarDispatch} as='div' transition={{ duration: 0.12 }}>
                <SchedulerCard calendarDispatch={calendarDispatch} dateString={calendarDispatch?.dateString ?? dateString} />
              </Reorder.Item>
            )
          })}
        </Reorder.Group>
      </ItemsContainer>

      <AnimatePresence>
        {isOverflowing ? (
          <OverflowDiv
            initial={{ opacity: 0, translateY: '-100%' }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: '-100%' }}
          >
            <span>{dispatches.length} Items</span>
          </OverflowDiv>
        ) : null}
      </AnimatePresence>
    </SideDateWrapper>
  )
}

export default RightPanelSelectedDate

const SideDateWrapper = styled.div`
  position: absolute;
  inset: 0;
  overflow-y: auto;
`

const ItemsContainer = styled.div`
  width: 100%;
  flex-grow: 1; // Allows the container to grow with its content
  border-radius: 4px;
  padding-top: ${token('space.200')};
  z-index: 0;
  font-size: 12px;
  transition:
    border 0.08s,
    opacity 0.2s,
    background 0.08s;
  /* max-height: 100%; // Ensures the ItemsContainer doesn't exceed the DayCell's height */
  /* height: auto; // Ensure the ItemsContainer grows based on its content */
  min-height: 120px;
  display: flex;
  flex-direction: column;
`
const OverflowDiv = styled(motion.div)`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${token('space.050')};
  box-shadow: ${token('elevation.shadow.overflow')};
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: ${token('elevation.surface.sunken')};

  font-size: 11px;
  font-weight: 600;
  color: ${token('color.text.subtlest')};
`
