import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

import { DispatchDropZone, hoveredDropZoneAtom, itemPickedUpAtom } from '../../dispatchTypes'

interface UseIsDropZoneHoveredProps {
  zone: DispatchDropZone
  vehicleDispatchId?: string | null
  vehicleId?: string | null
}

export const useIsDropZoneHovered = ({ zone, vehicleDispatchId, vehicleId }: UseIsDropZoneHoveredProps) => {
  const hoveredDropZone = useAtomValue(hoveredDropZoneAtom)
  const selectedItemPickedUp = useAtomValue(itemPickedUpAtom)
  let isHovered = false
  let itemPickedUp = null
  if (['INSTALLER_SIDEBAR', 'DISPATCH_SIDEBAR'].includes(zone)) isHovered = true
  if (['VEHICLE_HEADER', 'VEHICLE_BODY'].includes(zone)) isHovered = hoveredDropZone.vehicleId === vehicleId
  if (zone === 'VEHICLE_DISPATCH_INSTALLERS') isHovered = hoveredDropZone.vehicleDispatchId === vehicleDispatchId
  if (isHovered || (hoveredDropZone.zone === 'VEHICLE_HEADER' && vehicleId === hoveredDropZone.vehicleId))
    itemPickedUp = selectedItemPickedUp

  return useMemo(() => ({ isHovered, itemPickedUp }), [isHovered, itemPickedUp])
}
