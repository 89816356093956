/// <reference types="@types/google.maps" />

import styled from 'styled-components'

import { Label } from '@atlaskit/form'

import AddressFields from './AddressFields'

interface ProjectSiteInputProps {
  value: string
  onChange: (value: string) => void
  errors: Record<string, { type: string }>
  autocompleteRefs: (google.maps.places.Autocomplete | null)[]
  setAutocompleteRefs: React.Dispatch<React.SetStateAction<(google.maps.places.Autocomplete | null)[]>>
  hasPinLocation: boolean
  setIsMapPinModalOpen: (open: boolean) => void
  handlePlaceSelect: (place: google.maps.places.PlaceResult, index: number) => void
  addAddressField: () => void
  removeAddressField: (index: number) => void
  clearCoordinates: () => void
}

export default function ProjectSiteInput({
  value,
  onChange,
  errors,
  autocompleteRefs,
  setAutocompleteRefs,
  hasPinLocation,
  setIsMapPinModalOpen,
  handlePlaceSelect,
  addAddressField,
  removeAddressField,
  clearCoordinates,
}: ProjectSiteInputProps) {
  return (
    <div className='field-wrapper'>
      <Label htmlFor='project-site'>Project Site</Label>

      <LocationInputContainer>
        <AddressFields
          value={value}
          onChange={onChange}
          errors={errors}
          autocompleteRefs={autocompleteRefs}
          setAutocompleteRefs={setAutocompleteRefs}
          handlePlaceSelect={handlePlaceSelect}
          addAddressField={addAddressField}
          removeAddressField={removeAddressField}
          openMapModal={() => setIsMapPinModalOpen(true)}
          hasPinLocation={hasPinLocation}
          clearCoordinates={clearCoordinates}
        />
      </LocationInputContainer>
    </div>
  )
}

const LocationInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
