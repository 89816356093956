import { Temporal } from '@js-temporal/polyfill'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router'

import { checkValidWeekNumber, checkValidYearNumber, getMondayDateFromWeekId } from '@/utils/utilities'

import { useSelectedDate } from './useSelectedDate'

type WeekYearResult = {
  weekNumber: number | null
  weekYear: number | null
  mondayDate: Temporal.PlainDate | null
  weekId: string | null
}

export const useSelectedWeekYear = (): WeekYearResult => {
  const [searchParams] = useSearchParams()
  const originalWeekId = searchParams.get('weekId')
  const selectedDate = useSelectedDate()

  return useMemo(() => {
    let weekNumber: number | null = null
    let weekYear: number | null = null
    let weekId: string | null = null
    let mondayDate: Temporal.PlainDate | null = null
    if (originalWeekId && originalWeekId.includes('-')) {
      const [weekYearStr, weekNumberStr] = originalWeekId.split('-')
      weekNumber = Number(weekNumberStr)
      weekYear = Number(weekYearStr)
    }

    if (!checkValidWeekNumber(weekNumber)) weekNumber = null
    if (!checkValidYearNumber(weekYear)) weekYear = null

    if (weekNumber === null || weekYear === null) {
      if (selectedDate.date) {
        weekNumber = selectedDate.date.weekOfYear
        weekYear = selectedDate.date.yearOfWeek
        weekId = `${weekYear}-${weekNumber}`
      }
    } else weekId = `${weekYear}-${weekNumber}`

    if (weekId) {
      mondayDate = getMondayDateFromWeekId(weekId)
    }

    return { weekNumber, weekYear, weekId, mondayDate }
  }, [originalWeekId, selectedDate])
}
