import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'

import { SingleInstallerDispatches, SingleInstallerWeeklyPayroll } from '../ReconPayrollInstaller/hooks/usePaystubQuery'

import { NEUTRAL_100, NEUTRAL_1000, NEUTRAL_200, NEUTRAL_300, NEUTRAL_400, NEUTRAL_700 } from './paystubUtils'
import PrintTableDispatchesRow from './PrintPaystubTableDispatchesRow'
import { usePrintDispatchesColumns } from './usePrintDispatchesColumns'

interface PrintTableDispatchesProps {
  installerWeeklyPayroll?: SingleInstallerWeeklyPayroll
  dispatches: SingleInstallerDispatches[]
  payoutDispatches?: number
  isDavisBacon: boolean
}

const PrintTableDispatches = ({ installerWeeklyPayroll, dispatches, isDavisBacon, payoutDispatches = 0 }: PrintTableDispatchesProps) => {
  const isHourlyInstaller = installerWeeklyPayroll?.isHourlyInstaller === true
  const columns = usePrintDispatchesColumns({ isHourlyInstaller, isDavisBacon })

  const table = useReactTable({
    data: dispatches,
    columns: columns as ColumnDef<SingleInstallerDispatches>[],
    getCoreRowModel: getCoreRowModel(),
  })

  const rows = table.getRowModel().rows

  const colCount = columns.length

  return (
    <TableWrapper $isDavisBacon={isDavisBacon}>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} style={{ textAlign: header.column.columnDef.meta?.align }}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {!rows || rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className='no-dispatches-found'>
                No dispatches found
              </td>
            </tr>
          ) : (
            rows.map((row, rowIdx) => <PrintTableDispatchesRow key={row.id} installerDispatchRow={row} rowIdx={rowIdx} />)
          )}
        </tbody>

        {isDavisBacon ? null : (
          <tfoot>
            <tr>
              <td colSpan={colCount - 2}>Dispatch Payout</td>

              <td>{isHourlyInstaller ? <HrMinCell value={installerWeeklyPayroll?.totalDispatchesMinutesWorked ?? 0} /> : null}</td>

              <td>
                <NumberCell value={payoutDispatches} readOnly prefix='$' />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </TableWrapper>
  )
}

export default PrintTableDispatches

const TableWrapper = styled.div<{ $isDavisBacon: boolean }>`
  width: 100%;
  position: relative;
  border: 1px solid ${NEUTRAL_300};
  flex: 0 0 auto;
  border-radius: 4.5px;
  margin-bottom: 24px;

  table {
    min-width: 100%;
    border-collapse: separate; // Needed for sticky positioning
    border-spacing: 0;

    box-sizing: border-box;

    th,
    td {
      padding: 6px 8px;
      border: 0;
      text-align: left;

      &:nth-child(2) {
        max-width: 120px;
      }
      &:nth-child(3) {
        max-width: 120px;
      }
      &:last-child {
        max-width: 120px;
      }
    }

    tfoot {
      tr:first-child > td {
        border-top: 2px solid ${NEUTRAL_300};
      }
      td {
        &:not(:first-child) {
          padding: 0;
          text-align: right;
        }
        font-weight: 600;
        color: ${NEUTRAL_700};
      }
    }

    .installer-paystub-row-unapproved > td {
      color: ${token('color.text.danger')};
    }

    th {
      z-index: 2;
      background: ${NEUTRAL_200};
      font-weight: 600;
      color: ${NEUTRAL_700};
      border-bottom: 2px solid ${NEUTRAL_300};
      vertical-align: bottom;
      &:first-child {
        z-index: 3;
        background: ${NEUTRAL_200};
      }
    }

    td {
      color: ${NEUTRAL_1000};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:nth-child(3) {
        padding: 2px 8px;
      }

      span.work-order-id {
        font-family: ${MONOSPACE_FONT_FAMILY} !important;
      }
    }

    .no-dispatches-found {
      text-align: left;
      color: ${NEUTRAL_400};
      font-size: 18px;
      font-weight: 600;
      padding: 24px 24px;
      background: ${NEUTRAL_100} !important;
    }

    tr.installer-paystub-row-even {
      background-color: #fff;
    }
    tr.installer-paystub-row-odd {
      background-color: #f1f2f4;
    }

    tbody > tr > td:first-child {
      font-family: ${MONOSPACE_FONT_FAMILY};
    }

    tr.installer-paystub-row-bonus > td:last-child {
      padding: 0;
    }
    tr.installer-paystub-row-bonus > td:first-child {
      text-align: right;
      font-weight: 500;
      color: ${NEUTRAL_700};
    }
  }
`
