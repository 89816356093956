/// <reference types="@types/google.maps" />

import AddIcon from '@atlaskit/icon/glyph/add'
import CrossIcon from '@atlaskit/icon/glyph/cross'

import { Autocomplete } from '@react-google-maps/api'
import { ChangeEvent, useMemo, useState } from 'react'
import styled from 'styled-components'

import Button, { IconButton } from '@atlaskit/button/new'
import Textfield from '@atlaskit/textfield'

import PinButton from './ModeToggle'

interface AddressFieldsProps {
  value: string
  onChange: (value: string) => void
  errors: Record<string, { type: string }>
  autocompleteRefs: (google.maps.places.Autocomplete | null)[]
  setAutocompleteRefs: React.Dispatch<React.SetStateAction<(google.maps.places.Autocomplete | null)[]>>
  handlePlaceSelect: (place: google.maps.places.PlaceResult, index: number) => void
  addAddressField: () => void
  removeAddressField: (index: number) => void
  openMapModal: () => void
  hasPinLocation: boolean
  clearCoordinates: () => void
}

export default function AddressFields({
  value,
  onChange,
  errors,
  autocompleteRefs,
  setAutocompleteRefs,
  handlePlaceSelect,
  addAddressField,
  removeAddressField,
  openMapModal,
  hasPinLocation,
  clearCoordinates,
}: AddressFieldsProps) {
  // State for additional addresses
  const [additionalAddresses, setAdditionalAddresses] = useState<string[]>([])

  // Check if we have a primary place selected (but not from pin)
  const hasPrimaryPlace = useMemo(() => !hasPinLocation && value.trim() !== '', [hasPinLocation, value])

  const handleChangeAdditionalAddress = (index: number, newValue: string) => {
    const updatedAddresses = [...additionalAddresses]
    updatedAddresses[index] = newValue
    setAdditionalAddresses(updatedAddresses)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <AddressFieldsContainer>
      {/* Primary Field - Changes based on selection state */}
      <AddressField>
        <InputContainer>
          {hasPinLocation ? (
            // Pin coordinates selected - Show coordinates with clear button
            <>
              <Textfield
                value={value}
                onChange={handleInputChange}
                isInvalid={!!errors?.projectSiteName}
                isReadOnly={true}
                placeholder='Click Pin to select a location on the map'
                onClick={openMapModal}
              />
              <InputAfterElement>
                <RemoveButton appearance='subtle' icon={CrossIcon} onClick={clearCoordinates} type='button' label='Clear' />
              </InputAfterElement>
            </>
          ) : (
            // No coordinates - Show address field with Google Autocomplete and pin button
            <Autocomplete
              onLoad={(autocomplete: google.maps.places.Autocomplete) => {
                const updatedRefs = [...autocompleteRefs]
                updatedRefs[0] = autocomplete
                setAutocompleteRefs(updatedRefs)
              }}
              onPlaceChanged={() => {
                if (autocompleteRefs[0]) {
                  const place = autocompleteRefs[0]?.getPlace()
                  handlePlaceSelect(place, 0)
                }
              }}
            >
              <>
                <Textfield
                  value={value}
                  onChange={handleInputChange}
                  isInvalid={!!errors?.projectSiteName}
                  placeholder='Search for a location'
                />
                <InputAfterElement>
                  {value ? (
                    <RemoveButton
                      appearance='subtle'
                      icon={CrossIcon}
                      onClick={() => {
                        onChange('')
                        clearCoordinates()
                      }}
                      type='button'
                      label='Clear'
                    />
                  ) : (
                    <PinButton openMapModal={openMapModal} />
                  )}
                </InputAfterElement>
              </>
            </Autocomplete>
          )}
        </InputContainer>
      </AddressField>

      {/* Additional Address Fields - Only shown when not in pin mode */}
      {!hasPinLocation &&
        autocompleteRefs.length > 1 &&
        autocompleteRefs.slice(1).map((_, originalIndex) => {
          const index = originalIndex + 1 // Adjust index to account for first field
          return (
            <AddressField key={index}>
              <InputContainer>
                {/* Wrap only the Textfield with Autocomplete */}
                <Autocomplete
                  onLoad={(autocomplete: google.maps.places.Autocomplete) => {
                    const updatedRefs = [...autocompleteRefs]
                    updatedRefs[index] = autocomplete
                    setAutocompleteRefs(updatedRefs)
                  }}
                  onPlaceChanged={() => {
                    if (autocompleteRefs[index]) {
                      const place = autocompleteRefs[index]?.getPlace()
                      handlePlaceSelect(place, index)
                    }
                  }}
                >
                  <Textfield
                    value={additionalAddresses[originalIndex] || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeAdditionalAddress(originalIndex, e.target.value)}
                    placeholder='Add another address'
                  />
                </Autocomplete>

                {/* Position the remove button absolutely using the existing styled component */}
                <InputAfterElement>
                  <RemoveButton
                    appearance='subtle'
                    icon={CrossIcon}
                    onClick={() => removeAddressField(index)}
                    type='button'
                    label='Remove address'
                  />
                </InputAfterElement>
              </InputContainer>
            </AddressField>
          )
        })}

      {/* Show Add Another Address button only when a primary place is selected and not in pin mode */}
      {hasPrimaryPlace && (
        <div>
          <Button appearance='subtle' onClick={addAddressField} iconBefore={AddIcon} type='button' shouldFitContainer={false}>
            Add another address
          </Button>
        </div>
      )}
    </AddressFieldsContainer>
  )
}

const AddressFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`

const AddressField = styled.div``

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`

const InputAfterElement = styled.div`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`

const RemoveButton = styled(IconButton)`
  min-width: 32px;
  height: 32px;
  padding: 0;
`
