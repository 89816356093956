/** @jsxImportSource @emotion/react */
import DocumentIcon from '@atlaskit/icon/glyph/document'
import FileIcon from '@atlaskit/icon/glyph/file'
import AudioIcon from '@atlaskit/icon/glyph/media-services/audio'
import ImageIcon from '@atlaskit/icon/glyph/media-services/image'
import VideoIcon from '@atlaskit/icon/glyph/media-services/video'

import Button from '@atlaskit/button/new'
import { easeInOut } from '@atlaskit/motion/curves'
import { durations } from '@atlaskit/motion/durations'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { dropTargetForExternal, monitorForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter'
import { containsFiles, getFiles } from '@atlaskit/pragmatic-drag-and-drop/external/file'
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled'
import { css, Global } from '@emotion/react'
import { bind } from 'bind-event-listener'
import { Fragment, memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import invariant from 'tiny-invariant'

import { token } from '@atlaskit/tokens'

const globalStyles = css({
  ':root': {
    '--grid': '8px',
    '--border-radius': '2px',
    '--border-width': '2px',
  },
})

function GlobalStyles() {
  return <Global styles={globalStyles} />
}

const galleryStyles = css({
  display: 'flex',
  width: '70vw',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'var(--grid)',
  flexWrap: 'wrap',
})
const imageStyles = css({
  display: 'block',
  // borrowing values from pinterest
  // ratio: 0.6378378378
  width: '216px',
  height: '340px',
  objectFit: 'cover',
})
const uploadStyles = css({
  // overflow: 'hidden',
  position: 'relative',
  // using these to hide the details
  borderRadius: 'calc(var(--grid) * 2)',
  overflow: 'hidden',
  transition: `opacity ${durations.large}ms ${easeInOut}, filter ${durations.large}ms ${easeInOut}`,
})
const loadingStyles = css({
  opacity: '0',
  filter: 'blur(1.5rem)',
})
const readyStyles = css({
  opacity: '1',
  filter: 'blur(0)',
})

const uploadDetailStyles = css({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  padding: 'var(--grid)',
  position: 'absolute',
  bottom: 0,
  gap: 'var(--grid)',
  flexDirection: 'row',
  // background: token('color.background.sunken', fallbackColor),
  backgroundColor: 'rgba(255,255,255,0.5)',
})

const uploadFilenameStyles = css({
  flexGrow: '1',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

type UserUpload = {
  type: string
  dataUrl: string
  name: string
  size: number
}

const Upload = memo(function Upload({ upload }: { upload: UserUpload }) {
  const [state, setState] = useState<'loading' | 'ready'>('loading')
  const clearTimeout = useRef<() => void>(() => {})

  useEffect(function mount() {
    return function unmount() {
      clearTimeout.current()
    }
  }, [])

  return (
    <div css={[uploadStyles, state === 'loading' ? loadingStyles : readyStyles]}>
      {upload.type.startsWith('image/') ? (
        <img
          src={upload.dataUrl}
          css={imageStyles}
          onLoad={() => {
            // this is the _only_ way I could find to get the animation to run
            // correctly every time in all browsers
            // setTimeout(fn, 0) -> sometimes wouldn't work in chrome (event nesting two)
            // requestAnimationFrame -> nope (event nesting two)
            // requestIdleCallback -> nope (doesn't work in safari)
            // I can find no reliable hook for applying the `ready` state,
            // this is the best I could manage 😩
            const timerId = setTimeout(() => setState('ready'), 100)
            clearTimeout.current = () => window.clearTimeout(timerId)
          }}
        />
      ) : (
        <div css={imageStyles} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {getFileIcon(upload.type)}
          <div style={{ marginTop: '8px' }}>File Preview Not Available</div>
        </div>
      )}
      <div css={uploadDetailStyles}>
        <em css={uploadFilenameStyles}>{upload.name}</em>
        <code>{Math.round(upload.size / 1000)}kB</code>
      </div>
    </div>
  )
})

const getFileIcon = (fileType: string): ReactNode => {
  if (fileType.startsWith('image/')) {
    return <ImageIcon label='' />
  } else if (fileType.startsWith('video/')) {
    return <VideoIcon label='' />
  } else if (fileType.startsWith('audio/')) {
    return <AudioIcon label='' />
  } else if (fileType.startsWith('text/') || fileType.includes('document')) {
    return <DocumentIcon label='' />
  } else {
    return <FileIcon label='' />
  }
}

const Gallery = memo(function Gallery({ uploads: uploads }: { uploads: UserUpload[] }) {
  if (!uploads.length) {
    return null
  }

  return (
    <div css={galleryStyles}>
      {uploads.map((upload, index) => (
        <Upload upload={upload} key={index} />
      ))}
    </div>
  )
})

const fileStyles = css({
  display: 'flex',
  flexDirection: 'column',
  padding: 'calc(var(--grid) * 6) calc(var(--grid) * 4)',
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'center',
  background: token('elevation.surface.sunken', '#091E4208'),
  borderRadius: 'var(--border-radius)',
  transition: `all ${durations.medium}ms ${easeInOut}`,
  border: '2px dashed transparent',
  width: '100%',
  gap: token('space.300', '24px'),
})

const textStyles = css({
  color: token('color.text.disabled', '#091E424F'),
  fontSize: '1.4rem',
  display: 'flex',
  alignItems: 'center',
  gap: token('space.075'),
})

const overStyles = css({
  background: token('color.background.selected.hovered', '#CCE0FF'),
  color: token('color.text.selected', '#0C66E4'),
  borderColor: token('color.border.brand', '#0C66E4'),
})

const potentialStyles = css({
  borderColor: token('color.border.brand', '#0C66E4'),
})

const appStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: 'calc(var(--grid) * 2)',
  flexDirection: 'column',
})

const displayNoneStyles = css({ display: 'none' })

export interface FileDropProps {
  /**
   * Accepted file types (e.g., 'image/*', 'application/pdf', etc.)
   * @default 'image/*'
   */
  acceptedFileTypes?: string

  /**
   * Function to handle files after they are dropped or selected
   */
  onFileAccepted?: (file: File) => void

  /**
   * Custom text to display in the drop area
   * @default 'Drop files here'
   */
  dropzoneText?: string

  /**
   * Custom button text
   * @default 'Select files'
   */
  buttonText?: string

  /**
   * Whether to show the gallery of uploaded files
   * @default true
   */
  showGallery?: boolean

  /**
   * Whether to allow multiple file selection
   * @default true
   */
  multiple?: boolean

  /**
   * Custom icon to display in the drop area
   */
  customIcon?: ReactNode
}

const Uploader = ({
  acceptedFileTypes = 'image/*',
  onFileAccepted,
  dropzoneText = 'Drop files here',
  buttonText = 'Select files',
  showGallery = true,
  multiple = true,
  customIcon,
}: FileDropProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [state, setState] = useState<'idle' | 'potential' | 'over'>('idle')
  const [uploads, setUploads] = useState<UserUpload[]>([])

  /**
   * Creating a stable reference so that we can use it in our unmount effect.
   *
   * If we used uploads as a dependency in the second `useEffect` it would run
   * every time the uploads changed, which is not desirable.
   */
  const stableUploadsRef = useRef<UserUpload[]>(uploads)
  useEffect(() => {
    stableUploadsRef.current = uploads
  }, [uploads])

  useEffect(() => {
    return () => {
      /**
       * MDN recommends explicitly releasing the object URLs when possible,
       * instead of relying just on the browser's garbage collection.
       */
      stableUploadsRef.current.forEach(upload => {
        URL.revokeObjectURL(upload.dataUrl)
      })
    }
  }, [])

  const isFileTypeAccepted = useCallback(
    (file: File): boolean => {
      if (!file) return false

      // Handle wildcards like 'image/*'
      if (acceptedFileTypes.includes('/*')) {
        const generalType = acceptedFileTypes.split('/')[0]
        return file.type.startsWith(`${generalType}/`)
      }

      // Handle comma-separated list of mime types
      const acceptedTypes = acceptedFileTypes.split(',').map(type => type.trim())
      return acceptedTypes.some(type => file.type === type)
    },
    [acceptedFileTypes]
  )

  const addUpload = useCallback(
    (file: File | null) => {
      if (!file) {
        return
      }

      if (!isFileTypeAccepted(file)) {
        return
      }

      // Call the handler function if provided
      if (onFileAccepted) {
        onFileAccepted(file)
      }

      const upload: UserUpload = {
        type: file.type,
        dataUrl: URL.createObjectURL(file),
        name: file.name,
        size: file.size,
      }
      setUploads(current => [...current, upload])
    },
    [isFileTypeAccepted, onFileAccepted]
  )

  const onFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.currentTarget.files ?? [])
      files.forEach(addUpload)
    },
    [addUpload]
  )

  useEffect(() => {
    const el = ref.current
    invariant(el)
    return combine(
      dropTargetForExternal({
        element: el,
        canDrop: containsFiles,
        onDragEnter: () => setState('over'),
        onDragLeave: () => setState('potential'),
        onDrop: async ({ source }) => {
          const files = await getFiles({ source })

          files.forEach(file => {
            if (file == null) {
              return
            }
            if (!isFileTypeAccepted(file)) {
              return
            }

            // Call the handler function if provided
            if (onFileAccepted) {
              onFileAccepted(file)
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)

            // for simplicity:
            // - not handling errors
            // - not aborting the
            // - not unbinding the event listener when the effect is removed
            bind(reader, {
              type: 'load',
              listener() {
                const result = reader.result
                if (typeof result === 'string') {
                  const upload: UserUpload = {
                    type: file.type,
                    dataUrl: result,
                    name: file.name,
                    size: file.size,
                  }
                  setUploads(current => [...current, upload])
                }
              },
            })
          })
        },
      }),
      monitorForExternal({
        canMonitor: containsFiles,
        onDragStart: () => {
          setState('potential')
          preventUnhandled.start()
        },
        onDrop: () => {
          setState('idle')
          preventUnhandled.stop()
        },
      })
    )
  }, [isFileTypeAccepted, onFileAccepted])

  /**
   * We trigger the file input manually when clicking the button. This also
   * works when selecting the button using a keyboard.
   *
   * We do this for two reasons:
   *
   * 1. Styling file inputs is very limited.
   * 2. Associating the button as a label for the input only gives us pointer
   *    support, but does not work for keyboard.
   */
  const inputRef = useRef<HTMLInputElement>(null)
  const onInputTriggerClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  // Determine which icon to show
  const iconToShow = customIcon || getFileTypeIcon(acceptedFileTypes)

  return (
    <div css={appStyles}>
      <div
        ref={ref}
        data-testid='drop-target'
        css={[fileStyles, state === 'over' ? overStyles : state === 'potential' ? potentialStyles : undefined]}
      >
        <strong css={textStyles}>
          {dropzoneText} {iconToShow}
        </strong>

        <Button onClick={onInputTriggerClick}>{buttonText}</Button>

        <input
          ref={inputRef}
          css={displayNoneStyles}
          id='file-input'
          onChange={onFileInputChange}
          type='file'
          accept={acceptedFileTypes}
          multiple={multiple}
        />
      </div>
      {showGallery && <Gallery uploads={uploads} />}
    </div>
  )
}

const getFileTypeIcon = (acceptedTypes: string): ReactNode => {
  if (acceptedTypes.includes('image/')) {
    return <ImageIcon label='' />
  } else if (acceptedTypes.includes('video/')) {
    return <VideoIcon label='' />
  } else if (acceptedTypes.includes('audio/')) {
    return <AudioIcon label='' />
  } else if (acceptedTypes.includes('text/') || acceptedTypes.includes('application/pdf')) {
    return <DocumentIcon label='' />
  } else {
    return <FileIcon label='' />
  }
}

export default function Example() {
  const handleFileAccepted = (file: File) => {
    console.log('File accepted:', file.name, file.type, file.size)
    // Here you would typically process the file, e.g., upload to a server
  }

  return (
    <Fragment>
      <GlobalStyles />
      <div style={{ marginBottom: '40px' }}>
        <h2>Default Image Uploader</h2>
        <Uploader />
      </div>

      <div style={{ marginBottom: '40px' }}>
        <h2>PDF Uploader with Custom Handler</h2>
        <Uploader
          acceptedFileTypes='application/pdf'
          onFileAccepted={handleFileAccepted}
          dropzoneText='Drop PDF files here'
          buttonText='Select PDF'
          multiple={false}
        />
      </div>

      <div>
        <h2>Multi-type Document Uploader</h2>
        <Uploader
          acceptedFileTypes='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain'
          onFileAccepted={handleFileAccepted}
          dropzoneText='Drop documents here'
          buttonText='Select Documents'
        />
      </div>
    </Fragment>
  )
}

// Export the Uploader component for reuse
export { Uploader as FileDropZone }
