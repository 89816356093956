import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'

import { graphql } from '@/gql'
import { GetCurrentUserQuery } from '@/gql/graphql'

import { useGraphQLClient } from './useGraphQLClient'

type CurrentUserType = NonNullable<GetCurrentUserQuery['currentUser']>

export const useCurrentUser = (): CurrentUserType => {
  const graphQLClient = useGraphQLClient()
  const { data } = useQuery({
    queryKey: [GET_CURRENT_USER_QUERY_KEY],
    queryFn: async () => {
      const response = await graphQLClient.request(GET_CURRENT_USER)
      if (response.currentUser) {
        const { email, username, fullName } = response.currentUser
        Sentry.setUser({ email, username, fullName })
      } else {
        Sentry.setUser(null)
      }
      return response
    },
  })
  const user: CurrentUserType =
    data?.currentUser ??
    ({
      username: '',
      id: '',
      fullName: '',
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      isInstaller: false,
      isSuperuser: false,
      isHourly: false,
      isDriver: false,
      canReconcile: false,
      canApprovePayout: false,
    } as CurrentUserType)
  return user
}

const GET_CURRENT_USER_QUERY_KEY = 'currentUser'
const GET_CURRENT_USER = graphql(/* GraphQL */ `
  query GetCurrentUser {
    currentUser {
      username
      id
      fullName
      firstName
      lastName
      email
      role
      isInstaller
      isSuperuser
      isHourly
      isDriver
      canReconcile
      canApprovePayout
    }
  }
`)
