import Link from '@atlaskit/link'

import { useReconSearchParams } from '../../useReconSearchParams'
import { SingleInstallerDispatches } from '../hooks/usePaystubQuery'

const PaystubStatusCell = ({ installerDispatch }: { installerDispatch: SingleInstallerDispatches }) => {
  const status = !installerDispatch.isCompleted ? 'Incomplete' : !installerDispatch.isApproved ? 'Unapproved' : 'Approved'
  const { paramValues } = useReconSearchParams()

  const params = new URLSearchParams(paramValues)
  const dateId = installerDispatch.vehicleDispatch.dispatch.date
  const vehicleDateId = installerDispatch.vehicleDispatch.vehicleDateId

  params.set('dateId', dateId)
  params.set('vehicleDispatchId', installerDispatch.vehicleDispatch.id)
  params.set('vehicleDateId', vehicleDateId)

  const href = `/recon/daily?${params.toString()}`
  return (
    <Link href={href} target='_blank'>
      {status}
    </Link>
  )
}

export default PaystubStatusCell
