import { ErrorMessage, Label } from '@atlaskit/form'
import Select, { ValueType } from '@atlaskit/select'

import { SelectOption } from '../hooks/useJobForm'

interface CustomerSelectorProps {
  value: SelectOption | undefined
  onChange: (value: SelectOption | undefined) => void
  options: SelectOption[]
  isDisabled: boolean
  preselectedCustomer?: SelectOption
  errors: Record<string, { type: string }>
}

export default function CustomerSelector({ value, onChange, options, isDisabled, preselectedCustomer, errors }: CustomerSelectorProps) {
  const handleChange = (option: ValueType<SelectOption>) => {
    onChange((option as SelectOption) || undefined)
  }

  return (
    <div className='field-wrapper'>
      <Label htmlFor='basic-textfield'>Customer</Label>
      <Select
        options={options}
        autoFocus={true}
        placeholder='Select Customer'
        isDisabled={isDisabled || preselectedCustomer !== undefined}
        menuPosition={'fixed'}
        defaultValue={preselectedCustomer}
        value={value}
        onChange={handleChange}
      />

      {errors?.selectedCustomer?.type === 'required' ? <ErrorMessage>Must select a customer.</ErrorMessage> : null}
    </div>
  )
}
