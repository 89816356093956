import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'

import { installerPayrollWithDateKeysByIdAtom } from '../reconPayrollAtoms'

// Create a derived atom that computes the hidden installer IDs
const hiddenInstallerIdsAtom = selectAtom(
  installerPayrollWithDateKeysByIdAtom,
  payrollByInstallerId =>
    Object.keys(payrollByInstallerId).filter(installerId => payrollByInstallerId[installerId].installerWeeklyPayroll?.isHidden === true),
  // Custom equality function to compare two arrays of installer IDs
  (prev, next) => prev.length === next.length && prev.every((value, index) => value === next[index])
)

export const useHiddenInstallerIds = () => {
  return useAtomValue(hiddenInstallerIdsAtom)
}
