import { DispatchRowType } from '../ReconPayrollInstaller/components/PaystubStatusCell'

type WorkOrder = NonNullable<DispatchRowType['vehicleDispatch']['dispatch']['workOrder']>
const SiteAddressCell = ({ workOrder }: { workOrder?: WorkOrder }) => {
  const { projectSiteStreetAddress, projectSiteCity, projectSiteState, projectSiteZipCode } = workOrder ?? {}
  const ellipsisStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', fontSize: 11, alignItems: 'stretch' }}>
      <div style={ellipsisStyle}>{projectSiteStreetAddress}</div>
      {!projectSiteCity ? null : (
        <div style={ellipsisStyle}>
          {projectSiteCity}, {projectSiteState} {projectSiteZipCode}
        </div>
      )}
    </div>
  )
}

export default SiteAddressCell
