import { Temporal } from '@js-temporal/polyfill'
import { useNavigate } from 'react-router'

import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large'
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'
import RefreshIcon from '@atlaskit/icon/glyph/refresh'

import { ButtonGroup } from '@atlaskit/button'
import { IconButton } from '@atlaskit/button/new'

import PrintDayDispatchesModal from '../../../calendar/components/PrintDayDispatchesModal'
import VehicleCreatorModal from '../../CreateVehicle/VehicleCreatorModal'
import { ViewMode } from '../../dispatchTypes'

import PrintDayLoadSheetsModal from './PrintDayLoadSheetsModal'

interface DispatchPageActionsProps {
  selectedDate: Temporal.PlainDate
  isToday: boolean
  viewMode: ViewMode
}
const DispatchPageActions = ({ selectedDate, isToday, viewMode }: DispatchPageActionsProps) => {
  const navigate = useNavigate()
  const handleBackOneDay = () => {
    navigate(`/dispatch/${viewMode}/${selectedDate.subtract({ days: 1 }).toString()}`)
  }
  const handleForwardOneDay = () => {
    navigate(`/dispatch/${viewMode}/${selectedDate.add({ days: 1 }).toString()}`)
  }
  const handleToday = () => {
    navigate(`/dispatch/${viewMode}/`)
  }
  return (
    <div>
      <ButtonGroup label='Dispatch Date'>
        <VehicleCreatorModal />
        <PrintDayLoadSheetsModal dateString={selectedDate.toString()} />
        <PrintDayDispatchesModal dateString={selectedDate.toString()} />
        <IconButton icon={ChevronLeftLargeIcon} label='Back One Day' isTooltipDisabled={false} onClick={handleBackOneDay} />
        <IconButton icon={RefreshIcon} label='Back to Today' isTooltipDisabled={false} onClick={handleToday} isDisabled={isToday} />
        <IconButton icon={ChevronRightLargeIcon} label='Forward One Day' isTooltipDisabled={false} onClick={handleForwardOneDay} />
      </ButtonGroup>
    </div>
  )
}

export default DispatchPageActions
