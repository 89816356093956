import { Temporal } from '@js-temporal/polyfill'
import Papa from 'papaparse'

export interface CsvDayHours {
  date: Temporal.PlainDate
  totalHours: number // rounded to three decimals
}

export interface CsvInstallerWeek {
  name: string
  dayHours: CsvDayHours[]
}

function parseDate(dateStr: string): Temporal.PlainDate | null {
  // Try ISO format: YYYY-MM-DD
  const isoRegex = /^\d{4}-\d{2}-\d{2}$/
  // Try MM/DD/YYYY format
  const usRegexFull = /^\d{1,2}\/\d{1,2}\/\d{4}$/
  // Try MM/DD/YY format
  const usRegexShort = /^\d{1,2}\/\d{1,2}\/\d{2}$/

  try {
    if (isoRegex.test(dateStr)) {
      return Temporal.PlainDate.from(dateStr)
    } else if (usRegexFull.test(dateStr)) {
      const parts = dateStr.split('/')
      const month = parseInt(parts[0], 10)
      const day = parseInt(parts[1], 10)
      const year = parseInt(parts[2], 10)
      return Temporal.PlainDate.from({ year, month, day })
    } else if (usRegexShort.test(dateStr)) {
      const parts = dateStr.split('/')
      const month = parseInt(parts[0], 10)
      const day = parseInt(parts[1], 10)
      let year = parseInt(parts[2], 10)
      // Assume 20xx for years less than 50, 19xx for years 50 or greater
      year = year < 50 ? 2000 + year : 1900 + year
      return Temporal.PlainDate.from({ year, month, day })
    }
  } catch (error) {
    console.error('Error parsing date:', error)
    return null
  }

  return null
}

function roundToThree(num: number): number {
  return Math.round(num * 1000) / 1000
}

export function parseHoursCsv(csvContent: string): CsvInstallerWeek[] {
  // Use PapaParse to convert CSV to an array of rows.
  const parseResult = Papa.parse<string[]>(csvContent, {
    delimiter: ',',
    skipEmptyLines: true,
  })

  if (parseResult.errors.length > 0) {
    throw new Error('Error parsing CSV: ' + parseResult.errors.map(e => e.message).join(', '))
  }

  const rows = parseResult.data
  const installerWeeks: CsvInstallerWeek[] = []

  // Process the CSV data
  let i = 0

  while (i < rows.length) {
    const row = rows[i]

    // Check if this row starts with "Hours for" which indicates the beginning of an employee's hours section
    if (row.length && row[0].startsWith('Hours for ')) {
      // Extract the installer name
      const installerName = row[0].substring('Hours for '.length).trim()
      const installerWeek: CsvInstallerWeek = { name: installerName, dayHours: [] }
      i++

      // Skip to the header row
      if (i >= rows.length) {
        throw new Error("Expected header row after 'Hours for ...'")
      }

      // Find the column indices for Date and Total hours
      const header = rows[i]
      const dateColIndex = header.findIndex(col => col.trim() === 'Date')
      const totalHoursColIndex = header.findIndex(col => col.trim() === 'Total hours')

      if (dateColIndex === -1 || totalHoursColIndex === -1) {
        throw new Error("Invalid header row. Could not find 'Date' and/or 'Total hours' columns")
      }

      i++

      // Process data rows until we hit a row that doesn't have a valid date or we reach the end
      while (i < rows.length) {
        const currentRow = rows[i]

        // Check if we've reached the end of this employee's section
        if (currentRow.length === 0 || (currentRow.length === 1 && currentRow[0].startsWith('Hours for '))) {
          break
        }

        // Skip rows that don't have enough columns
        if (currentRow.length <= Math.max(dateColIndex, totalHoursColIndex)) {
          i++
          continue
        }

        const dateStr = currentRow[dateColIndex].trim()
        const dateObj = parseDate(dateStr)

        // If we can't parse the date, this might be the end of the employee's data
        if (!dateObj) {
          i++
          continue
        }

        const totalHoursStr = currentRow[totalHoursColIndex].trim()
        const totalHoursNum = parseFloat(totalHoursStr)

        if (isNaN(totalHoursNum)) {
          i++
          continue
        }

        installerWeek.dayHours.push({
          date: dateObj,
          totalHours: roundToThree(totalHoursNum),
        })

        i++
      }

      // Only add the installer if they have day hours
      if (installerWeek.dayHours.length > 0) {
        installerWeeks.push(installerWeek)
      }
    } else {
      i++
    }
  }

  return installerWeeks
}
