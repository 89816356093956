import PrinterIcon from '@atlaskit/icon/core/printer'

import Button from '@atlaskit/button/new'
import { useCallback, useState } from 'react'

import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import PrintPaystubsForm from './PrintPaystubsForm'

interface PrintPaystubsModalProps {
  weekOf: string | null
  installerId?: string | null
}

export default function PrintPaystubsModal({ weekOf, installerId }: PrintPaystubsModalProps) {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Button onClick={() => setIsOpen(true)} iconBefore={PrinterIcon}>
        Print Paystubs
      </Button>

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <PrintPaystubsForm closeModal={closeModal} weekOf={weekOf} installerId={installerId} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}
