// ui/src/components/MiniCalendarNav/WeekRow.tsx

import React, { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

import {
  DAY_CELL_PADDING_BOTTOM,
  DAY_CELL_PADDING_TOP,
  SELECTION_BACKGROUND_ANIMATION_DURATION,
  useDateSelector,
  useWeekProps,
} from './atoms'
import { CalendarWeekWithDates, SelectionMode } from './calendarTypes'
import DayCell from './DayCell'
import IndicatorDot, { IndicatorDotTooltipRow } from './IndicatorDot'

interface WeekRowProps extends CalendarWeekWithDates {
  selectionMode: SelectionMode
  monthNumber: number
}

/**
 * Renders a single horizontal "week" row: possibly including a "week number" cell,
 * plus 7 <DayCell> columns.
 */
const WeekRow: React.FC<WeekRowProps> = ({ id, weekNumber, weekYear, dates, selectionMode, monthNumber }) => {
  const { indicators, isSelected } = useWeekProps(id)
  // If in "week" mode, highlight entire row if this is the selected week
  const isWeekSelected = Boolean(selectionMode === 'week' && isSelected)
  const selectDate = useDateSelector({ selectionMode })
  const week = useMemo(() => ({ id, weekNumber, weekYear }), [id, weekNumber, weekYear])

  const indicatorDots = useMemo(() => {
    if (!indicators || indicators.length === 0) return null
    return (
      <>
        {indicators.map((ind, idx) => (
          <IndicatorDot key={idx} color={ind.color} />
        ))}
      </>
    )
  }, [indicators])

  const tooltipContent = useMemo(() => {
    if (!indicators || indicators.length === 0) return null
    return (
      <div>
        {indicators.map((ind, idx) => (
          <TooltipRow key={idx}>
            <IndicatorDotTooltipRow value={ind.tooltipNumber} label={ind.tooltipLabel} color={ind.color} />
          </TooltipRow>
        ))}
      </div>
    )
  }, [indicators])

  const displayWeek = useMemo(() => dates[0].monthNumber === monthNumber || dates[6].monthNumber === monthNumber, [dates, monthNumber])

  return (
    <WeekRowTr $isWeekMode={selectionMode === 'week'} $isWeekSelected={isWeekSelected}>
      <td className={`week-number-cell-td ${isWeekSelected ? 'week-selected' : ''}`}>
        <Tooltip content={tooltipContent} delay={500} hideTooltipOnClick>
          <WeekNumberCell $isWeekSelected={isWeekSelected} onClick={handleWeekClick}>
            <div className='week-number-cell-inner'>
              {!displayWeek ? null : <span className='week-number'>{weekNumber}</span>}

              <div className='week-row-indicator-dots'>{!displayWeek ? null : indicatorDots}</div>
            </div>
          </WeekNumberCell>
        </Tooltip>
      </td>

      {dates.map(calendarDate => (
        <td key={calendarDate.id}>
          {displayWeek && (
            <DayCell key={calendarDate.id} week={week} date={calendarDate} selectionMode={selectionMode} isWeekSelected={isWeekSelected} />
          )}
        </td>
      ))}
    </WeekRowTr>
  )
  function handleWeekClick() {
    const firstDay = dates[0]
    const lastDay = dates[6]
    const date = firstDay.dayNumber > lastDay.dayNumber ? dates[6 - lastDay.dayNumber] : firstDay
    selectDate({ date, week: { id, weekNumber, weekYear } })
  }
}

export default WeekRow

const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const WeekRowTr = styled.tr<{ $isWeekMode: boolean; $isWeekSelected: boolean }>`
  border-radius: 3px;
  cursor: default;
  background: transparent;
  transition: background ${SELECTION_BACKGROUND_ANIMATION_DURATION}ms ease;
  padding-left: 8px;
  font-size: 12px;
  position: relative;
  overflow: hidden;

  ${({ $isWeekMode, $isWeekSelected }) =>
    !$isWeekMode
      ? null
      : `
  > td {
    ${$isWeekSelected && `background: ${token('color.background.selected')};`}
  }
  &:hover {
    cursor: pointer;

    > td {
    background: ${$isWeekSelected ? token('color.background.selected.hovered') : token('elevation.surface.hovered')};
    }
  }
  &:active {
    > td {
      background: ${$isWeekSelected ? token('color.background.selected.pressed') : token('elevation.surface.pressed')};
    }
    cursor: pointer;
  }
  `}

  .week-number-cell-td {
    background: transparent;
    transition: background ${SELECTION_BACKGROUND_ANIMATION_DURATION}ms ease;
    &.week-selected {
      background: ${token('color.background.selected.bold')};
    }
  }
`

const WeekNumberCell = styled.div<{ $isWeekSelected: boolean }>`
  padding: ${DAY_CELL_PADDING_TOP}px 0 ${DAY_CELL_PADDING_BOTTOM}px 0;

  ${({ $isWeekSelected }) => ($isWeekSelected ? 'font-weight: 600;' : '')};
  color: ${({ $isWeekSelected }) => ($isWeekSelected ? token('color.text.inverse') : token('color.text.subtlest'))};
  transition: color ${SELECTION_BACKGROUND_ANIMATION_DURATION}ms ease;
  position: relative;
  .week-number-cell-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid transparent;
    .week-number {
      transition:
        transform ${SELECTION_BACKGROUND_ANIMATION_DURATION}ms ease,
        opacity ${SELECTION_BACKGROUND_ANIMATION_DURATION}ms ease;

      transform: ${({ $isWeekSelected }) => ($isWeekSelected ? 'scale(1.1) translateY(5px)' : 'scale(1) translateY(0)')};
      transform-origin: center;
      opacity: ${({ $isWeekSelected }) => ($isWeekSelected ? 1 : 0.6)};
      ${({ $isWeekSelected }) =>
        !$isWeekSelected
          ? null
          : ` text-shadow: 0 0 2px ${token('color.background.selected.bold.pressed')}, 0 0 6px ${token('color.background.selected.bold.pressed')};
      `};
    }
  }
  .week-row-indicator-dots {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    position: absolute;
    top: 9px;
    left: -10.5px;
    bottom: 0px;
  }

  white-space: nowrap;
  width: 100%;
`
