import { useSetAtom } from 'jotai'
import { useCallback } from 'react'

import { unifiedTokenAtom } from './appAtoms'

export const useSignOut = () => {
  const setUnifiedToken = useSetAtom(unifiedTokenAtom)
  const signOut = useCallback(() => {
    setUnifiedToken({
      accessToken: '',
      accessTokenExpiration: '',
      refreshToken: '',
      refreshTokenExpiration: '',
    })
  }, [setUnifiedToken])
  return signOut
}
