import { GraphQLClient } from 'graphql-request'
import { useMemo } from 'react'

import { GRAPHQL_ENDPOINT } from './constants'
import { useAccessToken } from './useAccessToken'

export const useGraphQLClient = (): GraphQLClient | { request: () => Promise<never> } => {
  const [accessToken, isRefreshing] = useAccessToken()
  const client = useMemo(() => {
    const newClient = new GraphQLClient(GRAPHQL_ENDPOINT)
    if (isRefreshing) return { request: () => Promise.reject('Refreshing tokens') }
    if (accessToken) newClient.setHeader('Authorization', `JWT ${accessToken}`)

    return newClient
  }, [accessToken, isRefreshing])

  return client
}
