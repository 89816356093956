import { ReactElement } from 'react'

import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'

import { INSTALLER_CELL_PAD } from '../constants'
import { DispatchPayoutType } from '../useDispatchPayoutQuery'

import { InstallerColumnKey } from './constants'
import RecalcCell from './RecalcCell'

type FooterRender = (dispatch?: DispatchPayoutType) => ReactElement

export const FOOTER_DEFS: Record<InstallerColumnKey, FooterRender> = {
  vehicle: () => <div className='footer-cell-unused' style={{ position: 'absolute', inset: 0 }}></div>,
  installer: () => <span>Total Entered</span>,
  payoutMode: () => <div className='footer-cell-unused' style={{ position: 'absolute', inset: 0 }}></div>,
  minutesWorked: dispatch => <HrMinCell value={dispatch?.totalMinutesWorked} />,
  // calculatedMinutesOnSite: () => <td className='footer-empty-cell'></td>,
  // hourlyRate: () => <td className='footer-empty-cell'></td>,
  payoutHourly: dispatch => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={dispatch?.totalPaidHourly} prefix='$' monoPrefixSuffix />
  ),
  payoutDavisBacon: dispatch => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={dispatch?.totalPaidDavisBacon} prefix='$' monoPrefixSuffix />
  ),
  // minutesDriveActual: () => <td className='footer-empty-cell'></td>,
  // calculatedMinutesDriveBuffer: () => <td className='footer-empty-cell'></td>,
  // minutesDrivePayable: () => <td className='footer-empty-cell'></td>,
  // minutesDrivePayableOverride: () => <td className='footer-empty-cell'></td>,
  // hourlyRateDrive: () => <td className='footer-empty-cell'></td>,
  payoutDrive: dispatch => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={dispatch?.totalPaidDrivePay} prefix='$' monoPrefixSuffix />
  ),
  percentPieceRate: dispatch => <RecalcCell dispatch={dispatch} payType='piece_rate' />,
  // isPercentPieceRateLocked: () => <td className='footer-empty-cell'></td>,
  calculatedPayoutPieceRate: dispatch => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={dispatch?.totalPaidPieceRate} prefix='$' monoPrefixSuffix />
  ),
  payoutPieceRateDavisBaconGap: dispatch => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={dispatch?.totalPaidPieceRateDavisBaconGap} prefix='$' monoPrefixSuffix />
  ),
  // canHourlyReceiveAddons: () => <td />,
  calculatedPayoutAddons: dispatch => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={dispatch?.totalPaidAddons} prefix='$' monoPrefixSuffix />
  ),
  percentAddons: dispatch => <RecalcCell dispatch={dispatch} payType='addons' />,
  // isPercentAddonsLocked: () => <td className='footer-empty-cell'></td>,
  minutesActual: () => <div className='footer-cell-unused' style={{ position: 'absolute', inset: 0 }}></div>,

  payoutTotal: dispatch => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={dispatch?.totalPaidLaborTotal} prefix='$' monoPrefixSuffix />
  ),
  isCompleted: () => <div className='footer-cell-unused' style={{ position: 'absolute', inset: 0 }}></div>,
  isApproved: () => <div className='footer-cell-unused' style={{ position: 'absolute', inset: 0 }}></div>,
}
