import { theme, ThemeConfig } from 'antd'

import { token, useThemeObserver } from '@atlaskit/tokens'

export const useAntdTableTheme = (forPrint: boolean = false): ThemeConfig => {
  const observedTheme = useThemeObserver()
  const isDark = observedTheme.colorMode === 'dark'

  return {
    algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      colorBgContainer: token('elevation.surface'),
      colorText: token('color.text'),
      // colorSplit: token('color.border', '#f0f0f0'),
    },
    components: {
      Table: {
        bodySortBg: forPrint ? '#eee' : token('elevation.surface'),
        borderColor: forPrint ? '#ccc' : token('color.border'),
        // cellFontSize: 14,
        // cellFontSizeMD: 14,
        // cellFontSizeSM: 14,
        // cellPaddingBlock: 16,
        // cellPaddingBlockMD: 12,
        // cellPaddingBlockSM: 8,
        // cellPaddingInline: 16,
        // cellPaddingInlineMD: 8,
        // cellPaddingInlineSM: 8,
        // expandIconBg: '#ffffff',
        // filterDropdownBg: '#ffffff',
        // filterDropdownMenuBg: '#ffffff',
        // fixedHeaderSortActiveBg: '#f0f0f0',
        footerBg: token('color.background.accent.gray.subtlest'),
        footerColor: token('color.text.subtlest'),
        headerBg: forPrint ? '#ddd' : token('color.background.accent.gray.subtlest'),
        headerBorderRadius: 0,
        headerColor: forPrint ? '#000' : token('color.text.subtlest'),
        headerFilterHoverBg: 'rgba(0, 0, 0, 0.06)',
        headerSortActiveBg: token('color.background.accent.gray.subtler'),
        headerSortHoverBg: token('color.background.accent.gray.subtler'),
        headerSplitColor: token('color.border'),
        // rowExpandedBg: 'rgba(0, 0, 0, 0.02)',
        rowHoverBg: token('color.background.accent.gray.subtlest'),
        // rowSelectedBg: '#e6f4ff',
        // rowSelectedHoverBg: '#bae0ff',
        // selectionColumnWidth: '32',
        // stickyScrollBarBg: 'rgba(0, 0, 0, 0.25)',
        // stickyScrollBarBorderRadius: 100,
        ...(!forPrint
          ? {}
          : {
              cellPaddingBlock: 4,
              cellPaddingBlockMD: 4,
              cellPaddingBlockSM: 6, // this one is height
              cellPaddingInline: 16,
              cellPaddingInlineMD: 8,
              cellPaddingInlineSM: 8,
              cellFontSize: 12,
              cellFontSizeMD: 12,
              cellFontSizeSM: 12,
            }),
      },
    },
  }
}
