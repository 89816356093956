import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { databaseEstimateAtom } from '../editorAtoms'
import { QueriedEstimate } from '../editorTypes'

export const useEstimateAttribute = (key: keyof Omit<QueriedEstimate, 'workItems'>) => {
  const atomValue = useMemo(() => selectAtom(databaseEstimateAtom, estimate => estimate?.[key] ?? null), [key])
  return useAtomValue(atomValue)
}
