import { Temporal } from '@js-temporal/polyfill'
import { format } from 'date-fns'

import { token } from '@atlaskit/tokens'

import { DateString, DecimalString, UuidString } from '../gql/branded-types'

export function formatDateTimeString(dateString?: string | null): string | null {
  if (!dateString) return null
  // make sure it's a string
  if (typeof dateString !== 'string') return null
  // make sure it's a valid date
  const date = new Date(dateString)
  return format(date, 'yyyy-MM-dd HH:mm')
}

export function formatPhoneNumberString(value: string): string {
  // return nothing if no value
  if (!value) return ''

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length

  // returns: "x", "xx", "xxx"
  if (cvLength < 4) return currentValue

  // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

  // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
}

type CellNumberStringFromValueFunction = (value: number | string | null | undefined, decimalPlaces?: number, prefix?: string) => string

export const cellNumberStringFromValue: CellNumberStringFromValueFunction = (value, decimalPlaces = 0, prefix) => {
  if (value === null || value === undefined) return '-'
  let parsedValue = value
  if (typeof value !== 'number') parsedValue = parseFloat(value)
  return (
    (prefix ?? '') +
    parsedValue.toLocaleString('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
  )
}

export function formatCurrencyString(value: number | null | undefined, decimalPlaces = 2, prefix = '$'): string {
  if (value === null || value === undefined) return '-'
  let parsedValue = value
  if (typeof value !== 'number') parsedValue = parseFloat(value)
  return (
    prefix +
    parsedValue.toLocaleString('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
  )
}

export const COLOR_OPTIONS = ['blue', 'gray', 'green', 'lime', 'magenta', 'orange', 'red', 'teal', 'yellow', 'purple'] as CardColorOptions[]
export type CardColorOptions = 'blue' | 'gray' | 'green' | 'lime' | 'magenta' | 'orange' | 'red' | 'teal' | 'yellow' | 'purple'
export const TRIP_COLORS: Record<string, CardColorOptions> = {
  'Spray Foam': 'magenta',
  'Prep': 'gray',
  'Batt': 'blue',
  'Blow': 'red',
  'BIBS': 'yellow',
  'Shelving': 'green',
  'Blanket': 'orange',
}

export const onlyMondaysFilter = (date: string) => new Date(date).getDay() !== 0

export const formatNumber = (num: number | null | undefined, decimalPlaces: number): string => {
  if (num === null || num === undefined) return '-'
  let value = num
  if (typeof num !== 'number') value = Number(num)
  if (isNaN(value)) return '#N/A'
  const parts = value.toFixed(decimalPlaces).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

// Runtime validation for UUID
export function validateUuid(value: string): UuidString {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  if (!uuidRegex.test(value)) {
    throw new Error(`Invalid UUID: ${value}`)
  }
  return value as UuidString
}

// Runtime validation for Decimal
export function validateDecimal(value: string): DecimalString {
  const decimalRegex = /^\d{1,10}(\.\d{1,2})?$/
  if (!decimalRegex.test(value)) {
    throw new Error(`Invalid Decimal: ${value}`)
  }
  return value as DecimalString
}

// Runtime validation for Date
export function validateDate(value: string): DateString {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/
  if (!dateRegex.test(value)) {
    throw new Error(`Invalid Date: ${value}`)
  }
  return value as DateString
}

export function getMondayDateFromWeekId(weekId: string): Temporal.PlainDate {
  const [year, week] = weekId.split('-')
  const simpleDate = Temporal.PlainDate.from({ year: Number(year), month: 1, day: 4 })
  const isoDayOfWeek = simpleDate.dayOfWeek
  const dayToAdjust = (Number(week) - 1) * 7 + 1
  return simpleDate.add({ days: dayToAdjust - isoDayOfWeek })
}

export function checkValidYearNumber(year: number | null | undefined): boolean {
  return !!year && !isNaN(year) && year >= 1981 && year <= 2300
}

export function checkValidMonthNumber(month: number | null | undefined): boolean {
  return !!month && !isNaN(month) && month >= 1 && month <= 12
}

export function checkValidDayNumber(day: number | null | undefined): boolean {
  return !!day && !isNaN(day) && day >= 1 && day <= 31
}
export function checkValidWeekNumber(week: number | null | undefined): boolean {
  return !!week && !isNaN(week) && week >= 1 && week <= 53
}

export function titleCaseFromCamelCase(str: string): string {
  return str
    .replace(/(?<!^)([A-Z])/g, ' $1') // insert space before capital letters, except at the start
    .replace(/\b\w/g, char => char.toUpperCase())
}

export const getNegativeCellStyle = (value: number) =>
  !value || value > 0 ? {} : { color: token('color.text.danger'), fontWeight: '600', backgroundColor: token('color.background.danger') }

export const getColorOptionFromValue = (value?: string | null): CardColorOptions => {
  let colorValue = value
  if (value === null || value === '' || !COLOR_OPTIONS.includes(value as CardColorOptions)) colorValue = 'gray'

  return colorValue as CardColorOptions
}
