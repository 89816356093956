import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import Spinner from '@atlaskit/spinner'
import { token } from '@atlaskit/tokens'

import { SECTION_HEADER_HEIGHT } from '../../dispatchConstants'
import { centerSectionWidthAtom, isSavingAssignmentAtom } from '../../dispatchTypes'
import { useVisibleDateVehicles } from '../hooks/useVisibleDateVehicles'

import VehicleContainer from './VehicleContainer'

const AvailableVehicles = ({ dateString }: { dateString: string }) => {
  const { vehicles } = useVisibleDateVehicles(dateString)
  const setCenterSectionWidth = useSetAtom(centerSectionWidthAtom)
  const containerRef = useRef<HTMLDivElement>(null)

  const isSavingAssignment = useAtomValue(isSavingAssignmentAtom)
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) setCenterSectionWidth(containerRef.current.offsetWidth)
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [setCenterSectionWidth])

  return (
    <AvailableVehiclesContainer ref={containerRef} $isLoading={isSavingAssignment}>
      <div className='center-list-header'>
        <h4>Vehicles Available: {vehicles.length}</h4>
        <div className='center-list-header-loading-container'>
          <div className='center-list-header-spinner-label'>Syncing with database...</div>
          {isSavingAssignment ? <Spinner interactionName='load' label='Loading' /> : null}
        </div>
      </div>

      <div className='scrollable-list'>
        {vehicles.map(vehicle => (
          <VehicleContainer dateString={dateString} key={vehicle.id} vehicle={vehicle} />
        ))}
      </div>
    </AvailableVehiclesContainer>
  )
}

export default AvailableVehicles

interface AvailableVehiclesContainerProps {
  $isLoading: boolean
}
const AvailableVehiclesContainer = styled.div<AvailableVehiclesContainerProps>`
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  box-sizing: border-box;

  max-width: 100%;
  .center-list-header {
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${token('color.border')};
    height: ${SECTION_HEADER_HEIGHT}px;
    flex: 0 0 auto;
    background: ${token('elevation.surface')};

    h4 {
      font-weight: 500;
      color: ${token('color.text.subtle')};
    }
    .center-list-header-loading-container {
      opacity: ${props => (props.$isLoading ? 1 : 0)};
      transition: opacity 0.12s;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
    .center-list-header-spinner-label {
      font-size: 12px;
      color: ${token('color.text.subtlest')};
    }
  }

  .scrollable-list {
    overscroll-behavior: auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    flex: 1 1 auto;
    padding: 12px 24px 36px 24px;
  }
`
