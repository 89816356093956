import { useQuery } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'

import { databaseEstimateAtom, databaseEstimateWorkOrdersAtom, databaseWorkItemsAtom } from '../editorAtoms'
import { QueriedEstimate, QueriedEstimateWorkItem } from '../editorTypes'

type UseEditorDataResult = {
  refetch: () => void
  isPending: boolean
}

export const useEditorData = (estimateId?: string): UseEditorDataResult => {
  const graphQLClient = useGraphQLClient()
  const setDatabaseWorkItems = useSetAtom(databaseWorkItemsAtom)
  const setDatabaseEstimate = useSetAtom(databaseEstimateAtom)
  const setDatabaseWorkOrders = useSetAtom(databaseEstimateWorkOrdersAtom)

  const { refetch, isPending } = useQuery({
    queryKey: [GET_ESTIMATE_QUERY_KEY, { estimateId }],
    queryFn: async () => {
      console.log('API: Fetching estimate data')
      const response = await graphQLClient.request(GET_ESTIMATE_QUERY, { estimateId: estimateId ?? '' })
      console.log('API: Estimate data fetched', response)
      if (!response.editorEstimate) throw new Error('Estimate not found')

      const { prefetchedWorkItems: workItems, ...estimate } = response.editorEstimate
      let estimateWithSortOrder = estimate
      if (!estimateWithSortOrder.sortOrder || estimateWithSortOrder.sortOrder.length !== workItems.length) {
        const defaultSortOrder = workItems.map(workItem => workItem.id)
        estimateWithSortOrder = { ...estimate, sortOrder: defaultSortOrder } as QueriedEstimate
      }
      const queriedWorkItems = {} as Record<string, QueriedEstimateWorkItem>
      const items = workItems ?? []
      items.forEach(workItem => {
        queriedWorkItems[workItem.id] = workItem
      })
      setDatabaseWorkItems(queriedWorkItems)
      setDatabaseEstimate(estimateWithSortOrder)
      setDatabaseWorkOrders(estimateWithSortOrder.prefetchedWorkOrders)
      return response
    },
    enabled: !!estimateId,
    // staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: true,
  })

  return { refetch, isPending }
}

export const GET_ESTIMATE_QUERY_KEY = 'GetEstimate'
export const GET_ESTIMATE_QUERY = graphql(/* GraphQL */ `
  query GetEstimate($estimateId: ID!) {
    # estimate(pk: $estimateId) {
    editorEstimate(estimateId: $estimateId) {
      id
      sid
      jobId
      title
      startDate
      status
      materialCostTotal
      laborCostTotal
      totalCostTotal
      marginPercent
      totalPriceCalculated
      totalPriceOverride
      totalPriceFinal
      modifiedAt
      sortOrder
      isDavisBacon
      isReconLocked

      salespersonId
      salesperson {
        id
        sid
        fullName
      }

      prefetchedWorkOrders {
        id
        name
      }

      jobId
      job {
        id
        sid
        title
        status

        customerId
        customer {
          id
          sid
          workOrderNotes
          name
          businessPhone
          phoneOffice
          phoneMobile
          email
        }

        projectSiteId
        projectSite {
          id
          sid
          name

          primaryPlace {
            id
            displayName
            formattedAddress
            googleMapsUri
            city
            state
            zip
          }
        }
      }

      modifiedById
      modifiedBy {
        id
        sid
        fullName
      }

      prefetchedWorkItems {
        id
        sid
        estimateId
        quantity
        modifiedAt
        isVisibleOnQuote
        isVisibleOnWorkOrder
        materialNote
        isOverrideLocked
        materialCostCalculated
        laborCostCalculated
        laborCostOverride
        laborCostFinal
        totalCostCalculated
        containersNeededCalculated
        containersNeededOverride
        containersNeededFinal
        marginPercent
        totalPriceCalculated
        totalPriceOverride
        totalPriceFinal
        sheetsWorkArea

        workOrderId
        workOrder {
          id
          name
          isConfirmed
          isWorkCompleted
        }

        tripId
        trip {
          id
          name
        }

        workAreaId
        workArea {
          id
          name
        }

        materialCostScheduleId
        materialCostSchedule {
          id
          effectiveDate
          costPerUnit
          costPerContainer
          purchaseContainerLabel
          purchaseContainerUnitQuantity
        }

        materialId
        material {
          id
          itemCode
          displayName
          name
          unitOfMeasure
          laborCostPerUnit

          activeCostSchedule {
            id
            costPerUnit
            purchaseContainerUnitQuantity
          }
        }
      }
    }
  }
`)
