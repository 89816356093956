type AddressComponentMapping = {
  type: string
  long_name?: keyof ParsedAddressComponents
  short_name?: keyof ParsedAddressComponents
}
const COMPONENT_TYPE_MAPPINGS: Record<string, AddressComponentMapping> = {
  street_number: {
    type: 'street_number',
    long_name: 'streetNumber',
  },
  route: {
    type: 'route',
    long_name: 'route',
    short_name: 'routeShort',
  },
  street_address: {
    type: 'street_address',
    long_name: 'streetAddress',
    short_name: 'streetAddressShort',
  },
  locality: {
    type: 'locality',
    long_name: 'city',
  },
  administrative_area_level_1: {
    type: 'administrative_area_level_1',
    short_name: 'state',
  },
  administrative_area_level_2: {
    type: 'administrative_area_level_2',
    long_name: 'county',
  },
  postal_code: {
    type: 'postal_code',
    long_name: 'zip',
  },
  country: {
    type: 'country',
    short_name: 'country',
  },
  subpremise: {
    type: 'subpremise',
    short_name: 'unit',
  },
}
const COMPONENT_TYPE_MAPPINGS_LIST: AddressComponentMapping[] = Object.values(COMPONENT_TYPE_MAPPINGS)

interface ParsedAddressComponents {
  // Address components
  city: string
  country: string
  county: string
  route: string
  routeShort: string
  state: string
  streetAddress: string
  streetAddressShort: string
  streetNumber?: string
  unit?: string
  zip: string
}

interface ParsedLatLng {
  lat: number | null
  lng: number | null
}

// Type for a place input from Google Maps API
export interface PlaceInputType extends Partial<ParsedAddressComponents>, ParsedLatLng {
  displayName: string
  formattedAddress: string
  formattedPhoneNumber?: string
  googleMapsUri: string
  googlePlaceId: string
  websiteUri?: string
}

// Type for internal UI tracking flags
interface PlaceFlags {
  isNew?: boolean
}

// Extended PlaceInputType for use within forms
export interface ExtendedPlaceInputType extends PlaceInputType, PlaceFlags {}

// GraphQL PlaceType derived from the GraphQL schema
export interface PlaceType {
  __typename?: 'PlaceType'
  id: string
  city?: string | null
  country?: string | null
  county?: string | null
  createdAt?: string | null
  displayName?: string | null
  formattedAddress?: string | null
  formattedPhoneNumber?: string | null
  googleMapsUri?: string | null
  googlePlaceId?: string | null
  isPrimary: boolean
  latitude?: string | null
  longitude?: string | null
  projectSiteId?: string | null
  route?: string | null
  routeShort?: string | null
  state?: string | null
  streetAddress?: string | null
  streetAddressShort?: string | null
  streetNumber?: string | null
  unit?: string | null
  websiteUri?: string | null
  zip?: string | null

  // Add lat/lng properties as computed getters to make PlaceType compatible with PlaceInputType
  lat?: number | null
  lng?: number | null
}

// Extended PlaceType with additional UI flags and computed properties
export interface ExtendedPlaceType extends PlaceType, PlaceFlags {
  // Ensure lat/lng are available by deriving from latitude/longitude if needed
  lat: number | null
  lng: number | null
}

// Helper to convert PlaceType to ExtendedPlaceType
export const convertPlaceToExtendedPlace = (place: PlaceType): ExtendedPlaceType => {
  // Convert string latitude/longitude to number lat/lng
  const lat = place.latitude ? parseFloat(place.latitude) : null
  const lng = place.longitude ? parseFloat(place.longitude) : null

  return {
    ...place,
    lat,
    lng,
  }
}

// Type for coordinates
export type CoordinatesType = {
  lat: number
  lng: number
}

// Type for pin drop
export interface PinDropType {
  id: string
  latitude: string | number
  longitude: string | number
  createdAt: string
}

// Type for project site data
export type ProjectSiteDataType = {
  name: string
  streetAddress: string
  city: string
  state: string
  zipCode: string
  pinDrop?: PinDropType | null
}

const parsePlaceResultAddressComponents = (place: google.maps.places.PlaceResult): Partial<ParsedAddressComponents> => {
  const components: Partial<ParsedAddressComponents> = {}

  if (place.address_components) {
    place.address_components.forEach(component => {
      const types = component.types

      const mapping = COMPONENT_TYPE_MAPPINGS_LIST.find(mapping => types.includes(mapping.type))

      if (mapping) {
        if (mapping.long_name) components[mapping.long_name] = component.long_name
        if (mapping.short_name) components[mapping.short_name] = component.short_name
      }
    })
  }

  return components
}

const parsePlaceResultLatLng = (place: google.maps.places.PlaceResult): ParsedLatLng => {
  if (!place.geometry?.location) return { lat: null, lng: null }

  return {
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
  }
}

export const parsePlaceResult = (place: google.maps.places.PlaceResult): PlaceInputType => {
  const addressComponents = parsePlaceResultAddressComponents(place)
  const latLng = parsePlaceResultLatLng(place)
  const formattedAddress = place?.formatted_address ?? ''
  const formattedPhoneNumber = place?.formatted_phone_number ?? ''
  const displayName = place?.name ?? ''
  const googlePlaceId = place?.place_id ?? ''
  const googleMapsUri = place?.url ?? ''
  const websiteUri = place?.website ?? ''

  return { ...addressComponents, ...latLng, formattedAddress, formattedPhoneNumber, displayName, googlePlaceId, googleMapsUri, websiteUri }
}
