import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { itemMouseOverAtom } from '../../dispatchTypes'

export const useIsMouseOverGlobal = (installerId: string) => {
  const isMouseOverAtom = useMemo(
    () => selectAtom(itemMouseOverAtom, itemMouseOver => itemMouseOver.installerId === installerId),
    [installerId]
  )
  return useAtomValue(isMouseOverAtom)
}
