import { token } from '@atlaskit/tokens'

import { useCurrentUser } from '@/utils/useCurrentUser'
import { getColorOptionFromValue } from '@/utils/utilities'

import { useSubmitInstallerPayout } from '../useSubmitInstallerPayout'

import { COL_RENDER_DEFS, InstallersTableRowRenderArgs } from './columnRenderDefs'
import { INSTALLER_COLUMNS, InstallerColumnGroup } from './constants'
import { useInstallerProperties } from './useInstallerProps'

interface InstallersTableRowProps {
  installerDispatchId: string
  isLastOfVehicle: boolean
  isFirstOfVehicle: boolean
  vehicleInstallerCount: number
  columnGroups: InstallerColumnGroup[]
  workOrderId?: string
  shouldLockPieceRate: boolean
  shouldLockAddons: boolean
  dispatchEnteredPieceRate: string
  dispatchEnteredAddons: string
}

const InstallersTableRow = ({
  installerDispatchId,
  isLastOfVehicle = false,
  isFirstOfVehicle = true,
  vehicleInstallerCount,
  columnGroups,
  workOrderId,
  shouldLockPieceRate,
  shouldLockAddons,
  dispatchEnteredPieceRate,
  dispatchEnteredAddons,
}: InstallersTableRowProps) => {
  const { canApprovePayout } = useCurrentUser()

  const { installerDispatch, setInstallerAmounts } = useInstallerProperties({ installerDispatchId, workOrderId })

  const { mutate } = useSubmitInstallerPayout()

  const vehicleColor = getColorOptionFromValue(installerDispatch.vehicleColor)
  const vehicleBackground = !vehicleColor ? token('elevation.surface') : token(`color.background.accent.${vehicleColor}.subtlest`)
  const color = token(`color.text.accent.${vehicleColor}`)

  return (
    <tr key={installerDispatch.id}>
      {columnGroups.map((columnGroup, groupIndex) => {
        return columnGroup.columns.map((colKey, columnIndex) => {
          const isFirstInGroup = columnIndex === 0

          const colDef = INSTALLER_COLUMNS?.[colKey]
          const colRender = COL_RENDER_DEFS?.[colKey]
          const renderArgs: InstallersTableRowRenderArgs = {
            installerDispatch,
            isLastOfVehicle,
            isFirstOfVehicle,
            vehicleInstallerCount,
            vehicleBackground,
            color,
            columnGroups,
            workOrderId,
            canApprovePayout,
            shouldLockPieceRate,
            shouldLockAddons,
            dispatchEnteredPieceRate,
            dispatchEnteredAddons,
            setInstallerAmounts,
            mutate,
            handleReturn,
            handleFocus,
          }

          let className = colDef.className
          if (colDef.left !== undefined) className += ' sticky-left'
          if (colDef.right !== undefined) className += ' sticky-right'
          if (isFirstInGroup && groupIndex !== 0 && groupIndex !== 1 && groupIndex !== columnGroups.length - 1)
            className += ' thick-left-border'

          if (!colRender)
            return (
              <td key={colKey} className={className}>
                ERR
              </td>
            )

          const rowSpan = ['isCompleted', 'isApproved', 'vehicle'].includes(colKey) ? vehicleInstallerCount : 1
          if (rowSpan > 1 && !isFirstOfVehicle) return null
          return (
            <td key={colKey} rowSpan={rowSpan} className={className}>
              {colRender(renderArgs)}
            </td>
          )
        })
      })}
    </tr>
  )

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    setTimeout(() => e.target.select(), 1) // Delay until the next event loop cycle
  }

  function handleReturn(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') (e.target as HTMLInputElement).blur()
  }
}

export default InstallersTableRow
