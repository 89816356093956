import { useMemo } from 'react'
import { useLocation } from 'react-router'

import MiniCalendarNav from '@/components/MiniCalendarNav'

import { useReconMonthStats } from './ReconCalendar/useReconMonthStats'

export const ReconCalendar = () => {
  const { weekIndicatorsByWeekId, dateIndicatorsByDateId } = useReconMonthStats()
  const selectionMode = useReconSelectionMode()

  return (
    <MiniCalendarNav
      selectionMode={selectionMode}
      weekIndicatorsByWeekId={weekIndicatorsByWeekId}
      dateIndicatorsByDateId={dateIndicatorsByDateId}
    />
  )
}

export const useReconSelectionMode = () => {
  const location = useLocation()
  const pathname = location.pathname
  return useMemo(() => (pathname.includes('installer-paystubs') || pathname.includes('payroll') ? 'week' : 'date'), [pathname])
}
