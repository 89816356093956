import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'

export const useTripsQuery = () => {
  const graphQLClient = useGraphQLClient()

  const { data, error, status } = useQuery({
    queryKey: [GET_TRIPS_QUERY_KEY],
    queryFn: () => graphQLClient.request(GET_TRIPS),
    refetchInterval: 1000 * 60 * 60, // 60 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  })

  const trips = useMemo(() => {
    const sortedTrips = [...(data?.trips ?? [])].sort((a, b) => a.name.localeCompare(b.name))
    return sortedTrips
  }, [data?.trips])

  console.log(
    'trips',
    trips.map(trip => trip.name)
  )
  return { trips, error, status }
}

export const GET_TRIPS_QUERY_KEY = 'trips'
export const GET_TRIPS = graphql(/* GraphQL */ `
  query GetTrips {
    trips {
      id
      isEnabled
      name
    }
  }
`)

export const TRIPS_TO_IGNORE = ['Batt Prep', 'BIBS Prep', 'Blow Prep', 'Spray Foam Prep']
