import { useState } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import Heading from '@atlaskit/heading'

import { formatDateTimeString } from '@/utils/utilities'

import AppRightSidebarAttributeRow from '@/components/AppRightSidebarAttributeRow'
import EditProjectSiteModal from '@/components/EditProjectSite'

import { GetJobQuery } from '@/gql/graphql'

import { GET_JOB_QUERY_KEY } from './JobPage'

type JobType = GetJobQuery['job']
type PlaceType = NonNullable<JobType['projectSite']>['primaryPlace']
interface JobPageRightSidebarProps {
  job?: JobType | null
}

const JobPageRightSidebar = ({ job }: JobPageRightSidebarProps) => {
  const projectSite = job?.projectSite ?? null
  const primaryPlace = projectSite?.primaryPlace ?? null
  const additionalPlaces = projectSite?.places?.filter(place => !place.isPrimary) ?? []
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  return (
    <div>
      <Heading size='small'>Project Site</Heading>
      <AttributesSection>
        <div>
          {job?.id && (
            <EditProjectSiteModal
              jobId={job.id}
              isOpen={isEditModalOpen}
              onClose={() => setIsEditModalOpen(false)}
              queriesToRefetchOnUpdate={[GET_JOB_QUERY_KEY]}
            >
              <SiteNameButton onClick={() => setIsEditModalOpen(true)}>{projectSite?.name ?? 'TBD'}</SiteNameButton>
            </EditProjectSiteModal>
          )}
        </div>
        {projectSite && (
          <>
            <AppRightSidebarAttributeRow label={'Street Address'} value={projectSite.streetAddress || ''} />
            <AppRightSidebarAttributeRow label={'City, State, ZIP'} value={projectSite.cityStateZip || ''} />
            <AppRightSidebarAttributeRow label={'City'} value={projectSite.city || ''} />
            <AppRightSidebarAttributeRow label={'State'} value={projectSite.state || ''} />
            <AppRightSidebarAttributeRow label={'Zip Code'} value={projectSite.zipCode || ''} />
          </>
        )}
      </AttributesSection>

      <Heading size='small'>Primary Location</Heading>
      <AttributesSection>
        {primaryPlace ? (
          <PlaceDetails place={primaryPlace} />
        ) : (
          <NoPlaceMessage>No primary location has been set for this project site.</NoPlaceMessage>
        )}
      </AttributesSection>

      <Heading size='small'>Additional Locations</Heading>
      <AttributesSection>
        {additionalPlaces.length > 0 ? (
          additionalPlaces.map((place, index) => (
            <div key={place.id || index}>
              {index > 0 && <DetailsDivider />}
              <SubHeading>{place.displayName || `Location ${index + 1}`}</SubHeading>
              <PlaceDetails place={place} />
            </div>
          ))
        ) : (
          <NoPlaceMessage>No additional locations have been added to this project site.</NoPlaceMessage>
        )}
      </AttributesSection>

      <Heading size='small'>Job Details</Heading>
      <AttributesSection>
        <AppRightSidebarAttributeRow label={'Customer'} value={job?.customer?.name} />
        <AppRightSidebarAttributeRow label={'Created By'} value={job?.createdBy?.fullName ?? null} />
        <AppRightSidebarAttributeRow label={'Created At'} value={formatDateTimeString(job?.createdAt)} />
      </AttributesSection>
    </div>
  )
}

interface PlaceDetailsProps {
  place: PlaceType
}

const PlaceDetails = ({ place }: PlaceDetailsProps) => {
  if (!place) return null

  return (
    <>
      <AppRightSidebarAttributeRow label={'Location Type'} value={place.googlePlaceId ? 'Google Place' : 'Map Pin'} />
      {place.displayName && <AppRightSidebarAttributeRow label={'Display Name'} value={place.displayName} />}
      {place.formattedAddress && <AppRightSidebarAttributeRow label={'Formatted Address'} value={place.formattedAddress} />}
      {(place.latitude || place.longitude) && (
        <AppRightSidebarAttributeRow label={'Coordinates'} value={`${place.latitude ?? 'N/A'}, ${place.longitude ?? 'N/A'}`} />
      )}
      {place.formattedPhoneNumber && <AppRightSidebarAttributeRow label={'Phone'} value={place.formattedPhoneNumber} />}
      {place.websiteUri && (
        <AppRightSidebarAttributeRow
          label={'Website'}
          value={
            <a href={place.websiteUri} target='_blank' rel='noopener noreferrer'>
              {place.websiteUri}
            </a>
          }
        />
      )}
      {place.googleMapsUri && (
        <AppRightSidebarAttributeRow
          label={'Google Maps'}
          value={
            <a href={place.googleMapsUri} target='_blank' rel='noopener noreferrer'>
              View on Google Maps
            </a>
          }
        />
      )}
      <DetailsDivider />
      {place.streetNumber && <AppRightSidebarAttributeRow label={'Street Number'} value={place.streetNumber} />}
      {place.route && <AppRightSidebarAttributeRow label={'Route'} value={place.route} />}
      {place.city && <AppRightSidebarAttributeRow label={'City'} value={place.city} />}
      {place.county && <AppRightSidebarAttributeRow label={'County'} value={place.county} />}
      {place.state && <AppRightSidebarAttributeRow label={'State'} value={place.state} />}
      {place.zip && <AppRightSidebarAttributeRow label={'Zip'} value={place.zip} />}
      {place.country && <AppRightSidebarAttributeRow label={'Country'} value={place.country} />}
    </>
  )
}

export default JobPageRightSidebar

const AttributesSection = styled.div`
  margin-bottom: 32px;
  margin-top: 8px;
`

const SubHeading = styled.h6`
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 600;
`

const DetailsDivider = styled.div`
  height: 1px;
  background-color: #e6e6e6;
  margin: 8px 0;
`

const NoPlaceMessage = styled.p`
  font-style: italic;
  color: #6b778c;
  margin: 4px 0;
`

const SiteNameButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.003em;
  line-height: 16px;
  padding: 4px 0;
  color: #0052cc;
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
`
