import { useAtom } from 'jotai'
import { useCallback, useRef } from 'react'
import styled from 'styled-components'

import TableIcon from '@atlaskit/icon/glyph/table'

import Button, { IconButton } from '@atlaskit/button/new'
import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import type { Vehicle } from '@/utils/queryHooks/useVehiclesQuery'
import { useIsModifierPressed } from '@/utils/useIsModifierPressed'
import { type CardColorOptions } from '@/utils/utilities'

import PickupTruckSvg from '@/components/PickupTruckSvg'
import TruckIcon from '@/components/TruckIcon'

import { isLoadSheetVisibleAtom } from '../../dispatchTypes'
import { useDropZoneVehicleContainerHeader, useIsVehicleHeaderHoveredByInstaller } from '../hooks/useDropZoneVehicleContainerHeader'
import { useSelectedVehicleDispatchIds } from '../selectors/useSelectedVehicleDispatchIds'

interface VehicleContainerHeaderProps {
  vehicle: Vehicle
  dateString: string
  color: CardColorOptions
}

const VehicleContainerHeader = ({ vehicle, dateString, color }: VehicleContainerHeaderProps) => {
  const vehicleHeaderRef = useRef<HTMLDivElement | null>(null)
  useDropZoneVehicleContainerHeader({ vehicle, vehicleHeaderRef })

  const [showLoadSheet, setShowLoadSheet] = useAtom(isLoadSheetVisibleAtom)
  const isLoadSheetVisible = showLoadSheet?.[vehicle.id] ?? false
  const vehicleId = vehicle.id
  const isHeaderHovered = useIsVehicleHeaderHoveredByInstaller(vehicle.id)

  const { vehicleDispatchIds, clearVehicle } = useSelectedVehicleDispatchIds(vehicleId, dateString)
  const noDispatches = vehicleDispatchIds.length === 0

  const submitClearVehicle = useCallback(() => {
    clearVehicle(vehicleId, dateString)
  }, [clearVehicle, vehicleId, dateString])

  const isHoldingOptionKey = useIsModifierPressed('alt')

  return (
    <VehicleContainerHeaderCard $color={color} $isHeaderHovered={isHeaderHovered} $noDispatches={noDispatches} ref={vehicleHeaderRef}>
      <div className='vehicle-header-section-left'>
        {isHeaderHovered ? (
          <div className='vehicle-header-hovered-by-installer-section'>
            <div>{noDispatches ? 'No Dispatches To Assign To' : 'Assign to all Dispatches'}</div>
          </div>
        ) : null}

        <div className='vehicle-icon'>
          {vehicle.name.includes('150') ? (
            <PickupTruckSvg color={token('color.icon.subtle')} size={32} />
          ) : (
            <TruckIcon color={token('color.icon.subtle')} size={32} />
          )}
        </div>

        <div className='vehicle-card-info'>
          <div className='vehicle-card-title'>{vehicle.name}</div>
        </div>
      </div>

      <div className='vehicle-header-section-right'>
        {isHoldingOptionKey && !noDispatches ? (
          <Button appearance='subtle' onClick={submitClearVehicle}>
            <span className='clear-vehicle-text'>Clear Vehicle</span>
          </Button>
        ) : null}
        {!isLoadSheetVisible ? null : (
          <Link href={`/load-sheet/${dateString}/${vehicleId}`} target='_blank' appearance='subtle'>
            Print
          </Link>
        )}
        {noDispatches ? null : (
          <>
            <IconButton
              icon={TableIcon}
              label={isLoadSheetVisible ? 'Hide Load Sheet' : 'Show Load Sheet'}
              onClick={() => setShowLoadSheet(prev => ({ ...prev, [vehicleId]: !isLoadSheetVisible }))}
              appearance={isLoadSheetVisible ? 'default' : 'subtle'}
              isTooltipDisabled={false}
            />
          </>
        )}
      </div>
    </VehicleContainerHeaderCard>
  )
}

export default VehicleContainerHeader

interface VehicleContainerHeaderCardProps {
  $color: CardColorOptions
  $isHeaderHovered: boolean
  $noDispatches: boolean
}

const VehicleContainerHeaderCard = styled.div<VehicleContainerHeaderCardProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 12px;

  background: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.background.accent.${$color}.subtlest`))};
  border-bottom: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  .vehicle-header-section-left {
    display: flex;
    align-items: center;
  }
  .vehicle-header-section-right {
    display: flex;
    align-items: center;
    gap: 12px;
    opacity: 0.5;
    transition: opacity 500ms;
    &:hover {
      opacity: 1;
    }

    .clear-vehicle-text {
      color: ${token('color.text.accent.red')};
    }
  }

  .vehicle-icon {
    padding: 4px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    svg {
      stroke: ${({ $color }) => (!$color ? token('color.icon.subtle') : token(`color.text.accent.${$color}`))};
      opacity: 0.8;
    }
  }

  .vehicle-card-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 4px 12px 4px 0;
    z-index: 1;

    .vehicle-card-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.text.accent.${$color}`))};
      opacity: 0.8;
    }

    .vehicle-card-license-plate {
      padding-right: 12px;
      font-size: 12px;
      color: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.text.accent.${$color}`))};
      font-weight: 300;
      font-family: ${MONOSPACE_FONT_FAMILY};
      opacity: 0.4;
    }
  }

  .vehicle-header-hovered-by-installer-section {
    position: absolute;
    inset: 4px;
    background: ${({ $noDispatches }) =>
      $noDispatches ? token('color.background.accent.gray.subtlest.hovered') : token('color.background.selected.hovered')};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: ${({ $noDispatches }) => ($noDispatches ? token('color.text.accent.gray') : token('color.text.accent.blue'))};
    z-index: 3;
    border: 1px solid ${({ $noDispatches }) => (!$noDispatches ? token('color.border') : token('color.border.accent.gray'))};
  }
`
