import { Temporal } from '@js-temporal/polyfill'
import { useMemo } from 'react'

import { useSelectedWeekYear } from '@/components/MiniCalendarNav/useSelectedWeekYear'

import {
  SingleInstallerDate,
  SingleInstallerDispatches,
  SingleInstallerWeeklyPayroll,
  usePaystubQuery,
} from '../ReconPayrollInstaller/hooks/usePaystubQuery'

import { getPieceRateAddonPayouts } from './paystubUtils'

interface PrintPaystubData {
  title: string
  paystubAttributes: Record<string, string>[]
  isHourlyInstaller: boolean
  installerDates?: SingleInstallerDate[]
  datesOfWeek: Temporal.PlainDate[]
  minutesManual: number
  minutesDispatch: number
  minutesDrive: number
  minutesNonDispatchPayable: number
  minutesAdjustment: number
  minutesTotal: number
  payOvertime: number
  payNonDispatch: number
  payAdjustments: number
  payoutDriveWeek: number
  payoutActual: number
  payoutDispatches: number
  dispatches: SingleInstallerDispatches[]
  dbDispatches: SingleInstallerDispatches[]
  installerPayroll?: SingleInstallerWeeklyPayroll
}

export const usePrintPaystubData = (): PrintPaystubData => {
  const { data } = usePaystubQuery()
  const { mondayDate, weekYear, weekNumber } = useSelectedWeekYear()

  const installerWeeklyPayroll = data?.installerPayroll
  const weekYearTitle = `${weekYear}-w${weekNumber}`
  const weekStartDate = !mondayDate ? 'No Week' : mondayDate.toLocaleString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })
  const weekEndDate = !mondayDate
    ? 'No Week'
    : mondayDate.add({ days: 6 }).toLocaleString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })

  const title = `${weekYearTitle} • ${installerWeeklyPayroll?.installer?.fullName}`

  const installerDispatches = installerWeeklyPayroll?.installerDispatches

  const installerDates: SingleInstallerDate[] = installerWeeklyPayroll?.installerDates ?? []

  const paystubAttributes: Record<string, string>[] = [
    {
      label: 'Week Start',
      value: weekStartDate,
    },
    {
      label: 'Week End',
      value: weekEndDate,
    },
    {
      label: 'Installer',
      value: installerWeeklyPayroll?.installer?.fullName ?? '',
    },
  ]

  const isHourlyInstaller = installerWeeklyPayroll?.isHourlyInstaller ?? false

  const minutesManual = Number(installerWeeklyPayroll?.minutesAdjustmentManual ?? 0)
  const minutesDispatch = Number(installerWeeklyPayroll?.totalDispatchesMinutesTotal ?? 0)
  const minutesDrive = Number(installerWeeklyPayroll?.totalDispatchesMinutesDrive ?? 0)
  const minutesNonDispatchPayable = Number(installerWeeklyPayroll?.totalDayMinutesNonDispatchPayable ?? 0)
  const minutesAdjustment = Number(installerWeeklyPayroll?.minutesAdjustmentManual ?? 0)
  const minutesTotal = Number(installerWeeklyPayroll?.minutesTotal ?? 0)
  const payOvertime = Number(installerWeeklyPayroll?.payoutOvertimeUplift ?? 0)
  const payNonDispatch = Number(installerWeeklyPayroll?.totalDayPayoutNonDispatch ?? 0)
  const payAdjustments =
    Number(installerWeeklyPayroll?.payoutAdjustment ?? 0) + Number(installerWeeklyPayroll?.payoutMinimumWageCorrection ?? 0)
  const { payoutDriveWeek, payoutDispatches } = !installerDispatches
    ? { payoutDriveWeek: 0, payoutDispatches: 0 }
    : installerDispatches.reduce(
        (acc, dispatch) => {
          const { weekDrivePayoutNumber, payoutNumber, bonusNumber } = getPieceRateAddonPayouts(dispatch)
          return {
            payoutDriveWeek: (acc.payoutDriveWeek ?? 0) + (weekDrivePayoutNumber ?? 0),
            payoutDispatches: (acc.payoutDispatches ?? 0) + payoutNumber + (bonusNumber ?? 0),
          }
        },
        { payoutDriveWeek: 0, payoutDispatches: 0 }
      )

  const payoutActual = Number(installerWeeklyPayroll?.payoutActual ?? 0)

  const { dispatches, dbDispatches } = useMemo(() => {
    const davisBacons: SingleInstallerDispatches[] = []
    const rest: SingleInstallerDispatches[] = []
    if (installerDispatches)
      installerDispatches.sort(sortDispatches).forEach(d => {
        if (d.isDavisBacon) davisBacons.push(d)
        else rest.push(d)
      })
    return { dispatches: rest, dbDispatches: davisBacons }
  }, [installerDispatches])

  const datesOfWeek = mondayDate ? Array.from({ length: 7 }, (_, i) => mondayDate?.add({ days: i })) : []
  return {
    title,
    installerPayroll: installerWeeklyPayroll,
    paystubAttributes,
    isHourlyInstaller,
    installerDates,
    datesOfWeek,
    minutesManual,
    minutesDispatch,
    minutesDrive,
    minutesTotal,
    minutesAdjustment,
    minutesNonDispatchPayable,
    payOvertime,
    payNonDispatch,
    payAdjustments,
    payoutDriveWeek,
    payoutActual,
    payoutDispatches,
    dispatches,
    dbDispatches,
  }
}

function sortDispatches(a: SingleInstallerDispatches, b: SingleInstallerDispatches) {
  const aDate = a?.vehicleDispatch?.dispatch?.date ? Temporal.PlainDate.from(a.vehicleDispatch.dispatch.date) : Temporal.Now.plainDateISO()

  const bDate = b?.vehicleDispatch?.dispatch?.date ? Temporal.PlainDate.from(b.vehicleDispatch.dispatch.date) : Temporal.Now.plainDateISO()

  return Temporal.PlainDate.compare(aDate, bDate)
}
