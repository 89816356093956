import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useAtomValue } from 'jotai'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import TableHeaderCell from './components/TableHeaderCell'
import { usePayrollRows } from './hooks/usePayrollRows'
import { useReconPayrollColumnDefs } from './hooks/useReconPayrollColumnDefs'
import { isShowLookupsAtom } from './reconPayrollAtoms'
import { FIRST_COLUMN_WIDTH, INSTALLER_COLUMN_WIDTH, SECOND_COLUMN_WIDTH } from './reconPayrollTypes'

const ReconPayrollTable = () => {
  const [isRendered, setIsRendered] = useState(false)
  const isLookupsVisible = useAtomValue(isShowLookupsAtom)

  const data = usePayrollRows()
  const columns = useReconPayrollColumnDefs()
  const table = useReactTable({ columns, data, ...REACT_TABLE_CONFIG })
  const rows = table.getRowModel().rows

  useEffect(() => setIsRendered(true), [])

  return (
    <TableScroller>
      {columns.length < 2 ? null : (
        <Table $hideLookups={!isLookupsVisible}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeaderCell key={header.id} header={header} />
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {!isRendered
              ? null
              : rows.map((row, idx) => {
                  const rowTopHeader = row.original.topHeader ?? ''
                  const previousRowTopHeader = rows[idx - 1]?.original.topHeader ?? ''
                  const hasTopHeaderRow = previousRowTopHeader !== rowTopHeader
                  let className = ''
                  if (hasTopHeaderRow) className += 'has-top-header-row'
                  if (row.original.highlightRow) className += ' should-highlight-table-row'
                  return (
                    <tr key={row.id} className={className}>
                      {row.getVisibleCells().map((cell, index) => {
                        const columnDef = cell.column.columnDef
                        const metaStyle = columnDef.meta?.style ?? {}
                        const isRowHeader = columnDef.header === 'Section' || columnDef.header === 'Field'
                        const isPinned = cell.column.getIsPinned()
                        const style = { ...metaStyle, width: cell.column.getSize() }
                        const className = `${isRowHeader ? '' : 'installer-column'}${isPinned ? ' pinned-' + isPinned : ''}`
                        const hideFirstCell = index === 0 && !hasTopHeaderRow
                        return (
                          <td key={cell.id} style={style} className={className}>
                            {hideFirstCell ? null : flexRender(columnDef.cell, cell.getContext())}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
          </tbody>
        </Table>
      )}
    </TableScroller>
  )
}

export default React.memo(ReconPayrollTable)
const TableScroller = styled.div`
  overflow: auto;
  position: absolute;
  inset: 0;
  /* Reserve space for scrollbars so that sticky footer stays flush */
  scrollbar-gutter: stable;

  /* Optional: Style scrollbars on WebKit browsers for a slimmer look */
  &::-webkit-scrollbar {
    width: 8px;
    background-color: ${token('elevation.surface.sunken')};
    height: 8px;
    border-left: 1px solid ${token('color.border')};
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: ${token('color.border')};
    }
  }
  &::-webkit-scrollbar-thumb {
    transition: background-color 0.3s ease;
    background-color: ${token('color.border')};
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${token('color.border')};
    &:hover {
      background-color: ${token('color.icon.disabled')};
    }
  }
`
const Table = styled.table<{ $hideLookups: boolean }>`
  /* Base table styles */
  z-index: 1;
  border-collapse: separate;
  border-spacing: 0;
  background: transparent;
  border-bottom: 0px solid ${token('color.border')};
  width: max-content;
  min-width: 100%;

  * {
    box-sizing: border-box;
  }

  /* Common cell styles */
  td,
  th {
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;

    &.installer-column {
      width: ${INSTALLER_COLUMN_WIDTH}px;
      min-width: ${INSTALLER_COLUMN_WIDTH}px;
      max-width: ${INSTALLER_COLUMN_WIDTH}px;
    }

    > div > a > div.header-tooltip-content-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${token('color.text.subtlest')};
    }
    & + td {
      border-left: 1px solid ${token('color.border')};
    }
  }

  /* All table rows (Header/Body/Footer) */
  tr {
    border-left: 1px solid ${token('color.border')};
    border-right: 1px solid ${token('color.border')};

    &:not(thead > tr:first-child) {
      td,
      th {
        &:nth-child(1) {
          width: ${FIRST_COLUMN_WIDTH}px;
          min-width: ${FIRST_COLUMN_WIDTH}px;
          max-width: ${FIRST_COLUMN_WIDTH}px;
        }
        &:nth-child(2) {
          width: ${SECOND_COLUMN_WIDTH}px;
          min-width: ${SECOND_COLUMN_WIDTH}px;
          max-width: ${SECOND_COLUMN_WIDTH}px;
        }
        &:nth-child(3) {
          border-left-width: 0;
        }
      }
    }
  }

  /* Table head */
  thead {
    position: sticky;
    top: 0;
    z-index: 5;
    box-sizing: border-box;
    height: 64px;
    min-height: 64px;
    max-height: 64px;

    > tr {
      background: ${token('color.background.accent.gray.subtlest')};
      z-index: 5;

      th {
        max-height: 32px;
        min-height: 32px;
        z-index: 5;
        padding: 4px 8px;
        text-align: left;
        position: relative;
        background: ${token('color.background.accent.gray.subtlest')};

        &.pinned-left {
          background: ${token('color.background.accent.gray.subtlest')};
          z-index: 6;
          position: sticky;
        }
      }

      /* Add left border between adjacent THs */
      th + th {
        border-left: 1px solid ${token('color.border')};
      }

      /* First row in thead */
      &:first-child {
        > th {
          color: ${token('color.text.disabled')};

          &:first-child {
            position: sticky;
            left: 0;
            z-index: 6;

            &::after {
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              width: 2px;
              box-shadow: inset -2px 0 2px -2px ${token('color.icon.disabled')};
            }
          }

          &:nth-child(2) {
            position: sticky;
            left: ${FIRST_COLUMN_WIDTH + SECOND_COLUMN_WIDTH}px;
            z-index: 3;
            border-left-width: 0;
          }

          /* For the third header cell: override any generic pinned-left offset */
          &:nth-child(3),
          &.pinned-left:nth-child(3) {
            position: sticky !important;
            left: calc(${FIRST_COLUMN_WIDTH}px + ${SECOND_COLUMN_WIDTH}px) !important;
            z-index: 4 !important;
          }
        }
      }

      /* Last row in thead */
      &:last-child {
        th {
          z-index: 5;

          &:after {
            z-index: 6;
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: 0;
            box-shadow: inset 0 -3px 2px -2.5px ${token('color.icon.disabled')};
          }

          &:first-child {
            position: sticky;
            left: 0;
            z-index: 6;

            text-align: right;
            color: ${token('color.text.disabled')};
          }

          &:nth-child(2) {
            position: sticky;
            left: ${FIRST_COLUMN_WIDTH}px;
            z-index: 6;

            color: ${token('color.text.disabled')};
            &:after {
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              left: 0;
              box-shadow:
                inset -2px 0 2px -2px ${token('color.icon.disabled')},
                inset 0 -3px 2px -2.5px ${token('color.icon.disabled')};
            }
          }
        }
      }
    }
  }

  /* Table body */
  tbody {
    z-index: 1;

    > tr {
      z-index: 1;

      /* Sticky first row in body */
      &:first-child {
        z-index: 3;
        position: sticky;
        top: 64px;
        background: ${token('elevation.surface')};
        box-shadow: ${token('elevation.shadow.overflow')};

        > td {
          border-top-width: 0 !important;
          border-bottom-width: 2px;
          /* box-shadow: inset 0px -1px 0px 0px ${token('color.border.bold')}; */

          &.pinned-left {
            z-index: 4;
          }
        }
      }

      /* Sticky last row in body */
      &:last-child {
        position: sticky;
        bottom: 0;
        z-index: 3;
        box-shadow: ${token('elevation.shadow.overflow')};

        td {
          background: ${token('elevation.surface')};
          z-index: 3;
          box-shadow: inset 0px 1px 0px 0px ${token('color.border')};
          font-weight: bold;
          height: 40px;

          &.pinned-left {
            z-index: 4;
          }
        }
      }
      &:nth-child(2) > td {
        border-top-width: 0 !important;
      }

      /* Even rows get neutral background */
      &:nth-child(odd) {
        background: ${token('color.background.neutral')};

        td.pinned-left:nth-child(2) {
          background: ${token('elevation.surface.hovered')};
        }
      }

      /* Optional thick top border */
      &.has-top-header-row > td {
        border-top: 3px solid ${token('color.border')};
      }

      /* Highlight certain rows if $hideLookups is true/false */
      &.should-highlight-table-row {
        background: ${token('color.background.selected')};

        td.pinned-left:nth-child(2) > div {
          position: absolute;
          inset: 0;
          display: flex;
          align-items: center;
          background: ${token('color.background.selected')};
        }

        div[role='presentation']:hover:not(:has(input:focus)) {
          background: ${token('color.background.selected.hovered')};
        }
      }

      /* Body cells */
      > td {
        padding: 0;
        border-bottom: 1px solid ${token('color.border')};
        vertical-align: middle;
        text-align: right;

        /* Slightly special case for first cell */
        &:first-child {
          padding-left: 8px;
          padding-right: 8px;
        }

        /* Sticky pinned columns */
        &.pinned-left {
          position: sticky;
          z-index: 2;
          background: ${token('elevation.surface.raised')};
        }

        &.pinned-left:first-child {
          left: 0;
        }

        &.pinned-left:nth-child(2) {
          left: ${FIRST_COLUMN_WIDTH}px;
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 2px;
            box-shadow: inset -2px 0 2px -2px ${token('color.icon.disabled')};
          }
        }
      }
    }
  }
`

const REACT_TABLE_CONFIG = {
  getCoreRowModel: getCoreRowModel(),
  state: {
    columnPinning: {
      left: ['topHeader', 'fieldTitle'], // Pin the first two columns
    },
  },
  enableColumnPinning: true,
}
