import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo, useRef } from 'react'
import { Controller, ControllerRenderProps, SubmitHandler, useForm, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { DatePicker } from '@atlaskit/datetime-picker'
import { ErrorMessage, HelperMessage, Label } from '@atlaskit/form'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import { RadioGroup } from '@atlaskit/radio'
import Textfield from '@atlaskit/textfield'

import { containerLabelOptions, unitOfMeasureOptions } from '@/utils/constants'
import { GET_MATERIALS_QUERY_KEY } from '@/utils/queryHooks/useMaterialsQuery'
import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { CreateMaterialMutationVariables, GetMaterialsQuery } from '@/gql/graphql'
import NumberTextField from '@/pages/customers/NumberTextField'

type FormInputs = {
  name: string
  itemCode: string
  unitOfMeasure: string
  abbreviation: string
  laborCostPerUnit: string
  costPerContainer: string
  effectiveDate: string
  purchaseContainerLabel: string
  purchaseContainerUnitQuantity: string
  costPerUnit: string
}

type CreateMaterialFormProps = {
  materials: GetMaterialsQuery['materials']
  closeModal: () => void
}

export default function CreateMaterialForm({ materials, closeModal }: CreateMaterialFormProps) {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    setError,
  } = useForm<FormInputs>()

  const { namesList, itemCodesList } = useMemo(() => {
    const namesList = materials.map(material => material.name) ?? []
    const itemCodesList = materials.map(material => material.itemCode) ?? []
    return { namesList, itemCodesList }
  }, [materials])

  const queryClient = useQueryClient()
  const graphQLClient = useGraphQLClient()
  const modalBodyRef = useRef<HTMLDivElement>(null)

  const mutation = useMutation({
    mutationFn: async (variables: CreateMaterialMutationVariables) => {
      const response = await graphQLClient.request(CREATE_MATERIAL_MUTATION, variables)
      if (!response.createMaterial?.success === true) throw new Error(response.createMaterial?.message ?? 'Failed to create material')
      return response.createMaterial
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_MATERIALS_QUERY_KEY] })
      closeModal()
    },
    onError: (error: Error) => {
      setError('root', {
        type: 'manual',
        message: error.message,
      })
      // Scroll to top of modal body
      modalBodyRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    },
  })
  const { isPending } = mutation

  const nameDoesNotExist = useCallback(
    (name: string) => {
      return !namesList.includes(name)
    },
    [namesList]
  )
  const itemCodeDoesNotExist = useCallback(
    (itemCode: string) => {
      return !itemCodesList.includes(itemCode)
    },
    [itemCodesList]
  )

  const onSubmit: SubmitHandler<FormInputs> = data => {
    mutation.mutate(data)
  }

  const formIsInvalid = Object.keys(errors).length > 0

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <ModalTitle>Create Material</ModalTitle>
      </ModalHeader>

      <ModalBody ref={modalBodyRef}>
        {errors.root && <ErrorMessage>{errors.root.message}</ErrorMessage>}
        <FieldWrapper>
          <Controller
            name='name'
            control={control}
            defaultValue=''
            rules={{ required: true, minLength: 3, maxLength: 255, validate: nameDoesNotExist }}
            render={({ field }) => (
              <>
                <Label htmlFor='basic-textfield'>Material Name</Label>
                <Textfield isInvalid={!!errors?.name} {...field} />
                {errors?.name?.type === 'validate' ? <ErrorMessage>Material with this name exists.</ErrorMessage> : null}
                {errors?.name?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
              </>
            )}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Controller
            name='itemCode'
            control={control}
            defaultValue=''
            // must be only letters and numbers, no spaces
            rules={{ required: true, maxLength: 50, minLength: 2, pattern: /^[a-zA-Z0-9]*$/, validate: itemCodeDoesNotExist }}
            render={({ field }) => (
              <>
                <Label htmlFor='basic-textfield'>Item Code</Label>
                <Textfield isInvalid={!!errors?.itemCode} {...field} />
                {errors?.itemCode?.type === 'validate' ? <ErrorMessage>Item Code Already Exists.</ErrorMessage> : null}
              </>
            )}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Controller
            name='unitOfMeasure'
            control={control}
            defaultValue='each'
            rules={{ required: true }}
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { ref, ...fieldProps } = field
              return (
                <>
                  <Label htmlFor='basic-textfield'>Unit of Measure</Label>
                  <RadioGroup {...fieldProps} options={unitOfMeasureOptions} />
                  <HelperMessage>Options can be added in Settings.</HelperMessage>
                </>
              )
            }}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Controller
            name='laborCostPerUnit'
            control={control}
            defaultValue=''
            render={({ field }) => renderLaborCostPerUnit({ field, setValue, getValues })}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Controller
            name='purchaseContainerLabel'
            control={control}
            defaultValue='item'
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { ref, ...fieldProps } = field

              return (
                <>
                  <Label htmlFor='basic-textfield'>Container Type</Label>
                  <RadioGroup {...fieldProps} options={containerLabelOptions} />
                  <HelperMessage>Options can be added in Settings.</HelperMessage>
                </>
              )
            }}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Controller
            name='costPerContainer'
            control={control}
            defaultValue=''
            render={({ field }) => renderCostPerContainer({ field, setValue, getValues })}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Controller
            name='purchaseContainerUnitQuantity'
            control={control}
            defaultValue='1.00'
            render={({ field }) => renderPurchaseContainerUnitQuantity({ field, setValue, getValues })}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Controller
            name='costPerUnit'
            control={control}
            defaultValue='0.0000'
            disabled={true}
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { ref, ...fieldProps } = field

              return (
                <>
                  <Label htmlFor='basic-textfield'>Cost Per Unit</Label>
                  <NumberTextField isMoney={true} isDisabled={true} {...fieldProps} />
                  <HelperMessage>Before tax.</HelperMessage>
                </>
              )
            }}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Controller
            name='effectiveDate'
            control={control}
            defaultValue={new Date().toISOString().slice(0, 10)}
            render={renderEffectiveDate}
          />
        </FieldWrapper>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={closeModal}>
          Close
        </Button>

        <Button isLoading={isPending} appearance='primary' type='submit' isDisabled={formIsInvalid}>
          Create
        </Button>
      </ModalFooter>
    </form>
  )
}
const FieldWrapper = styled.div`
  margin-top: 18px;
`
interface ControllerRenderFunctionProps {
  field: ControllerRenderProps<FormInputs>
  setValue: UseFormSetValue<FormInputs>
  getValues: UseFormGetValues<FormInputs>
}

function renderEffectiveDate({ field: { onChange, onBlur, value, name, ref } }: { field: ControllerRenderProps<FormInputs> }) {
  return (
    <>
      <Label htmlFor='basic-textfield'>Effective Date of Cost</Label>
      <DatePicker
        innerProps={{ style: { width: 150 } }}
        selectProps={{
          // inputId: 'effectiveDate',
          name,
          ref,
        }}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={value}
      />
    </>
  )
}

function renderCostPerContainer({ field, setValue, getValues }: ControllerRenderFunctionProps) {
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [purchaseContainerUnitQuantity] = getValues(['purchaseContainerUnitQuantity'])
    const newValue = parseFloat(event.target.value) / parseFloat(purchaseContainerUnitQuantity)
    if (isNaN(newValue)) {
      setValue('costPerUnit', '')
      setValue('costPerContainer', event.target.value)
    } else {
      setValue('costPerUnit', newValue.toFixed(4))
      setValue('costPerContainer', event.target.value)
    }
  }
  function onBlur() {
    field.onBlur()
    const [costPerContainer] = getValues(['costPerContainer'])
    const numberValue = parseFloat(costPerContainer)
    if (isNaN(numberValue)) {
      setValue('costPerContainer', '')
    } else {
      setValue('costPerContainer', numberValue.toFixed(4))
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ref, ...fieldProps } = field

  return (
    <>
      <Label htmlFor='basic-textfield'>Cost per Container</Label>
      <NumberTextField isMoney {...{ ...fieldProps, onChange, onBlur }} />
    </>
  )
}
function renderLaborCostPerUnit({ field, setValue, getValues }: ControllerRenderFunctionProps) {
  function onBlur() {
    field.onBlur()
    const [laborCostPerUnit] = getValues(['laborCostPerUnit'])
    const numberValue = parseFloat(laborCostPerUnit)
    if (isNaN(numberValue)) {
      setValue('laborCostPerUnit', '')
    } else {
      setValue('laborCostPerUnit', numberValue.toFixed(4))
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ref, ...fieldProps } = field

  return (
    <>
      <Label htmlFor='basic-textfield'>Labor Cost per Unit</Label>
      <NumberTextField isMoney {...{ ...fieldProps, onBlur }} />
    </>
  )
}

function renderPurchaseContainerUnitQuantity({ field, setValue, getValues }: ControllerRenderFunctionProps) {
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [costPerContainer] = getValues(['costPerContainer'])
    const newValue = parseFloat(costPerContainer) / parseFloat(event.target.value)
    if (isNaN(newValue)) {
      setValue('costPerUnit', '')
      setValue('purchaseContainerUnitQuantity', event.target.value)
    } else {
      setValue('costPerUnit', newValue.toFixed(4))
      setValue('purchaseContainerUnitQuantity', event.target.value)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ref, ...fieldProps } = field

  return (
    <>
      <Label htmlFor='basic-textfield'>Units per Container</Label>
      <NumberTextField isMoney={false} {...{ ...fieldProps, onChange }} />
    </>
  )
}

const CREATE_MATERIAL_MUTATION = graphql(/* GraphQL */ `
  mutation CreateMaterial(
    $name: String!
    $itemCode: String!
    $unitOfMeasure: String!
    $abbreviation: String
    $laborCostPerUnit: Decimal!
    $costPerContainer: Decimal!
    $effectiveDate: Date
    $purchaseContainerLabel: String!
    $purchaseContainerUnitQuantity: Decimal!
    $costPerUnit: Decimal
  ) {
    createMaterial(
      name: $name
      itemCode: $itemCode
      unitOfMeasure: $unitOfMeasure
      abbreviation: $abbreviation
      laborCostPerUnit: $laborCostPerUnit
      costPerContainer: $costPerContainer
      effectiveDate: $effectiveDate
      purchaseContainerLabel: $purchaseContainerLabel
      purchaseContainerUnitQuantity: $purchaseContainerUnitQuantity
      costPerUnit: $costPerUnit
    ) {
      success
      message

      material {
        id
        sid
        name
        itemCode
        unitOfMeasure
        laborCostPerUnit
        activeCostSchedule {
          id
          sid
          effectiveDate
          costPerUnit
          costPerContainer
          purchaseContainerLabel
          purchaseContainerUnitQuantity
        }
      }
    }
  }
`)
