import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'

import GET_PAYROLL_DAY_VEHICLES, { GET_PAYROLL_DAY_VEHICLES_QUERY_KEY } from '@/utils/api/getPayrollDayVehicles'
import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { GetPayrollDayVehiclesQuery } from '@/gql/graphql'

import { useReconSearchParams } from '../useReconSearchParams'

import { useDispatchPayoutQuery, type DispatchPayoutType, type DispatchPayoutWorkOrder } from './useDispatchPayoutQuery'

type Vehicle = NonNullable<GetPayrollDayVehiclesQuery['vehiclesByDate']>[number]

export interface UseReconDatePageResult {
  date: string
  title: string
  isPending: boolean
  vehicle: Vehicle | undefined
  workOrder: DispatchPayoutWorkOrder | undefined
  workOrderId: string | undefined
  selectedDispatch: DispatchPayoutType | undefined
  isSomeCompleted: boolean | undefined
}

export const useReconDatePage = (): UseReconDatePageResult => {
  const graphQLClient = useGraphQLClient()
  const { paramValues } = useReconSearchParams()
  const dateId = paramValues.get('dateId')
  const vehicleDateId = paramValues.get('vehicleDateId')
  const vehicleDispatchId = paramValues.get('vehicleDispatchId')

  const date = dateId ?? Temporal.Now.plainDateISO().toString()

  const { data, isPending } = useQuery({
    queryKey: [GET_PAYROLL_DAY_VEHICLES_QUERY_KEY, date],
    queryFn: async () => await graphQLClient.request(GET_PAYROLL_DAY_VEHICLES, { date }),
  })

  const vehicles = data?.vehiclesByDate ?? ([] as Vehicle[])

  const vehicle = vehicles.find(vehicle => vehicle.vehicleDate?.id === vehicleDateId)
  const selectedVehicleDispatch = !vehicleDispatchId
    ? null
    : vehicle?.vehicleDate?.vehicleDispatches?.find(assignment => assignment.id === vehicleDispatchId)
  const workOrderId = selectedVehicleDispatch?.dispatch?.workOrderId ?? undefined
  const { data: dispatchPayoutData } = useDispatchPayoutQuery({ workOrderId })
  const workOrder = dispatchPayoutData?.workOrder
  const dispatches = (workOrder?.dispatches ?? []).sort((a, b) => a.date.localeCompare(b.date))

  const selectedDispatch = dispatches.find(dispatch => dispatch.date === date)
  const isSomeCompleted = selectedDispatch?.installerDispatches.some(assignment => assignment.isCompleted)

  const title = `Daily Recon for ${date}`

  return {
    date,
    title,
    isPending,
    vehicle,
    workOrder,
    workOrderId,
    selectedDispatch,
    isSomeCompleted,
  }
}
