import { token } from '@atlaskit/tokens'

export const useSelectColors = () => {
  const colors = {
    // danger: '#DE350B',
    danger: token('color.chart.danger.hovered'),
    // dangerLight: '#FFBDAD',
    dangerLight: token('color.background.danger.hovered'),
    // neutral0: 'hsl(0, 0%, 100%)',
    neutral0: token('elevation.surface'),
    // neutral5: 'hsl(0, 0%, 95%)',
    neutral5: token('color.background.input'),
    // neutral10: 'hsl(0, 0%, 90%)',
    neutral10: token('color.background.input.hovered'),
    // neutral20: 'hsl(0, 0%, 80%)',
    neutral20: token('color.background.accent.gray.subtlest.hovered'), // Neutral300/350
    // neutral30: 'hsl(0, 0%, 70%)',
    neutral30: token('color.background.input'),
    neutral40: token('color.background.accent.gray.subtle.hovered'),
    neutral50: token('color.background.accent.gray.subtle'),
    neutral60: token('color.icon.subtle'),
    neutral70: token('color.text.subtle'),
    neutral80: token('color.background.accent.gray.bolder.pressed'), // Neutral900
    neutral90: token('color.background.neutral.bold.pressed'),
    // primary: '#2684FF',
    primary: token('color.chart.brand'),
    primary25: token('color.background.selected'),
    // primary50: '#B2D4FF',
    primary50: token('color.background.accent.blue.subtler'),
    primary75: token('color.background.selected.pressed'),
  }
  return colors
}
