import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import { useStaffById } from '@/utils/queryHooks/useStaffById'

import { DragSourceData, itemMouseOverAtom, itemPickedUpAtom } from '../../dispatchTypes'
import { useSelectedInstallerDispatches } from '../selectors/useSelectedInstallerDispatches'

import { useIsDragging } from './useIsDragging'
import { useIsMouseOverGlobal } from './useIsMouseOverGlobal'
import { useIsShiftKeyPressed } from './useIsShiftKeyPressed'

interface useInstallerStateProps {
  installerId: string
  vehicleDispatchId?: string | null
  vehicleId?: string | null
}

export const useInstallerState = ({ installerId, vehicleDispatchId, vehicleId }: useInstallerStateProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const setItemPickedUp = useSetAtom(itemPickedUpAtom)
  const setItemMouseOver = useSetAtom(itemMouseOverAtom)

  const assignedVehicleDispatchIds = useSelectedInstallerDispatches(installerId)
  const isMouseOverGlobal = useIsMouseOverGlobal(installerId)
  const staff = useStaffById(installerId)
  const isShiftKeyPressed = useIsShiftKeyPressed()

  const [isMouseOver, setIsMouseOver] = useState(false)

  const draggableItem: DragSourceData = useMemo(
    () => ({
      installerId,
      vehicleDispatchId,
      vehicleId,
      assignedVehicleDispatchIds,
      itemIdentifiedVariant: 'INSTALLER',
    }),
    [installerId, vehicleDispatchId, vehicleId, assignedVehicleDispatchIds]
  )

  const handleMouseEnter = useCallback(() => {
    setIsMouseOver(true)
    setItemMouseOver(draggableItem)
  }, [draggableItem, setItemMouseOver])

  const handleMouseLeave = useCallback(() => {
    setIsMouseOver(false)
    setItemMouseOver(prev => (prev.installerId === installerId ? {} : prev))
  }, [installerId, setItemMouseOver])

  const onDrop = useCallback(() => setItemPickedUp(prev => (prev.installerId !== installerId ? prev : {})), [installerId, setItemPickedUp])
  const onDragStart = useCallback(() => setItemPickedUp(draggableItem), [draggableItem, setItemPickedUp])
  const getInitialData = useCallback(() => draggableItem, [draggableItem])

  useEffect(() => {
    if (ref.current) return draggable({ element: ref.current, getInitialData, onDragStart, onDrop })
  }, [getInitialData, onDragStart, onDrop])

  const { isDragging: isDraggingGlobal, isDraggingExactMatch: isDragging } = useIsDragging(draggableItem)
  const shouldHighlight = useMemo(() => isMouseOverGlobal || isDraggingGlobal, [isMouseOverGlobal, isDraggingGlobal])
  const isCopyMode = useMemo(
    () => Boolean(vehicleDispatchId) && isShiftKeyPressed && isMouseOver,
    [vehicleDispatchId, isShiftKeyPressed, isMouseOver]
  )

  const name = staff?.fullName ?? 'NOT FOUND'
  const initials = (staff?.firstName ?? 'X')[0] + (staff?.lastName ?? 'X')[0]

  return {
    ref,
    isDragging,
    shouldHighlight,
    isCopyMode,
    name,
    initials,
    isHourly: staff?.isHourly,
    isDriver: staff?.isDriver,
    isMouseOver,
    assignmentCount: assignedVehicleDispatchIds?.length ?? 0,
    handleMouseEnter,
    handleMouseLeave,
  }
}
