/// <reference types="@types/google.maps" />

import styled from 'styled-components'

import { ErrorMessage } from '@atlaskit/form'
import { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'

import GoogleMapsProvider from '@/components/GoogleMapsProvider'
import MapPinModal from '@/components/MapPinModal'

import CustomerSelector from './CreateJobForm/components/CustomerSelector'
import FormFooter from './CreateJobForm/components/FormFooter'
import JobTitleInput from './CreateJobForm/components/JobTitleInput'
import ProjectSiteInput from './CreateJobForm/components/ProjectSiteInput'
import { useCreateJobForm } from './CreateJobForm/hooks/useCreateJobForm'
import { SelectOption } from './CreateJobForm/hooks/useJobForm'

interface CreateJobFormProps {
  closeModal: () => void
  preselectedCustomer?: SelectOption
}

export default function CreateJobForm({ closeModal, preselectedCustomer }: CreateJobFormProps) {
  const {
    // Form state
    title,
    setTitle,
    projectSiteName,
    setProjectSiteName,
    selectedCustomer,
    setSelectedCustomer,
    projectSiteCoordinates,

    // Validation
    errors,
    formIsInvalid,

    // Places management
    autocompleteRefs,
    setAutocompleteRefs,
    isMapPinModalOpen,
    setIsMapPinModalOpen,
    hasPinLocation,

    // Functions
    handlePlaceSelect,
    handleMapPinSelect,
    clearCoordinates,
    addAddressField,
    removeAddressField,
    handleSubmit,

    // API related
    customersOptions,
    isPendingCustomersList,
    isPending,
  } = useCreateJobForm({ closeModal, preselectedCustomer })

  return (
    <StyledModalWrapper>
      <GoogleMapsProvider>
        <form onSubmit={handleSubmit}>
          <ModalHeader>
            <ModalTitle>Create Job {preselectedCustomer?.label ? `for ${preselectedCustomer?.label}` : ''}</ModalTitle>
          </ModalHeader>

          <ModalBody>
            <StyledModalContent>
              <CustomerSelector
                value={selectedCustomer}
                onChange={setSelectedCustomer}
                options={customersOptions}
                isDisabled={isPendingCustomersList}
                preselectedCustomer={preselectedCustomer}
                errors={errors}
              />

              <JobTitleInput value={title} onChange={setTitle} errors={errors} />

              <ProjectSiteInput
                value={projectSiteName}
                onChange={setProjectSiteName}
                errors={errors}
                autocompleteRefs={autocompleteRefs}
                setAutocompleteRefs={setAutocompleteRefs}
                hasPinLocation={hasPinLocation}
                setIsMapPinModalOpen={setIsMapPinModalOpen}
                handlePlaceSelect={handlePlaceSelect}
                addAddressField={addAddressField}
                removeAddressField={removeAddressField}
                clearCoordinates={clearCoordinates}
              />

              {errors?.projectSiteName?.type === 'required' ? <ErrorMessage>Project site is required.</ErrorMessage> : null}
              {errors?.projectSiteName?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
            </StyledModalContent>
          </ModalBody>

          <FormFooter closeModal={closeModal} isPending={isPending} isDisabled={formIsInvalid} />
        </form>

        <ModalTransition>
          {isMapPinModalOpen && (
            <MapPinModal
              onSelect={coords => {
                handleMapPinSelect(coords)
                setIsMapPinModalOpen(false)
              }}
              onClose={() => setIsMapPinModalOpen(false)}
              initialLocation={projectSiteCoordinates}
            />
          )}
        </ModalTransition>
      </GoogleMapsProvider>
    </StyledModalWrapper>
  )
}

const StyledModalContent = styled.div`
  padding-bottom: 24px;

  .field-wrapper {
    margin-bottom: 18px;
    max-width: 100%;
    overflow-x: hidden;
  }
`

const StyledModalWrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-self: center; */
`
