import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'

import { UpdateProjectSiteMutationVariables } from '../../../gql/graphql'
import { FormDataType, NewPlaceType } from '../types'

import { PROJECT_SITE_QUERY_KEY } from './useProjectSiteData'

// Mutation to update a project site
const UPDATE_PROJECT_SITE_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateProjectSite(
    $jobId: ID!
    $projectSiteName: String!
    $projectSiteStreetAddress: String
    $projectSiteCity: String
    $projectSiteState: String
    $projectSiteZipCode: String
    $placesData: [PlaceInput!]
    $projectSiteCoordinates: CoordinatesInput
    $primaryPlaceId: ID
    $deletePlaceIds: [ID!]
  ) {
    updateProjectSite(
      jobId: $jobId
      projectSiteName: $projectSiteName
      projectSiteStreetAddress: $projectSiteStreetAddress
      projectSiteCity: $projectSiteCity
      projectSiteState: $projectSiteState
      projectSiteZipCode: $projectSiteZipCode
      placesData: $placesData
      projectSiteCoordinates: $projectSiteCoordinates
      primaryPlaceId: $primaryPlaceId
      deletePlaceIds: $deletePlaceIds
    ) {
      success
      message
      projectSite {
        id
        name
        streetAddress
        city
        state
        zipCode
        cityStateZip
        pinDrop {
          id
          latitude
          longitude
          createdAt
        }
      }
    }
  }
`)

// Format place data for API submission
const formatPlaceForSubmission = (place: NewPlaceType) => {
  return {
    id: place.id ? Number(place.id) : undefined,
    googlePlaceId: place.googlePlaceId || '',
    displayName: place.displayName || '',
    formattedAddress: place.formattedAddress || '',
    formattedPhoneNumber: place.formattedPhoneNumber || null,
    websiteUri: place.websiteUri || '',
    googleMapsUri: place.googleMapsUri || '',
    lat: place.latitude ? Number(place.latitude) : 0,
    lng: place.longitude ? Number(place.longitude) : 0,
    streetNumber: place.streetNumber,
    route: place.route,
    routeShort: place.routeShort,
    streetAddress: place.streetAddress,
    streetAddressShort: place.streetAddressShort,
    state: place.state,
    county: place.county,
    city: place.city,
    zip: place.zip,
    country: place.country,
    unit: place.unit,
    isPrimary: place.isPrimary,
  }
}

export const useProjectSiteSubmit = (jobId: string, queriesToRefetchOnUpdate: string[] = [], onSuccess?: () => void) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (formData: FormDataType) => {
      // Prepare mutation variables
      const variables: UpdateProjectSiteMutationVariables = {
        jobId,
        projectSiteName: formData.projectSite.name,
        projectSiteStreetAddress: formData.projectSite.streetAddress,
        projectSiteCity: formData.projectSite.city,
        projectSiteState: formData.projectSite.state,
        projectSiteZipCode: formData.projectSite.zipCode,
        primaryPlaceId: formData.primaryPlaceId,
        deletePlaceIds: formData.deletedPlaceIds,
        // Send either placesData or projectSiteCoordinates, but not both
        placesData: formData.coordinates ? undefined : formData.places.filter(place => place.isNew).map(formatPlaceForSubmission),
        projectSiteCoordinates: formData.coordinates,
      }

      const response = await graphQLClient.request(UPDATE_PROJECT_SITE_MUTATION, variables)

      if (!response.updateProjectSite.success) throw new Error(response.updateProjectSite.message || 'Failed to update project site')

      return response
    },
    onSuccess: () => {
      // Invalidate queries
      queryClient.invalidateQueries({ queryKey: [PROJECT_SITE_QUERY_KEY, jobId] })

      // Invalidate additional queries if specified
      queriesToRefetchOnUpdate.forEach(queryKey => {
        queryClient.invalidateQueries({ queryKey: [queryKey] })
      })

      // Call onSuccess callback if provided
      if (onSuccess) {
        onSuccess()
      }
    },
  })

  return {
    submit: mutation.mutate,
    isPending: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  }
}
