import { useQuery } from '@tanstack/react-query'
import { motion, Reorder } from 'framer-motion'
import { useSetAtom } from 'jotai'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'

import { unscheduledCountAtom } from './atoms'
import SchedulerCard from './components/SchedulerCard'
import { CALENDAR_WORK_ORDERS_QUERY_KEY, CalendarWorkOrderType } from './types'

const SidebarUnscheduledWorkOrders = () => {
  const graphQLClient = useGraphQLClient()
  const ref = useRef<HTMLDivElement | null>(null)

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const setUnscheduledCount = useSetAtom(unscheduledCountAtom)

  const { data, isPending: isLoading } = useQuery({
    queryKey: [CALENDAR_WORK_ORDERS_QUERY_KEY],
    queryFn: async () => {
      const data = await graphQLClient.request(CALENDAR_WORK_ORDERS)
      const count = data.workOrders.length
      setUnscheduledCount(count)
      return data
    },
  })
  const workOrders = data?.workOrders ?? []

  useEffect(() => {
    const el = ref.current
    if (!el) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: 'UNSCHEDULE' }),
      onDragEnter: () => setIsHovered(true),
      onDragLeave: () => setIsHovered(false),
      onDrop: () => setIsHovered(false),
    })
  }, [setIsHovered])

  return (
    <div>
      <Container ref={ref}>
        {isHovered && (
          <HoveredDiv initial={{ scale: 0.95 }} animate={{ scale: 1.05 }} transition={{ duration: 0.15, ease: [0.0, 0.99, 0.33, 0.99] }}>
            <h3>
              Drop to
              <br /> Unschedule
            </h3>
          </HoveredDiv>
        )}

        <div className='unscheduled-work-orders'>
          {workOrders.length === 0 ? (isLoading ? 'Searching...' : 'None found') : null}

          <Reorder.Group values={workOrders} onReorder={() => {}} as='div'>
            {workOrders.length === 0
              ? null
              : workOrders.map(workOrder => {
                  const calendarDispatch: CalendarWorkOrderType = {
                    workOrderId: workOrder.id,
                    workOrder,
                    dispatchId: null,
                    dateString: null,
                    estimateTitle: workOrder.estimateTitle,
                    estimateId: workOrder.estimateId,
                  }
                  return (
                    <Reorder.Item key={workOrder.id} value={calendarDispatch} as='div' transition={{ duration: 0.12 }}>
                      <SchedulerCard key={workOrder.id} calendarDispatch={calendarDispatch} />
                    </Reorder.Item>
                  )
                })}
          </Reorder.Group>
        </div>
      </Container>
    </div>
  )
}
export default SidebarUnscheduledWorkOrders

const Container = styled.div`
  position: relative;
  z-index: 0;

  .unscheduled-work-orders {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    position: relative;
    z-index: 1;
  }
`

const CALENDAR_WORK_ORDERS = graphql(/* GraphQL */ `
  query WorkOrdersToSchedule {
    workOrders(filters: { onlyWithoutDispatches: true }) {
      id
      name
      isConfirmed
      isWorkCompleted

      tripName

      estimateId
      estimateTitle

      isDavisBacon

      projectSiteAddress
      customerName
    }
  }
`)

const HoveredDiv = styled(motion.div)`
  border: 1px solid ${token('color.border.selected')};
  border-radius: 4px;
  min-height: 200px;
  transition:
    background-color 0.2s,
    opacity 0.2s;
  background-color: ${token('color.background.selected')};
  position: absolute;
  opacity: 0.9;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* Add this line for Safari support */
  z-index: 3;
  > h3 {
    color: ${token('color.text.brand')};
    font-weight: 700;
    text-align: center;
    padding-bottom: ${token('space.250')};
  }
`
