import request from 'graphql-request'
import { useAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { toast } from 'sonner'

import { graphql } from '@/gql'

import { isRefreshingTokensAtom, unifiedTokenAtom } from './appAtoms'
import { GRAPHQL_ENDPOINT } from './constants'
import { useSignOut } from './useSignOut'

export const useAccessToken = () => {
  const [isRefreshing, setIsRefreshing] = useAtom(isRefreshingTokensAtom)

  const [unifiedToken, setUnifiedToken] = useAtom(unifiedTokenAtom)
  const { accessToken, accessTokenExpiration, refreshToken, refreshTokenExpiration } = unifiedToken

  const refreshTokenIsExpired = useMemo(() => new Date(refreshTokenExpiration) < new Date(), [refreshTokenExpiration])
  const accessTokenIsExpired = useMemo(() => new Date(accessTokenExpiration) < new Date(), [accessTokenExpiration])
  const signOut = useSignOut()

  useEffect(() => {
    if (isRefreshing) return

    const refreshTokens = async () => {
      setIsRefreshing(true)
      try {
        const response = await request({
          document: REFRESH_TOKEN_MUTATION,
          variables: { refreshToken },
          url: GRAPHQL_ENDPOINT,
          requestHeaders: accessToken ? { Authorization: `JWT ${accessToken}` } : {},
        })

        if (
          response.refreshToken.success &&
          response.refreshToken?.refreshToken?.token &&
          response.refreshToken?.refreshToken?.expiresAt &&
          response.refreshToken?.token?.payload?.exp &&
          response.refreshToken?.token?.token
        ) {
          console.log('useAccessToken: Received success: ', response.refreshToken)
          setUnifiedToken({
            refreshToken: response.refreshToken.refreshToken.token,
            refreshTokenExpiration: new Date(response.refreshToken.refreshToken.expiresAt).toISOString(),
            accessTokenExpiration: new Date(response.refreshToken.token.payload.exp).toISOString(),
            accessToken: response.refreshToken.token.token,
          })
        } else {
          console.error('useAccessToken: Did not get expected refresh response.', response)
          return null
        }
      } catch (error) {
        console.error('useAccessToken: Error refreshing tokens', error)
        toast.error('Error refreshing tokens. Please sign in again.')
        setUnifiedToken({
          refreshToken: '',
          refreshTokenExpiration: '',
          accessTokenExpiration: '',
          accessToken: '',
        })
      } finally {
        setIsRefreshing(false)
      }
    }
    if (refreshTokenIsExpired) {
      console.error('useAccessToken: Refresh token expired, logging out')
      signOut()
    } else if (accessTokenIsExpired) {
      console.warn('useAccessToken: Access token expired')
      if (isRefreshing) console.warn('Currently refreshing. Waiting in the queue')
      else {
        console.log('useAccessToken: Not refreshing. Triggering refresh mutation.')
        refreshTokens()
      }
    }
  }, [signOut, isRefreshing, accessToken, accessTokenIsExpired, setUnifiedToken, refreshToken, refreshTokenIsExpired, setIsRefreshing])

  useEffect(() => {
    if (refreshTokenIsExpired || accessTokenIsExpired) {
      const tokenStatus = [
        { label: 'Access Token', value: accessToken },
        { label: 'Access Token Is Expired', value: accessTokenIsExpired },
        { label: 'Refresh Token', value: refreshToken },
        { label: 'Refresh Token Is Expired', value: refreshTokenIsExpired },
      ]
      console.warn('useAccessToken: A token is expired. Token status:')
      console.table(tokenStatus)
    }
  }, [accessToken, accessTokenExpiration, refreshToken, refreshTokenExpiration, refreshTokenIsExpired, accessTokenIsExpired])

  return [accessToken, isRefreshing]
}

export const REFRESH_TOKEN_MUTATION = graphql(/* GraphQL */ `
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken, revokeRefreshToken: false) {
      refreshToken {
        token
        expiresAt
      }
      token {
        token
        payload {
          exp
        }
      }
      success
      errors
    }
  }
`)
