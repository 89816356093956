import { Temporal } from '@js-temporal/polyfill'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import Tabs, { Tab, TabList } from '@atlaskit/tabs'
import { token } from '@atlaskit/tokens'

import { getMondayDateFromWeekId } from '@/utils/utilities'

import ReconAppPage from '../ReconAppPage'
import { useReconSearchParams } from '../useReconSearchParams'

import ReconPayrollActions from './components/ReconHoursActions'
import { useInstallersList } from './hooks/useInstallersList'
import { useReconPayrollQuery } from './hooks/useReconPayrollQuery'
import ReconPayrollTable from './TabPayroll'
import WeeklyTimecardsTab from './TabWeeklyTimecards'

const ReconPayrollPage = () => {
  const { weekText, weekOf, weekNumber, year } = useReconPayrollWeekText()
  const navigate = useNavigate()
  const location = useLocation()
  // Determine selected tab based on URL
  const selectedTab = useMemo(() => (location.pathname.includes('timesheets') ? 1 : 0), [location.pathname])

  const { installers, status } = useInstallersList()
  const { isLoading: isLoadingPayroll } = useReconPayrollQuery(weekOf, status)

  const isLoading = useMemo(() => status === 'pending' || isLoadingPayroll, [status, isLoadingPayroll])

  const handleTabChange = (index: number) => {
    const searchParams = new URLSearchParams(location.search)
    const path = index === 1 ? '/recon/payroll/timesheets' : '/recon/payroll'
    navigate({ pathname: path, search: searchParams.toString() })
  }

  return (
    <ReconAppPage
      header={`Weekly Payroll • ${year} w${weekNumber}`}
      subHeader={weekText}
      actions={<ReconPayrollActions />}
      isLoading={isLoading}
    >
      <TabsWrapper $isLoading={isLoading}>
        <Tabs id='payroll-tabs' onChange={handleTabChange} selected={selectedTab} shouldUnmountTabPanelOnChange>
          <TabList>
            <Tab>Payroll</Tab>
            <Tab>Timesheets</Tab>
          </TabList>
        </Tabs>

        <div className='tab-panel-wrapper'>
          {!installers ? (
            status === 'pending' ? (
              <div>Loading...</div>
            ) : (
              <div>Error loading data</div>
            )
          ) : (
            <TableWrapper>
              {selectedTab === 0 ? <ReconPayrollTable /> : <WeeklyTimecardsTab />}
              <div className='footer-spacer' />
            </TableWrapper>
          )}
        </div>
      </TabsWrapper>
    </ReconAppPage>
  )
}

export default ReconPayrollPage

export const useReconPayrollWeekText = () => {
  const { paramValues } = useReconSearchParams()
  const weekId = paramValues.get('weekId') // weekId is a string like 'YYYY-M'
  return useMemo(() => {
    if (!weekId) {
      const today = Temporal.Now.plainDateISO()
      const dayOfWeek = today.dayOfWeek
      const weekOf = today.subtract({ days: dayOfWeek - 1 })
      return { weekText: getWeekText(weekOf), weekOf }
    }
    const adjustedDate = getMondayDateFromWeekId(weekId)
    const weekText = getWeekText(adjustedDate)
    return { weekText, weekOf: adjustedDate, weekNumber: Number(adjustedDate.weekOfYear), year: Number(adjustedDate.yearOfWeek) }
  }, [weekId])
}

const TableWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  inset: 0;
  border: 2px solid ${token('color.border')};
  border-radius: 4px;

  .footer-spacer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    height: 2px;
    width: 100%;
    opacity: 0;
    background: ${token('elevation.surface')};
  }
`

const TabsWrapper = styled.div<{ $isLoading: boolean }>`
  position: absolute;
  inset: 0px 32px 16px 32px;
  opacity: ${props => (props.$isLoading ? 0.5 : 1)};
  transition: opacity 0.2s ease-in-out;
  .tab-panel-wrapper {
    position: absolute;
    inset: 42px 0 0 0;
    /* [role='tabpanel'] {
      height: 100%;
      position: relative;
    } */
  }
`

function getWeekText(weekOf: Temporal.PlainDate) {
  const startOfWeek = weekOf
  const endOfWeek = startOfWeek.add({ days: 6 })
  return `${startOfWeek.toLocaleString('en-US', {
    // weekday: 'short',
    month: 'short',
    day: 'numeric',
  })} — ${endOfWeek.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
  })}`
}
