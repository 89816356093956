import { Temporal } from '@js-temporal/polyfill'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { toast } from 'sonner'

import { useGraphQLClient } from '@/utils/useGraphQLClient'
import { titleCaseFromCamelCase } from '@/utils/utilities'

import { graphql } from '@/gql'
import { SubmitInstallerPayrollValueMutationVariables } from '@/gql/graphql'

import { GET_INSTALLER_PAYROLL_QUERY_KEY } from '../../ReconPayrollInstaller/hooks/usePaystubQuery'
import { installerPayrollWithDateKeysByIdAtom } from '../reconPayrollAtoms'

import { parseInstallerWeeklyPayroll } from './useReconPayrollQuery'

export const useSubmitInstallerPayroll = () => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const setPayrollByInstallerId = useSetAtom(installerPayrollWithDateKeysByIdAtom)
  const { mutate, mutateAsync, isPending } = useMutation({
    mutationFn: async (variables: SubmitInstallerPayrollValueMutationVariables) => {
      const fieldName = titleCaseFromCamelCase(variables.attribute)
      const toastId = `${variables.attribute}-${variables.value}-${variables.installerId}`
      toast.loading(`Updating field: ${fieldName}`, { id: toastId })

      const result = await graphQLClient.request(SUBMIT_INSTALLER_PAYROLL_VALUE, variables)
      if (!result.submitInstallerPayrollValue.success) {
        console.error('## submitInstallerPayrollValue error', result.submitInstallerPayrollValue)
        toast.error(result.submitInstallerPayrollValue.message, { id: toastId })
        throw new Error(result.submitInstallerPayrollValue.message)
      }

      return result
    },
    onSuccess: (result, variables) => {
      const fieldName = titleCaseFromCamelCase(variables.attribute)
      const toastId = `${variables.attribute}-${variables.value}-${variables.installerId}`
      toast.success(result.submitInstallerPayrollValue.message || `Successfully updated field: ${fieldName}`, { id: toastId })
      const weekOf = Temporal.PlainDate.from(variables.weekOf)
      const year = weekOf.yearOfWeek
      const weekNumber = weekOf.weekOfYear
      const newWeeklyStats = result.submitInstallerPayrollValue?.installerWeeklyPayroll
      queryClient.invalidateQueries({ queryKey: [GET_INSTALLER_PAYROLL_QUERY_KEY, year, weekNumber, variables.installerId] })
      if (newWeeklyStats) {
        setPayrollByInstallerId(prev => ({
          ...prev,
          [variables.installerId]: parseInstallerWeeklyPayroll(newWeeklyStats),
        }))
      }
    },
  })

  return { mutate, mutateAsync, isPending }
}

const SUBMIT_INSTALLER_PAYROLL_VALUE = graphql(/* GraphQL */ `
  mutation SubmitInstallerPayrollValue($weekOf: Date!, $installerId: ID!, $attribute: String!, $value: String, $date: Date) {
    submitInstallerPayrollValue(weekOf: $weekOf, installerId: $installerId, attribute: $attribute, value: $value, date: $date) {
      success
      message
      installerWeeklyPayroll {
        ...InstallerWeeklyPayrollFragment
      }
    }
  }
`)
