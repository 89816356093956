import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const isRefreshingTokensAtom = atom(false)
export const showMinutesAsHrDecimalAtom = atomWithStorage('showMinutesAsHrDecimal', false, undefined, { getOnInit: true })

interface UnifiedToken {
  accessToken: string
  accessTokenExpiration: string
  refreshToken: string
  refreshTokenExpiration: string
}

export const unifiedTokenAtom = atomWithStorage<UnifiedToken>(
  'thermalUnifiedToken',
  {
    accessToken: '',
    accessTokenExpiration: '',
    refreshToken: '',
    refreshTokenExpiration: '',
  },
  undefined,
  { getOnInit: true }
)
