// ui/src/components/MiniCalendarNav/NavRow.tsx

import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left-large'
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right-large'

import { IconButton } from '@atlaskit/button/new'
import { useSetAtom } from 'jotai'
import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { directionAtom, isMonthYearPickerOpenAtom, pageAtom } from './atoms'
import { useDisplayMonthYear } from './useDisplayMonthYear'

// Convert month number to name
function monthName(month: number): string {
  return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][
    month - 1
  ]
}

const NavRow: React.FC = () => {
  const [, setSearchParams] = useSearchParams()
  const { month, year } = useDisplayMonthYear(false)
  const { previousMonthYear, nextMonthYear } = useMemo(() => {
    let previousMonthNumber = month - 1
    let previousYear = year
    if (previousMonthNumber < 1) {
      previousMonthNumber = 12
      previousYear -= 1
    }
    let nextMonthNumber = month + 1
    let nextYear = year
    if (nextMonthNumber > 12) {
      nextMonthNumber = 1
      nextYear += 1
    }
    return {
      previousMonthYear: { monthNumber: previousMonthNumber, year: previousYear },
      nextMonthYear: { monthNumber: nextMonthNumber, year: nextYear },
    }
  }, [month, year])
  const setPage = useSetAtom(pageAtom)
  const setDirection = useSetAtom(directionAtom)
  const setIsMonthYearPickerOpen = useSetAtom(isMonthYearPickerOpenAtom)
  return (
    <NavRowWrapper>
      <th />
      <th>
        <IconButton appearance='subtle' icon={ChevronLeftIcon} label='Previous Month' onClick={onPrevMonth} spacing='compact' />
      </th>
      <th colSpan={5}>
        <MonthYearButton onClick={handleMonthYearClick}>
          {monthName(month)} {year}
        </MonthYearButton>
      </th>
      <th>
        <IconButton appearance='subtle' icon={ChevronRightIcon} label='Next Month' onClick={onNextMonth} spacing='compact' />
      </th>
    </NavRowWrapper>
  )

  function handleMonthYearClick() {
    setIsMonthYearPickerOpen(true)
  }

  function onPrevMonth() {
    setPage(prev => prev - 1)
    setDirection(-1)
    setSearchParams(prev => {
      const cId = `${previousMonthYear.year}-${previousMonthYear.monthNumber}`
      prev.set('cId', cId)
      return prev
    })
  }

  function onNextMonth() {
    setPage(prev => prev + 1)
    setDirection(1)
    setSearchParams(prev => {
      const cId = `${nextMonthYear.year}-${nextMonthYear.monthNumber}`
      prev.set('cId', cId)
      return prev
    })
  }
}

export default NavRow

const NavRowWrapper = styled.tr`
  > :nth-child(2),
  > :nth-child(4) {
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
  }
  > :nth-child(3) {
  }
`

const MonthYearButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${token('color.text', '#172B4D')};
  font-size: 14px;
  font-weight: 600;
  &:hover {
    color: ${token('color.link.pressed', '#0052CC')};
    text-decoration: underline;
  }
`
