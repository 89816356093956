import { useMutation } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import { useCallback } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { ErrorMessage, Label } from '@atlaskit/form'
import Heading from '@atlaskit/heading'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import { RadioGroup } from '@atlaskit/radio'
import { OptionsPropType } from '@atlaskit/radio/types'
import Textfield from '@atlaskit/textfield'

import { useGraphQLClient } from '@/utils/useGraphQLClient'
import { formatPhoneNumberString } from '@/utils/utilities'

import { graphql } from '@/gql'
import { CreateCustomerMutationMutationVariables as MutationVariables } from '@/gql/graphql'

const customerTypeOptions: OptionsPropType = [
  { name: 'isBusiness', value: 'Yes', label: 'Builder' },
  { name: 'isBusiness', value: 'No', label: 'Single Family' },
]

type FormInputs = {
  isBusiness: string
  businessName: string
  businessPhone: string
  firstName: string
  lastName: string
  phoneOffice: string
  phoneMobile: string
  email: string
}

export default function CreateCustomerForm({ setIsOpen }: { setIsOpen: (isOpen: boolean) => void }) {
  const navigate = useNavigate()

  const graphQLClient = useGraphQLClient()

  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: MutationVariables) => graphQLClient.request(CREATE_CUSTOMER_MUTATION, variables),
  })

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { errors },
    watch,
  } = useForm<FormInputs>()

  const isBusiness = watch('isBusiness')

  const closeModal = useCallback(() => {
    reset()
    setIsOpen(false)
  }, [reset, setIsOpen])

  const onSubmit: SubmitHandler<FormInputs> = data => {
    const variables = { ...data, isBusiness: data.isBusiness === 'Yes' }
    console.log('onSubmit variables', variables)
    mutate(variables, { onSuccess: data => navigate(`/customers/${data.createCustomer.id}`) })
  }

  const formIsInvalid = Object.keys(errors).length > 0
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <ModalTitle>Create Customer</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <StyledModalContent>
          <FieldWrapper>
            <Controller
              name='isBusiness'
              control={control}
              defaultValue={'Yes'}
              rules={{ required: true }}
              render={({ field: { value, ...field } }) => (
                <>
                  <Label htmlFor='basic-textfield'>Customer Type</Label>
                  <RadioGroup options={customerTypeOptions} value={value} {...field} />
                </>
              )}
            />
          </FieldWrapper>

          {isBusiness === 'Yes' || isBusiness === undefined ? (
            <>
              <Heading size='medium'>Builder Attributes</Heading>

              <FieldWrapper style={{ marginTop: 12 }}>
                <Controller
                  name='businessName'
                  control={control}
                  defaultValue=''
                  rules={{
                    minLength: 2,
                    maxLength: 255,
                    validate: {
                      hasOneName: (value, formValues) => {
                        return Boolean(formValues.firstName || value || formValues.lastName)
                      },
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <Label htmlFor='basic-textfield'>Business Name</Label>
                      <Textfield
                        isInvalid={!!errors?.businessName}
                        {...field}
                        onChange={async e => {
                          field.onChange(e)
                          if (errors?.firstName || errors?.lastName) await trigger(['firstName', 'lastName'])
                        }}
                      />

                      {errors?.businessName?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
                      {errors?.businessName?.type === 'hasOneName' ? (
                        <ErrorMessage>Must include either Business Name, First Name, or Last Name.</ErrorMessage>
                      ) : null}
                    </>
                  )}
                />
              </FieldWrapper>

              <FieldWrapper style={{ marginBottom: 24 }}>
                <Controller
                  name='businessPhone'
                  control={control}
                  defaultValue=''
                  rules={{ validate: value => !value || value.length === 0 || value.replace(/[^\d]/g, '').length === 10 }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Label htmlFor='basic-textfield'>Business Phone</Label>
                      <Textfield
                        isInvalid={!!errors?.businessPhone}
                        value={value}
                        onChange={e => onChange(formatPhoneNumberString((e.target as HTMLInputElement).value))}
                      />

                      {errors?.businessPhone?.type === 'validate' ? <ErrorMessage>Must be 10 digits or blank.</ErrorMessage> : null}
                    </>
                  )}
                />
              </FieldWrapper>
            </>
          ) : null}

          <motion.div layout>
            <Heading size='medium'>Primary Contact</Heading>

            <FieldWrapper style={{ marginTop: 12 }}>
              <Controller
                name='firstName'
                control={control}
                defaultValue=''
                rules={{
                  minLength: 2,
                  maxLength: 255,
                  validate: {
                    hasOneName: (value, formValues) => {
                      return Boolean(formValues.lastName || value || formValues.businessName)
                    },
                  },
                }}
                render={({ field }) => (
                  <>
                    <Label htmlFor='basic-textfield'>First Name</Label>
                    <Textfield
                      isInvalid={!!errors?.firstName}
                      {...field}
                      onChange={async e => {
                        field.onChange(e)
                        if (errors?.businessName || errors?.lastName) await trigger(['businessName', 'lastName'])
                      }}
                    />

                    {errors?.firstName?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
                    {errors?.firstName?.type === 'hasOneName' ? (
                      <ErrorMessage>Must include either Business Name, First Name, or Last Name.</ErrorMessage>
                    ) : null}
                  </>
                )}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Controller
                name='lastName'
                control={control}
                defaultValue=''
                rules={{
                  minLength: 2,
                  maxLength: 255,
                  validate: {
                    hasOneName: (value, formValues) => {
                      return Boolean(formValues.firstName || value || formValues.businessName)
                    },
                  },
                }}
                render={({ field }) => (
                  <>
                    <Label htmlFor='basic-textfield'>Last Name</Label>
                    <Textfield
                      isInvalid={!!errors?.lastName}
                      {...field}
                      onChange={async e => {
                        field.onChange(e)
                        if (errors?.businessName || errors?.firstName) await trigger(['businessName', 'firstName'])
                      }}
                    />

                    {errors?.lastName?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
                    {errors?.lastName?.type === 'hasOneName' ? (
                      <ErrorMessage>Must include either Business Name, First Name, or Last Name.</ErrorMessage>
                    ) : null}
                  </>
                )}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Controller
                name='phoneOffice'
                control={control}
                defaultValue=''
                rules={{ validate: value => value.length === 0 || value.replace(/[^\d]/g, '').length === 10 }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Label htmlFor='basic-textfield'>Phone (Office)</Label>
                    <Textfield
                      isInvalid={!!errors?.phoneOffice}
                      value={value}
                      onChange={e => {
                        const newValue = formatPhoneNumberString((e.target as HTMLInputElement).value)
                        onChange(newValue)
                      }}
                    />

                    {errors?.phoneOffice?.type === 'validate' ? <ErrorMessage>Must be 10 digits or blank.</ErrorMessage> : null}
                  </>
                )}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Controller
                name='phoneMobile'
                control={control}
                defaultValue=''
                rules={{ validate: value => value.length === 0 || value.replace(/[^\d]/g, '').length === 10 }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Label htmlFor='basic-textfield'>Phone (Mobile)</Label>
                    <Textfield
                      isInvalid={!!errors?.phoneMobile}
                      value={value}
                      onChange={e => {
                        const newValue = formatPhoneNumberString((e.target as HTMLInputElement).value)
                        onChange(newValue)
                      }}
                    />

                    {errors?.phoneMobile?.type === 'validate' ? <ErrorMessage>Must be 10 digits or blank.</ErrorMessage> : null}
                  </>
                )}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Controller
                name='email'
                control={control}
                defaultValue=''
                rules={{
                  validate: value => {
                    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    return regex.test(value) || value.length === 0
                  },
                }}
                render={({ field }) => (
                  <>
                    <Label htmlFor='basic-textfield'>Email</Label>
                    <Textfield isInvalid={!!errors?.email} {...field} />

                    {errors?.email?.type === 'validate' ? <ErrorMessage>Must be a valid email address or blank.</ErrorMessage> : null}
                  </>
                )}
              />
            </FieldWrapper>
          </motion.div>
        </StyledModalContent>
      </ModalBody>
      <ModalFooter>
        <Button appearance='subtle' onClick={closeModal} isDisabled={isPending}>
          Close
        </Button>

        <Button isLoading={isPending} appearance='primary' type='submit' isDisabled={formIsInvalid}>
          Create
        </Button>
      </ModalFooter>
    </form>
  )
}
const FieldWrapper = styled.div`
  margin-bottom: 18px;
`
const StyledModalContent = styled.div`
  padding-bottom: 100px;
`

const CREATE_CUSTOMER_MUTATION = graphql(/* GraphQL */ `
  mutation CreateCustomerMutation(
    $isBusiness: Boolean!
    $businessName: String
    $businessPhone: String
    $firstName: String
    $lastName: String
    $phoneOffice: String
    $phoneMobile: String
    $email: String
  ) {
    createCustomer(
      isBusiness: $isBusiness
      businessName: $businessName
      businessPhone: $businessPhone
      firstName: $firstName
      lastName: $lastName
      phoneOffice: $phoneOffice
      phoneMobile: $phoneMobile
      email: $email
    ) {
      id
      sid
      name
      businessName
      businessPhone
      email
      isBusiness
      createdAt

      createdBy {
        sid
        fullName
      }

      contacts {
        id
        sid
        contactType
        emailPrimary
        emailSecondary
        firstName
        lastName
        phoneMobile
        phoneOffice
      }

      locations {
        place {
          displayName
          city
          state
          streetNumber
          websiteUri
          streetAddressShort
          googleMapsUri
          formattedAddress
          formattedPhoneNumber
          zip
        }
      }
    }
  }
`)
