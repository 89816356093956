import { ReactElement } from 'react'
import styled from 'styled-components'

import { Checkbox } from '@atlaskit/checkbox'

import AbsoluteTooltip from '@/components/AbsoluteTooltip'
import HoursMinutesInput from '@/components/HoursMinutesInput'
import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'

import { SubmitInstallerPayoutValueMutationVariables } from '@/gql/graphql'

import { INSTALLER_CELL_PAD } from '../constants'
import { InstallerDispatchPayoutType } from '../useDispatchPayoutQuery'

import CellHourlyRate from './CellHourlyRate'
import CellHourlyRateDrive from './CellHourlyRateDrive'
import CellPayoutAddons from './CellPayoutAddons'
import CellPayoutPieceRate from './CellPayoutPieceRate'
import CellPercentAddons from './CellPercentAddons'
import CellPercentPieceRate from './CellPercentPieceRate'
import { InstallerColumnGroup, InstallerColumnKey } from './constants'
import InstallersTableNameCell from './InstallersTableNameCell'
import PayoutModeCell from './PayoutModeCell'
import { InstallerPropertiesSetterArgs } from './useInstallerProps'

export interface InstallersTableRowRenderArgs {
  installerDispatch: InstallerDispatchPayoutType
  isLastOfVehicle: boolean
  isFirstOfVehicle: boolean
  vehicleInstallerCount: number
  vehicleBackground: string
  color: string
  columnGroups: InstallerColumnGroup[]
  workOrderId?: string
  shouldLockPieceRate: boolean
  shouldLockAddons: boolean
  canApprovePayout: boolean
  dispatchEnteredPieceRate: string
  dispatchEnteredAddons: string
  setInstallerAmounts: (args: InstallerPropertiesSetterArgs) => void
  mutate: (variables: SubmitInstallerPayoutValueMutationVariables) => void
  handleReturn: (e: React.KeyboardEvent<HTMLInputElement>) => void
  handleFocus: (e: React.FocusEvent<HTMLInputElement>) => void
}

type ColumnRender = (props: InstallersTableRowRenderArgs) => ReactElement

export const COL_RENDER_DEFS: Record<InstallerColumnKey, ColumnRender> = {
  // ASSIGNMENT (3)
  vehicle: ({ installerDispatch, isLastOfVehicle, isFirstOfVehicle, vehicleInstallerCount, vehicleBackground, color }) => (
    <div
      style={{
        padding: '0 8px',
        position: 'absolute',
        inset: 0,
        left: 1,
        verticalAlign: 'middle',
        alignItems: 'center',
        display: 'flex',
        background: vehicleBackground,
        color,
        borderBottomColor: isLastOfVehicle ? undefined : vehicleBackground,
        whiteSpace: vehicleInstallerCount === 1 ? 'nowrap' : 'normal',
        fontWeight: 'bold',
      }}
      className={'col-vehicle' + (isFirstOfVehicle ? ' vehicle-name-cell' : '')}
    >
      {isFirstOfVehicle ? installerDispatch?.vehicleName : ''}
    </div>
  ),

  installer: ({ installerDispatch: { installer, isHourlyInstaller } }) => (
    <InstallersTableNameCell installer={installer} isHourlyInstaller={isHourlyInstaller === true} />
  ),

  payoutMode: ({
    installerDispatch: { isHourlyPayout, isCompleted, isDavisBacon, id: installerDispatchId, isPayrollLocked },
    setInstallerAmounts,
    mutate,
  }) => (
    <PayoutModeCell
      isDavisBacon={isDavisBacon}
      isHourlyPayout={isHourlyPayout}
      isCompleted={isCompleted}
      isPayrollLocked={isPayrollLocked}
      onClick={() => {
        if (isDavisBacon) return
        const newValue = !isHourlyPayout
        console.log('handlePayoutModeChange: ', { newValue, isHourlyPayout })
        setInstallerAmounts({ key: 'isHourlyPayout', value: newValue })
        mutate({ installerDispatchId, attribute: 'isHourlyPayout', value: newValue ? 'true' : 'false' })
      }}
    />
  ),

  // ON-SITE (2)
  calculatedMinutesOnSite: ({ installerDispatch: { calculatedMinutesOnSite, minutesWorked } }) => {
    let strikethrough = false
    let strikethroughReason = ''
    if (calculatedMinutesOnSite && minutesWorked && minutesWorked !== 0 && minutesWorked !== calculatedMinutesOnSite) {
      strikethrough = true
      strikethroughReason = 'Hours Worked overrides the vehicle on-site time.'
    }
    return (
      <>
        <HrMinCell value={calculatedMinutesOnSite} strikethrough={strikethrough} />
        {strikethrough && <AbsoluteTooltip content={strikethroughReason} position='right' />}
      </>
    )
  },

  minutesWorked: ({
    installerDispatch: { minutesWorked, isCompleted, id: installerDispatchId, isPayrollLocked },
    setInstallerAmounts,
    mutate,
  }) => (
    <HoursMinutesInput
      usedWithReadOnly
      minutes={minutesWorked ? Number(minutesWorked) : null}
      isDisabled={isCompleted || isPayrollLocked}
      onChangeCommit={(newMinutes: number | null) => {
        const value = newMinutes === null ? '' : newMinutes.toString()
        setInstallerAmounts({ key: 'minutesWorked', value: newMinutes })
        mutate({ installerDispatchId, attribute: 'minutesWorked', value })
      }}
    />
  ),

  // HOURLY PAYOUT (3)
  hourlyRate: props => <CellHourlyRate {...props} />,

  payoutDavisBacon: ({ installerDispatch: { payoutDavisBacon } }) => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={payoutDavisBacon} prefix='$' />
  ),

  payoutHourly: ({ installerDispatch: { payoutHourly } }) => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={payoutHourly} prefix='$' />
  ),

  minutesDriveActual: ({ installerDispatch: { minutesDriveActual } }) => <HrMinCell value={minutesDriveActual} />,

  calculatedMinutesDriveBuffer: ({
    installerDispatch: { isHourlyPayout, calculatedMinutesDriveBuffer, minutesDrivePayableOverride, isHourlyInstaller },
  }) => {
    let strikethrough = false
    let strikethroughReason = ''
    if (isHourlyPayout && isHourlyInstaller) {
      strikethrough = true
      strikethroughReason = 'Buffer does not apply to hourly installers in Hourly payout mode.'
    } else if (minutesDrivePayableOverride !== null) {
      strikethrough = true
      strikethroughReason = 'Drive hours have been overridden.'
    }

    return (
      <>
        <HrMinCell value={calculatedMinutesDriveBuffer} strikethrough={strikethrough} />
        {strikethrough && <AbsoluteTooltip content={strikethroughReason} position='right' />}
      </>
    )
  },

  minutesDrivePayable: ({ installerDispatch: { minutesDrivePayable } }) => <HrMinCell value={minutesDrivePayable} />,

  minutesDrivePayableOverride: ({
    installerDispatch: { minutesDrivePayableOverride, isCompleted, id: installerDispatchId, isPayrollLocked },
    setInstallerAmounts,
    mutate,
  }) => (
    <HoursMinutesInput
      usedWithReadOnly
      minutes={minutesDrivePayableOverride === null ? null : Number(minutesDrivePayableOverride)}
      isDisabled={isCompleted || isPayrollLocked}
      onChangeCommit={(newMinutes: number | null) => {
        const value = newMinutes === null ? '' : newMinutes.toString()
        setInstallerAmounts({ key: 'minutesDrivePayableOverride', value })
        mutate({ installerDispatchId, attribute: 'minutesDrivePayableOverride', value })
      }}
    />
  ),

  hourlyRateDrive: props => <CellHourlyRateDrive {...props} />,

  payoutDrive: ({ installerDispatch: { payoutDrive } }) => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={payoutDrive} prefix='$' />
  ),

  percentPieceRate: props => <CellPercentPieceRate {...props} />,

  isPercentPieceRateLocked: ({
    installerDispatch: { isPercentPieceRateLocked, isPayrollLocked, isCompleted, id: installerDispatchId },
    setInstallerAmounts,
    mutate,
  }) => (
    <div className='checkbox-wrapper'>
      <Checkbox
        isChecked={isPercentPieceRateLocked || isPayrollLocked || isCompleted}
        onChange={() => {
          const newValue = !isPercentPieceRateLocked
          setInstallerAmounts({ key: 'isPercentPieceRateLocked', value: newValue })
          mutate({ installerDispatchId, attribute: 'isPercentPieceRateLocked', value: newValue ? 'true' : 'false' })
        }}
        isDisabled={isCompleted || isPayrollLocked}
      />
    </div>
  ),

  calculatedPayoutPieceRate: props => <CellPayoutPieceRate {...props} />,

  payoutPieceRateDavisBaconGap: ({ installerDispatch: { payoutPieceRateDavisBaconGap } }) => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={payoutPieceRateDavisBaconGap} prefix='$' />
  ),

  percentAddons: props => <CellPercentAddons {...props} />,

  isPercentAddonsLocked: ({
    installerDispatch: { isPercentAddonsLocked, isPayrollLocked, isCompleted, id: installerDispatchId },
    setInstallerAmounts,
    mutate,
  }) => (
    <div className='checkbox-wrapper'>
      <Checkbox
        isChecked={isPercentAddonsLocked || isPayrollLocked || isCompleted}
        onChange={() => {
          const newValue = !isPercentAddonsLocked
          setInstallerAmounts({ key: 'isPercentAddonsLocked', value: newValue })
          mutate({ installerDispatchId, attribute: 'isPercentAddonsLocked', value: newValue ? 'true' : 'false' })
        }}
        isDisabled={isCompleted || isPayrollLocked}
      />
    </div>
  ),

  calculatedPayoutAddons: props => <CellPayoutAddons {...props} />,

  minutesActual: ({ installerDispatch: { minutesActual } }) => <HrMinCell value={minutesActual} />,

  payoutTotal: ({ installerDispatch: { payoutTotal } }) => (
    <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={payoutTotal} prefix='$' />
  ),

  isCompleted: ({
    installerDispatch: { isCompleted, isApproved, id: installerDispatchId, isPayrollLocked },
    canApprovePayout,
    vehicleBackground,
    setInstallerAmounts,
    mutate,
  }) => (
    <CheckboxWrapper style={{ background: vehicleBackground }}>
      <div className='checkbox-wrapper'>
        <Checkbox
          isChecked={isCompleted}
          onChange={() => {
            const newValue = !isCompleted
            setInstallerAmounts({ key: 'isCompleted', value: newValue })
            mutate({ installerDispatchId, attribute: 'isCompleted', value: newValue ? 'true' : 'false' })
          }}
          isDisabled={(isApproved && !canApprovePayout) || isPayrollLocked}
        />
      </div>
    </CheckboxWrapper>
  ),

  isApproved: ({
    installerDispatch: { isApproved, id: installerDispatchId, isPayrollLocked },
    vehicleBackground,
    canApprovePayout,
    setInstallerAmounts,
    mutate,
  }) => (
    <CheckboxWrapper style={{ background: vehicleBackground }}>
      <div className='checkbox-wrapper'>
        <Checkbox
          isChecked={isApproved}
          onChange={() => {
            const newValue = !isApproved
            setInstallerAmounts({ key: 'isApproved', value: newValue })
            mutate({ installerDispatchId, attribute: 'isApproved', value: newValue ? 'true' : 'false' })
          }}
          isDisabled={!canApprovePayout || isPayrollLocked}
        />
      </div>
    </CheckboxWrapper>
  ),
}

const CheckboxWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
