import { useAtomValue } from 'jotai'

import { isShowLookupsAtom } from '../reconPayrollAtoms'

import { PAYROLL_ROWS, SIMPLE_ROWS } from './reconPayrollRowDefs'

export const usePayrollRows = () => {
  const isShowLookups = useAtomValue(isShowLookupsAtom)

  return isShowLookups ? PAYROLL_ROWS : SIMPLE_ROWS
}
