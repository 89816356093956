import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'

import { GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY, InstallerDispatchPayoutType } from '../useDispatchPayoutQuery'

interface UseInstallerDispatchSelectorProps {
  installerDispatchId: string
  workOrderId?: string
}

export const useInstallerDispatchSelector = ({
  installerDispatchId,
  workOrderId,
}: UseInstallerDispatchSelectorProps): InstallerDispatchPayoutType | undefined => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const queryKey = useMemo(() => [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY, workOrderId], [workOrderId])
  const query = queryCache.find({ queryKey })

  const data = queryClient.getQueryData<GetDispatchPayoutDetailsQuery>(queryKey)

  return useMemo(() => {
    if (!data?.workOrder || !installerDispatchId) return undefined

    for (const dispatch of data.workOrder.dispatches) {
      const installerDispatch = dispatch.installerDispatches.find(id => id.id === installerDispatchId)
      if (installerDispatch) return installerDispatch
    }

    return undefined
  }, [data, installerDispatchId])
}
