import { Temporal } from '@js-temporal/polyfill'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router'

import { checkValidDayNumber, checkValidMonthNumber, checkValidYearNumber } from '@/utils/utilities'

type SelectedDateResult = {
  day: number | null
  month: number | null
  year: number | null
  dateId: string | null
  date: Temporal.PlainDate | null
}

export const useSelectedDate = (): SelectedDateResult => {
  const [searchParams] = useSearchParams()
  const originalDateId = searchParams.get('dateId')
  return useMemo(() => {
    let day: number | null = null
    let month: number | null = null
    let year: number | null = null
    let date: Temporal.PlainDate | null = null
    const dateId: string | null = originalDateId ?? null
    if (originalDateId) {
      const [yearStr, monthStr, dayStr] = originalDateId.split('-')
      day = Number(dayStr)
      month = Number(monthStr)
      year = Number(yearStr)
    }
    if (!checkValidDayNumber(day)) day = null
    if (!checkValidMonthNumber(month)) month = null
    if (!checkValidYearNumber(year)) year = null
    if (day !== null && month !== null && year !== null) {
      date = Temporal.PlainDate.from({ year, month, day })
    }

    return { day, month, year, dateId, date }
  }, [originalDateId])
}
