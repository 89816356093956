import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useCallback, useEffect, useMemo } from 'react'

import { setGlobalTheme } from '@atlaskit/tokens'

export const isDarkModeAtom = atomWithStorage<boolean | null>('isDarkMode', null, undefined, { getOnInit: true })

export const useIsDarkMode = () => {
  const [isDarkModeOverride, setIsDarkModeOverride] = useAtom(isDarkModeAtom)
  const prefersDark = useMemo(() => window.matchMedia('(prefers-color-scheme: dark)').matches, [])
  const isDarkMode = isDarkModeOverride ?? prefersDark

  useEffect(() => {
    setGlobalTheme({ colorMode: isDarkMode ? 'dark' : 'light' })
  }, [isDarkMode])

  const toggleDarkMode = useCallback(() => {
    setIsDarkModeOverride(prev => (prev === null ? !prefersDark : !prev))
  }, [setIsDarkModeOverride, prefersDark])

  return { isDarkMode, toggleDarkMode }
}
