import { useMutation, useQueryClient } from '@tanstack/react-query'

import { GET_PAYROLL_DAY_VEHICLES_QUERY_KEY } from '@/utils/api/getPayrollDayVehicles'
import { GET_VEHICLE_DATE_QUERY_KEY } from '@/utils/api/getVehicleDate'
import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { SubmitInstallerPayoutValueMutationVariables } from '@/gql/graphql'

import { RECON_MONTH_STATS_QUERY_KEY } from '../ReconCalendar/useReconMonthStats'
import { useReconSearchParams } from '../useReconSearchParams'

import { GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY } from './useDispatchPayoutQuery'

export const useSubmitInstallerPayout = () => {
  const { paramValues } = useReconSearchParams()
  const dateId = paramValues.get('dateId')

  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (variables: SubmitInstallerPayoutValueMutationVariables) => {
      const response = await graphQLClient.request(SUBMIT_INSTALLER_PAYOUT_VALUE, variables)
      if (!response.submitInstallerPayoutValue?.success)
        throw new Error(response.submitInstallerPayoutValue?.message ?? 'Failed to submit addon')
      return response.submitInstallerPayoutValue
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY] })
      console.log('onSuccess: ', { variables })
      if (['isCompleted', 'isApproved'].includes(variables.attribute)) {
        console.log('onSuccess: invalidating reconMonthStats')

        queryClient.invalidateQueries({ queryKey: [RECON_MONTH_STATS_QUERY_KEY] })
        queryClient.invalidateQueries({ queryKey: [GET_PAYROLL_DAY_VEHICLES_QUERY_KEY, dateId] })
      }
      if (variables.attribute === 'isCompleted' || variables.attribute === 'isApproved') {
        console.log('onSuccess: invalidating getVehicleDate')
        queryClient.invalidateQueries({ queryKey: [GET_VEHICLE_DATE_QUERY_KEY] })
      }
    },
  })
}

const SUBMIT_INSTALLER_PAYOUT_VALUE = graphql(/* GraphQL */ `
  mutation SubmitInstallerPayoutValue($installerDispatchId: ID!, $attribute: String!, $value: String) {
    submitInstallerPayoutValue(installerDispatchId: $installerDispatchId, attribute: $attribute, value: $value) {
      success
      message
    }
  }
`)
