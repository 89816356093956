import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useAtom } from 'jotai'

import { columnSizingAtom, columnSizingInfoAtom, editorSelectionStateAtom } from '../editorAtoms'
import { EditorColumnKey } from '../editorTypes'

import { useColumnDefs } from './useColumnDefs'
import { useSortedWorkItems } from './useSortedWorkItems'

export const useTableState = () => {
  const data = useSortedWorkItems()
  // const [columnOrder, setColumnOrder] = useAtom(columnOrderAtom)
  const [columnSizing, setColumnSizing] = useAtom(columnSizingAtom)
  const [columnSizingInfo, setColumnSizingInfo] = useAtom(columnSizingInfoAtom)
  const columns = useColumnDefs()
  const [editorSelectionState, setEditorSelectionState] = useAtom(editorSelectionStateAtom)

  console.log('editorSelectionState', editorSelectionState)
  const table = useReactTable({
    data,
    columns,
    // Default column options to use for all column defs supplied to the table
    defaultColumn: {
      minSize: 30,
      maxSize: 800,
    },
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    initialState: {
      columnPinning: { left: ['dragHandle', 'select', 'indicators'] as EditorColumnKey[], right: ['totalPriceFinal'] as EditorColumnKey[] },
    },
    state: {
      // columnOrder,
      columnSizing,
      columnSizingInfo,
      rowSelection: editorSelectionState,
    }, // optional for where we control our own state
    getRowId: row => row.id,
    // onColumnOrderChange: newColumnOrder => setColumnOrder(newColumnOrder),
    enableColumnResizing: true,
    onColumnSizingChange: newColumnSizing => {
      // console.log('newColumnSizing', newColumnSizing)
      setColumnSizing(newColumnSizing)
    },
    onColumnSizingInfoChange: newColumnSizingInfo => {
      // console.log('newColumnSizingInfo', newColumnSizingInfo)
      setColumnSizingInfo(newColumnSizingInfo)
    },
    onRowSelectionChange: newRowSelection => {
      setEditorSelectionState(newRowSelection)
    },
    enableColumnPinning: true,
    enableRowSelection: row => !(row.original.workOrder?.isWorkCompleted === true),
  })

  return table
}
