import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useNavigate } from 'react-router'

import CrossIcon from '@atlaskit/icon/glyph/cross'

import Button, { IconButton } from '@atlaskit/button/new'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import { Flex, Grid, xcss } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { DeleteJobMutationVariables, GetJobQuery } from '@/gql/graphql'

import { GET_JOBS_QUERY_KEY } from '../JobsList/JobsList'

const gridStyles = xcss({
  width: '100%',
})

const closeContainerStyles = xcss({
  gridArea: 'close',
})

const titleContainerStyles = xcss({
  gridArea: 'title',
})
export type JobQueryJobType = GetJobQuery['job']

interface DeleteJobModalProps {
  job: JobQueryJobType
  onClose: () => void
  isOpen: boolean
}

const DeleteJobModal: React.FC<DeleteJobModalProps> = ({ job, isOpen, onClose }) => {
  const graphQLClient = useGraphQLClient()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { mutate: deleteJob, isPending } = useMutation({
    mutationFn: async (variables: DeleteJobMutationVariables) => {
      const result = await graphQLClient.request(DELETE_JOB_MUTATION, variables)
      if (!result.deleteJob.success) {
        console.error(result.deleteJob.message)
        throw new Error(result.deleteJob.message)
      }
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_JOBS_QUERY_KEY] })
      navigate('/jobs')
    },
  })
  return (
    <ModalTransition>
      {isOpen ? (
        <Modal onClose={onClose}>
          <ModalHeader>
            <Grid gap='space.200' templateAreas={['title close']} xcss={gridStyles}>
              <Flex xcss={closeContainerStyles} justifyContent='end'>
                <IconButton appearance='subtle' icon={CrossIcon} label='Close Modal' onClick={onClose} />
              </Flex>
              <Flex xcss={titleContainerStyles} justifyContent='start'>
                <ModalTitle appearance='danger'>You’re about to delete this job</ModalTitle>
              </Flex>
            </Grid>
          </ModalHeader>

          <ModalBody>
            <p>If you continue, you will delete it permanently{job.estimates === null ? '.' : ', along with the following:'}</p>
            <ul>
              <li>
                {job.estimates.length} Estimate(s):
                <ul>
                  {job.estimates.map(estimate => (
                    <li>
                      {estimate.title}
                      {!estimate.workItemCount && estimate.workOrders === null ? null : (
                        <ul>
                          {!estimate.workItemCount ? null : <li>{estimate.workItemCount} Work Items:</li>}

                          {estimate.workOrders === null ? null : (
                            <li>
                              {estimate.workOrders.length} Work Orders:
                              <ul>
                                {estimate.workOrders.map(workOrder => (
                                  <li>
                                    {workOrder.name}&nbsp;&nbsp;
                                    {workOrder.dispatches === null ? (
                                      <span style={{ color: token('color.text.disabled') }}>Unscheduled, </span>
                                    ) : (
                                      <span style={{ color: token('color.text.accent.yellow'), fontWeight: 'bold' }}>Dispatched, </span>
                                    )}
                                    {workOrder.isConfirmed ? (
                                      <span style={{ color: token('color.text.danger'), fontWeight: 'bold' }}>Confirmed</span>
                                    ) : (
                                      <span style={{ color: token('color.text.disabled') }}>Unconfirmed</span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </ModalBody>

          <ModalFooter>
            <Button appearance='subtle' onClick={onClose}>
              Cancel
            </Button>
            <Button appearance='danger' isDisabled={isPending} isLoading={isPending} onClick={handleDeleteJob}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </ModalTransition>
  )
  function handleDeleteJob() {
    console.log('delete job')
    deleteJob({ jobId: job.id })
  }
}

export default DeleteJobModal

const DELETE_JOB_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteJob($jobId: UUID!) {
    deleteJob(jobId: $jobId) {
      success
      message
    }
  }
`)
