import { useAtomValue, useSetAtom } from 'jotai'
import Select, { Theme } from 'react-select'

import { databaseEstimateWorkOrdersAtom, workItemEditsQueueAtom, workItemsOptimisticAttributesAtom } from '../editorAtoms'
import { UNIVERSAL_EDITOR_SELECT_PROPS } from '../editorConstants'
import { QueriedEstimateWorkOrder } from '../editorTypes'
import { useSelectColors } from '../hooks/useSelectColors'
import { useWorkItemAttribute } from '../hooks/useWorkItemAttribute'

type SelectWorkOrderOption = {
  label: string
  value: string
  workOrder: QueriedEstimateWorkOrder
}

interface WorkOrderSelectorCellProps {
  workItemId: string | null
}

const WorkOrderSelectorCell = ({ workItemId }: WorkOrderSelectorCellProps) => {
  const setWorkItemEditsQueue = useSetAtom(workItemEditsQueueAtom)
  const setOptimisticAttributes = useSetAtom(workItemsOptimisticAttributesAtom)
  const estimateWorkOrders = useAtomValue(databaseEstimateWorkOrdersAtom)

  const options = estimateWorkOrders.map((workOrder: QueriedEstimateWorkOrder) => ({
    label: workOrder.name,
    value: workOrder.id,
    workOrder,
  }))

  const workOrderId = useWorkItemAttribute('workOrderId', workItemId ?? 'null')
  const selectedOption = options.find(option => option.value === workOrderId)
  const handleChange = (option: SelectWorkOrderOption | null) => {
    setOptimisticAttributes(prev => {
      const key = workItemId ?? 'null'
      const existingWorkItem = prev?.[key] ?? {}
      return {
        ...prev,
        [key]: {
          ...existingWorkItem,
          workOrder: option?.workOrder,
          workOrderId: option?.workOrder?.id,
        },
      }
    })
    // @ts-expect-error Doesn't like JSON value
    if (workItemId !== 'NEW') setWorkItemEditsQueue(prev => [...prev, { workItemId, attribute: 'workOrderId', value: option?.value }])
  }

  const colors = useSelectColors()

  return (
    <div style={{ width: '100%', minWidth: 90 }}>
      <Select
        options={options}
        components={{ IndicatorSeparator: null, DropdownIndicator: null }}
        value={selectedOption ?? null}
        placeholder='Select Work Order'
        inputId='work-order-selector'
        classNamePrefix='work-order-selector'
        onChange={handleChange}
        theme={(theme: Theme) => ({
          ...theme,
          borderRadius: 0,
          colors,
        })}
        {...UNIVERSAL_EDITOR_SELECT_PROPS}
      />
    </div>
  )
}

export default WorkOrderSelectorCell

// const MaterialOptionComponent = (props: OptionProps<SelectMaterialOption>) => {
//   const { material } = props.data

//   return (
//     <components.Option {...props}>
//       <div style={{ display: 'flex', flexDirection: 'column' }}>
//         <div style={{ fontSize: '11px', color: token('color.text.disabled') }}>{material.itemCode}</div>
//         <div style={{ fontSize: '13px' }}>{material.name}</div>
//       </div>
//     </components.Option>
//   )
// }

// type SelectMaterialFilterOption = (
//   option: {
//     label: string
//     value: string
//     data: SelectMaterialOption
//   },
//   inputValue: string
// ) => boolean

// const filterOption: SelectMaterialFilterOption = (option, inputValue: string) => {
//   const { value, data } = option
//   const materialName = data?.material?.name ?? null
//   const matchesMaterialName = !materialName ? false : materialName.toLowerCase().includes(inputValue.toLowerCase())
//   const matchesWorkOrder = value.toLowerCase().includes(inputValue.toLowerCase())
//   return matchesWorkOrder || matchesMaterialName
// }
