import { useSearchParams } from 'react-router'

import { useStaffById } from './useStaffById'

export const useSelectedInstaller = () => {
  const [searchParams] = useSearchParams()
  const installerId = searchParams.get('installerId')
  const installer = useStaffById(installerId ?? undefined)
  return installer
}
