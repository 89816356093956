import { Autocomplete } from '@react-google-maps/api'
import React, { useState } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { Label } from '@atlaskit/form'
import Textfield from '@atlaskit/textfield'

import GoogleMapsProvider from '@/components/GoogleMapsProvider'

import { ExtendedPlaceInputType, parsePlaceResult } from '@/types/project-site'

interface AddPlaceFormProps {
  jobId: string
  onAdd: (place: ExtendedPlaceInputType) => void
  onCancel: () => void
}

const AddPlaceForm: React.FC<AddPlaceFormProps> = ({ onAdd, onCancel }) => {
  const [autocompleteRef, setAutocompleteRef] = useState<google.maps.places.Autocomplete | null>(null)
  const [address, setAddress] = useState('')

  const handlePlaceSelect = () => {
    if (autocompleteRef) {
      const place = autocompleteRef.getPlace()

      if (place.formatted_address) {
        setAddress(place.formatted_address)

        if (place.geometry?.location) {
          const newPlace = parsePlaceResult(place) as ExtendedPlaceInputType
          onAdd(newPlace)
        }
      }
    }
  }

  return (
    <GoogleMapsProvider>
      <FormContainer>
        <FormHeader>Add New Address</FormHeader>

        <div>
          <Label htmlFor='location-input'>Address</Label>
          <Autocomplete onLoad={autocomplete => setAutocompleteRef(autocomplete)} onPlaceChanged={handlePlaceSelect}>
            <Textfield
              id='location-input'
              value={address}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
              placeholder='Search for an address'
            />
          </Autocomplete>
        </div>

        <ButtonsContainer>
          <Button appearance='subtle' onClick={onCancel}>
            Cancel
          </Button>
        </ButtonsContainer>
      </FormContainer>
    </GoogleMapsProvider>
  )
}

const FormContainer = styled.div`
  background-color: #f4f5f7;
  padding: 16px;
  border-radius: 3px;
  margin-top: 16px;
`

const FormHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`

export default AddPlaceForm
