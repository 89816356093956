import { ChangeEvent } from 'react'

import { ErrorMessage, Label } from '@atlaskit/form'
import Textfield from '@atlaskit/textfield'

interface JobTitleInputProps {
  value: string
  onChange: (value: string) => void
  errors: Record<string, { type: string }>
}

export default function JobTitleInput({ value, onChange, errors }: JobTitleInputProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <div className='field-wrapper'>
      <Label htmlFor='basic-textfield'>Job Title</Label>
      <Textfield value={value} onChange={handleChange} isInvalid={!!errors?.title} />

      {errors?.title?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
    </div>
  )
}
