import React from 'react'
import styled from 'styled-components'

import { Label } from '@atlaskit/form'
import Heading from '@atlaskit/heading'
import Textfield from '@atlaskit/textfield'

import { ProjectSiteDataType } from '../types'

interface ProjectSiteFormProps {
  data: ProjectSiteDataType
  onChange: (data: ProjectSiteDataType) => void
}

const ProjectSiteForm: React.FC<ProjectSiteFormProps> = ({ data, onChange }) => {
  const handleChange = (field: keyof ProjectSiteDataType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...data,
      [field]: event.target.value,
    })
  }

  return (
    <FormContainer>
      <Heading size='small'>Project Site Details</Heading>

      <FieldGroup>
        <Label htmlFor='project-site-name'>Project Site Name</Label>
        <Textfield id='project-site-name' value={data.name} onChange={handleChange('name')} placeholder='Enter project site name' />
      </FieldGroup>

      <FieldGroup>
        <Label htmlFor='street-address'>Street Address</Label>
        <Textfield
          id='street-address'
          value={data.streetAddress}
          onChange={handleChange('streetAddress')}
          placeholder='Enter street address'
        />
      </FieldGroup>

      <FieldRow>
        <FieldGroup>
          <Label htmlFor='city'>City</Label>
          <Textfield id='city' value={data.city} onChange={handleChange('city')} placeholder='Enter city' />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='state'>State</Label>
          <Textfield id='state' value={data.state} onChange={handleChange('state')} placeholder='Enter state' />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='zip-code'>Zip Code</Label>
          <Textfield id='zip-code' value={data.zipCode} onChange={handleChange('zipCode')} placeholder='Enter zip code' />
        </FieldGroup>
      </FieldRow>

      <HelpText>Note: These details can be automatically populated from places you add below.</HelpText>
    </FormContainer>
  )
}

const FormContainer = styled.div`
  margin-bottom: 24px;
`

const FieldGroup = styled.div`
  margin-bottom: 16px;
  flex: 1;

  &:last-child {
    margin-bottom: 0;
  }
`

const FieldRow = styled.div`
  display: flex;
  gap: 16px;

  ${FieldGroup} {
    margin-bottom: 0;
  }
`

const HelpText = styled.p`
  font-size: 12px;
  color: #6b778c;
  margin-top: 8px;
  font-style: italic;
`

export default ProjectSiteForm
