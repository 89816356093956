import { Temporal } from '@js-temporal/polyfill'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router'

import { getMondayDateFromWeekId } from '@/utils/utilities'

interface ReconSearchParams {
  cId?: string
  weekId?: string
  dateId?: string
  installerId?: string
}
export const useReconSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const today = useMemo(() => Temporal.Now.plainDateISO(), [])

  const paramValues = useMemo(() => {
    const newParams = new URLSearchParams(searchParams)
    if (!newParams.has('cId')) {
      if (newParams.has('dateId')) {
        const dateId = newParams.get('dateId')
        const date = Temporal.PlainDate.from(dateId ?? today.toString())
        newParams.set('cId', `${date.year}-${date.month}`)
      } else if (newParams.has('weekId')) {
        const weekId = newParams.get('weekId')
        const monday = getMondayDateFromWeekId(weekId ?? today.toString())
        newParams.set('cId', `${monday.year}-${monday.month}`)
      } else {
        newParams.set('cId', `${today.year}-${today.month}`)
      }
    }
    return newParams
  }, [searchParams, today])

  const setParams = useCallback(
    (paramsToUpdate: Partial<ReconSearchParams>) => {
      const newParams = new URLSearchParams(searchParams)
      Object.entries(paramsToUpdate).forEach(([param, value]) => {
        newParams.delete(param)
        if (value !== undefined) newParams.set(param, value)
      })

      setSearchParams(newParams)
    },
    [setSearchParams, searchParams]
  )
  return { searchParams, paramValues, setParams }
}
