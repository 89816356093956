import { useQuery } from '@tanstack/react-query'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { GetInstallerPayrollQuery } from '@/gql/graphql'

import { useWeekYearInstallerParams } from './useWeekYearInstallerParams'

export type SingleInstallerWeeklyPayroll = NonNullable<GetInstallerPayrollQuery['installerPayroll']>
export type SingleInstallerDate = NonNullable<SingleInstallerWeeklyPayroll['installerDates']>[number]
export type SingleInstallerDispatches = NonNullable<SingleInstallerWeeklyPayroll['installerDispatches']>[number]

export const usePaystubQuery = () => {
  const { weekNumber, year, installerId } = useWeekYearInstallerParams()
  const graphqlClient = useGraphQLClient()

  return useQuery({
    queryKey: [GET_INSTALLER_PAYROLL_QUERY_KEY, year, weekNumber, installerId],
    queryFn: async () => {
      const response = await graphqlClient.request(GET_INSTALLER_PAYROLL, {
        weekYear: year,
        weekNumber,
        installerId: installerId ?? '',
      })
      if (!response.installerPayroll) {
        throw new Error('No week of data found')
      }
      return response
    },
  })
}

export const GET_INSTALLER_PAYROLL_QUERY_KEY = 'GET_INSTALLER_PAYROLL'
const GET_INSTALLER_PAYROLL = graphql(/* GraphQL */ `
  query GetInstallerPayroll($weekNumber: Int!, $weekYear: Int!, $installerId: ID!) {
    installerPayroll(weekNumber: $weekNumber, weekYear: $weekYear, installerId: $installerId) {
      ...InstallerWeeklyPayrollFragment
      installerDispatches {
        id
        sid
        installerId
        modifiedAt
        vehicleDispatchId
        vehicleDispatch {
          id
          vehicleDateId
          dispatchId
          dispatch {
            id
            date
            workOrderId
            workOrder {
              id
              name
              projectSiteAddress
              projectSiteStreetAddress
              projectSiteCity
              projectSiteState
              projectSiteZipCode
              estimateId
              customerName
            }
          }
        }
        date
        isHourlyPayout
        isDavisBacon
        isCompleted
        isApproved
        isHourlyInstaller

        calculatedMinutesDriveBuffer
        calculatedMinutesOnSite

        hourlyRate
        hourlyRateDrive

        minutesDriveActual
        minutesDrivePayable
        minutesDrivePayableOverride

        minutesWorked
        minutesActual

        payoutAddons
        payoutDavisBacon
        payoutDrive
        payoutHourly
        payoutPieceRate
        payoutPieceRateDavisBaconGap
        payoutTotal
        percentPieceRate
      }
    }
  }
`)
