import TableCellNumberTextField from '@/components/TableCellNumberTextField'

import { InstallersTableRowRenderArgs } from './columnRenderDefs'

const CellHourlyRateDrive = ({
  setInstallerAmounts,
  mutate,
  handleReturn,
  handleFocus,
  installerDispatch,
}: InstallersTableRowRenderArgs) => {
  const {
    hourlyRateDrive,
    isCompleted,
    isHourlyPayout,
    isHourlyInstaller,
    isApproved,
    hourlyRate,
    id: installerDispatchId,
    isPayrollLocked,
  } = installerDispatch

  let isDisabled = false
  let disabledReason = ''
  const useHourlyRate = isHourlyPayout && isHourlyInstaller
  if (isPayrollLocked) {
    isDisabled = true
    disabledReason = 'Payroll has been completed for the week.'
  } else if (useHourlyRate) {
    isDisabled = true
    disabledReason = 'Hourly installers get their hourly rate for drive time.'
  } else if (isCompleted || isApproved) {
    isDisabled = true
    disabledReason = 'This line has been ' + (isCompleted ? 'completed.' : 'approved.')
  }

  return (
    <TableCellNumberTextField
      isCompact
      isDisabled={isDisabled}
      value={useHourlyRate ? (hourlyRate ?? undefined) : (hourlyRateDrive ?? undefined)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInstallerAmounts({ key: 'hourlyRateDrive', value: e.target.value })}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value).toFixed(2)
        console.log('hourlyRateDrive: ', { value })
        setInstallerAmounts({ key: 'hourlyRateDrive', value })
        mutate({ installerDispatchId, attribute: 'hourlyRateDrive', value })
      }}
      tooltip={disabledReason}
      onKeyDown={handleReturn}
      onFocus={handleFocus}
      suffix='/ hr'
      prefix='$'
    />
  )
}

export default CellHourlyRateDrive
