import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { Fragment } from 'react'
import styled from 'styled-components'

import ButtonGroup from '@atlaskit/button/button-group'
import Button from '@atlaskit/button/new'
import Form, { ErrorMessage, Field, FormFooter, FormHeader, FormSection, HelperMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import { token } from '@atlaskit/tokens'

import { unifiedTokenAtom } from '@/utils/appAtoms'
import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'
import { SubmitTokenAuthMutationVariables as MutationVariables } from '@/gql/graphql'

const Login = () => {
  const setUnifiedToken = useSetAtom(unifiedTokenAtom)

  const graphQLClient = useGraphQLClient()
  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: MutationVariables) => graphQLClient.request(SUBMIT_AUTH_TOKEN_MUTATION, variables),
    onError: error => console.error(error),
    onSuccess: data => {
      if (data.tokenAuth.token) {
        setUnifiedToken({
          accessToken: data.tokenAuth.token.token,
          accessTokenExpiration: data.tokenAuth.token?.payload?.exp,
          refreshToken: data.tokenAuth?.refreshToken?.token ?? '',
          refreshTokenExpiration: data.tokenAuth?.refreshToken?.expiresAt ?? '',
        })
      }
    },
  })

  return (
    <PageWrapper>
      <title>Login - Thermal Shop Admin</title>
      <LoginBox>
        <Form<{ username: string; password: string }> onSubmit={handleSubmit}>
          {({ formProps, submitting }) => (
            <form {...formProps}>
              <FormHeader title='Thermal Shop Admin Sign in.'></FormHeader>

              <FormSection>
                <Field aria-required={true} name='username' label='Username' isRequired defaultValue=''>
                  {({ fieldProps, error }) => (
                    <Fragment>
                      <TextField autoComplete='off' {...fieldProps} />
                      {!error && <HelperMessage>You can use letters, numbers and periods.</HelperMessage>}
                      {error && <ErrorMessage>This username is already in use, try another one.</ErrorMessage>}
                    </Fragment>
                  )}
                </Field>

                <Field aria-required={true} name='password' label='Password' defaultValue='' isRequired>
                  {({ fieldProps, error }) => {
                    return (
                      <Fragment>
                        <TextField type='password' {...fieldProps} />
                        {error && <ErrorMessage>Authorization failed. Try again.</ErrorMessage>}
                      </Fragment>
                    )
                  }}
                </Field>
              </FormSection>

              <FormFooter>
                <ButtonGroup>
                  <Button type='submit' appearance='primary' isLoading={submitting || isPending}>
                    Sign In
                  </Button>
                </ButtonGroup>
              </FormFooter>
            </form>
          )}
        </Form>
      </LoginBox>
    </PageWrapper>
  )
  function handleSubmit(data: FormData) {
    mutate({ username: data.username, password: data.password })
  }
}

export default Login

type FormData = {
  username: string
  password: string
}

const PageWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${token('elevation.surface.sunken')};
`

const LoginBox = styled.div`
  width: 400px;
  background-color: ${token('elevation.surface')};
  border-radius: 4px;
  padding: 32px;
  border: 1px solid ${token('color.border')};
  border-radius: 8px;
`

const SUBMIT_AUTH_TOKEN_MUTATION = graphql(/* GraphQL */ `
  mutation SubmitTokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      success
      errors
      refreshToken {
        token
        expiresAt
      }
      token {
        token
        payload {
          exp
        }
      }
    }
  }
`)
