import { useMemo } from 'react'

import DetailViewIcon from '@atlaskit/icon/glyph/detail-view'
import HomeIcon from '@atlaskit/icon/glyph/home'
import PersonCircleIcon from '@atlaskit/icon/glyph/person-circle'
import TableIcon from '@atlaskit/icon/glyph/table'

import { useStaffListQuery } from '@/utils/queryHooks/useStaffListQuery'

import RenderNavLayer, { LinkItem, NavLayer } from '@/components/RenderNavLayer'

import { useReconSearchParams } from '../useReconSearchParams'

import DailyReconNav from './DailyReconNav'

// import InstallerHours from './payroll/InstallerHours'
// import InstallerPayouts from './reports/InstallerPayouts'
export const useMainLinks = (): NavLayer => {
  const { staffList } = useStaffListQuery()
  const { paramValues } = useReconSearchParams()

  const staffLinks: LinkItem[] = useMemo(() => {
    return staffList
      .filter(staff => staff.isInstaller)
      .sort((a, b) => {
        const lastNameCompare = a.lastName.localeCompare(b.lastName)
        return lastNameCompare !== 0 ? lastNameCompare : a.firstName.localeCompare(b.firstName)
      })
      .map(staff => {
        const newParams = new URLSearchParams(paramValues)
        newParams.delete('installerId')
        newParams.set('installerId', staff.id)
        const linkSection: LinkItem = {
          label: `${staff.lastName}, ${staff.firstName}`,
          link: `/recon/payroll/installer-paystubs?${newParams.toString()}`,
          isSelected: (path: string) => path.startsWith('/recon/payroll/installer-paystubs') && paramValues.get('installerId') === staff.id,
          iconBefore: <PersonCircleIcon label='Installer Paystubs' />,
        }
        return linkSection
      })
  }, [staffList, paramValues])

  const paramsWithoutInstallerId = new URLSearchParams(paramValues)
  paramsWithoutInstallerId.delete('installerId')
  const paramsString = paramsWithoutInstallerId.toString()
  return {
    headerTitle: 'Recon',
    headerDescription: 'Payroll, Miles, etc.',
    linkSections: [
      {
        sectionHeader: '',
        links: [
          {
            label: 'Recon Home',
            link: '/recon/?' + paramsString,
            isSelected: (path: string) => ['/recon', '/recon/'].includes(path),
            iconBefore: <HomeIcon label='Recon Home' />,
          },
          {
            label: 'Daily Recon',
            link: '/recon/daily?' + paramsString,
            isSelected: (path: string) => path.startsWith('/recon/daily'),
            iconBefore: <DetailViewIcon label='Daily Recon' />,
            nestedLayer: {
              headerTitle: 'Daily Recon',
              headerDescription: 'Daily Recon',
              backNavigateTo: '/recon/?' + paramsString,
              backLabel: 'Back to Recon',
              nestingId: '/recon/daily',
              linkSections: [],
              customComponent: <DailyReconNav />,
              customComponentTitle: 'Dispatches By Truck',
            },
          },
          {
            label: 'Weekly Payroll',
            link: '/recon/payroll?' + paramsString,
            isSelected: (path: string) => path.startsWith('/recon/payroll'),
            iconBefore: <TableIcon label='Weekly Payroll' />,
            nestedLayer: {
              headerTitle: 'Weekly Payroll',
              headerDescription: 'and InstallerPaystubs',
              backNavigateTo: '/recon/?' + paramsString,
              backLabel: 'Back to Recon',
              nestingId: '/recon/payroll',
              linkSections: [
                {
                  sectionHeader: '',
                  links: [
                    {
                      label: 'Weekly Payroll',
                      link: '/recon/payroll?' + paramsString,
                      iconBefore: <TableIcon label='Weekly Payroll' />,
                      isSelected: (path: string) => path.startsWith('/recon/payroll') && !path.includes('installer-paystubs'),
                    },
                  ],
                },
                {
                  sectionHeader: 'Installer Paystubs',
                  links: staffLinks,
                },
              ],
            },
          },
        ],
      },

      {
        sectionHeader: 'Reports',
        links: [
          {
            label: 'Financials By Work Order',
            link: '/recon/financials/work-order?' + paramsString,
            isSelected: (path: string) => path.startsWith('/recon/financials/work-order'),
          },
        ],
      },
    ],
  }
}
const MainLinks = () => {
  const navLayer = useMainLinks()

  return <RenderNavLayer navLayer={navLayer} />
}

export default MainLinks

// interface BackComponentProps {
//   backLabel: string
//   backNavigateTo: string
// }
// const BackComponent = ({ backLabel, backNavigateTo }: BackComponentProps) => {
//   const navigate = useNavigate()
//   return (
//     <Section>
//       <GoBackItem onClick={() => navigate(backNavigateTo)}>Back to {backLabel}</GoBackItem>
//     </Section>
//   )
// }
