import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import GET_CUSTOMERS_QUERY, { GET_CUSTOMERS_QUERY_KEY } from '@/utils/api/getCustomers'
import SUBMIT_JOB_MUTATION from '@/utils/api/submitJobMutation'
import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { GET_JOBS_QUERY_KEY } from '@/pages/jobs/JobsList/JobsList'

import { PlaceInput, SubmitJobMutationVariables } from '@/gql/graphql'

import { FormInputs } from './useJobForm'

export function useJobAPI(closeModal: () => void) {
  const queryClient = useQueryClient()
  const graphQLClient = useGraphQLClient()
  const navigate = useNavigate()

  // Customers query
  const { data, isPending: isPendingCustomersList } = useQuery({
    queryKey: [GET_CUSTOMERS_QUERY_KEY],
    queryFn: async () => graphQLClient.request(GET_CUSTOMERS_QUERY),
  })

  const customersOptions = useMemo(() => (data?.customers ?? []).map(customer => ({ label: customer.name, value: customer.id })), [data])

  // Submit job mutation
  const { mutate: submitJob, isPending } = useMutation({
    mutationFn: async (variables: SubmitJobMutationVariables) => {
      const result = await graphQLClient.request(SUBMIT_JOB_MUTATION, variables)
      if (!result.submitJob.success) {
        throw new Error(result.submitJob.message)
      }
      return result
    },
    onError: error => console.error('Error creating job: ', error),
    onSuccess: ({ submitJob }) => {
      queryClient.invalidateQueries({ queryKey: [GET_JOBS_QUERY_KEY] })
      closeModal()
      navigate(`/jobs/${submitJob.job?.id}/`)
    },
  })

  // Prepare submission data
  const prepareSubmission = (formData: FormInputs, hasPinLocation: boolean): SubmitJobMutationVariables => {
    const { title, projectSiteName, selectedCustomer, projectSiteCoordinates, placesData } = formData

    return {
      title,
      projectSiteName,
      customerId: selectedCustomer.value,
      status: 'Created',
      projectSiteCoordinates:
        hasPinLocation && projectSiteCoordinates ? { lat: projectSiteCoordinates.lat, lng: projectSiteCoordinates.lng } : undefined,
      placesData: placesData?.map(place => {
        const placeInput: PlaceInput = {
          googlePlaceId: place.googlePlaceId,
          displayName: place.displayName,
          formattedAddress: place.formattedAddress,
          formattedPhoneNumber: place.formattedPhoneNumber,
          googleMapsUri: place.googleMapsUri,
          websiteUri: place.websiteUri,
          lat: place.lat,
          lng: place.lng,
          streetNumber: place.streetNumber,
          route: place.route,
          routeShort: place.routeShort,
          city: place.city,
          county: place.county,
          state: place.state,
          zip: place.zip,
          country: place.country,
          streetAddress: place.streetAddress,
          streetAddressShort: place.streetAddressShort,
          unit: place.unit,
        }
        return placeInput
      }),
    }
  }

  return {
    customersOptions,
    isPendingCustomersList,
    submitJob,
    isPending,
    prepareSubmission,
  }
}
