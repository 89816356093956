import { AnimatePresence, motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { useMemo, useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import {
  DRAGGABLE_INSTALLER_CARD_BORDER_RADIUS,
  DRAGGABLE_INSTALLER_CARD_HEIGHT,
  DRAGGABLE_INSTALLER_CARD_WIDTH,
  VEHICLE_DISPATCH_CARD_HEIGHT,
  VEHICLE_DISPATCH_LEFT_COL_WIDTH,
} from '../../dispatchConstants'
import { centerSectionWidthAtom } from '../../dispatchTypes'
import { useCombinedInstallerIds } from '../hooks/useCombinedInstallerIds'
import { useDropZoneVehicleInstallers } from '../hooks/useDropZoneVehicleInstallers'
import { useHighlightedInstallerId } from '../hooks/useHighlightedInstallerId'

import InstallerCard from './InstallerCard'

interface VehicleDispatchInstallerSectionProps {
  vehicleDispatchId: string
  vehicleId: string
  assignedInstallerIds: string[]
}

const installerPadding = 8
const maxOffset = DRAGGABLE_INSTALLER_CARD_WIDTH + installerPadding

const VehicleDispatchInstallerSection = ({ assignedInstallerIds, vehicleId, vehicleDispatchId }: VehicleDispatchInstallerSectionProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const centerSectionWidth = useAtomValue(centerSectionWidthAtom)

  const highlightedInstallerId = useHighlightedInstallerId(assignedInstallerIds)

  const { combinedInstallerIds, pendingInstallerId, alreadyAssigned } = useCombinedInstallerIds(
    assignedInstallerIds,
    vehicleDispatchId,
    vehicleId
  )
  useDropZoneVehicleInstallers({ vehicleId, vehicleDispatchId, assignedInstallerIds, containerRef })

  const count = combinedInstallerIds.length
  const cardOffset = useMemo(() => calcOffset(centerSectionWidth, count), [centerSectionWidth, count])
  const shouldShowShadow = cardOffset < maxOffset - installerPadding

  return (
    <StyledInstallerSection $shouldShowShadow={shouldShowShadow} ref={containerRef}>
      <div className='vehicle-dispatch-card-right-column'>
        {count === 0 && (
          <div className='job-card-installers-header'>
            <span>No Crew</span>
          </div>
        )}

        <div className='installer-cards-container'>
          <AnimatePresence initial={false}>
            {combinedInstallerIds.map((installerId, ind) => {
              const left = cardOffset * ind
              const isOverlapped = cardOffset < maxOffset - 20
              const zIndex = highlightedInstallerId === installerId ? 3 : 0
              const isPending = installerId === pendingInstallerId && !alreadyAssigned
              return (
                <motion.div
                  layout
                  layoutScroll
                  key={installerId}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.12 }}
                  style={{ zIndex, left }}
                >
                  <InstallerCard
                    installerId={installerId}
                    vehicleDispatchId={vehicleDispatchId}
                    isPending={isPending}
                    isOverlapped={isOverlapped}
                  />
                </motion.div>
              )
            })}
          </AnimatePresence>
        </div>
      </div>
    </StyledInstallerSection>
  )
}

export default VehicleDispatchInstallerSection

function calcOffset(centerSectionWidth: number | null, count: number) {
  if (!centerSectionWidth) return 200
  const vehicleWidth = centerSectionWidth - (24 + 24 + 12 + 12)
  const rightColWidth = vehicleWidth - (VEHICLE_DISPATCH_LEFT_COL_WIDTH + installerPadding)
  const remainingWidth = rightColWidth - (DRAGGABLE_INSTALLER_CARD_WIDTH + 2 * installerPadding)
  const offset = remainingWidth / (count - 1)
  return offset > maxOffset ? maxOffset : offset < 70 ? 70 : offset
}

const StyledInstallerSection = styled.div<{ $shouldShowShadow: boolean }>`
  position: relative;
  min-width: 200px;
  flex: 1 1 auto;

  display: flex;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;

  div {
    box-sizing: border-box;
  }

  .vehicle-dispatch-card-right-column {
    border-left-width: 0px;
    border-radius: 0px 6px 6px 0px;

    background: ${token('elevation.surface')};
    min-width: 200px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    height: ${VEHICLE_DISPATCH_CARD_HEIGHT}px;

    .installer-cards-container {
      position: absolute;
      left: 7px;
      right: 7px;
      height: ${DRAGGABLE_INSTALLER_CARD_HEIGHT + 0}px;

      border-radius: ${DRAGGABLE_INSTALLER_CARD_BORDER_RADIUS}px;
      overflow: hidden;

      > div {
        position: absolute;

        > div {
          transition: box-shadow 0.2s ease-in-out;
          box-shadow: ${({ theme, $shouldShowShadow }) =>
            $shouldShowShadow
              ? theme.colorMode === 'dark'
                ? '0px 0px 0px rgba(57, 66, 74, 1), -3px 0px 6px rgba(3, 4, 4, 1),0px 0px 1px rgba(3, 4, 4, 0.5)'
                : '-3px 0px 6px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)'
              : 'none'};
        }
      }
    }

    .job-card-installers-header {
      position: absolute;
      top: 2px;
      left: 12px;
      bottom: 0;

      font-size: 15px;
      line-height: 15px;
      margin-bottom: 4px;
      color: ${token('color.text.subtle')};
      opacity: 0.6;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`
