import { Temporal } from '@js-temporal/polyfill'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router'

import { getMondayDateFromWeekId } from '@/utils/utilities'

export const useParamsWeek = () => {
  const [searchParams] = useSearchParams()
  const weekId = useMemo(() => {
    const today = Temporal.Now.plainDateISO()
    const todayWeekId = `${today.yearOfWeek}-${today.weekOfYear}`
    return searchParams.get('weekId') ?? todayWeekId
  }, [searchParams])
  const { weekNumber, yearNumber } = useMemo(() => {
    const [year, week] = weekId.split('-')
    return { weekNumber: parseInt(week), yearNumber: parseInt(year) }
  }, [weekId])
  const weekOf = getMondayDateFromWeekId(weekId)
  return { weekOf, weekNumber, yearNumber }
}
