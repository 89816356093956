import { useAtomValue } from 'jotai'

import { installerPayrollWithDateKeysByIdAtom } from '../reconPayrollAtoms'
import {
  InstallerDateType,
  InstallerDatesLookupKey,
  InstallerWeeklyPayrollWithDateKeys,
  PayrollFieldValue,
  PayrollValueSelectorFn,
} from '../reconPayrollTypes'

interface UsePayrollFieldValueProps {
  selector?: PayrollValueSelectorFn
  installerId: string
}

export const usePayrollFieldValue = ({ selector, installerId }: UsePayrollFieldValueProps): PayrollFieldValue => {
  const payrollByInstallerId = useAtomValue(installerPayrollWithDateKeysByIdAtom)
  const payroll = payrollByInstallerId[installerId]
  if (!selector) return 'NO_SELECTOR'
  return payroll ? selector(payroll) : 'NO_PAYROLL'
}

interface UsePayrollRecordProps {
  installerId: string
}
export const usePayrollRecord = ({ installerId }: UsePayrollRecordProps): InstallerWeeklyPayrollWithDateKeys | undefined => {
  const payrollByInstallerId = useAtomValue(installerPayrollWithDateKeysByIdAtom)
  const installerPayroll = payrollByInstallerId[installerId]
  return installerPayroll
}

export const useInstallerDate = (installerId: string, dayOfWeek: InstallerDatesLookupKey): InstallerDateType | null => {
  const payrollByInstallerId = useAtomValue(installerPayrollWithDateKeysByIdAtom)
  const payroll = payrollByInstallerId[installerId]
  return payroll ? payroll[dayOfWeek] : null
}
