import InfoIcon from '@atlaskit/icon/glyph/info'

import React, { useState } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import Heading from '@atlaskit/heading'
import Tooltip from '@atlaskit/tooltip'

import MapPinModal from '@/components/MapPinModal'

import { CoordinatesType, ExtendedPlaceInputType } from '@/types/project-site'

import { PlaceType } from '../types'

import AddPlaceForm from './AddPlaceForm'
import PlacesList from './PlacesList'

interface PlacesManagerProps {
  jobId: string
  places: PlaceType[]
  primaryPlaceId: string | null
  deletedPlaceIds: string[]
  coordinates: CoordinatesType | null
  isPinMode: boolean
  onChange: (data: {
    places: PlaceType[]
    primaryPlaceId: string | null
    deletedPlaceIds: string[]
    coordinates: CoordinatesType | null
    isPinMode: boolean
  }) => void
}

const PlacesManager: React.FC<PlacesManagerProps> = ({
  jobId,
  places,
  primaryPlaceId,
  deletedPlaceIds,
  coordinates,
  isPinMode,
  onChange,
}) => {
  const [isAddingPlace, setIsAddingPlace] = useState(false)
  const [isMapPinModalOpen, setIsMapPinModalOpen] = useState(false)

  // Handler for when a place is added
  const handleAddPlace = (newPlace: ExtendedPlaceInputType) => {
    // If we're adding a place, switch from pin mode to places mode
    if (isPinMode) {
      // Clear the pin coordinates
      onChange({
        places: [{ ...newPlace, isNew: true, isPrimary: true } as unknown as PlaceType],
        primaryPlaceId: newPlace.googlePlaceId,
        deletedPlaceIds,
        coordinates: null,
        isPinMode: false,
      })
    } else {
      // Set as primary if it's the first place
      const isFirstPlace: boolean = places.length === 0
      const placeWithFlag = {
        ...newPlace,
        isNew: true,
        isPrimary: isFirstPlace,
      } as unknown as PlaceType

      const updatedPlaces = [...places, placeWithFlag]

      onChange({
        places: updatedPlaces,
        primaryPlaceId: isFirstPlace ? newPlace.googlePlaceId : primaryPlaceId,
        deletedPlaceIds,
        coordinates: null,
        isPinMode: false,
      })
    }

    setIsAddingPlace(false)
  }

  // Handler for pin selection
  const handlePinSelect = (pinCoordinates: CoordinatesType) => {
    // If we're adding a pin, remove all places
    onChange({
      places: [],
      primaryPlaceId: null,
      // We need to delete all existing places
      deletedPlaceIds: [...deletedPlaceIds, ...places.filter(p => !p.isNew).map(p => p.id)],
      coordinates: pinCoordinates,
      isPinMode: true,
    })

    setIsMapPinModalOpen(false)
  }

  const handleSetPrimary = (placeId: string) => {
    const updatedPlaces = places.map(place => ({
      ...place,
      isPrimary: place.id === placeId,
    }))

    onChange({
      places: updatedPlaces,
      primaryPlaceId: placeId,
      deletedPlaceIds,
      coordinates,
      isPinMode,
    })
  }

  const handleDeletePlace = (placeId: string) => {
    // If it has an ID (existing place), add to deletedPlaceIds
    // Otherwise just remove from the places array (new place)
    const placeToDelete = places.find(p => p.googlePlaceId === placeId)
    const updatedPlaces = places.filter(p => p.googlePlaceId !== placeId)
    const updatedDeletedPlaceIds: string[] = [...deletedPlaceIds]

    if (placeToDelete && !placeToDelete.isNew) {
      updatedDeletedPlaceIds.push(placeId)
    }

    // If the deleted place was primary, set a new primary
    let updatedPrimaryId = primaryPlaceId
    if (placeId === primaryPlaceId && updatedPlaces.length > 0) {
      updatedPrimaryId = updatedPlaces[0].id
      updatedPlaces[0] = { ...updatedPlaces[0], isPrimary: true }
    } else if (updatedPlaces.length === 0) {
      updatedPrimaryId = null
    }

    onChange({
      places: updatedPlaces,
      primaryPlaceId: updatedPrimaryId,
      deletedPlaceIds: updatedDeletedPlaceIds,
      coordinates,
      isPinMode,
    })
  }

  // Function to switch to pin mode
  const switchToPinMode = () => {
    setIsMapPinModalOpen(true)
  }

  return (
    <Container>
      <HeaderRow>
        <Heading size='small'>Locations</Heading>
        <Tooltip content='A project site can have either a pin location or one or more address locations, but not both.'>
          <InfoIcon label='Info' size='small' />
        </Tooltip>
      </HeaderRow>

      {isPinMode ? (
        // Pin mode view
        <PinLocationContainer>
          <PinLocationHeader>
            <div>Pin Location</div>
            <Button appearance='subtle' spacing='compact' onClick={() => setIsMapPinModalOpen(true)}>
              Edit Pin
            </Button>
          </PinLocationHeader>

          <PinLocationCoordinates>
            {coordinates ? (
              <span>
                Latitude: {coordinates.lat.toFixed(6)}, Longitude: {coordinates.lng.toFixed(6)}
              </span>
            ) : (
              <span>No pin location set</span>
            )}
          </PinLocationCoordinates>

          <PinLocationActions>
            <Button
              appearance='subtle'
              onClick={() => {
                onChange({
                  places: [],
                  primaryPlaceId: null,
                  deletedPlaceIds,
                  coordinates: null,
                  isPinMode: false,
                })
              }}
            >
              Remove Pin
            </Button>
            <Button appearance='subtle' onClick={() => setIsAddingPlace(true)}>
              Switch to Address Mode
            </Button>
          </PinLocationActions>
        </PinLocationContainer>
      ) : (
        // Places mode view
        <>
          {places.length > 0 ? (
            <PlacesList places={places} primaryPlaceId={primaryPlaceId} onSetPrimary={handleSetPrimary} onDelete={handleDeletePlace} />
          ) : (
            <EmptyState>No locations added yet. Add a location to specify the exact address for this project site.</EmptyState>
          )}

          {isAddingPlace ? (
            <AddPlaceForm jobId={jobId} onAdd={handleAddPlace} onCancel={() => setIsAddingPlace(false)} />
          ) : (
            <ButtonsContainer>
              <Button appearance='primary' onClick={() => setIsAddingPlace(true)}>
                Add Address
              </Button>

              {places.length === 0 && (
                <Button appearance='subtle' onClick={switchToPinMode}>
                  Use Pin Location
                </Button>
              )}
            </ButtonsContainer>
          )}
        </>
      )}

      {isMapPinModalOpen && (
        <MapPinModal onSelect={handlePinSelect} onClose={() => setIsMapPinModalOpen(false)} initialLocation={coordinates || undefined} />
      )}
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 24px;
`

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`

const EmptyState = styled.div`
  padding: 24px;
  background-color: #f4f5f7;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 16px;
  color: #6b778c;
`

const ButtonsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 8px;
`

const PinLocationContainer = styled.div`
  padding: 16px;
  background-color: #f4f5f7;
  border-radius: 3px;
  margin-bottom: 16px;
`

const PinLocationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 500;
`

const PinLocationCoordinates = styled.div`
  margin-bottom: 16px;
  font-family: monospace;
  word-break: break-all;
`

const PinLocationActions = styled.div`
  display: flex;
  gap: 8px;
`

export default PlacesManager
