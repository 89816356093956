import { Temporal } from '@js-temporal/polyfill'
import { ColumnMeta, createColumnHelper } from '@tanstack/react-table'

import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'

import PaystubStatusCell, { DispatchRowType } from '../components/PaystubStatusCell'

const columnHelper = createColumnHelper<DispatchRowType>()

const meta: ColumnMeta<DispatchRowType, string> = {
  align: 'right',
  style: {
    padding: 0,
  },
}

const PAYOUT_FIELDS = ['payoutAddons', 'payoutHourly', 'payoutDavisBacon', 'payoutPieceRate', 'payoutPieceRateDavisBaconGap', 'payoutDrive']

export const DISPATCHES_COLUMN_DEFS = (hasUnapprovedDispatches: boolean, showDebugColumns: boolean) => {
  const columnDefs = [
    columnHelper.accessor('vehicleDispatch.dispatch.date', {
      header: 'Date',
      cell: info => {
        const date = info.row.original.vehicleDispatch.dispatch.date
        return Temporal.PlainDate.from(date).toLocaleString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        })
      },
    }),
    columnHelper.accessor('vehicleDispatch.dispatch.workOrder.customerName', {
      header: 'Customer Name',
    }),
    columnHelper.accessor('vehicleDispatch.dispatch.workOrder.projectSiteAddress', {
      header: 'Job Site Address',
      cell: info => (!!info.getValue() && info.getValue()?.includes(', IA') ? info.getValue()?.split(', IA')[0] : info.getValue()),
    }),
    columnHelper.accessor('vehicleDispatch.dispatch.workOrder.name', {
      header: 'Work Order',
      cell: info => {
        const workOrder = info.row.original.vehicleDispatch.dispatch.workOrder
        return '#' + workOrder?.id + ' - ' + workOrder?.name
      },
    }),
  ]
  if (showDebugColumns) {
    columnDefs.push(
      // @ts-expect-error - push is not defined on columnDefs
      columnHelper.accessor('isHourlyPayout', {
        header: 'Dispatch Payout Type',
        meta,
        cell: ({ row }) => (row.original.isHourlyPayout ? (row.original.isDavisBacon ? 'Davis Bacon' : 'Hourly') : 'Piece Rate'),
      })
    )
    columnDefs.push(
      columnHelper.accessor('hourlyRate', {
        header: 'Hourly Rate',
        meta,
        cell: info => <NumberCell value={info.getValue()} readOnly prefix='$' suffix='/ hr' />,
      })
    )
    columnDefs.push(
      // @ts-expect-error - push is not defined on columnDefs
      columnHelper.accessor('minutesWorked', {
        header: 'Hours Worked',
        meta,
        cell: info => <HrMinCell value={info.getValue()} />,
      })
    )
  }
  columnDefs.push(
    // @ts-expect-error - push is not defined on columnDefs
    columnHelper.accessor('minutesActual', {
      header: 'Hours Total',
      meta,
      cell: info => <HrMinCell value={info.getValue()} />,
    })
  )
  if (showDebugColumns) {
    PAYOUT_FIELDS.forEach(accessor => {
      columnDefs.push(
        // @ts-expect-error - push is not defined on columnDefs
        columnHelper.accessor(accessor, {
          header: accessor,
          meta,
          cell: info => <NumberCell value={info.getValue()} readOnly prefix='$' />,
        })
      )
    })
  }
  columnDefs.push(
    columnHelper.accessor('payoutTotal', {
      header: showDebugColumns ? 'payoutTotal' : 'Payout',
      meta,
      cell: info => <NumberCell value={info.getValue()} readOnly prefix='$' />,
    })
  )

  if (hasUnapprovedDispatches || showDebugColumns) {
    columnDefs.push(
      // @ts-expect-error don't care
      columnHelper.display({
        id: 'isApproved',
        header: 'Status',
        cell: info => {
          return <PaystubStatusCell installerDispatch={info.row.original} />
        },
      })
    )
  }
  return columnDefs
}
