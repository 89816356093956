import { Temporal } from '@js-temporal/polyfill'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IconButton } from '@atlaskit/button/new'
import AddIcon from '@atlaskit/icon/glyph/add'
import TrashIcon from '@atlaskit/icon/glyph/trash'
import { token } from '@atlaskit/tokens'

import { graphql } from '@/gql'
import { MutationDeleteDispatchArgs, MutationExtendDispatchArgs } from '@/gql/graphql'
import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { CALENDAR_WORK_ORDERS_QUERY_KEY, CalendarWorkOrderType, DISPATCHES_BY_DATE_QUERY_KEY, GET_WORK_ORDER_QUERY_KEY } from '../types'

interface DispatchControlsProps {
  calendarWorkOrder: CalendarWorkOrderType
  isLast: boolean
}

const DispatchControls = ({ calendarWorkOrder, isLast }: DispatchControlsProps) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: MutationDeleteDispatchArgs) => await graphQLClient.request(DELETE_DISPATCH, variables),
    onError: error => {
      console.log('onError', error)
    },
    onSuccess: data => {
      console.log('onSuccess', data)
      queryClient.invalidateQueries({ queryKey: [CALENDAR_WORK_ORDERS_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [GET_WORK_ORDER_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [DISPATCHES_BY_DATE_QUERY_KEY] })
    },
  })

  const handleDelete = () => mutate({ dispatchId: calendarWorkOrder.dispatchId ?? '' })

  const { mutate: extendMutate, isPending: isPendingExtend } = useMutation({
    mutationFn: async (variables: MutationExtendDispatchArgs) => await graphQLClient.request(EXTEND_DISPATCH, variables),
    onError: error => {
      console.log('onError', error)
    },
    onSuccess: data => {
      console.log('onSuccess', data)
      queryClient.invalidateQueries({ queryKey: [CALENDAR_WORK_ORDERS_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [GET_WORK_ORDER_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [DISPATCHES_BY_DATE_QUERY_KEY] })
    },
  })

  function handleExtend() {
    const date = calendarWorkOrder.dateString
      ? Temporal.PlainDate.from(calendarWorkOrder.dateString).add({ days: 1 })
      : Temporal.Now.plainDate
    extendMutate({
      workOrderId: `${calendarWorkOrder.workOrderId}`,
      dateToAdd: date,
    })
  }

  const isWorkCompleted = calendarWorkOrder.workOrder?.isWorkCompleted ?? false
  const isDisabled = isWorkCompleted || isPendingExtend || isPending

  return (
    <div style={{ opacity: isPending ? 0.6 : 1, transition: 'opacity 0.5s', position: 'relative' }}>
      {!isLast ? null : (
        <IconButton appearance='subtle' label='Extend Dispatch' onClick={handleExtend} icon={AddIcon} isDisabled={isDisabled} />
      )}
      <IconButton
        appearance='subtle'
        label='Delete Dispatch'
        onClick={handleDelete}
        icon={iconProps => <TrashIcon {...iconProps} primaryColor={token('color.icon.danger')} />}
        isDisabled={isDisabled}
      />
    </div>
  )
}
export default DispatchControls

const DELETE_DISPATCH = graphql(/* GraphQL */ `
  mutation DeleteDispatch($dispatchId: UUID!) {
    deleteDispatch(dispatchId: $dispatchId) {
      success
      message
    }
  }
`)
const EXTEND_DISPATCH = graphql(/* GraphQL */ `
  mutation ExtendDispatch($workOrderId: ID!, $dateToAdd: Date!) {
    extendDispatch(workOrderId: $workOrderId, dateToAdd: $dateToAdd) {
      success
      message
    }
  }
`)
