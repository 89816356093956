import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { DragSourceData, itemPickedUpAtom } from '../../dispatchTypes'

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface UseIsDraggingProps extends Partial<DragSourceData> {}
interface UseIsDraggingReturn {
  isDragging: boolean
  isDraggingExactMatch: boolean
}

export const useIsDragging = ({
  installerId,
  dispatchId,
  vehicleId,
  vehicleDispatchId,
  itemIdentifiedVariant,
}: UseIsDraggingProps): UseIsDraggingReturn => {
  const isDraggingAtom = useMemo(
    () =>
      selectAtom(itemPickedUpAtom, pickedUp => {
        let isDragging = false
        let isDraggingExactMatch = false
        if (itemIdentifiedVariant === pickedUp.itemIdentifiedVariant) {
          if (itemIdentifiedVariant === 'INSTALLER' && installerId === pickedUp.installerId) {
            isDragging = true
            if (vehicleDispatchId === pickedUp.vehicleDispatchId) isDraggingExactMatch = true
          } else if (itemIdentifiedVariant === 'DISPATCH' && dispatchId === pickedUp.dispatchId) {
            isDragging = true
            if (vehicleId === pickedUp.vehicleId) isDraggingExactMatch = true
          }
        }
        // if (isDragging)
        //   console.log('isDragging', {
        //     isDraggingExactMatch,
        //     pickedUp,
        //     installerId,
        //     dispatchId,
        //     vehicleDispatchId,
        //     itemIdentifiedVariant,
        //   })
        return { isDragging, isDraggingExactMatch }
      }),
    [dispatchId, installerId, itemIdentifiedVariant, vehicleDispatchId, vehicleId]
  )
  return useAtomValue(isDraggingAtom)
}
