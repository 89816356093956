import { useQuery } from '@tanstack/react-query'

import DownloadIcon from '@atlaskit/icon/glyph/download'

import { IconButton } from '@atlaskit/button/new'

import GET_FINANCIALS_BY_WORK_ORDER, { GET_FINANCIALS_BY_WORK_ORDER_QUERY_KEY } from '@/utils/api/getFinancialsByWorkOrder'
import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { GetFinancialsByWorkOrderQuery } from '@/gql/graphql'

const DownloadFinancialsButton = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
  const graphqlClient = useGraphQLClient()
  const variables = { startDate, endDate }
  const { isLoading, error, data } = useQuery({
    queryKey: [GET_FINANCIALS_BY_WORK_ORDER_QUERY_KEY, variables],
    queryFn: () => graphqlClient.request(GET_FINANCIALS_BY_WORK_ORDER, variables),
    enabled: !!variables.startDate && !!variables.endDate,
  })

  const handleDownload = () => {
    // @ts-expect-error don't really care about this one
    const csvContent = generateCSV(data?.financialsByWorkOrder)
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `financials_by_work_order_${startDate}_${endDate}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <IconButton
      icon={DownloadIcon}
      label='Download Financials'
      isTooltipDisabled={false}
      onClick={handleDownload}
      isDisabled={isLoading || !!error}
    />
  )
}

type FinancialsByWorkOrderType = GetFinancialsByWorkOrderQuery['financialsByWorkOrder'][number]

const generateCSV = (items: FinancialsByWorkOrderType[]) => {
  const headers = columns.map(column => escapeCSVField(column.header)).join(',')
  const rows = items.map(item => columns.map(column => escapeCSVField(String(item[column.key]), column.decimals)).join(','))
  return [headers, ...rows].join('\n')
}

const escapeCSVField = (field: string, decimals?: number) => {
  if (field == null) return ''
  // Wrap in quotes if the field contains a comma or quotes
  const newField = decimals ? Number(field).toFixed(decimals) : field
  if (newField.includes(',') || newField.includes('"')) {
    // Double up any quotes in the field and wrap in quotes
    return `"${newField.replace(/"/g, '""')}"`
  }
  return newField
}

export default DownloadFinancialsButton

const columns: { key: keyof FinancialsByWorkOrderType; header: string; decimals?: number }[] = [
  { key: 'customerName', header: 'Customer Name' },
  { key: 'estimateTitle', header: 'Estimate Title' },
  { key: 'isDavisBacon', header: 'Is Davis Bacon' },
  { key: 'salespersonName', header: 'Salesperson Name' },
  { key: 'jobTitle', header: 'Job Title' },
  { key: 'projectSiteName', header: 'Project Site Name' },
  { key: 'workOrderId', header: 'Work Order Id' },
  { key: 'workOrderName', header: 'Work Order Name' },
  { key: 'tripName', header: 'Trip Name' },
  { key: 'isWorkCompleted', header: 'Is Work Completed' },
  { key: 'lastDispatchDate', header: 'Last Dispatch Date' },
  { key: 'firstDispatchDate', header: 'First Dispatch Date' },
  { key: 'dispatchCountOutsideRange', header: 'Dispatch Count Outside Range', decimals: 0 },
  { key: 'materialCost', header: 'Material Cost', decimals: 2 },
  { key: 'projectedDrivePay', header: 'Drive Pay Cost', decimals: 2 },
  { key: 'projectedPieceRate', header: 'Piece Rate Cost', decimals: 2 },
  { key: 'projectedAddons', header: 'Addons Cost', decimals: 2 },
  { key: 'laborCost', header: 'Total Labor Cost', decimals: 2 },
  { key: 'totalCost', header: 'Total Cost', decimals: 2 },
  { key: 'totalPrice', header: 'Total Price', decimals: 2 },
  { key: 'marginAmount', header: 'Margin Amount', decimals: 2 },
  { key: 'marginPercent', header: 'Margin Percent', decimals: 1 },
  { key: 'estimateId', header: 'Estimate Id' },
]
