import { Temporal } from '@js-temporal/polyfill'
import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { DAYS_OF_WEEK_ABBR_LIST } from '@/utils/constants'

import { useIsVisible } from '../hooks/useIsVisible'

import CalendarDayCell from './CalendarDayCell'
import CalendarDayHeader from './CalendarDayHeader'

interface CalendarWeeksProps {
  firstWeekDate: Temporal.PlainDate
  lastWeekDate: Temporal.PlainDate
  today: Temporal.PlainDate
}

const CalendarWeeks = ({ firstWeekDate, lastWeekDate, today }: CalendarWeeksProps) => {
  const scrollingContainerRef = useRef<HTMLDivElement>(null)
  const componentRef = useRef<HTMLDivElement>(null)
  const isVisible = useIsVisible(componentRef as React.RefObject<HTMLElement>)
  const didScrollOnce = useRef(false)

  const differenceInWeeks = lastWeekDate.since(firstWeekDate, { largestUnit: 'weeks' }).weeks
  const mondays = Array.from({ length: differenceInWeeks + 1 }, (_, i) => (i === 0 ? firstWeekDate : firstWeekDate.add({ weeks: i })))

  const stuckHeaders = useStuckHeaders(scrollingContainerRef as React.RefObject<HTMLElement>)

  useEffect(() => {
    if (isVisible && !didScrollOnce.current) {
      const container = scrollingContainerRef.current
      if (container) {
        // Find the index of the current week
        const currentWeekIndex = mondays.findIndex(monday => today.since(monday, { largestUnit: 'weeks' }).weeks === 0)

        // Scroll to the current week
        if (currentWeekIndex !== -1) {
          const weekElements = container.querySelectorAll('.eh-week-headers-row')
          if (weekElements[currentWeekIndex]) {
            weekElements[currentWeekIndex].scrollIntoView({ behavior: 'smooth', block: 'start' })
            didScrollOnce.current = true
          }
        }
      }
    }
  }, [mondays, today, isVisible])

  return (
    <CalendarContainer ref={componentRef}>
      <div className='eh-header-row'>
        {DAYS_OF_WEEK_ABBR_LIST.map(day => (
          <div key={day} className='eh-header-cell'>
            {day}
          </div>
        ))}
      </div>

      <ScrollingRowsContainer ref={scrollingContainerRef as React.RefObject<HTMLElement>}>
        <div className='eh-scrolling-rows'>
          {mondays.map(monday => {
            const sunday = monday.subtract({ days: 1 })
            const weekKey = sunday.toString()
            const isStuck = stuckHeaders.has(weekKey)

            return (
              <div className='eh-week-container' key={weekKey}>
                <div className='eh-week-headers-row' data-week-key={weekKey} data-stuck={isStuck}>
                  {DAYS_OF_WEEK_ABBR_LIST.map((day, ind) => {
                    const date = sunday.add({ days: ind })
                    return <CalendarDayHeader date={date} today={today} key={day} />
                  })}
                </div>
                <div className='eh-week-row'>
                  {DAYS_OF_WEEK_ABBR_LIST.map((day, ind) => {
                    const date = sunday.add({ days: ind })
                    return <CalendarDayCell date={date} today={today} key={day} />
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </ScrollingRowsContainer>
    </CalendarContainer>
  )
}

export default CalendarWeeks

const CalendarContainer = styled(motion.div)`
  position: absolute;
  inset: 0;
  left: ${token('space.400')};
  right: ${token('space.400')};
  overflow: hidden;
  z-index: 0;

  .eh-header-row {
    position: relative;
    z-index: 3;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    height: 1.6rem;

    border: 1px solid ${token('color.border')};
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    background: ${token('elevation.surface')};

    transition: box-shadow 0.2s;

    .eh-header-cell + .eh-header-cell {
      border-left: 1px solid ${token('color.border')};
    }
    .eh-header-cell {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      letter-spacing: 0.3px;
      font-weight: 600;
      text-transform: uppercase;
      color: ${token('color.text.subtlest')};
    }
  }
`

const ScrollingRowsContainer = styled(motion.div)`
  z-index: 1;

  position: absolute;
  inset: 0;
  top: 1.7rem;

  display: flex;
  flex-direction: column;
  flex: 1;

  min-height: 0;
  max-height: 100%;

  overflow-y: auto;

  border-left: 1px solid ${token('color.border')};
  border-right: 1px solid ${token('color.border')};

  .eh-scrolling-rows {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    position: relative;

    .eh-week-container {
      position: relative;

      .eh-week-headers-row {
        z-index: 1;
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;

        .eh-day-cell + .eh-day-cell {
          border-left: 1px solid ${token('color.border')};
        }

        transition:
          box-shadow 0.3s,
          border-bottom 0.3s;

        /* border-bottom: 1px solid transparent; */
        box-shadow: 0 0 0 0 transparent;
        &[data-stuck='true'] {
          box-shadow: ${token('elevation.shadow.overflow')};
          /* border-bottom: 1px solid ${token('color.border')}; */
        }
      }

      .eh-week-row {
        z-index: 0;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid ${token('color.border')};

        position: relative;
        width: 100%;
        transition: height 0.08s;
        height: auto;
        align-items: stretch;

        .eh-day-cell + .eh-day-cell {
          border-left: 1px solid ${token('color.border')};
        }
      }
    }
  }
`

// Add this hook to detect stuck headers
export const useStuckHeaders = (scrollContainerRef: React.RefObject<HTMLElement>) => {
  const [stuckHeaders, setStuckHeaders] = useState<Set<string>>(new Set())

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const weekKey = entry.target.getAttribute('data-week-key')
          if (!weekKey) return

          const rect = entry.target.getBoundingClientRect()
          const containerRect = scrollContainer.getBoundingClientRect()
          const isAtTop = Math.abs(rect.top - containerRect.top) < 1
          const scrollTop = scrollContainer.scrollTop

          setStuckHeaders(prev => {
            const next = new Set(prev)
            // Check if it's the first header and we've scrolled down
            if (isAtTop && (entry.intersectionRatio < 1 || scrollTop > 0)) {
              next.add(weekKey)
            } else {
              next.delete(weekKey)
            }
            return next
          })
        })
      },
      {
        threshold: [1],
        root: scrollContainer,
        rootMargin: '-1px 0px 0px 0px',
      }
    )

    const headers = scrollContainer.querySelectorAll('.eh-week-headers-row')
    headers.forEach(header => observer.observe(header))

    // Also watch for scroll events to handle the first header
    const handleScroll = () => {
      const firstHeader = scrollContainer.querySelector('.eh-week-headers-row')
      if (!firstHeader) return

      const weekKey = firstHeader.getAttribute('data-week-key')
      if (!weekKey) return

      const scrollTop = scrollContainer.scrollTop
      setStuckHeaders(prev => {
        const next = new Set(prev)
        if (scrollTop > 0) {
          next.add(weekKey)
        } else {
          next.delete(weekKey)
        }
        return next
      })
    }

    scrollContainer.addEventListener('scroll', handleScroll)
    handleScroll() // Initial check

    return () => {
      observer.disconnect()
      scrollContainer.removeEventListener('scroll', handleScroll)
    }
  }, [scrollContainerRef])

  return stuckHeaders
}
