import { CardColorOptions } from '@/utils/utilities'

export interface InstallerColumn {
  className: string
  header: string
  width: number
  align?: 'left' | 'right' | 'center'
  left?: number
  right?: number
  editable?: boolean
}

const HR_WIDTH = 85
const HR_WIDTH_EDIT = 90
const PAY_WIDTH = 85
const RATE_WIDTH_EDIT = 112

export const INSTALLER_COLUMNS: Record<string, InstallerColumn> = {
  // ASSIGNMENT (3)
  vehicle: { className: 'col-vehicle', width: 108, align: 'left', left: 0, header: 'Vehicle' },
  installer: { className: 'col-installer', width: 140, align: 'left', left: 108, header: 'Installer' },
  payoutMode: { className: 'col-payout-mode-toggle', width: 58, align: 'center', left: 248, header: 'Mode', editable: true },
  // ON SITE (2)
  calculatedMinutesOnSite: { className: 'col-minutes-on-site', width: HR_WIDTH, header: 'Vehicle' },
  minutesWorked: { className: 'col-minutes-worked', width: HR_WIDTH_EDIT, header: 'Worked', editable: true },
  // HOURLY PAYOUT / DAVIS BACON (3)
  hourlyRate: { className: 'col-hourly-rate', width: RATE_WIDTH_EDIT, header: 'Hourly Rate', editable: true },
  payoutHourly: { className: 'col-payout-hourly', width: PAY_WIDTH, header: 'Payout' },
  payoutDavisBacon: { className: 'col-payout-davis-bacon', width: PAY_WIDTH, header: 'Payout DB' },
  // DRIVE PAY (6)
  minutesDriveActual: { className: 'col-minutes-drive-actual', width: HR_WIDTH, header: 'Actual' },
  calculatedMinutesDriveBuffer: { className: 'col-minutes-drive-buffer', width: HR_WIDTH, header: 'Buffer' },
  minutesDrivePayable: { className: 'col-minutes-drive-payable', width: HR_WIDTH, header: 'Payable' },
  minutesDrivePayableOverride: { className: 'col-minutes-drive-override', width: HR_WIDTH_EDIT, header: 'Override', editable: true },
  hourlyRateDrive: { className: 'col-hourly-rate-drive', width: RATE_WIDTH_EDIT, header: 'Drive Rate', editable: true },
  payoutDrive: { className: 'col-payout-drive', width: PAY_WIDTH, header: 'Payout' },
  // PIECE RATE (4)
  percentPieceRate: { className: 'col-percent-of-work', width: 95, header: '% of Work', editable: true },
  isPercentPieceRateLocked: { className: 'col-is-percent-locked', width: 70, align: 'center', header: 'Lock', editable: true },
  calculatedPayoutPieceRate: { className: 'col-payout-piece-rate', width: 100, header: 'Payout' },
  payoutPieceRateDavisBaconGap: { className: 'col-pr-gap', width: PAY_WIDTH, header: 'PR Gap' },
  // ADDONS (3)
  percentAddons: { className: 'col-percent-of-addons-work', width: 95, header: '% Addons', editable: true },
  isPercentAddonsLocked: { className: 'col-is-percent-locked', width: 70, align: 'center', header: 'Lock', editable: true },
  calculatedPayoutAddons: { className: 'col-payout-piece-rate', width: 100, header: 'Payout' },
  // TOTAL (4)
  minutesActual: { className: 'col-minutes-actual', width: 100, right: 80 + 80 + 110, header: 'Hours Total' },
  payoutTotal: { className: 'col-payout-total', width: 110, right: 80 + 80, header: 'Payout Total' },
  isCompleted: { className: 'col-completed', width: 80, align: 'center', right: 80, header: 'Done', editable: true },
  isApproved: { className: 'col-approved', width: 80, align: 'center', right: 0, header: 'Locked', editable: true },
}

export type InstallerColumnKey = keyof typeof INSTALLER_COLUMNS
export const INSTALLER_COL_ORDER: InstallerColumnKey[] = [
  // ASSIGNMENT (3)
  'vehicle',
  'installer',
  'payoutMode',
  // ON-SITE (2)
  'calculatedMinutesOnSite',
  'minutesWorked',
  // HOURLY PAYOUT (3)
  'hourlyRate',
  'payoutHourly',
  'payoutDavisBacon',
  // DRIVE PAY (6)
  'minutesDriveActual',
  'calculatedMinutesDriveBuffer',
  'minutesDrivePayable',
  'minutesDrivePayableOverride',
  'hourlyRateDrive',
  'payoutDrive',
  // PIECE RATE (4)
  'percentPieceRate',
  'isPercentPieceRateLocked',
  'calculatedPayoutPieceRate',
  'payoutPieceRateDavisBaconGap',
  // ADDONS (3)
  'percentAddons',
  'isPercentAddonsLocked',
  'calculatedPayoutAddons',

  // TOTAL (4)
  'minutesActual',
  'payoutTotal',
  'isCompleted',
  'isApproved',
]

export interface InstallerColumnGroup {
  key: string
  label: string
  color?: CardColorOptions
  columns: InstallerColumnKey[]
}
export const INSTALLER_COL_GROUPS: Record<string, InstallerColumnGroup> = {
  assignment: {
    key: 'assignment',
    label: 'Assignment',
    columns: [
      // ASSIGNMENT (3)
      'vehicle',
      'installer',
      'payoutMode',
    ],
  },

  hourlyPayout: {
    key: 'hourlyPayout',
    label: 'Hourly Payout',
    color: 'purple',
    columns: [
      // HOURLY PAYOUT (2)
      'hourlyRate',
      'payoutHourly',
    ],
  },
  davisBacon: {
    key: 'davisBacon',
    label: 'Davis Bacon',
    color: 'blue',
    columns: [
      // DAVIS BACON PAYOUT (2)
      'hourlyRate',
      'payoutDavisBacon',
    ],
  },
  onSite: {
    key: 'onSite',
    label: 'On-Site',
    color: 'gray',
    columns: [
      // ON-SITE (2)
      'calculatedMinutesOnSite',
      'minutesWorked',
    ],
  },
  drivePay: {
    key: 'drivePay',
    label: 'Drive Pay',
    color: 'gray',
    columns: [
      // DRIVE PAY (6)
      'minutesDriveActual',
      'calculatedMinutesDriveBuffer',
      'minutesDrivePayableOverride',
      'minutesDrivePayable',
      'hourlyRateDrive',
      'payoutDrive',
    ],
  },
  pieceRate: {
    key: 'pieceRate',
    label: 'Piece Rate',
    color: 'yellow',
    columns: [
      // PIECE RATE (4)
      'percentPieceRate',
      'isPercentPieceRateLocked',
      'calculatedPayoutPieceRate',
      'payoutPieceRateDavisBaconGap',
    ],
  },
  addons: {
    key: 'addons',
    label: 'Addons',
    color: 'orange',
    columns: [
      // ADDONS (3)
      'percentAddons',
      'isPercentAddonsLocked',
      'calculatedPayoutAddons',
    ],
  },
  total: {
    key: 'total',
    label: '',
    columns: [
      // TOTAL (4)
      'minutesActual',
      'payoutTotal',
      'isCompleted',
      'isApproved',
    ],
  },
}

export const INSTALLER_COL_GROUPS_ORDER: (keyof typeof INSTALLER_COL_GROUPS)[] = [
  'assignment',
  'drivePay',
  'hourlyPayout',
  'davisBacon',
  'onSite',
  'pieceRate',
  'addons',
  'total',
]
