import { Temporal } from '@js-temporal/polyfill'
import { AnimatePresence, motion, Reorder } from 'framer-motion'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'

import { dateStringHoveredAtom, dateStringSelectedAtom, isWeeksExpandedAtom } from '../atoms'
import { useSelectedDateDispatches } from '../hooks/useSelectedDateDispatches'

import SchedulerCard from './SchedulerCard'

const MAX_ITEMS_VISIBLE = 4
const OVERFLOW_INDICATOR_HEIGHT = 28
const HEADER_PADDING = 62

interface CalendarDayCellProps {
  date: Temporal.PlainDate
  today: Temporal.PlainDate
}

const CalendarDayCell = ({ date, today }: CalendarDayCellProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const itemsContainerRef = useRef<HTMLDivElement | null>(null)

  const setHoveredDateString = useSetAtom(dateStringHoveredAtom)
  const dateStringSelected = useAtomValue(dateStringSelectedAtom)
  const isExpanded = useAtomValue(isWeeksExpandedAtom)

  const dispatches = useSelectedDateDispatches(date)
  const itemCount = dispatches.length
  const makeRoomFor = isExpanded ? itemCount : Math.min(itemCount, MAX_ITEMS_VISIBLE)
  const isOverflowing = itemCount > MAX_ITEMS_VISIBLE
  const indicatorHeightToAdd = !isExpanded && itemCount > MAX_ITEMS_VISIBLE ? OVERFLOW_INDICATOR_HEIGHT : 0
  const height = makeRoomFor * HEADER_PADDING + indicatorHeightToAdd

  const isToday = date.day === today.day && date.month === today.month && date.year === today.year
  const isPast = Temporal.PlainDate.compare(date, today) < 0
  const boldBorderBottom = date.daysInMonth - date.day < 7
  const isLastDayOfMonth = date.daysInMonth === date.day
  const isSaturday = date.dayOfWeek === 6
  const boldBorderRight = isLastDayOfMonth && !isSaturday
  const dateString = date.toString()
  const isSelected = dateStringSelected === dateString

  useEffect(() => {
    const el = ref.current
    if (!el) throw new Error('ref not set correctly')

    return dropTargetForElements({
      element: el,
      getData: () => ({ dateString }),
      onDragEnter: () => setHoveredDateString(dateString),
      onDragLeave: () => setHoveredDateString(prevValue => (prevValue === dateString ? null : prevValue)),
    })
  }, [dateString, setHoveredDateString])

  return (
    <DayCell
      ref={ref}
      className='eh-day-cell'
      $isPast={isPast}
      $isSelected={isSelected}
      $height={height}
      $isToday={isToday}
      $boldBorderRight={boldBorderRight}
      $boldBorderBottom={boldBorderBottom}
    >
      <div ref={itemsContainerRef} className='eh-items-container'>
        <Reorder.Group values={dispatches} onReorder={() => {}} as='div'>
          {dispatches.map(calendarDispatch => {
            const key = calendarDispatch?.workOrderId ?? '' + (calendarDispatch?.dispatchId ?? '')
            if (!calendarDispatch) return null
            return (
              <Reorder.Item key={key} value={calendarDispatch} as='div' transition={{ duration: 0.12 }}>
                <SchedulerCard calendarDispatch={calendarDispatch} dateString={calendarDispatch.dateString ?? dateString} />
              </Reorder.Item>
            )
          })}
        </Reorder.Group>
      </div>

      <AnimatePresence>
        {isOverflowing && !isExpanded ? (
          <OverflowDiv
            initial={{ opacity: 0, translateY: '100%' }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: '100%' }}
            transition={{
              duration: 0.15,
              enter: { ease: 'easeIn' },
              exit: { ease: 'easeOut' },
            }}
          >
            <span>+{dispatches.length - MAX_ITEMS_VISIBLE} Items</span>
          </OverflowDiv>
        ) : null}
      </AnimatePresence>
    </DayCell>
  )
}

export default CalendarDayCell

const OverflowDiv = styled(motion.div)`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${token('space.050')};
  box-shadow: ${token('elevation.shadow.overflow')};
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: ${token('elevation.surface.sunken')};

  font-size: 11px;
  font-weight: 600;
  color: ${token('color.text.subtlest')};
`
interface DayCellProps {
  $isToday: boolean
  $isPast?: boolean
  $isSelected?: boolean
  $height: number
  $boldBorderRight?: boolean
  $boldBorderBottom?: boolean
}
const DayCell = styled.div<DayCellProps>`
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: center;
  padding: 0 ${token('space.100')};

  background: ${({ $isPast, $isSelected }) =>
    $isSelected ? token('color.background.selected') : $isPast ? token('elevation.surface.sunken') : token('elevation.surface')};

  overflow: hidden;

  /* ${({ $boldBorderRight }) => ($boldBorderRight ? `border-right:1px solid ${token('color.border.bold')}` : '')};
  ${({ $boldBorderBottom }) => ($boldBorderBottom ? `border-bottom:1px solid ${token('color.border.bold')}` : '')}; */
  transition: background 0.08s;

  .eh-items-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: 100%;
    border-radius: 4px;
    font-size: 12px;
    padding-top: ${token('space.050')};

    min-height: 100px;
    height: ${({ $height }) => $height}px;

    transition: height 150ms;
  }
`
