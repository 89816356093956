import { useSetAtom } from 'jotai'
import Select, { components, OptionProps, Theme } from 'react-select'

import { token } from '@atlaskit/tokens'

import { useMaterialsQuery } from '@/utils/queryHooks/useMaterialsQuery'

import { GetMaterialsQuery } from '@/gql/graphql'
import { UNIVERSAL_EDITOR_SELECT_PROPS } from '../editorConstants'

import { useSelectColors } from '../hooks/useSelectColors'
import { useWorkItemAttribute } from '../hooks/useWorkItemAttribute'

import { workItemEditsQueueAtom, workItemsOptimisticAttributesAtom } from '../editorAtoms'

type QueriedMaterial = GetMaterialsQuery['materials'][0]

type SelectMaterialOption = {
  label: string
  value: string
  material: QueriedMaterial
}

interface ItemCodeSelectorCellProps {
  workItemId: string | null
}

const ItemCodeSelectorCell = ({ workItemId }: ItemCodeSelectorCellProps) => {
  const setWorkItemEditsQueue = useSetAtom(workItemEditsQueueAtom)
  const setOptimisticAttributes = useSetAtom(workItemsOptimisticAttributesAtom)

  const { materials } = useMaterialsQuery()
  const options = materials.map((material: QueriedMaterial) => ({ label: material.itemCode, value: material.itemCode, material: material }))

  const material = useWorkItemAttribute('material', workItemId ?? 'null')
  const itemCode = material?.itemCode ?? null

  const handleChange = (option: SelectMaterialOption | null) => {
    setOptimisticAttributes(prev => {
      const key = workItemId ?? 'null'
      const existingWorkItem = prev?.[key] ?? {}
      return {
        ...prev,
        [key]: {
          ...existingWorkItem,
          material: option?.material,
          materialId: option?.material?.id,
        },
      }
    })
    // @ts-expect-error Doesn't like JSON value
    if (workItemId !== 'NEW') setWorkItemEditsQueue(prev => [...prev, { workItemId, attribute: 'itemCode', value: option?.value }])
  }

  const colors = useSelectColors()

  return (
    <div style={{ width: '100%', minWidth: 160 }}>
      <Select
        options={options}
        components={{ Option: MaterialOptionComponent, IndicatorSeparator: null, DropdownIndicator: null }}
        value={{ label: itemCode, value: itemCode }}
        placeholder='Select Material'
        inputId='material-selector'
        classNamePrefix='material-selector'
        onChange={handleChange}
        filterOption={filterOption}
        theme={(theme: Theme) => ({
          ...theme,
          borderRadius: 0,
          colors,
        })}
        {...UNIVERSAL_EDITOR_SELECT_PROPS}
      />
    </div>
  )
}

export default ItemCodeSelectorCell

const MaterialOptionComponent = (props: OptionProps<SelectMaterialOption>) => {
  const { material } = props.data

  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ fontSize: '11px', color: token('color.text.disabled') }}>{material.itemCode}</div>
        <div style={{ fontSize: '13px' }}>{material.name}</div>
      </div>
    </components.Option>
  )
}

type SelectMaterialFilterOption = (
  option: {
    label: string
    value: string
    data: SelectMaterialOption
  },
  inputValue: string
) => boolean

const filterOption: SelectMaterialFilterOption = (option, inputValue: string) => {
  const { value, data } = option
  const materialName = data?.material?.name ?? null
  const matchesMaterialName = !materialName ? false : materialName.toLowerCase().includes(inputValue.toLowerCase())
  const matchesItemCode = value.toLowerCase().includes(inputValue.toLowerCase())
  return matchesItemCode || matchesMaterialName
}
