import { ConfigProvider, Table } from 'antd'
import { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import { useAntdTableTheme } from '@/utils/useAntdTableTheme'
import { formatCurrencyString } from '@/utils/utilities'

import { DispatchPayoutWorkOrder, useDispatchPayoutQuery } from '../useDispatchPayoutQuery'

import type { TableProps } from 'antd'

interface WorkItemsTableProps {
  workOrderId: string | undefined
}

const WorkItemsTable = ({ workOrderId }: WorkItemsTableProps) => {
  const theme = useAntdTableTheme()

  const { data } = useDispatchPayoutQuery({ workOrderId })
  const workItems = useMemo(() => data?.workOrder?.workItems ?? [], [data])

  return (
    <>
      <h4 style={{ paddingBottom: 8 }}>Work Order Items</h4>

      <AntdTableWrapper>
        <ConfigProvider theme={theme}>
          <Table<WorkItemType>
            size='small'
            dataSource={workItems}
            columns={workOrderItemsColumns}
            rowKey='id'
            rowClassName={(item, index) => {
              let className = index % 2 === 1 ? 'alt-row-style' : 'main-row-style'
              if (item.materialName.toLowerCase().includes('total')) className = className + ' total-row-style'
              return className
            }}
            pagination={false}
          />
        </ConfigProvider>
      </AntdTableWrapper>
    </>
  )
}

export default WorkItemsTable

type WorkItemType = NonNullable<DispatchPayoutWorkOrder['workItems']>[number]
const workOrderItemsColumns: TableProps<WorkItemType>['columns'] = [
  {
    key: 'workAreaName',
    dataIndex: 'workAreaName',
    title: 'Work Area',
  },
  {
    key: 'materialName',
    dataIndex: 'materialName',
    title: 'Material',
  },

  {
    key: 'quantity',
    dataIndex: 'quantity',
    title: 'Qty',
    align: 'right',
    className: 'right mono',
  },
  {
    key: 'unitOfMeasure',
    dataIndex: 'unitOfMeasure',
    title: 'Unit',
  },
  {
    key: 'needed',
    dataIndex: 'containersNeededFinal',
    title: 'Needed',
    align: 'right',
    className: 'right mono',
  },
  {
    key: 'containerLabel',
    dataIndex: 'containerLabel',
    title: 'Cont',
  },
  {
    key: 'laborCost',
    dataIndex: 'laborCostFinal',
    title: 'Labor Cost',
    align: 'right',
    className: 'right mono',

    render: text => formatCurrencyString(text),
  },
]

const AntdTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  margin-bottom: 24px;
  padding-bottom: 24px;
  align-self: flex-start;
  overflow-x: auto;
  flex: 0 0 auto;

  .alt-row-style {
    background-color: ${token('color.background.neutral')};
  }

  .mono {
    font-family: ${MONOSPACE_FONT_FAMILY};
  }

  .ant-table {
    font-size: 13px !important;
    margin-bottom: 48px !important;
  }
`
