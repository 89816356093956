import UploadIcon from '@atlaskit/icon/core/upload'

import Button from '@atlaskit/button/new'
import { useCallback, useState } from 'react'

import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import ImportHoursForm from './ImportHoursForm'

export default function ImportHoursModal() {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Button onClick={() => setIsOpen(true)} iconBefore={UploadIcon}>
        Import Gusto Hours
      </Button>

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <ImportHoursForm closeModal={closeModal} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}
