import { useSelectedInstaller } from '@/utils/queryHooks/useSelectedInstaller'

import { useSelectedWeekYear } from '@/components/MiniCalendarNav/useSelectedWeekYear'

import ImportHoursModal from './ImportHoursModal'
import PrintPaystubsModal from './PrintPaystubsModal'
import ToggleShowLookups from './ToggleShowLookups'

const ReconPayrollActions = () => {
  const { mondayDate } = useSelectedWeekYear()
  const installer = useSelectedInstaller()

  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
      <ImportHoursModal />
      <PrintPaystubsModal weekOf={mondayDate?.toString() ?? null} installerId={installer?.id} />
      <ToggleShowLookups />
    </div>
  )
}

export default ReconPayrollActions
