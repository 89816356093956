import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { toast } from 'sonner'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import SectionMessage from '@atlaskit/section-message'

import { CsvInstallerWeek, parseHoursCsv } from '@/utils/parsePayroll'
import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'

import { FileDropZone } from '@/components/DropFile'

import { useSelectedWeekYear } from '../../../../components/MiniCalendarNav/useSelectedWeekYear'
import { MONOSPACE_FONT_FAMILY } from '../../../../utils/constants'
import { GET_ALL_INSTALLER_PAYROLL_QUERY_KEY } from '../reconPayrollTypes'

import ImportHoursTable from './ImportHoursTable'

interface ImportHoursFormProps {
  closeModal: () => void
}

// Define the input types expected by the GraphQL API
interface CsvDayHoursInput {
  date: string
  totalHours: string
}

interface CsvInstallerWeekInput {
  name: string
  dayHours: CsvDayHoursInput[]
}

interface InstallerError {
  date_string: string
  installer_name: string
  dispatch_hrs: string
  submitted_hrs: string
  difference: string
}

type ParsedInstallerErrors = Record<string, InstallerError[]>

const ImportHoursForm = ({ closeModal }: ImportHoursFormProps) => {
  const graphqlClient = useGraphQLClient()
  const [data, setData] = useState<CsvInstallerWeek[]>([])
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const queryClient = useQueryClient()
  const { weekNumber, weekYear } = useSelectedWeekYear()
  const handleFileAccepted = (file: File) => {
    if (!file) return

    const reader = new FileReader()
    reader.onload = event => {
      const text = event.target?.result
      if (typeof text === 'string') {
        try {
          const parsedData = parseHoursCsv(text)
          setData(parsedData)
          setIsFileUploaded(true)
        } catch (err) {
          console.error('Error parsing CSV:', err)
        }
      }
    }
    reader.readAsText(file)
  }

  const { mutate, isPending, error, reset } = useMutation({
    mutationFn: async () => {
      // Convert Date objects to ISO strings for the API and ensure totalHours is a string
      const formattedData: CsvInstallerWeekInput[] = data.map(week => ({
        name: week.name,
        dayHours: week.dayHours.map(day => ({
          date: day.date.toString(),
          totalHours: String(day.totalHours),
        })),
      }))
      console.log({ formattedData })
      const result = await graphqlClient.request(SUBMIT_CSV_HOURS_MUTATION, {
        installerWeeks: formattedData,
      })
      if (!result.submitCsvHours.success) {
        throw new Error(result.submitCsvHours.message)
      }
      return result
    },
    onSuccess: data => {
      const message = data.submitCsvHours.message
      queryClient.invalidateQueries({ queryKey: [GET_ALL_INSTALLER_PAYROLL_QUERY_KEY, { weekNumber, weekYear }] })
      toast.success(message)
      closeModal()
    },
  })

  const handleSubmit = () => {
    if (data.length > 0) mutate()
  }

  const errorMessage = useMemo(() => {
    if (error && error.message) {
      try {
        console.log({ error })
        const installerErrors = JSON.parse(error.message) as ParsedInstallerErrors
        const sortedKeys = Object.keys(installerErrors).sort((a, b) => a.localeCompare(b))
        return (
          <>
            <p>Error: Cannot import fewer hours than what is logged in Dispatch Recon. Each instance listed in the following table.</p>
            <StyledTable>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Installer</th>
                  <th>Dispatch</th>
                  <th>Gusto</th>
                  <th>Diff</th>
                </tr>
              </thead>
              {sortedKeys.map(dateString =>
                installerErrors[dateString].map(error => (
                  <tr key={error.date_string + error.installer_name}>
                    <td>{error.date_string}</td>
                    <td>{error.installer_name}</td>
                    <td>{error.dispatch_hrs}</td>
                    <td>{error.submitted_hrs}</td>
                    <td>{error.difference}</td>
                  </tr>
                ))
              )}
            </StyledTable>
          </>
        )
      } catch (err) {
        console.error('Error parsing error message:', err)
        return (
          <p>
            The server gave the following error message. Copy the message and your current URL and send it to Evan:
            <br />
            {error.message}
          </p>
        )
      }
    }
    return null
  }, [error])

  return (
    <>
      <ModalHeader>
        <ModalTitle>Import Gusto Hours</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <div style={{ paddingBottom: 20 }}>
          {!isFileUploaded ? (
            <div style={{ marginBottom: '20px' }}>
              <FileDropZone
                acceptedFileTypes='text/csv,application/vnd.ms-excel'
                onFileAccepted={handleFileAccepted}
                dropzoneText='Drop CSV file here'
                buttonText='Select CSV file'
                multiple={false}
                showGallery={false}
              />
              <p style={{ marginTop: '10px', fontSize: '14px', color: '#6B778C' }}>
                Please upload a CSV file exported from Gusto containing employee hours.
              </p>
            </div>
          ) : (
            <>
              {isPending ? (
                <SectionMessage appearance='warning'>
                  <p>Importing hours...</p>
                </SectionMessage>
              ) : null}

              {error ? (
                <SectionMessage title='Error Importing Hours' appearance='error'>
                  {errorMessage}
                </SectionMessage>
              ) : null}

              {error ? null : (
                <SectionMessage appearance='success' title='CSV File Parsed Successfully'>
                  <p>The following data was extracted from your CSV file. Click "Import Hours" to submit.</p>
                </SectionMessage>
              )}

              <ImportHoursTable data={data} />
            </>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={closeModal} isDisabled={isPending} isLoading={isPending}>
          Cancel
        </Button>
        {data.length > 0 && (
          <Button appearance='default' onClick={handleReset}>
            Reset
          </Button>
        )}
        {isFileUploaded && (
          <Button appearance='primary' onClick={handleSubmit} isDisabled={isPending} isLoading={isPending}>
            Import Hours
          </Button>
        )}
      </ModalFooter>
    </>
  )

  function handleReset() {
    setData([])
    setIsFileUploaded(false)
    reset()
  }
}

export default ImportHoursForm

const StyledTable = styled.table`
  td,
  th {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      text-align: right;
    }
  }

  td {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      font-family: ${MONOSPACE_FONT_FAMILY};
    }
  }
`
const SUBMIT_CSV_HOURS_MUTATION = graphql(/* GraphQL */ `
  mutation SubmitCsvHours($installerWeeks: [CsvInstallerWeekInput!]!) {
    submitCsvHours(installerWeeks: $installerWeeks) {
      success
      message
    }
  }
`)
