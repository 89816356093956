import { useQuery } from '@tanstack/react-query'
import { useAtom, useSetAtom } from 'jotai'

import { isRefreshingTokensAtom, unifiedTokenAtom } from '@/utils/appAtoms'
import { REFRESH_TOKEN_MUTATION } from '@/utils/useAccessToken'
import { useCurrentUser } from '@/utils/useCurrentUser'

import { useGraphQLClient } from './useGraphQLClient'

export const useRefreshToken = () => {
  const setIsRefreshing = useSetAtom(isRefreshingTokensAtom)

  const [unifiedToken, setUnifiedToken] = useAtom(unifiedTokenAtom)
  const { refreshToken } = unifiedToken
  const graphQLClient = useGraphQLClient()

  useCurrentUser()
  const { isPending } = useQuery({
    queryKey: ['refreshToken', { refreshToken }],
    queryFn: async () => {
      setIsRefreshing(true)
      const response = await graphQLClient.request(REFRESH_TOKEN_MUTATION, { refreshToken })

      if (
        response.refreshToken.success &&
        response.refreshToken?.refreshToken?.token &&
        response.refreshToken?.refreshToken?.expiresAt &&
        response.refreshToken?.token?.payload?.exp &&
        response.refreshToken?.token?.token
      ) {
        console.log('useRefreshToken: Received refresh success: ', response.refreshToken)
        setUnifiedToken({
          refreshToken: response.refreshToken.refreshToken.token,
          refreshTokenExpiration: new Date(response.refreshToken.refreshToken.expiresAt).toISOString(),
          accessTokenExpiration: new Date(response.refreshToken.token.payload.exp).toISOString(),
          accessToken: response.refreshToken.token.token,
        })
        setIsRefreshing(false)
        return response
      } else {
        console.error('Did not get expected refresh response.', response)
        setIsRefreshing(false)
        throw new Error('Failed to refresh token')
      }
    },
    staleTime: 1000 * 60 * 220, // 220 minutes, 20 less than expiration
    refetchInterval: 1000 * 60 * 60, // 1 hour
    refetchIntervalInBackground: true,
  })
  return { refreshToken, isPending }
}
