import { useSearchParams } from 'react-router'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { token } from '@atlaskit/tokens'

import ReconAppPage from '../ReconAppPage'

import InstallerWeekDates from './components/InstallerWeekDatesTable'
import InstallerWeekDispatchTable from './components/InstallerWeekDispatchTable'
import { usePaystubQuery } from './hooks/usePaystubQuery'
import { useWeekYearInstallerParams } from './hooks/useWeekYearInstallerParams'

const ReconPayrollInstallerPage = () => {
  const { weekNumber, year, installerId } = useWeekYearInstallerParams()
  const [searchParams] = useSearchParams()

  const { data, isLoading } = usePaystubQuery()

  const installerPayroll = data?.installerPayroll
  const installer = installerPayroll?.installer

  const headerText = `Paystub • ${year}-w${weekNumber}` + (!installer?.fullName ? '' : ` • ${installer?.fullName}`)

  const handlePrintClick = () => {
    const currentUrl = window.location.pathname
    const printUrl = `${currentUrl}/print?${searchParams.toString()}`
    window.open(printUrl, '_blank')
  }

  return (
    <ReconAppPage header={headerText} isLoading={isLoading} actions={<Button onClick={handlePrintClick}>Print</Button>}>
      <title>{headerText}</title>

      {installerId ? (
        <PageWrapper>
          <InstallerWeekDispatchTable installerPayroll={installerPayroll} />

          <InstallerWeekDates installerPayroll={installerPayroll} isLoading={isLoading} />
        </PageWrapper>
      ) : (
        <PageContainer>No installer selected</PageContainer>
      )}
    </ReconAppPage>
  )
}

export default ReconPayrollInstallerPage

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%; // Changed from align-self: stretch
  max-width: 100%;
  .header {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${token('color.text')};
  }
`
