import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtom, useSetAtom } from 'jotai'
import { useCallback, useMemo } from 'react'

import SUBMIT_DISPATCH_ASSIGNMENT from '@/utils/api/submitDispatchAssignment'
import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { DispatchAssignmentAction as Actions, SubmitDispatchAssignmentMutationVariables } from '@/gql/graphql'

import { hoveredDropZoneAtom, isSavingAssignmentAtom, itemPickedUpAtom, pendingMutationsAtom } from '../../dispatchTypes'
import { useSelectedVehicleDispatchIds } from '../selectors/useSelectedVehicleDispatchIds'

import { GET_DISPATCH_DATE_QUERY_KEY } from './useDispatchDateQuery'
import { useIsShiftKeyPressed } from './useIsShiftKeyPressed'

type SubmitDispatchVariables = SubmitDispatchAssignmentMutationVariables

export const useVehicleHasDispatches = (vehicleId: string) => {
  const { vehicleDispatchIds } = useSelectedVehicleDispatchIds(vehicleId)
  const result = useMemo(() => vehicleDispatchIds.length > 0, [vehicleDispatchIds])
  return result
}

export const useDragStateMutation = (dateString: string) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const isShiftKeyPressed = useIsShiftKeyPressed()

  const [itemHovered, setItemHovered] = useAtom(hoveredDropZoneAtom)
  const [itemPickedUp, setItemPickedUp] = useAtom(itemPickedUpAtom)

  const setPendingMutations = useSetAtom(pendingMutationsAtom)
  const setIsSavingAssignment = useSetAtom(isSavingAssignmentAtom)

  const doesHoveredVehicleHaveDispatches = useVehicleHasDispatches(itemHovered?.vehicleId ?? '')
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (variables: SubmitDispatchVariables) => {
      const result = await graphQLClient.request(SUBMIT_DISPATCH_ASSIGNMENT, variables)
      if (!result.submitDispatchAssignment.success) throw new Error(result.submitDispatchAssignment.message)
      return result
    },
    onSuccess: () => {
      console.info('mutation success')
      queryClient.invalidateQueries({ queryKey: [GET_DISPATCH_DATE_QUERY_KEY] })
    },
    onError: error => {
      console.error('mutation error: ', error)
      setPendingMutations({})
    },
    onSettled: () => {
      setIsSavingAssignment(false)
      setItemHovered({})
      setItemPickedUp({})
    },
  })

  const variables = useMemo(() => {
    let action: Actions | null = null
    const zone = itemHovered?.zone ?? null

    const installerId = itemPickedUp?.installerId ?? null
    const dispatchId = itemPickedUp?.dispatchId ?? null

    const targetVehicleDispatchId = itemHovered?.vehicleDispatchId ?? null
    const targetVehicleId = itemHovered?.vehicleId ?? null

    const originVehicleDispatchId = itemPickedUp?.vehicleDispatchId ?? null
    const originVehicleId = itemPickedUp?.vehicleId ?? null

    if (installerId !== null) {
      if (zone === 'VEHICLE_HEADER') {
        if (doesHoveredVehicleHaveDispatches) action = Actions.CreateInstallerAssignmentAll
      } else if (zone === 'VEHICLE_DISPATCH_INSTALLERS') {
        if (originVehicleDispatchId === null) action = Actions.CreateInstallerAssignment
        else {
          if (isShiftKeyPressed) action = Actions.CopyInstallerAssignment
          else action = Actions.MoveInstallerAssignment
        }
      } else if (zone === 'INSTALLER_SIDEBAR') action = Actions.RemoveInstallerAssignment
      // TODO: use another modifier key for remove all
    } else if (dispatchId !== null) {
      if (zone === 'VEHICLE_HEADER' || zone === 'VEHICLE_BODY') {
        if (originVehicleId === null) action = Actions.CreateVehicleDispatch
        else {
          if (isShiftKeyPressed) action = Actions.CopyVehicleDispatch
          else action = Actions.MoveVehicleDispatch
        }
      } else if (zone === 'DISPATCH_SIDEBAR') action = Actions.RemoveVehicleDispatch
    }

    const input: Partial<SubmitDispatchVariables> = {
      date: dateString,

      installerId,
      dispatchId,
      targetVehicleDispatchId,
      targetVehicleId,
      originVehicleDispatchId,
      originVehicleId,
    }
    if (action) {
      input.action = action
      const inputAttributesList = Object.entries(input).map(([key, value]) => ({ key, value }))
      console.table(inputAttributesList)
    } else {
      console.log('no action')
    }

    return input
  }, [doesHoveredVehicleHaveDispatches, itemHovered, itemPickedUp, dateString, isShiftKeyPressed])

  const submitAssignment = useCallback(() => {
    if (variables === null || !variables.action) {
      console.warn('useDragState submitAssignment called with null variables or null action: ', variables)
      return
    }
    setPendingMutations(variables)

    setIsSavingAssignment(true)
    mutate(variables as SubmitDispatchVariables)
  }, [mutate, variables, setIsSavingAssignment, setPendingMutations])

  return { submitAssignment, isPending, error }
}
