import LocationIcon from '@atlaskit/icon/glyph/location'

import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import Tooltip from '@atlaskit/tooltip'

interface PinButtonProps {
  openMapModal: () => void
}

export default function PinButton({ openMapModal }: PinButtonProps) {
  return (
    <Tooltip content='Drop a pin on the map'>
      <StyledPinButton appearance='subtle' iconBefore={LocationIcon} onClick={openMapModal} type='button'>
        <span className='visually-hidden'>Drop a pin</span>
      </StyledPinButton>
    </Tooltip>
  )
}

const StyledPinButton = styled(Button)`
  min-width: 36px;
  padding: 0 8px;

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`
