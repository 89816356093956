import { useCallback, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { createGlobalStyle } from 'styled-components'

import Button from '@atlaskit/button/new'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'

import CreateJobForm from './CreateJobForm'

interface CreateJobModalProps {
  preselectedCustomer?: { label: string; value: string }
}

const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => {
  console.error(error)
  return (
    <div style={{ padding: '24px', maxWidth: '100%' }}>
      <h3>Something went wrong:</h3>
      <pre>{error.message}</pre>
      <Button appearance='primary' onClick={resetErrorBoundary}>
        Close
      </Button>
    </div>
  )
}

// Specific CSS overrides targeting Atlaskit's generated classes
const ModalOverride = createGlobalStyle`
  /* Target the Atlaskit class directly that sets width:100% */
  [class*="_1bsb1osq"] {
    width: 600px !important;
  }

  /* Target other potential containers */
  [data-layer-manager-modal-mount-point] [role="dialog"] > div {
    width: 600px !important;
  }

  /* Set the --modal-dialog-width CSS variable directly */
  :root {
    --modal-dialog-width: 600px !important;
  }
`

export default function CreateJobModal({ preselectedCustomer }: CreateJobModalProps) {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <>
      {isOpen && <ModalOverride />}
      <Button appearance='primary' onClick={openModal}>
        Add Job
      </Button>

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal} width='720px'>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={closeModal}>
              <CreateJobForm closeModal={closeModal} preselectedCustomer={preselectedCustomer} />
            </ErrorBoundary>
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )
}
