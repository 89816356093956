import { token } from '@atlaskit/tokens'

import AbsoluteTooltip from '@/components/AbsoluteTooltip'
import NumberCell from '@/components/NumberCell'
import TableCellNumberTextField from '@/components/TableCellNumberTextField'

import { INSTALLER_CELL_PAD } from '../constants'

import { InstallersTableRowRenderArgs } from './columnRenderDefs'

const CellPercentAddons = ({
  shouldLockAddons,
  setInstallerAmounts,
  mutate,
  handleReturn,
  handleFocus,
  installerDispatch,
}: InstallersTableRowRenderArgs) => {
  const { percentAddons, isPercentAddonsLocked, isCompleted, isApproved, id: installerDispatchId, isPayrollLocked } = installerDispatch

  let isDisabled = false
  let disabledReason = ''
  if (isPayrollLocked) {
    isDisabled = true
    disabledReason = 'Payroll has been completed for the week.'
  } else if (isPercentAddonsLocked) {
    isDisabled = true
    disabledReason = 'This has been locked via the checkbox to the right.'
  } else if (shouldLockAddons) {
    isDisabled = true
    disabledReason = 'At least two installers must have their addons % unlocked to change one.'
  } else if (isCompleted || isApproved) {
    isDisabled = true
    disabledReason = 'This line has been ' + (isCompleted ? 'completed.' : 'approved.')
  }
  if (isDisabled) {
    return (
      <>
        <NumberCell
          padding={INSTALLER_CELL_PAD}
          readOnly
          value={percentAddons ?? undefined}
          suffix='%'
          numberStyle={{ fontWeight: '600', color: token('color.icon.subtlest') }}
        />
        {<AbsoluteTooltip content={disabledReason} position='right' />}
      </>
    )
  }

  return (
    <TableCellNumberTextField
      isCompact
      isDisabled={isDisabled}
      value={percentAddons ?? undefined}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInstallerAmounts({ key: 'percentAddons', value: e.target.value })}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        let value: number | string = e.target.value === '' ? Number(0) : Number(e.target.value)
        if (value > 100) value = Number('100.00')
        value = value.toFixed(2)
        setInstallerAmounts({ key: 'percentAddons', value })
        mutate({ installerDispatchId, attribute: 'percentAddons', value })
      }}
      onKeyDown={handleReturn}
      onFocus={handleFocus}
      suffix='%'
    />
  )
}

export default CellPercentAddons
