import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { CardColorOptions } from '@/utils/utilities'

import { InstallerColumnGroup } from './constants'

const TopHeaderCell = ({ group }: { group: InstallerColumnGroup }) => {
  return (
    <>
      <StyledSpan $color={group.color}> {group.label}</StyledSpan>
      <StyledAbsoluteBorder $color={group.color} />
    </>
  )
}

export default TopHeaderCell

interface StyledAbsoluteBorderProps {
  $color?: CardColorOptions
}

const StyledAbsoluteBorder = styled.div<StyledAbsoluteBorderProps>`
  position: absolute;
  inset: 0;
  z-index: 1;
  background: ${props => token(`color.background.accent.${props.$color ?? 'gray'}.subtlest`)};
  border: 1.5px solid ${props => token(`color.background.accent.${props.$color ?? 'gray'}.subtler`)};
`

interface StyledSpanProps {
  $color?: CardColorOptions
}
const StyledSpan = styled.span<StyledSpanProps>`
  position: relative;
  color: ${props => token(`color.text.accent.${props.$color ?? 'gray'}`)};
  z-index: 2;
`
