import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

import { workOrderPickedUpAtom } from '../atoms'

export const useIsPendingRemoval = (workOrderId: string) => {
  const workOrderPickedUp = useAtomValue(workOrderPickedUpAtom)

  return useMemo(() => workOrderPickedUp?.workOrderId === workOrderId, [workOrderPickedUp, workOrderId])
}
