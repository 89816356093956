import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router'

import GET_VEHICLE_DATE, { GET_VEHICLE_DATE_QUERY_KEY } from '@/utils/api/getVehicleDate'
import { useGraphQLClient } from '@/utils/useGraphQLClient'
import { COLOR_OPTIONS } from '@/utils/utilities'

import { GetPayrollDayVehiclesQuery, GetVehicleDateQuery } from '@/gql/graphql'

import { useSubmitVehicleDateStats } from './VehicleSection/useSubmitVehicleDateStats'

export type ReconDateVehicle = GetPayrollDayVehiclesQuery['vehiclesByDate'][number]
type VehicleDateDetailed = GetVehicleDateQuery['vehicleDate']
type VehicleDispatch = NonNullable<VehicleDateDetailed['vehicleDispatches'][number]>

interface VehicleSectionProps {
  vehicle?: ReconDateVehicle
}

export const useVehicleSection = ({ vehicle }: VehicleSectionProps) => {
  const graphQLClient = useGraphQLClient()
  const [searchParams] = useSearchParams()
  const paramVehicleDateId = searchParams.get('vehicleDateId')
  const { vehicleDateId, color } = useMemo(() => {
    const vdId = vehicle?.vehicleDate?.id ?? null
    // @ts-expect-error - vehicle.color is not always a CardColorOptions
    const vehicleColor = (COLOR_OPTIONS.includes(vehicle?.color ?? '') ? vehicle.color : 'gray') as CardColorOptions
    return { vehicleDateId: vdId, color: vehicleColor }
  }, [vehicle])

  const [vehicleDate, setVehicleDate] = useState<VehicleDateDetailed | undefined>()
  const [vehicleDispatchesSorted, setVehicleDispatchesSorted] = useState<VehicleDispatch[]>([])

  const { mutate, isPending, data } = useSubmitVehicleDateStats()

  useEffect(() => {
    if (data?.submitVehicleDateStats?.vehicleDate) {
      const vehicleDispatchesSorted = sortVehicleDispatchesFromVehicle(data.submitVehicleDateStats.vehicleDate)
      setVehicleDispatchesSorted(vehicleDispatchesSorted)
      setVehicleDate(data.submitVehicleDateStats.vehicleDate)
    }
  }, [data])

  useEffect(() => {
    if (!vehicle || !paramVehicleDateId) {
      setVehicleDispatchesSorted([])
      setVehicleDate(undefined)
    }
  }, [vehicle, paramVehicleDateId])

  useQuery({
    queryKey: [GET_VEHICLE_DATE_QUERY_KEY, vehicleDateId],
    queryFn: async () => {
      try {
        const response = await graphQLClient.request(GET_VEHICLE_DATE, { vehicleDateId: vehicleDateId ?? '' })
        if (response.vehicleDate?.id) {
          const vehicleDispatchesSorted = sortVehicleDispatchesFromVehicle(response.vehicleDate)
          setVehicleDispatchesSorted(vehicleDispatchesSorted)
          setVehicleDate(response.vehicleDate)
        }
        return response
      } catch (error) {
        console.error(error)
        setVehicleDispatchesSorted([])
        setVehicleDate(undefined)
        return null
      }
    },
    enabled: !!vehicleDateId,
  })

  const isSomeCompleted = useMemo(
    () => vehicleDispatchesSorted?.some(vehicleDispatch => vehicleDispatch.dispatch?.countInstallerDispatchesCompleted > 0) ?? false,
    [vehicleDispatchesSorted]
  )

  return { vehicleDispatchesSorted, vehicleDate, color, mutate, isPending, isSomeCompleted }
}

function sortVehicleDispatchesFromVehicle(vehicleDate?: VehicleDateDetailed): VehicleDispatch[] {
  if (!vehicleDate) return []
  const vehicleDispatchIds = vehicleDate?.sortedVehicleDispatchIds ?? []

  const vehicleDispatchesById = (vehicleDate?.vehicleDispatches ?? []).reduce<Record<string, VehicleDispatch>>((acc, vehicleDispatch) => {
    if (!vehicleDispatch || !vehicleDispatch.id) return acc
    acc[vehicleDispatch.id] = vehicleDispatch
    return acc
  }, {})

  const result = vehicleDispatchIds.map(id => vehicleDispatchesById[id])
  console.log('result', result)
  return result
}
