import styled from 'styled-components'

import ErrorBoundary from '@/components/ErrorBoundary'

import ReconAppPage from '../ReconAppPage'

import WorkItemsTable from './components/WorkItemsTable'
import InstallerTable from './InstallersTable/InstallersTable'
import { useReconDatePage } from './useReconDatePage'
import VehicleSection from './VehicleSection'
import WorkOrderTotalsTable from './WorkOrderTotalsTable'

const ReconDatePage = () => {
  const { title, isPending, vehicle, workOrder, workOrderId, date, selectedDispatch, isSomeCompleted } = useReconDatePage()

  return (
    <ReconAppPage header={title} isLoading={isPending}>
      <title>{title} - Thermal Shop Admin</title>

      <PageContainer>
        <ErrorBoundary customDisplayMessage='Error loading vehicle section'>
          <VehicleSection vehicle={vehicle} />
        </ErrorBoundary>

        <ErrorBoundary customDisplayMessage='Error loading work order totals table'>
          <WorkOrderTotalsTable workOrder={workOrder} dateString={date} isSomeCompleted={isSomeCompleted === true} />
        </ErrorBoundary>

        <ErrorBoundary customDisplayMessage='Error loading installers table'>
          <InstallerTable dispatch={selectedDispatch} isDavisBacon={workOrder?.isDavisBacon === true} />
        </ErrorBoundary>

        <ErrorBoundary customDisplayMessage='Error loading work items table'>
          <WorkItemsTable workOrderId={workOrderId} />
        </ErrorBoundary>
      </PageContainer>
    </ReconAppPage>
  )
}

export default ReconDatePage

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  position: relative;
  flex: 1 1 auto;
  max-width: 100%;
`
