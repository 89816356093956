import { Temporal } from '@js-temporal/polyfill'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import { CsvInstallerWeek } from '@/utils/parsePayroll'

interface ImportHoursTableProps {
  data: CsvInstallerWeek[]
}

// Define the structure for our table row data
interface TableRow {
  name: string
  // Dynamic day columns will be added as Record<string, string | number>
  [key: string]: string | number | null
}

const columnHelper = createColumnHelper<TableRow>()

/**
 * Formats a number to always display with 3 decimal places
 */
const formatHours = (value: string | number | null): string => {
  if (value === null) return '-'
  // Convert to number if it's a string
  const numValue = typeof value === 'string' ? parseFloat(value) : value
  return numValue.toFixed(3)
}

const ImportHoursTable = ({ data }: ImportHoursTableProps) => {
  // Generate the week dates starting from Monday
  const weekDates = useMemo(() => {
    // Find the Monday of the week from the first date in the data
    // If no data, use current week
    let mondayDate: Temporal.PlainDate

    if (data.length > 0 && data[0].dayHours.length > 0) {
      const firstDate = data[0].dayHours[0].date
      // firstDate is already a Temporal.PlainDate, so we can use it directly
      const plainDate = firstDate

      // Calculate days to subtract to get to Monday
      // Temporal.PlainDate.dayOfWeek: 1 = Monday, 2 = Tuesday, ..., 7 = Sunday
      const dayOfWeek = plainDate.dayOfWeek
      // Subtract (dayOfWeek - 1) days to get to Monday
      mondayDate = plainDate.subtract({ days: dayOfWeek - 1 })
    } else {
      // If no data, use current date and find the Monday
      const now = Temporal.Now.plainDateISO()
      // Temporal.PlainDate.dayOfWeek: 1 = Monday, 2 = Tuesday, ..., 7 = Sunday
      const dayOfWeek = now.dayOfWeek
      // Subtract (dayOfWeek - 1) days to get to Monday
      mondayDate = now.subtract({ days: dayOfWeek - 1 })
    }

    // Generate array of 7 dates starting from Monday
    const dates: Temporal.PlainDate[] = []
    for (let i = 0; i < 7; i++) {
      dates.push(mondayDate.add({ days: i }))
    }

    return dates
  }, [data])

  // Transform the data into the format needed for the table
  const tableData = useMemo(() => {
    return data.map(installer => {
      const row: TableRow = { name: installer.name }

      // Initialize all days with null
      weekDates.forEach(date => {
        row[date.toString()] = null
      })

      // Fill in the hours data where available
      installer.dayHours.forEach(dayHour => {
        const dateStr = dayHour.date.toString()

        row[dateStr] = dayHour.totalHours
      })

      return row
    })
  }, [data, weekDates])

  // Define columns
  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('name', {
        header: 'Installer',
        cell: info => info.getValue(),
        size: 200,
      }),
    ]

    // Add a column for each day of the week
    weekDates.forEach(date => {
      const dayName = date.toLocaleString('en-US', { weekday: 'short' })
      const dateStr = date.toString()

      cols.push(
        columnHelper.accessor(dateStr, {
          header: () => (
            <div>
              <div>{dayName}</div>
              <div>{date.day}</div>
            </div>
          ),
          cell: info => {
            const value = info.getValue()
            return formatHours(value)
          },
          size: 100,
        })
      )
    })

    return cols
  }, [weekDates])

  // Initialize the table
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <StyledTh key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </StyledTh>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <StyledTr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <StyledTd key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</StyledTd>
              ))}
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  )
}

const TableContainer = styled.div`
  margin-top: 20px;
  border: 1px solid ${token('color.border')};
  border-radius: 3px;
  overflow: auto;
  max-height: 300px;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    z-index: 2;
    background-color: ${token('elevation.surface.hovered')};
    > tr {
      > th {
        background-color: ${token('elevation.surface.hovered')};
      }
    }
  }
`

const StyledTh = styled.th`
  background-color: ${token('color.background.neutral')};
  color: ${token('color.text')};
  font-weight: 600;
  text-align: left;
  padding: 4px 8px;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid ${token('color.border')};
  vertical-align: bottom;
  &:first-child {
    text-align: left;
    padding: 4px 8px;
  }
  &:not(:first-child) {
    text-align: center;
  }
`

const StyledTd = styled.td`
  padding: 8px 12px;
  border-bottom: 1px solid ${token('color.border')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:first-child {
    text-align: left;
    padding: 0 8px;
  }
  &:last-child {
    text-align: right;
    padding: 0 8px;
  }
  &:not(:first-child) {
    font-family: ${MONOSPACE_FONT_FAMILY};
    text-align: right;
  }
`

const StyledTr = styled.tr`
  &:hover {
    background-color: ${token('color.background.input.hovered')};
  }
`

export default ImportHoursTable
