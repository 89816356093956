import Tooltip from '@atlaskit/tooltip'

const AbsoluteTooltip = ({
  content,
  position,
  zIndex = 1000,
}: {
  content: string | React.ReactNode
  position: 'left' | 'right'
  zIndex?: number
}) => {
  return (
    <Tooltip content={content} position={position}>
      <div
        style={{
          zIndex,
          position: 'absolute',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
    </Tooltip>
  )
}

export default AbsoluteTooltip
