import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { editingCellAtom } from '../editorAtoms'
import { WorkItemEditableAttributeKeys } from '../editorTypes'

import { useWorkItemAttribute } from './useWorkItemAttribute'

interface UseEditingCellValueProps {
  workItemId: string
  attribute: WorkItemEditableAttributeKeys
}

export const useEditingCellValue = ({ workItemId, attribute }: UseEditingCellValueProps) => {
  const optimisticAttribute = useWorkItemAttribute(attribute, workItemId)
  const editedValueAtom = useMemo(
    () => selectAtom(editingCellAtom, ({ edits }) => edits?.[workItemId]?.[attribute]),
    [workItemId, attribute]
  )
  const editedValue = useAtomValue(editedValueAtom)
  if (editedValue !== undefined) return editedValue
  return optimisticAttribute
}
