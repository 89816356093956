import { useSetAtom } from 'jotai'
import { useCallback } from 'react'

import { editingCellAtom, workItemEditsQueueAtom, workItemsOptimisticAttributesAtom } from '../editorAtoms'
import { QueriedEstimateWorkItem, WorkItemEditableAttributeKeys } from '../editorTypes'

interface CommitEditsParams {
  workItemId: string
  attribute: WorkItemEditableAttributeKeys
  value: string | boolean | null
  additionalOptimisticAttributes?: Partial<QueriedEstimateWorkItem>
  nextRowId?: string | null
  nextColumnId?: string | null
}

export const useCellNavigation = () => {
  const setEditingCell = useSetAtom(editingCellAtom)
  const setWorkItemEditsQueue = useSetAtom(workItemEditsQueueAtom)
  const setOptimisticAttributes = useSetAtom(workItemsOptimisticAttributesAtom)

  const handleEscape = (attribute: WorkItemEditableAttributeKeys) => {
    setEditingCell(({ rowId, edits }) => {
      // If there's no active row or no edits for the attribute, exit edit mode
      console.log('handleEscape: ', { rowId, edits, attribute })
      let newEdits = edits
      if (rowId) {
        const itemEdits = edits?.[rowId]

        if (itemEdits) {
          const itemAttributeEdits = itemEdits[attribute]

          if (itemAttributeEdits !== undefined) {
            newEdits = {
              ...edits,
              [rowId]: {
                ...itemEdits,
                [attribute]: undefined,
              },
            }
          }
        }
      }

      // Keep the row focused but remove column focus
      return { rowId, columnId: null, edits: newEdits }
    })
  }

  const handleLeaveCellAndCommitEdits = useCallback(
    ({ workItemId, attribute, nextRowId = null, nextColumnId = null, value, additionalOptimisticAttributes = {} }: CommitEditsParams) => {
      // Commit changes on Enter
      let shouldSubmit = false
      console.log('handleLeaveCellAndCommitEdits: ', {
        workItemId,
        attribute,
        nextRowId,
        nextColumnId,
        value,
        additionalOptimisticAttributes,
      })
      setEditingCell(({ edits }) => {
        const attributeEditsExist = edits?.[workItemId]?.[attribute] !== undefined
        if (attributeEditsExist) {
          shouldSubmit = true
        }
        const newEdits = { ...edits, [workItemId]: { ...edits?.[workItemId], [attribute]: undefined } }
        console.log('handleLeaveCellAndCommitEdits: next cell', nextRowId, nextColumnId)
        console.log('handleLeaveCellAndCommitEdits: newEdits', newEdits)
        return {
          rowId: nextRowId,
          columnId: nextColumnId,
          edits: newEdits,
        }
      })
      if (shouldSubmit) {
        console.log('handleLeaveCellAndCommitEdits: optimistic attributes', additionalOptimisticAttributes)
        setOptimisticAttributes(prev => {
          const existingWorkItem = prev?.[workItemId] ?? {}
          return {
            ...prev,
            [workItemId]: {
              ...existingWorkItem,
              ...additionalOptimisticAttributes,
              [attribute]: value,
            },
          }
        })
        console.log('handleLeaveCellAndCommitEdits: work item edits queue', workItemId, attribute, value)
        setWorkItemEditsQueue(prev => [...prev, { workItemId, attribute, value: value ?? null }])
      }
    },
    [setOptimisticAttributes, setWorkItemEditsQueue, setEditingCell]
  )

  // const handleTab = useCallback(
  //   ({ goForward = true, workItemId, attribute, value }: HandleTabParams) => {
  //     console.log('handleTab: ', { goForward, workItemId, attribute, value })
  //     handleLeaveCellAndCommitEdits({
  //       workItemId,
  //       attribute,
  //       value,
  //       nextRowId: goForward ? nextCell?.rowId : previousCell?.rowId,
  //       nextColumnId: goForward ? nextCell?.columnId : previousCell?.columnId,
  //     })
  //   },
  //   [nextCell, previousCell, handleLeaveCellAndCommitEdits]
  // )

  return {
    handleEscape,
    handleLeaveCellAndCommitEdits,
    // handleTab,
    // nextCell,
    // previousCell,
  }
}
