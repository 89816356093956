import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import AbsoluteTooltip from '@/components/AbsoluteTooltip'
import NumberCell from '@/components/NumberCell'

import { graphql } from '@/gql'
import { SubmitDispatchPayoutRecalcMutationVariables } from '@/gql/graphql'

import { CELL_PAD } from '../constants'
import { DispatchPayoutType, GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY } from '../useDispatchPayoutQuery'

interface RecalcCellProps {
  dispatch?: DispatchPayoutType
  payType: 'piece_rate' | 'addons'
}

const RecalcCell = ({ dispatch, payType = 'piece_rate' }: RecalcCellProps) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const workOrderId = dispatch?.workOrderId

  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: SubmitDispatchPayoutRecalcMutationVariables) => {
      if (!dispatch) throw new Error('Dispatch is required')
      const response = await graphQLClient.request(SUBMIT_DISPATCH_PAYOUT_RECALC, variables)
      if (!response.submitDispatchPayoutRecalc?.success)
        throw new Error(response.submitDispatchPayoutRecalc?.message ?? 'Failed to recalculate payout')
      return response.submitDispatchPayoutRecalc
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY, workOrderId] })
    },
  })

  const { shouldLock, reason } = useMemo(() => {
    console.log('shouldLock payType', payType)

    if (!dispatch?.installerDispatches) return { shouldLock: false, reason: 'No installers' }

    if (payType === 'piece_rate') {
      if (!dispatch.percentPieceRateAllocated || Number(dispatch.percentPieceRateAllocated) < 99)
        return { shouldLock: false, reason: 'Percent piece rate is less not 100%' }
      // Return true if there are less than two installerDispatches where isPercentPieceRateLocked is false
      const unlockedCount = dispatch.installerDispatches.filter(
        installerDispatch => installerDispatch.isPercentPieceRateLocked === false && installerDispatch.isCompleted === false
      ).length
      return {
        shouldLock: unlockedCount < 2,
        reason: unlockedCount === 0 ? 'All installers are locked' : 'Less than two installers are unlocked',
      }
    }

    if (!dispatch.isAddonsSeparated === true) return { shouldLock: true, reason: 'Addons are not separated' }

    const unlockedInDispatch = dispatch.installerDispatches.filter(
      installerDispatch => installerDispatch.isPercentAddonsLocked === false && installerDispatch.isCompleted === false
    )
    console.log('shouldLock unlockedInDispatch', unlockedInDispatch)
    return {
      shouldLock: unlockedInDispatch.length === 0,
      reason: 'All installers are locked',
    }
  }, [dispatch, payType])

  return (
    <>
      {shouldLock ? (
        <AbsoluteTooltip content={reason} position='left' zIndex={5} />
      ) : (
        <StyledRecalcButtonWrapper>
          <Button
            appearance='primary'
            onClick={() => dispatch?.id && mutate({ dispatchId: dispatch?.id, payType })}
            isDisabled={!dispatch?.id || isPending || shouldLock}
            isLoading={isPending}
            shouldFitContainer
          >
            Recalc
          </Button>
        </StyledRecalcButtonWrapper>
      )}

      <NumberCell
        padding={CELL_PAD}
        readOnly
        value={payType === 'piece_rate' ? dispatch?.percentPieceRateAllocated : dispatch?.percentAddonsAllocated}
        suffix='%'
        // appearance={recalcEnabled ? 'warning' : 'default'}
      />
    </>
  )
}

export default RecalcCell

const SUBMIT_DISPATCH_PAYOUT_RECALC = graphql(/* GraphQL */ `
  mutation SubmitDispatchPayoutRecalc($dispatchId: ID!, $payType: String!) {
    submitDispatchPayoutRecalc(dispatchId: $dispatchId, payType: $payType) {
      success
      message
    }
  }
`)

const StyledRecalcButtonWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  opacity: 0;
  > button {
    z-index: 6;
  }

  transition: opacity 0.1s ease-in-out;
  &:hover {
    opacity: 1;
  }
`
