import { Temporal } from '@js-temporal/polyfill'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router'

import {
  CalendarDate,
  CalendarWeek,
  DateSelectorProps,
  IndicatorById,
  InitialProps,
  MiniCalOnChangeSelectionFunction,
  SelectionMode,
} from './calendarTypes'
import { useDisplayMonthYear } from './useDisplayMonthYear'
import { useSelectedDate } from './useSelectedDate'
import { useSelectedWeekYear } from './useSelectedWeekYear.ts'

export const WEEK_NUM_WIDTH = 28
export const DAY_WIDTH = 38
export const DAY_NUMBER_DIV_HEIGHT = 18
export const INDICATOR_DOTS_DIV_HEIGHT = 12
export const DAY_CELL_PADDING_TOP = 4
export const DAY_CELL_PADDING_BOTTOM = 6
export const DAY_CELL_HEIGHT = DAY_CELL_PADDING_TOP + DAY_NUMBER_DIV_HEIGHT + INDICATOR_DOTS_DIV_HEIGHT + DAY_CELL_PADDING_BOTTOM + 2
export const HEADER_ROW_HEIGHT = 24
export const NAV_ROW_HEIGHT = 30
export const WEEK_VIEW_HEIGHT = 6 * DAY_CELL_HEIGHT
export const CALENDAR_HEIGHT = WEEK_VIEW_HEIGHT + HEADER_ROW_HEIGHT + NAV_ROW_HEIGHT
export const SELECTION_BACKGROUND_ANIMATION_DURATION = 70
export const dateIndicatorsByDateIdAtom = atom<IndicatorById>({})
export const weekIndicatorsByWeekIdAtom = atom<IndicatorById>({})

export const isMonthYearPickerOpenAtom = atom<boolean>(false)

const useDateIndicators = (dateId: string) => {
  const slicedAtom = useMemo(() => selectAtom(dateIndicatorsByDateIdAtom, sel => sel[dateId]), [dateId])
  return useAtomValue(slicedAtom)
}

const useWeekIndicators = (weekId: string) => {
  const slicedAtom = useMemo(() => selectAtom(weekIndicatorsByWeekIdAtom, sel => sel[weekId]), [weekId])
  return useAtomValue(slicedAtom)
}

const useIsDateSelected = (dateId: string) => {
  const { dateId: paramDateId } = useSelectedDate()
  return useMemo(() => paramDateId === dateId, [dateId, paramDateId])
}

const useIsDateInDisplayMonth = (dateMonthNumber: number, dateYear: number) => {
  const { month, year } = useDisplayMonthYear(false)
  return useMemo(() => month === dateMonthNumber && year === dateYear, [month, year, dateMonthNumber, dateYear])
}

const useIsToday = (date: CalendarDate) => {
  const today = Temporal.Now.plainDateISO()
  return date.year === today.year && date.monthNumber === today.month && date.dayNumber === today.day
}

const useIsWeekSelected = (weekId: string) => {
  const { weekId: paramWeekId } = useSelectedWeekYear()
  return useMemo(() => paramWeekId === weekId, [weekId, paramWeekId])
}

export const useDateProps = (date: CalendarDate) => {
  const { monthNumber, year, id } = date
  const isToday = useIsToday(date)
  const indicators = useDateIndicators(id)
  const isSelected = useIsDateSelected(id)
  const isInDisplayMonth = useIsDateInDisplayMonth(monthNumber, year)
  return { indicators, isSelected, isInDisplayMonth, isToday }
}

export const useWeekProps = (weekId: string) => {
  const indicators = useWeekIndicators(weekId)
  const isSelected = useIsWeekSelected(weekId)
  return { indicators, isSelected }
}
export const useDateChangeTrigger = (onChange?: MiniCalOnChangeSelectionFunction) => {
  const { dateId } = useSelectedDate()
  const { weekId } = useSelectedWeekYear()

  const [localSelectedDateId, setLocalSelectedDateId] = useState<string | null>(null)
  const [localSelectedWeekId, setLocalSelectedWeekId] = useState<string | null>(null)

  useEffect(() => {
    if (dateId && weekId && (dateId !== localSelectedDateId || weekId !== localSelectedWeekId)) {
      setLocalSelectedDateId(dateId)
      setLocalSelectedWeekId(weekId)
      if (onChange)
        onChange({
          selectedDate: createSelectedDateFromPlainDate(Temporal.PlainDate.from(dateId)),
          selectedWeek: createSelectedWeekFromPlainDate(Temporal.PlainDate.from(weekId)),
        })
    }
  }, [onChange, dateId, weekId, localSelectedDateId, localSelectedWeekId])
}

export const useDateSelector = ({ selectionMode }: DateSelectorProps) => {
  const { month, year } = useDisplayMonthYear(false)
  const [, setSearchParams] = useSearchParams()

  const selectDate = useCallback(
    ({ date, week }: { date: CalendarDate; week: CalendarWeek }) => {
      const newDateId = date.id
      const newWeekId = week.id
      const doesWeekOverlap = doesMonthYearIncludeWeekNumber(month, year, week.weekNumber, week.weekYear)
      const sameMonthYear = date.monthNumber === month && date.year === year
      const shouldUpdateMonthYear = selectionMode === ('date' as SelectionMode) ? !sameMonthYear : !doesWeekOverlap
      const newCId = `${date.year}-${date.monthNumber}`
      setSearchParams(prev => {
        if (shouldUpdateMonthYear) prev.set('cId', newCId)
        prev.set('dateId', newDateId)
        prev.set('weekId', newWeekId)
        return prev
      })
    },
    [selectionMode, month, year, setSearchParams]
  )
  return selectDate
}

// For "page" animations (left-right transitions)
export const pageAtom = atom<number>(0)
export const directionAtom = atom<1 | -1>(1)

export const useInitialProps = ({ initialDisplayMonth, initialDisplayYear, initialSelectedDate }: InitialProps) => {
  const [, setSearchParams] = useSearchParams()

  const paramDate = useSelectedDate()
  const paramWeek = useSelectedWeekYear()

  const idsToSetToUrl: { dateId: string; weekId: string | null; cId: string | null } | null = useMemo(() => {
    if (!initialSelectedDate) return null
    if (paramDate.date === initialSelectedDate) return null

    const newDateId = initialSelectedDate.toString()
    const { weekNumber, weekYear } = paramWeek
    let newDisplayMonth = initialDisplayMonth
    let newDisplayYear = initialDisplayYear
    if (
      initialDisplayMonth &&
      initialDisplayYear &&
      !doesMonthYearIncludeWeekNumber(
        initialDisplayMonth,
        initialDisplayYear,
        initialSelectedDate.weekOfYear,
        initialSelectedDate.yearOfWeek
      )
    ) {
      newDisplayMonth = initialSelectedDate.month
      newDisplayYear = initialSelectedDate.year
    }

    const newCId = `${newDisplayYear}-${newDisplayMonth}`
    const weekOverlaps =
      !weekNumber || !weekYear
        ? false
        : doesMonthYearIncludeWeekNumber(initialSelectedDate.month, initialSelectedDate.year, weekNumber, weekYear)
    const newWeekId = weekOverlaps ? null : `${initialSelectedDate.yearOfWeek}-${initialSelectedDate.weekOfYear}`
    return { dateId: newDateId, weekId: newWeekId, cId: newCId }
  }, [initialDisplayMonth, initialDisplayYear, initialSelectedDate, paramDate, paramWeek])

  useEffect(() => {
    if (idsToSetToUrl !== null) {
      setSearchParams(prev => {
        const prevDateId = prev.get('dateId')
        const prevWeekId = prev.get('weekId')
        const prevCId = prev.get('cId')
        if (idsToSetToUrl) {
          const { dateId, weekId, cId } = idsToSetToUrl
          if (cId && prevCId !== cId) prev.set('cId', cId)
          if (dateId && prevDateId !== dateId) prev.set('dateId', dateId)
          if (weekId && prevWeekId !== weekId) prev.set('weekId', weekId)
        }
        return prev
      })
    }
  }, [idsToSetToUrl, setSearchParams])
}

export const areIndicatorsLoadingAtom = atom<boolean>(false)

interface UseInitialIndicatorsProps {
  weekIndicatorsByWeekId?: IndicatorById
  dateIndicatorsByDateId?: IndicatorById
  areIndicatorsLoading?: boolean
}

export const useInitialIndicators = ({
  weekIndicatorsByWeekId,
  dateIndicatorsByDateId,
  areIndicatorsLoading,
}: UseInitialIndicatorsProps) => {
  const setAreIndicatorsLoading = useSetAtom(areIndicatorsLoadingAtom)
  const setWeekIndicators = useSetAtom(weekIndicatorsByWeekIdAtom)
  const setDateIndicators = useSetAtom(dateIndicatorsByDateIdAtom)

  useEffect(() => {
    setAreIndicatorsLoading(areIndicatorsLoading ?? false)
  }, [areIndicatorsLoading, setAreIndicatorsLoading])

  useEffect(() => {
    setWeekIndicators(weekIndicatorsByWeekId ?? ({} as IndicatorById))
    setDateIndicators(dateIndicatorsByDateId ?? ({} as IndicatorById))
  }, [weekIndicatorsByWeekId, dateIndicatorsByDateId, setWeekIndicators, setDateIndicators])
}

function createSelectedDateFromPlainDate(date: Temporal.PlainDate) {
  const newSelectedDate: CalendarDate = {
    id: date.toString(),
    year: date.year,
    monthNumber: date.month,
    dayNumber: date.day,
    dayOfWeek: date.dayOfWeek,
    weekNumber: date.weekOfYear,
    weekYear: date.yearOfWeek,
    date: date,
  }
  return newSelectedDate
}

function createSelectedWeekFromPlainDate(date: Temporal.PlainDate) {
  const newSelectedWeek: CalendarWeek = {
    id: `${date.year}-${date.weekOfYear}`,
    weekNumber: date.weekOfYear,
    weekYear: date.year,
  }
  return newSelectedWeek
}

function doesMonthYearIncludeWeekNumber(month: number, year: number, weekNumber: number, weekYear: number) {
  const firstOfMonth = Temporal.PlainDate.from({ year, month, day: 1 })
  const firstOfNextMonth = firstOfMonth.add({ months: 1 })
  const lastOfMonth = firstOfNextMonth.subtract({ days: 1 })
  const firstWeekNumber = firstOfMonth.weekOfYear
  const lastWeekNumber = lastOfMonth.weekOfYear
  const firstWeekYear = firstOfMonth.yearOfWeek
  const lastWeekYear = lastOfMonth.yearOfWeek
  return weekNumber >= firstWeekNumber && weekNumber <= lastWeekNumber && weekYear >= firstWeekYear && weekYear <= lastWeekYear
}
