import { useSetAtom } from 'jotai'
import { useCallback } from 'react'
import styled from 'styled-components'

import Textfield from '@atlaskit/textfield'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import { editingCellAtom } from '../editorAtoms'
import { CELL_PADDING_HORIZONTAL, CELL_PADDING_VERTICAL, ROW_HEIGHT_OUTER } from '../editorConstants'
import { QueriedEstimateWorkItem, WorkItemEditableAttributeKeys } from '../editorTypes'
import { useCellNavigation } from '../hooks/useCellNavigation'
import { useEditingCellValue } from '../hooks/useEditingCellValue'

interface NumberFieldEditModeProps {
  workItemId: string
  attribute: keyof QueriedEstimateWorkItem
}

const NumberFieldEditMode = ({ workItemId, attribute }: NumberFieldEditModeProps) => {
  console.log('NumberFieldEditMode: ', { workItemId, attribute })
  const setEditingCell = useSetAtom(editingCellAtom)

  const value = useEditingCellValue({ workItemId, attribute })
  const { handleEscape, handleLeaveCellAndCommitEdits } = useCellNavigation()

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        handleEscape(attribute as WorkItemEditableAttributeKeys)
      } else if (event.key === 'Enter') {
        event.preventDefault()
        handleLeaveCellAndCommitEdits({ workItemId, attribute: attribute as WorkItemEditableAttributeKeys, value })
      }
    },
    [handleEscape, handleLeaveCellAndCommitEdits, workItemId, attribute, value]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditingCell(prev => {
        const newValue = event.target.value
        const newEdits = { ...prev.edits, [workItemId]: { ...(prev.edits?.[workItemId] ?? {}), [attribute]: newValue } }
        return { ...prev, edits: newEdits }
      })
    },
    [setEditingCell, workItemId, attribute]
  )

  return (
    <StyledTextfield
      onKeyDown={handleKeyDown}
      type='number'
      value={value}
      onChange={handleChange}
      autoFocus
      onFocus={e => e.target.select()}
      onBlur={handleBlur}
    />
  )
  function handleBlur() {
    console.log('NumberFieldEditMode handleBlur')
    handleLeaveCellAndCommitEdits({ workItemId, attribute: attribute as WorkItemEditableAttributeKeys, value })
  }
}

export default NumberFieldEditMode

const StyledTextfield = styled(Textfield)`
  height: ${ROW_HEIGHT_OUTER - 2}px;
  input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    font-family: ${MONOSPACE_FONT_FAMILY};
    text-align: right;
    padding: ${CELL_PADDING_VERTICAL - 1}px ${CELL_PADDING_HORIZONTAL - 1}px !important;
  }
`
