import { Temporal } from '@js-temporal/polyfill'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import { GetInstallerPayrollQuery } from '@/gql/graphql'

import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'

import { usePaystubColumns } from '../hooks/usePaystubColumns'

type InstallerPayroll = NonNullable<GetInstallerPayrollQuery['installerPayroll']>

const DEBUG = true
const InstallerWeekDispatchTable = ({ installerPayroll }: { installerPayroll?: InstallerPayroll }) => {
  const dispatches = useMemo(() => {
    // If there's no data, return an empty array
    if (!installerPayroll?.installerDispatches) return []

    // Make a new copy and sort it
    return [...installerPayroll.installerDispatches].sort((a, b) => {
      const aDate = a?.vehicleDispatch?.dispatch?.date
        ? Temporal.PlainDate.from(a.vehicleDispatch.dispatch.date)
        : Temporal.Now.plainDateISO()

      const bDate = b?.vehicleDispatch?.dispatch?.date
        ? Temporal.PlainDate.from(b.vehicleDispatch.dispatch.date)
        : Temporal.Now.plainDateISO()

      return Temporal.PlainDate.compare(aDate, bDate)
    })
  }, [installerPayroll?.installerDispatches])
  const hasUnapprovedDispatches = dispatches.some(d => !d.isApproved)

  const columns = usePaystubColumns(hasUnapprovedDispatches, DEBUG)

  const table = useReactTable({
    data: dispatches,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const installerWeeklyPayroll = installerPayroll
  const rows = table.getRowModel().rows

  return (
    <TableWrapper>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} style={{ textAlign: header.column.columnDef.meta?.align }}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {!rows || rows.length === 0 ? (
            <tr>
              <td colSpan={8} className='no-dispatches-found'>
                No dispatches found
              </td>
              <td colSpan={columns.length - 8} className='no-dispatches-found'></td>
            </tr>
          ) : (
            rows.map(row => {
              const isApproved = row.original.isApproved === true
              return (
                <tr key={row.id} className={isApproved ? '' : 'installer-paystub-row-unapproved'}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} style={cell.column.columnDef.meta?.style}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              )
            })
          )}
        </tbody>

        <tfoot>
          <tr>
            <td>Subtotal</td>

            <td colSpan={DEBUG ? 5 : 2} />

            <td>Dispatch Hours:</td>

            <td>
              <HrMinCell value={installerWeeklyPayroll?.totalDispatchesMinutesTotal ?? 0} />
            </td>

            {DEBUG ? (
              <>
                <td>
                  <NumberCell value={installerWeeklyPayroll?.totalDispatchesPayoutAddons ?? 0} readOnly prefix='$' />
                </td>

                <td>
                  <NumberCell value={installerWeeklyPayroll?.totalDispatchesPayoutHourly ?? 0} readOnly prefix='$' />
                </td>

                <td>
                  <NumberCell value={installerWeeklyPayroll?.totalDispatchesPayoutDavisBacon ?? 0} readOnly prefix='$' />
                </td>

                <td>
                  <NumberCell value={installerWeeklyPayroll?.totalDispatchesPayoutPieceRate ?? 0} readOnly prefix='$' />
                </td>

                <td>
                  <NumberCell value={installerWeeklyPayroll?.totalDispatchesPayoutDavisBaconPieceRateGap ?? 0} readOnly prefix='$' />
                </td>

                <td>
                  <NumberCell value={installerWeeklyPayroll?.totalDispatchesPayoutDrive ?? 0} readOnly prefix='$' />
                </td>
              </>
            ) : null}
            <td>
              <NumberCell value={installerWeeklyPayroll?.totalDispatchesPayoutTotal ?? 0} readOnly prefix='$' />
            </td>

            {hasUnapprovedDispatches || DEBUG ? <td /> : null}
          </tr>

          <tr>
            <td />

            <td colSpan={DEBUG ? 5 : 2} />

            <td>Warehouse Hours:</td>

            <td>
              <HrMinCell value={installerWeeklyPayroll?.totalDayMinutesNonDispatch ?? 0} />
            </td>

            {DEBUG ? (
              <>
                <td colSpan={5} />

                <td>Payout Non Dispatch</td>
              </>
            ) : null}
            <td>
              <NumberCell value={installerWeeklyPayroll?.totalDayPayoutNonDispatch ?? 0} readOnly prefix='$' />
            </td>

            {hasUnapprovedDispatches || DEBUG ? <td /> : null}
          </tr>

          {!DEBUG ? null : (
            <>
              <tr>
                <td />

                <td colSpan={5} />

                <td>totalDayMinutesPayable:</td>

                <td>
                  <HrMinCell value={installerWeeklyPayroll?.totalDayMinutesPayable ?? 0} />
                </td>

                <td colSpan={5} />

                <td>Subtotal Raw</td>

                <td>
                  <NumberCell value={installerWeeklyPayroll?.payoutSubtotalRaw ?? 0} readOnly prefix='$' />
                </td>

                <td />
              </tr>
              <tr>
                <td />

                <td colSpan={5} />

                <td>totalDispatchesMinutesDrive:</td>

                <td>
                  <HrMinCell value={installerWeeklyPayroll?.totalDispatchesMinutesDrive ?? 0} />
                </td>

                <td colSpan={5} />

                <td>Min Wage Correct</td>

                <td>
                  <NumberCell value={installerWeeklyPayroll?.payoutMinimumWageCorrection ?? 0} readOnly prefix='$' />
                </td>

                <td />
              </tr>
              <tr>
                <td />

                <td colSpan={5} />

                <td>minutesTotal:</td>

                <td>
                  <HrMinCell value={installerWeeklyPayroll?.minutesTotal ?? 0} />
                </td>

                <td colSpan={5} />

                <td>Subtotal Before OT</td>

                <td>
                  <NumberCell value={installerWeeklyPayroll?.payoutSubtotalBeforeOvertime ?? 0} readOnly prefix='$' />
                </td>

                <td />
              </tr>
            </>
          )}
          <tr>
            <td />

            <td colSpan={DEBUG ? 5 : 2} />

            <td>Overtime:</td>

            <td>
              <HrMinCell value={installerWeeklyPayroll?.minutesOvertime ?? 0} />
            </td>

            {DEBUG ? (
              <>
                <td colSpan={5} />

                <td>Payout Overtime</td>
              </>
            ) : null}
            <td>
              <NumberCell value={installerWeeklyPayroll?.payoutOvertimeUplift ?? 0} readOnly prefix='$' />
            </td>

            {hasUnapprovedDispatches || DEBUG ? <td /> : null}
          </tr>

          <tr>
            <td>Total</td>

            <td colSpan={DEBUG ? 7 : 4} />
            {DEBUG ? (
              <>
                <td colSpan={5} />

                <td>Payout Total</td>
              </>
            ) : null}
            <td>
              <NumberCell value={installerWeeklyPayroll?.payoutActual ?? 0} readOnly prefix='$' />
            </td>

            {hasUnapprovedDispatches || DEBUG ? <td /> : null}
          </tr>
        </tfoot>
      </table>
    </TableWrapper>
  )
}

export default InstallerWeekDispatchTable

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
  border: 1px solid ${token('color.border')};
  flex: 0 0 auto;
  border-radius: 4.5px;

  scrollbar-gutter: stable;

  /* Optional: Style scrollbars on WebKit browsers for a slimmer look */
  &::-webkit-scrollbar {
    width: 8px;
    background-color: ${token('elevation.surface.sunken')};
    height: 8px;

    &:horizontal {
      border-top: 1px solid ${token('color.border')};
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      border-radius: 4px;
    }

    border: 1px solid ${token('color.border')};
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: ${token('color.border')};
    }
  }
  &::-webkit-scrollbar-thumb {
    transition: background-color 0.3s ease;
    background-color: ${token('color.border')};
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${token('color.border')};
    &:hover {
      background-color: ${token('color.icon.disabled')};
    }
  }

  table {
    min-width: 100%;
    border-collapse: separate; // Needed for sticky positioning
    border-spacing: 0;

    box-sizing: border-box;

    th,
    td {
      padding: 6px 8px;
      border: 0;
      text-align: left;

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        background: ${token('elevation.surface.raised')};
        box-shadow: 2px 0 2px -1px rgba(0, 0, 0, 0.1);
      }
    }

    tfoot {
      tr:first-child > td {
        border-top: 2px solid ${token('color.border')};
      }
      td {
        &:not(:first-child) {
          padding: 0;
          text-align: right;
        }
        font-weight: 600;
        color: ${token('color.text.subtlest')};
      }
    }

    .installer-paystub-row-unapproved > td {
      color: ${token('color.text.danger')};
    }

    th {
      position: sticky;
      top: 0;
      z-index: 2;
      background: ${token('color.background.accent.gray.subtlest')};
      font-weight: 600;
      color: ${token('color.text.subtlest')};
      border-bottom: 2px solid ${token('color.border')};
      vertical-align: bottom;
      &:first-child {
        z-index: 3;
        background: ${token('color.background.accent.gray.subtlest')};
      }
    }

    td {
      color: ${token('color.text')};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .no-dispatches-found {
      text-align: left;
      color: ${token('color.text.disabled')};
      font-size: 18px;
      font-weight: 600;
      padding: 24px 24px;
      background: ${token('elevation.surface.sunken')} !important;
    }

    tr:nth-child(even) {
      background-color: ${token('color.background.neutral')};
      td:first-child {
        background: ${token('color.background.accent.gray.subtlest')};
      }
    }

    tr:hover {
      background-color: ${token('color.background.selected')};
    }

    tbody > tr > td:first-child {
      font-family: ${MONOSPACE_FONT_FAMILY};
    }
  }
`
