import { LoadScript } from '@react-google-maps/api'
import { ReactNode } from 'react'

interface GoogleMapsProviderProps {
  children: ReactNode
}

const GoogleMapsProvider = ({ children }: GoogleMapsProviderProps) => {
  return (
    <LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API} libraries={['places']}>
      {children}
    </LoadScript>
  )
}

export default GoogleMapsProvider
