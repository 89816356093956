import { token } from '@atlaskit/tokens'

import EditPayrollCell from '../components/EditPayrollCell'
import StatusCell from '../components/StatusCell'
import { FIELD_KEYS_ENUM, ReconTableRowDef } from '../reconPayrollTypes'

type RowOrderGroup = {
  id: string
  label: string
  rows: (keyof typeof PAYROLL_ROW_DEFS)[]
}
const ROW_ORDER: RowOrderGroup[] = [
  {
    id: 'status',
    label: 'Status',
    rows: ['isCompleted'],
  },
  {
    id: 'installer',
    label: 'Installer',
    rows: ['isHourlyInstaller', 'isDriver', 'defaultHourlyRate', 'hourlyRateNonDispatch'],
  },
  {
    id: 'dispatches',
    label: 'Dispatches',
    rows: ['countDaysWorked', 'countDispatchesAll', 'countDispatchesCompleted', 'countDispatchesApproved'],
  },
  {
    id: 'davisBaconDispatches',
    label: 'Davis Bacon Dispatches',
    rows: [
      'countDispatchesDavisBacon',
      'davisBaconDispatchesMinutesDrive',
      'davisBaconDispatchesMinutesWorked',
      'davisBaconDispatchesMinutesTotal',
      'davisBaconDispatchesPayoutDrive',
      'davisBaconDispatchesPayoutDavisBacon',
      'davisBaconDispatchesPayoutPieceRateGap',
      'davisBaconDispatchesPayoutTotal',
    ],
  },
  {
    id: 'hourlyDispatches',
    label: 'Hourly Dispatches',
    rows: [
      'countDispatchesHourly',
      'hourlyDispatchesMinutesDrive',
      'hourlyDispatchesMinutesWorked',
      'hourlyDispatchesMinutesTotal',
      'hourlyDispatchesPayoutDrive',
      'hourlyDispatchesPayoutHourly',
      'hourlyDispatchesPayoutAddons',
      'hourlyDispatchesPayoutTotal',
    ],
  },
  {
    id: 'pieceRateDispatches',
    label: 'Piece Rate Dispatches',
    rows: [
      'countDispatchesPieceRate',
      'pieceRateDispatchesMinutesDrive',
      'pieceRateDispatchesMinutesWorked',
      'pieceRateDispatchesMinutesTotal',
      'pieceRateDispatchesPayoutDrive',
      'pieceRateDispatchesPayoutPieceRate',
      'pieceRateDispatchesPayoutAddons',
      'pieceRateDispatchesPayoutTotal',
    ],
  },
  {
    id: 'hoursSubtotals',
    label: 'Hours Subtotals',
    rows: [
      'totalDispatchesMinutesDrive',
      'totalDispatchesMinutesWorked',
      'totalDispatchesMinutesTotal',
      'totalDayMinutesNonDispatch',
      'totalDayMinutesLunch',
      'totalDayMinutesPayable',
      'minutesAdjustmentManual',
      'minutesTotal',
      'minutesOvertime',
    ],
  },
  {
    id: 'dispatchPayout',
    label: 'Dispatch Payout',
    rows: [
      'totalDispatchesPayoutDrive',
      'totalDispatchesPayoutDavisBacon',
      'totalDispatchesPayoutDavisBaconPieceRateGap',
      'totalDispatchesPayoutHourly',
      'totalDispatchesPayoutPieceRate',
      'totalDispatchesPayoutAddons',
    ],
  },
  {
    id: 'subtotals',
    label: 'Subtotals',
    rows: [
      'totalDispatchesPayoutTotal',
      'totalDayPayoutNonDispatchTotals',
      'payoutSubtotalRaw', // totalDayPayoutNonDispatch + totalDispatchesPayoutTotal
    ],
  },
  {
    id: 'payoutCalculations',
    label: 'Payout Calculations',
    rows: [
      'payoutMinimumWageCorrection', // 0 if hourlyRateRaw >= hourlyRateMinimum, else (hourlyRateMinimum* (minutesTotal / 60)) - payoutSubtotalRaw
      'payoutSubtotalBeforeOvertime', // payoutSubtotalRaw + payoutMinimumWageCorrection
      'hourlyRateBase', // payoutSubtotalBeforeOvertime / (minutesTotal / 60)
      'payoutOvertimeUplift', // hourlyRateBase * 0.5 * (minutesOvertime / 60)
      'payoutTotalBeforeAdjustment', // payoutSubtotalBeforeOvertime + payoutOvertimeUplift
    ],
  },
  {
    id: 'payout',
    label: 'Payout',
    rows: [
      'payoutAdjustment', // manually entered
      'payoutActual', // payoutTotalBeforeAdjustment + payoutAdjustment
    ],
  },
]

const SIMPLE_ROW_ORDER: RowOrderGroup[] = [
  {
    id: 'status',
    label: 'Status',
    rows: ['isCompleted'],
  },
  {
    id: 'installer',
    label: 'Dispatches',
    rows: ['countDispatchesAll'],
  },
  {
    id: 'hoursSubtotals',
    label: 'Hours Subtotals',
    rows: [
      'totalDispatchesMinutesTotal',
      'totalDayMinutesNonDispatch',
      'totalDayMinutesLunch',
      'totalDayMinutesPayable',
      'minutesAdjustmentManual',
      'minutesTotal',
      'minutesOvertime',
    ],
  },
  {
    id: 'subtotals',
    label: 'Subtotals',
    rows: [
      'totalDispatchesPayoutTotal',
      // 'hourlyRateNonDispatch',
      'totalDayPayoutNonDispatchTotals',
      'payoutSubtotalRaw', // totalDayPayoutNonDispatch + totalDispatchesPayoutTotal
    ],
  },
  {
    id: 'payoutCalculations',
    label: 'Payout Calculations',
    rows: [
      'payoutMinimumWageCorrection', // 0 if hourlyRateRaw >= hourlyRateMinimum, else (hourlyRateMinimum* (minutesTotal / 60)) - payoutSubtotalRaw
      'payoutSubtotalBeforeOvertime', // payoutSubtotalRaw + payoutMinimumWageCorrection
      'hourlyRateBase', // payoutSubtotalBeforeOvertime / (minutesTotal / 60)
      'payoutOvertimeUplift', // hourlyRateBase * 0.5 * (minutesOvertime / 60)
      'payoutTotalBeforeAdjustment', // payoutSubtotalBeforeOvertime + payoutOvertimeUplift
    ],
  },
  {
    id: 'payout',
    label: 'Payout',
    rows: [
      'payoutAdjustment', // manually entered
      'payoutActual', // payoutTotalBeforeAdjustment + payoutAdjustment
    ],
  },
]
export const PAYROLL_ROW_DEFS: Record<string, ReconTableRowDef> = {
  isCompleted: {
    fieldTitle: 'Status',
    id: FIELD_KEYS_ENUM.isCompleted,
    installerPayrollTooltip: 'Mark Timecard as Completed, which locks it from being edited.',
    installerPayrollAccessorFn: installerWeeklyPayroll => installerWeeklyPayroll?.isCompleted,
    installerPayrollCellFn: (_installerId, _value, row) => <StatusCell row={row} />,
  },
  isHourlyInstaller: {
    fieldTitle: 'Hourly or Piece Rate',
    id: FIELD_KEYS_ENUM.isHourlyInstaller,
    installerPayrollTooltip: "Installer indicated as hourly or piece rate on Timecard. If not set, it's pulled from Installer record.",
    lookupTooltip: 'Installer indicated as hourly or piece rate on Installer record.',
    installerPayrollAccessorFn: ({ isHourlyInstaller }) => (isHourlyInstaller === true ? 'Hourly' : 'Piece Rate'),
    installerPayrollCellFn: (_, value) => <span style={{ padding: '4px 8px' }}>{value}</span>,
  },
  isDriver: {
    fieldTitle: 'Is Driver',
    id: 'isDriver',
    installerPayrollTooltip: 'Installer indicated as a driver on installer record.',
    installerPayrollAccessorFn: ({ installer }) =>
      (installer?.isDriver ?? null) === null ? '-' : installer?.isDriver === true ? 'Yes' : 'No',
    installerPayrollCellFn: (_, value) => (
      <span
        style={{
          padding: '4px 8px',
          ...(value === 'No' ? { color: token('color.text.danger'), fontWeight: '600' } : { color: token('color.text.subtlest') }),
        }}
      >
        {value}
      </span>
    ),
  },
  defaultHourlyRate: {
    fieldTitle: 'Default Hourly Rate',
    id: FIELD_KEYS_ENUM.defaultHourlyRate,
    installerPayrollTooltip: "Installer's default hourly rate, pulled from installer record at time of last sync.",
    fieldType: 'hourlyRate',
  },
  hourlyRateNonDispatch: {
    fieldTitle: 'Non-Dispatch Pay Rate',
    id: FIELD_KEYS_ENUM.hourlyRateNonDispatch,
    highlightRow: true,
    installerPayrollTooltip: 'Pay rate for non-dispatch assignments.',
    installerPayrollCellFn: (installerId, value, installerWeeklyPayroll) => (
      <EditPayrollCell
        fieldKey='hourlyRateNonDispatch'
        installerId={installerId}
        databaseValue={value ? `${value}` : null}
        isDisabled={installerWeeklyPayroll?.isCompleted === true || installerWeeklyPayroll?.isApproved === true}
      />
    ),
  },
  countDaysWorked: {
    fieldTitle: '# Days Dispatched',
    id: FIELD_KEYS_ENUM.countDaysWorked,
    installerPayrollTooltip: 'Number of days an Installer had at least one dispatch assignment.',
    fieldType: 'int',
  },
  countDispatchesAll: {
    fieldTitle: '# Dispatches',
    id: FIELD_KEYS_ENUM.countDispatchesAll,
    installerPayrollTooltip: 'Number of dispatches an Installer was assigned.',
    fieldType: 'int',
  },
  countDispatchesCompleted: {
    fieldTitle: '# Recon Completed',
    id: FIELD_KEYS_ENUM.countDispatchesCompleted,
    installerPayrollTooltip: 'Number of dispatches an Installer was assigned where recon is marked as Completed',
    fieldType: 'int',
  },
  countDispatchesApproved: {
    fieldTitle: '# Recon Approved',
    id: FIELD_KEYS_ENUM.countDispatchesApproved,
    installerPayrollTooltip: 'Number of dispatches an Installer was assigned where recon is marked as Approved',
    fieldType: 'int',
  },
  // DAVIS BACON DISPATCHES
  countDispatchesDavisBacon: {
    fieldTitle: '# Dispatches',
    id: FIELD_KEYS_ENUM.countDispatchesDavisBacon,
    installerPayrollTooltip: 'Number of dispatches an Installer was assigned where the related Estimate was marked as Davis Bacon.',
    fieldType: 'int',
  },
  davisBaconDispatchesMinutesDrive: {
    fieldTitle: 'Hours Driving',
    id: FIELD_KEYS_ENUM.davisBaconDispatchesMinutesDrive,
    installerPayrollTooltip: 'Total hours an Installer was driving, on all dispatches marked as Davis Bacon.',
    fieldType: 'hrMin',
  },
  davisBaconDispatchesMinutesWorked: {
    fieldTitle: 'Hours On Site',
    id: FIELD_KEYS_ENUM.davisBaconDispatchesMinutesWorked,
    installerPayrollTooltip: 'Total hours an Installer was on site, on all dispatches marked as Davis Bacon.',
    fieldType: 'hrMin',
  },
  davisBaconDispatchesMinutesTotal: {
    fieldTitle: 'Hours Total',
    id: FIELD_KEYS_ENUM.davisBaconDispatchesMinutesTotal,
    installerPayrollTooltip: 'Total hours an Installer was driving and on site, on all dispatches marked as Davis Bacon.',
    fieldType: 'hrMin',
  },
  totalDispatchesPayoutTotal: {
    fieldTitle: 'Payout Dispatches Total',
    id: FIELD_KEYS_ENUM.totalDispatchesPayoutTotal,
    installerPayrollTooltip: 'Total payout for all dispatches.',
    // fieldType: 'hrMin',
  },
  davisBaconDispatchesPayoutDrive: {
    fieldTitle: 'Payout Drive',
    id: FIELD_KEYS_ENUM.davisBaconDispatchesPayoutDrive,
    installerPayrollTooltip: 'Total payout for driving, on all dispatches marked as Davis Bacon.',
  },
  davisBaconDispatchesPayoutDavisBacon: {
    fieldTitle: 'Payout Work',
    id: FIELD_KEYS_ENUM.davisBaconDispatchesPayoutDavisBacon,
    installerPayrollTooltip: 'Total payout for hours worked, on all dispatches marked as Davis Bacon.',
  },
  davisBaconDispatchesPayoutPieceRateGap: {
    fieldTitle: 'Payout Piece-Rate Gap',
    id: FIELD_KEYS_ENUM.davisBaconDispatchesPayoutPieceRateGap,
    installerPayrollTooltip: 'Total payout for piece-rate gap, on all dispatches marked as Davis Bacon.',
  },
  davisBaconDispatchesPayoutTotal: {
    fieldTitle: 'Payout SubTotal D. Bacon',
    id: FIELD_KEYS_ENUM.davisBaconDispatchesPayoutTotal,
    installerPayrollTooltip: 'Total payout for all dispatches marked as Davis Bacon.',
  },

  // HOURLY DISPATCHES
  countDispatchesHourly: {
    fieldTitle: '# Dispatches',
    id: FIELD_KEYS_ENUM.countDispatchesHourly,
    installerPayrollTooltip: 'Number of dispatches an Installer was assigned where the related Estimate was marked as Hourly.',
    fieldType: 'int',
  },
  hourlyDispatchesMinutesDrive: {
    fieldTitle: 'Hours Driving',
    id: FIELD_KEYS_ENUM.hourlyDispatchesMinutesDrive,
    installerPayrollTooltip: 'Total hours an Installer was driving, on all dispatch assignments marked as Hourly.',
    fieldType: 'hrMin',
  },
  hourlyDispatchesMinutesWorked: {
    fieldTitle: 'Hours On Site',
    id: FIELD_KEYS_ENUM.hourlyDispatchesMinutesWorked,
    installerPayrollTooltip: 'Total hours an Installer was on site, on all dispatch assignments marked as Hourly.',
    fieldType: 'hrMin',
  },
  hourlyDispatchesMinutesTotal: {
    fieldTitle: 'Hours Total',
    id: FIELD_KEYS_ENUM.hourlyDispatchesMinutesTotal,
    installerPayrollTooltip: 'Total hours an Installer was driving and on site, on all dispatch assignments marked as Hourly.',
    fieldType: 'hrMin',
  },
  hourlyDispatchesPayoutDrive: {
    fieldTitle: 'Payout Drive',
    id: FIELD_KEYS_ENUM.hourlyDispatchesPayoutDrive,
    installerPayrollTooltip: 'Total payout for driving, on all dispatch assignments marked as Hourly.',
  },
  hourlyDispatchesPayoutHourly: {
    fieldTitle: 'Payout Work',
    id: FIELD_KEYS_ENUM.hourlyDispatchesPayoutHourly,
    installerPayrollTooltip: 'Total payout for hours worked, on all dispatch assignments marked as Hourly.',
  },
  hourlyDispatchesPayoutAddons: {
    fieldTitle: 'Payout Addons',
    id: FIELD_KEYS_ENUM.hourlyDispatchesPayoutAddons,
    installerPayrollTooltip: 'Total payout for addons, on all dispatch assignments marked as Hourly.',
  },
  hourlyDispatchesPayoutTotal: {
    fieldTitle: 'Payout SubTotal Hourly',
    id: FIELD_KEYS_ENUM.hourlyDispatchesPayoutTotal,
    installerPayrollTooltip: 'Total payout for all dispatch assignments marked as Hourly.',
  },
  // PIECE RATE DISPATCHES
  countDispatchesPieceRate: {
    fieldTitle: '# Dispatches',
    id: FIELD_KEYS_ENUM.countDispatchesPieceRate,
    installerPayrollTooltip: 'Number of dispatches assignments for this Installer where the payout type is marked as Piece Rate.',
    fieldType: 'int',
  },
  pieceRateDispatchesMinutesDrive: {
    fieldTitle: 'Hours Driving',
    id: FIELD_KEYS_ENUM.pieceRateDispatchesMinutesDrive,
    installerPayrollTooltip: 'Total hours an Installer was driving, on all dispatch assignments marked as Piece Rate.',
    fieldType: 'hrMin',
  },
  pieceRateDispatchesMinutesWorked: {
    fieldTitle: 'Hours On Site',
    id: FIELD_KEYS_ENUM.pieceRateDispatchesMinutesWorked,
    installerPayrollTooltip: 'Total hours an Installer was on site, on all dispatch assignments marked as Piece Rate.',
    fieldType: 'hrMin',
  },
  pieceRateDispatchesMinutesTotal: {
    fieldTitle: 'Hours Total',
    id: FIELD_KEYS_ENUM.pieceRateDispatchesMinutesTotal,
    installerPayrollTooltip: 'Total hours an Installer was driving and on site, on all dispatch assignments marked as Piece Rate.',
    fieldType: 'hrMin',
  },
  pieceRateDispatchesPayoutDrive: {
    fieldTitle: 'Payout Drive',
    id: FIELD_KEYS_ENUM.pieceRateDispatchesPayoutDrive,
    installerPayrollTooltip: 'Total payout for driving, on all dispatch assignments marked as Piece Rate.',
  },
  pieceRateDispatchesPayoutPieceRate: {
    fieldTitle: 'Payout Work',
    id: FIELD_KEYS_ENUM.pieceRateDispatchesPayoutPieceRate,
    installerPayrollTooltip: 'Total payout for piece rate work done, on all dispatch assignments marked as Piece Rate.',
  },
  pieceRateDispatchesPayoutAddons: {
    fieldTitle: 'Payout Addons',
    id: FIELD_KEYS_ENUM.pieceRateDispatchesPayoutAddons,
    installerPayrollTooltip: 'Total payout for addons, on all dispatch assignments marked as Piece Rate.',
  },
  pieceRateDispatchesPayoutTotal: {
    fieldTitle: 'Payout SubTotal Piece Rate',
    id: FIELD_KEYS_ENUM.pieceRateDispatchesPayoutTotal,
    installerPayrollTooltip: 'Total payout for all dispatch assignments marked as Piece Rate.',
  },

  // HOURS CALCULATIONS
  totalDispatchesMinutesDrive: {
    fieldTitle: 'Dispatch Hrs Driving',
    id: FIELD_KEYS_ENUM.totalDispatchesMinutesDrive,
    installerPayrollTooltip: 'Total hours an Installer was driving, on all dispatches.',
    fieldType: 'hrMin',
  },
  totalDispatchesMinutesWorked: {
    fieldTitle: 'Dispatch Hrs On Site',
    id: FIELD_KEYS_ENUM.totalDispatchesMinutesWorked,
    installerPayrollTooltip: 'Total hours an Installer was on site, on all dispatches.',
    fieldType: 'hrMin',
  },
  totalDayMinutesNonDispatch: {
    fieldTitle: 'Hours Non-Dispatch',
    id: FIELD_KEYS_ENUM.totalDayMinutesNonDispatch,
    fieldKey: FIELD_KEYS_ENUM.totalDayMinutesNonDispatch,
    installerPayrollTooltip: 'Total hours an Installer worked on non-dispatch assignments.',
    fieldType: 'hrMin',
  },
  totalDayMinutesPayable: {
    fieldTitle: 'Hours Subtotal',
    id: FIELD_KEYS_ENUM.totalDayMinutesPayable,
    installerPayrollTooltip: 'Total Hours before any adjustments.',
    fieldType: 'hrMin',
  },
  totalDayMinutesLunch: {
    fieldTitle: 'Lunch Deduction',
    id: FIELD_KEYS_ENUM.totalDayMinutesLunch,
    installerPayrollTooltip: 'Adjustment to the hours worked to account for lunch time.',
    fieldType: 'hrMin',
  },
  minutesAdjustmentManual: {
    fieldTitle: 'Manual Adjustment',
    highlightRow: true,
    id: FIELD_KEYS_ENUM.minutesAdjustmentManual,
    installerPayrollTooltip: 'Manual adjustment to the hours worked.',
    installerPayrollAccessorFn: row => row.installerWeeklyPayroll?.minutesAdjustmentManual,
    installerPayrollCellFn: (installerId, value, row) => (
      <EditPayrollCell
        fieldKey='minutesAdjustmentManual'
        installerId={installerId}
        databaseValue={value ? `${value}` : null}
        isDisabled={row?.installerWeeklyPayroll?.isCompleted === true || row?.installerWeeklyPayroll?.isApproved === true}
      />
    ),
  },
  minutesTotal: {
    fieldTitle: 'Hours Total',
    id: FIELD_KEYS_ENUM.minutesTotal,
    installerPayrollTooltip: 'Total hours an Installer worked this week.',
    fieldType: 'hrMin',
  },
  minutesOvertime: {
    fieldTitle: 'Hours Overtime',
    id: FIELD_KEYS_ENUM.minutesOvertime,
    installerPayrollTooltip: 'Total hours beyond 40 that an Installer worked this week.',
    fieldType: 'hrMin',
  },
  // PAYOUT CALCULATIONS
  totalDispatchesPayoutDrive: {
    fieldTitle: 'Payout Drive',
    id: FIELD_KEYS_ENUM.totalDispatchesPayoutDrive,
    installerPayrollTooltip: 'Total payout for time spent driving on all dispatches.',
  },
  totalDispatchesPayoutDavisBacon: {
    fieldTitle: 'Payout Davis Bacon',
    id: FIELD_KEYS_ENUM.totalDispatchesPayoutDavisBacon,
    installerPayrollTooltip: 'Total payout for hours worked on all dispatches with the related estimate marked as Davis Bacon.',
  },
  totalDispatchesPayoutDavisBaconPieceRateGap: {
    fieldTitle: 'Payout Davis Bacon PR Gap',
    id: FIELD_KEYS_ENUM.totalDispatchesPayoutDavisBaconPieceRateGap,
    installerPayrollTooltip:
      'Total payout for the gap between piece rate and the Davis Bacon payout for hours worked on all dispatches with the related estimate marked as Davis Bacon.',
  },
  totalDispatchesPayoutHourly: {
    fieldTitle: 'Payout Hourly',
    id: FIELD_KEYS_ENUM.totalDispatchesPayoutHourly,
    installerPayrollTooltip: 'Total payout for hours worked on hourly assignments (does not include Davis Bacon or Non-Dispatch hours).',
  },
  totalDispatchesPayoutPieceRate: {
    fieldTitle: 'Payout Piece Rate',
    id: FIELD_KEYS_ENUM.totalDispatchesPayoutPieceRate,
    installerPayrollTooltip: 'Total payout for piece rate work, not including addons.',
  },
  totalDispatchesPayoutAddons: {
    fieldTitle: 'Payout Addons',
    id: FIELD_KEYS_ENUM.totalDispatchesPayoutAddons,
    installerPayrollTooltip: 'Total payout for addons, on all dispatches.',
  },
  totalDispatchesMinutesTotal: {
    fieldTitle: 'Dispatches Hours Total',
    id: FIELD_KEYS_ENUM.totalDispatchesMinutesTotal,
    fieldType: 'hrMin',
    installerPayrollTooltip: 'Total hours an Installer worked on all dispatches.',
  },
  totalDayPayoutNonDispatchTotals: {
    fieldTitle: 'Payout Non-Dispatch',
    id: FIELD_KEYS_ENUM.totalDayPayoutNonDispatch,
    installerPayrollTooltip: 'Total payout for non-dispatch assignments.',
  },
  payoutSubtotalRaw: {
    fieldTitle: 'Subtotal Raw',
    id: FIELD_KEYS_ENUM.payoutSubtotalRaw,
    installerPayrollTooltip: 'Total payout for all dispatches.',
  },
  payoutMinimumWageCorrection: {
    fieldTitle: 'Minimum Wage Correction',
    id: FIELD_KEYS_ENUM.payoutMinimumWageCorrection,
    installerPayrollTooltip: 'Amount added to payout to ensure the installer is paid at least the minimum wage.',
  },
  payoutSubtotalBeforeOvertime: {
    fieldTitle: 'Payout Subtotal Before OT',
    id: FIELD_KEYS_ENUM.payoutSubtotalBeforeOvertime,
    installerPayrollTooltip: 'Total payout before overtime and adjustments.',
  },
  hourlyRateBase: {
    fieldTitle: 'Calculated Hourly Rate',
    id: FIELD_KEYS_ENUM.hourlyRateBase,
    installerPayrollTooltip: 'Calculated hourly rate based on the total payout and hours worked.',
    fieldType: 'hourlyRate',
  },
  payoutOvertimeUplift: {
    fieldTitle: 'Overtime Uplift',
    id: FIELD_KEYS_ENUM.payoutOvertimeUplift,
    installerPayrollTooltip: 'Amount added to payout for overtime hours.',
  },
  payoutTotalBeforeAdjustment: {
    fieldTitle: 'Payout Subtotal Before Adj.',
    id: FIELD_KEYS_ENUM.payoutTotalBeforeAdjustment,
    installerPayrollTooltip: 'Total payout with overtime, before adjustments.',
  },
  payoutAdjustment: {
    fieldTitle: 'Manual Payout Adjustment',
    id: FIELD_KEYS_ENUM.payoutAdjustment,
    highlightRow: true,
    installerPayrollTooltip:
      'Manual entry to adjust the "Paycheck Amount" to differ from the "Total Payout", positive or negative, for any reason.',
    installerPayrollAccessorFn: row => row.installerWeeklyPayroll?.payoutAdjustment,
    installerPayrollCellFn: (installerId, value, row) => (
      <EditPayrollCell
        fieldKey='payoutAdjustment'
        installerId={installerId}
        databaseValue={value ? `${value}` : null}
        isDisabled={row?.installerWeeklyPayroll?.isCompleted === true || row?.installerWeeklyPayroll?.isApproved === true}
      />
    ),
  },
  payoutActual: {
    fieldTitle: 'Paycheck Amount',
    id: FIELD_KEYS_ENUM.payoutActual,
    installerPayrollTooltip:
      'Calculated as "Total Payout" + "Entered Adjustment". This should be the actual paycheck amount the installer receives.',
  },
}

export const PAYROLL_ROWS: ReconTableRowDef[] = [] as ReconTableRowDef[]

ROW_ORDER.forEach(group => {
  group.rows.forEach(key => {
    const rowDef = PAYROLL_ROW_DEFS[key]
    if (rowDef) {
      rowDef.topHeader = group.label
      PAYROLL_ROWS.push(rowDef)
    } else {
      console.error(`Row definition not found: ${key}`)
    }
  })
})

export const SIMPLE_ROWS: ReconTableRowDef[] = [] as ReconTableRowDef[]

SIMPLE_ROW_ORDER.forEach(simpleGroup => {
  simpleGroup.rows.forEach(rowKey => {
    const rowDefinition = PAYROLL_ROW_DEFS[rowKey]
    if (rowDefinition) {
      rowDefinition.topHeader = simpleGroup.label
      SIMPLE_ROWS.push(rowDefinition)
    } else {
      console.error(`Row definition not found: ${rowKey}`)
    }
  })
})
