import { useAtomValue } from 'jotai'
import { useEffect } from 'react'

import { pendingMutationsAtom } from '../../dispatchTypes'

export const useWatchAtoms = () => {
  const pendingMutation = useAtomValue(pendingMutationsAtom)
  useEffect(() => {
    if (pendingMutation.action) console.log('pendingMutation', pendingMutation)
  }, [pendingMutation])
}
