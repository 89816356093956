import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import Styled from 'styled-components'

import { LeftSidebar } from '@atlaskit/page-layout'
import { NavigationFooter, NavigationHeader, NestableNavigationContent, SideNavigation } from '@atlaskit/side-navigation'
import { token } from '@atlaskit/tokens'

import { VERSION_NUMBER } from '@/utils/constants'

import { DAY_WIDTH, WEEK_NUM_WIDTH } from '@/components/MiniCalendarNav/atoms'

import MainLinks from './navigation/MainLinks'
import { ReconCalendar } from './ReconCalendar'
import { useReconSearchParams } from './useReconSearchParams'

const SIDEBAR_WIDTH = WEEK_NUM_WIDTH + 7 * DAY_WIDTH + 16

const ReconAppLeftNavigation = () => {
  const location = useLocation()
  const path = location.pathname
  const navigate = useNavigate()
  const { paramValues } = useReconSearchParams()

  const stack = useMemo(() => {
    if (path.startsWith('/recon/payroll')) return ['/recon/payroll']
    if (path.startsWith('/recon/daily')) return ['/recon/daily']
    if (path.startsWith('/recon/installer-paystubs')) return ['/recon/payroll']
    return []
  }, [path])

  return (
    <LeftSidebar shouldPersistWidth id='space-navigation' skipLinkTitle='Space Navigation' isFixed={true} width={SIDEBAR_WIDTH}>
      <SideNavigation label='SideNav'>
        <NavigationHeader>
          <ReconCalendarWrapper>
            <ReconCalendar />
          </ReconCalendarWrapper>
        </NavigationHeader>

        <NestableNavigationContent
          stack={stack}
          onChange={stack => {
            console.log('$$$$ stack: ', stack)
            if (stack.length === 0) {
              const newParams = new URLSearchParams(paramValues)
              newParams.delete('vehicleDispatchId')
              newParams.delete('vehicleDateId')
              newParams.delete('installerId')
              navigate('/recon/?' + newParams.toString())
            }
          }}
        >
          <MainLinks />
        </NestableNavigationContent>

        <NavigationFooter>
          <VersionNumber>
            <div>v{VERSION_NUMBER}</div>
          </VersionNumber>
        </NavigationFooter>
      </SideNavigation>
    </LeftSidebar>
  )
}

export default ReconAppLeftNavigation

const ReconCalendarWrapper = Styled.div`
  flex: 0 0 auto;
  padding: 0 0px;
`

const VersionNumber = Styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  justify-content: center;
  color: ${token('color.text.disabled')};
`
