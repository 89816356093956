import { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import { useSelectedInstaller } from '@/utils/queryHooks/useSelectedInstaller'

import HrMinCell from '@/components/HrMinCell'
import { useSelectedWeekYear } from '@/components/MiniCalendarNav/useSelectedWeekYear'
import NumberCell from '@/components/NumberCell'

import { SingleInstallerDate, SingleInstallerWeeklyPayroll } from '../hooks/usePaystubQuery'

import InstallerWeekDateRow from './InstallerWeekDatesTableRow'

type PayrollKey = keyof SingleInstallerWeeklyPayroll
const SUBTOTAL_PAYOUT_FIELDS: PayrollKey[] = ['payoutMinimumWageCorrection', 'payoutOvertimeUplift', 'payoutAdjustment']
const SUBTOTAL_HOURS_FIELDS: PayrollKey[] = ['minutesAdjustmentManual', 'minutesOvertime']

const InstallerWeekDates = ({ installerPayroll, isLoading }: { installerPayroll?: SingleInstallerWeeklyPayroll; isLoading: boolean }) => {
  const { mondayDate } = useSelectedWeekYear()
  const staff = useSelectedInstaller()
  const installer = installerPayroll?.installer ?? staff
  const installerWeeklyPayroll = installerPayroll
  const weekOf = mondayDate ? mondayDate.toString() : installerWeeklyPayroll?.weekOf

  const installerDates = installerWeeklyPayroll?.installerDates

  const weekDates: string[] = useMemo(() => {
    const weekArray: string[] = []
    for (let i = 0; i < 7; i++) {
      const dateString = mondayDate?.add({ days: i })?.toString()
      if (!dateString) console.warn('Date string not found for: ', mondayDate?.add({ days: i }))
      else weekArray.push(dateString)
    }
    return weekArray
  }, [mondayDate])

  const weekInstallerDates: Record<string, SingleInstallerDate> = useMemo(() => {
    const weekArray: Record<string, SingleInstallerDate> = {}
    installerDates?.forEach(installerDate => {
      weekArray[installerDate.date] = installerDate
    })
    return weekArray
  }, [installerDates])

  const subTotalPayoutRows = useMemo(() => {
    const row: Record<string, string>[] = []
    if (installerWeeklyPayroll)
      SUBTOTAL_PAYOUT_FIELDS.forEach(field => {
        const rawValue = installerWeeklyPayroll[field as keyof SingleInstallerWeeklyPayroll]
        const value = Number(rawValue ?? 0)
        if (value === 0) return
        row.push({
          field,
          value: rawValue,
        })
      })
    return row
  }, [installerWeeklyPayroll])

  const subTotalHoursRows = useMemo(() => {
    const row: Record<string, string>[] = []
    if (installerWeeklyPayroll)
      SUBTOTAL_HOURS_FIELDS.forEach(field => {
        const rawValue = installerWeeklyPayroll[field as keyof SingleInstallerWeeklyPayroll]
        const value = Number(rawValue ?? 0)
        if (value === 0) return
        row.push({
          field,
          value: rawValue,
        })
      })
    return row
  }, [installerWeeklyPayroll])

  const subTotalRowsToShow = Math.max(subTotalPayoutRows.length, subTotalHoursRows.length)
  const isDisabled = !installerWeeklyPayroll || installerWeeklyPayroll.isCompleted === true || installerWeeklyPayroll.isApproved === true
  console.log('installerWeeklyPayroll', installerWeeklyPayroll)
  return (
    <div>
      <h3>{installer?.fullName} Totals by Day</h3>
      <StyledTable $isLoading={isLoading}>
        <thead>
          <tr>
            <th></th>
            <th colSpan={5}>Hours</th>
            <th colSpan={4}>Payout</th>
          </tr>
          <tr>
            <th>Date</th>
            <th className='minutes-cell'>Dispatch Total</th>
            <th className='minutes-cell'>Non-Dispatch</th>
            <th className='minutes-cell'>Actual</th>
            <th className='minutes-cell'>Lunch</th>
            <th className='minutes-cell'>Payable</th>
            <th className='payout-cell'>Dispatches</th>
            <th className='payout-cell'>Non Dispatch</th>
            <th className='payout-cell'>Total</th>
            <th className='payout-cell'>Net $/hr</th>
          </tr>
        </thead>
        <tbody>
          {weekDates.map(date => (
            <InstallerWeekDateRow
              key={date}
              weekOf={weekOf}
              date={date}
              installerDate={weekInstallerDates?.[date]}
              isDisabled={isDisabled}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>

            <td className='minutes-cell'>
              <HrMinCell value={installerWeeklyPayroll?.totalDispatchesMinutesTotal ?? 0} />
            </td>
            <td className='minutes-cell'>
              <HrMinCell value={installerWeeklyPayroll?.totalDayMinutesNonDispatch ?? 0} />
            </td>
            <td className='minutes-cell'>
              <HrMinCell value={installerWeeklyPayroll?.totalDayMinutesActual ?? 0} />
            </td>
            <td className='minutes-cell'>
              <HrMinCell value={installerWeeklyPayroll?.totalDayMinutesLunch ?? 0} />
            </td>
            <td className='minutes-cell'>
              <HrMinCell value={installerWeeklyPayroll?.totalDayMinutesPayable ?? 0} />
            </td>
            <td className='payout-cell'>
              <NumberCell value={installerWeeklyPayroll?.totalDispatchesPayoutTotal ?? 0} readOnly prefix='$' />
            </td>
            <td className='payout-cell'>
              <NumberCell value={installerWeeklyPayroll?.totalDayPayoutNonDispatch ?? 0} readOnly prefix='$' />
            </td>
            <td className='payout-cell'>
              <NumberCell value={installerWeeklyPayroll?.payoutSubtotalRaw ?? 0} readOnly prefix='$' />
            </td>
            <td className='payout-cell'>
              <NumberCell value={installerWeeklyPayroll?.hourlyRateBase ?? 0} readOnly prefix='$' suffix='/ hr' />
            </td>
          </tr>
          {Array.from({ length: subTotalRowsToShow }).map((_, index) => {
            const payoutRow = subTotalPayoutRows?.[index] ?? null
            const hoursRow = subTotalHoursRows?.[index] ?? null
            const payoutValue = Number(payoutRow?.value ?? 0)
            const payoutField: PayrollKey = payoutRow?.field as PayrollKey
            const hoursValue = Number(hoursRow?.value ?? 0)
            const hoursField: PayrollKey = hoursRow?.field as PayrollKey
            if (payoutValue === 0 && hoursValue === 0) return null
            return (
              <tr key={`${payoutField}-${hoursField}`}>
                <td />

                <td colSpan={4} style={{ padding: '0 8px' }}>
                  {!hoursRow ? null : (FIELD_TITLES?.[hoursField] ?? hoursField)}
                </td>

                <td className='minutes-cell'>{!hoursRow ? null : <HrMinCell value={hoursValue} />}</td>

                <td className='payout-cell' colSpan={2} style={{ padding: '0 8px' }}>
                  {!payoutRow ? null : (FIELD_TITLES?.[payoutField] ?? payoutField)}
                </td>

                <td className='payout-cell'>{!payoutRow ? null : <NumberCell value={payoutValue} readOnly prefix='$' />}</td>
              </tr>
            )
          })}
        </tfoot>
      </StyledTable>
    </div>
  )
}

const FIELD_TITLES: Partial<Record<PayrollKey, string>> = {
  payoutMinimumWageCorrection: 'Wage Correction',
  payoutOvertimeUplift: 'Overtime Uplift',
  payoutAdjustment: 'Manual Adjustment',
  minutesAdjustmentManual: 'Manual Adjustment',
  minutesOvertime: 'Overtime Hours',
}

export default InstallerWeekDates

const COL_WIDTH_MIN = 90
const COL_WIDTH_PAYOUT = 108

const StyledTable = styled.table<{ $isLoading: boolean }>`
  width: fit-content;
  border-collapse: collapse;
  border: 1px solid ${token('color.border')};
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  td,
  th {
    border-left: 1px solid ${token('color.border')};

    &.minutes-cell {
      width: ${COL_WIDTH_MIN}px;
      min-width: ${COL_WIDTH_MIN}px;
      max-width: ${COL_WIDTH_MIN}px;
    }
    &.payout-cell {
      width: ${COL_WIDTH_PAYOUT}px;
      min-width: ${COL_WIDTH_PAYOUT}px;
      max-width: ${COL_WIDTH_PAYOUT}px;
    }

    &.editable-cell {
      background: ${token('color.background.selected')};
      > div:hover {
        background: ${token('color.background.selected.hovered')};
      }
    }
  }
  td.minutes-cell + td.payout-cell,
  th.minutes-cell + th.payout-cell {
    border-left: 2px solid ${token('color.border.bold')};
  }

  > thead {
    > tr {
      background: ${token('color.background.neutral')};
      &:not(:last-child) {
        color: ${token('color.text.subtlest')};
      }
    }
  }
  > thead > tr:first-child > th {
    text-align: center;

    &:nth-child(3) {
      border-left: 2px solid ${token('color.border.bold')};
    }
  }
  > thead > tr:last-child > th {
    vertical-align: bottom;
    &:not(:first-child) {
      text-align: right;
    }
    &:first-child {
      text-align: left;
      padding: 0 8px;
    }
  }
  > tbody {
    opacity: ${props => (props.$isLoading ? 0.5 : 1)};
    transition: opacity 0.2s ease-in-out;

    > tr {
      &:nth-child(even) {
        background: ${token('color.background.neutral')};
      }
    }
  }
  > tbody > tr > td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;

    &:not(:first-child) {
      text-align: right;
    }
    &:first-child {
      text-align: left;
      padding: 0 8px;
      font-family: ${MONOSPACE_FONT_FAMILY};
    }
  }
  > tfoot > tr > td {
    font-weight: 600;
    &:not(:first-child) {
      text-align: right;
      padding: 0;
    }
    &:first-child {
      text-align: left;
      padding: 0 8px;
    }
  }
`
