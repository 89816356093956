import { Temporal } from '@js-temporal/polyfill'

import { useReconSearchParams } from '../../useReconSearchParams'

export const useWeekYearInstallerParams = () => {
  const { paramValues } = useReconSearchParams()
  const installerId = paramValues.get('installerId')
  const today = Temporal.Now.plainDateISO()
  const weekId = paramValues.get('weekId')
  const todayWeekNumber = today.weekOfYear
  const todayYear = today.year
  let todayWeekYear = todayYear
  const todayMonthNumber = today.month
  if (todayMonthNumber === 1 && todayWeekNumber > 50) todayWeekYear -= 1

  const year = Number(weekId?.split('-')?.[0] ?? todayWeekYear)
  const weekNumber = Number(weekId?.split('-')?.[1] ?? todayWeekNumber)

  return { weekNumber, year, installerId }
}
