import { useQuery } from '@tanstack/react-query'

import Button from '@atlaskit/button/new'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'

interface PrintDayLoadSheetsFormProps {
  dateString: string | null
  closeModal: () => void
  vehicleId?: string | null
}

export default function PrintDayLoadSheetsForm({ dateString, vehicleId, closeModal }: PrintDayLoadSheetsFormProps) {
  const graphqlClient = useGraphQLClient()
  const { isPending } = useQuery({
    queryKey: [GET_LOAD_SHEET_PDF_QUERY_KEY, dateString, vehicleId],
    queryFn: async () => {
      if (!dateString) throw new Error('No date string provided')
      const result = await graphqlClient.request(GET_LOAD_SHEET_PDF, { dateString, vehicleId })
      const downloadUrl = result?.getLoadSheetPdf
      if (!downloadUrl) throw new Error('No download link found')

      console.log('downloadUrl: ', downloadUrl)

      // Create a temporary anchor element
      const link = document.createElement('a')
      link.href = downloadUrl
      link.target = '_blank' // Open in a new tab/window
      link.download = `load_sheets_${dateString}.pdf` // Suggest a filename

      // Programmatically click the link to trigger the download
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      closeModal()
      return downloadUrl
    },
  })

  return (
    <>
      <ModalHeader>
        <ModalTitle>Generating Work Orders for {dateString ?? '(no date)'}</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <div style={{ paddingBottom: 100 }}>{isPending ? 'Generating Work Orders for date: ' + dateString : null}</div>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={closeModal} isDisabled={isPending} isLoading={isPending}>
          Close
        </Button>
      </ModalFooter>
    </>
  )
}

const GET_LOAD_SHEET_PDF_QUERY_KEY = 'GetLoadSheetPdf'
const GET_LOAD_SHEET_PDF = graphql(/* GraphQL */ `
  query GetLoadSheetPdf($dateString: String!, $vehicleId: String) {
    getLoadSheetPdf(dateString: $dateString, vehicleId: $vehicleId)
  }
`)
