import styled from 'styled-components'

import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal'

import Button, { IconButton, SplitButton } from '@atlaskit/button/new'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import { token } from '@atlaskit/tokens'

import { useCurrentUser } from '@/utils/useCurrentUser'

import { useSubmitInstallerPayroll } from '../hooks/useSubmitInstallerPayroll'
import { InstallerWeeklyPayrollWithDateKeys } from '../reconPayrollTypes'

type StatusColorsOptions = 'None' | 'Approved' | 'Completed' | 'Ready' | 'Recon'
type TokenArg = Parameters<typeof token>[0]

const StatusCell = ({ row }: { row?: InstallerWeeklyPayrollWithDateKeys }) => {
  const { canApprovePayout } = useCurrentUser()
  const { isApproved, isCompleted, isHidden, payoutActual, countDispatchesAll, countDispatchesApproved } = row ?? {}

  const isStillBlank = (countDispatchesAll ?? 0) === 0 && (!payoutActual || Number(payoutActual) === 0) && !isCompleted
  const percentApproved = !countDispatchesAll
    ? 'None'
    : 'Recon: ' + (((countDispatchesApproved ?? 0) / (countDispatchesAll ?? 1)) * 100).toFixed(0) + '%'

  const hasDispatches = countDispatchesAll && countDispatchesAll > 0
  let status: StatusColorsOptions = 'None'
  if (isApproved) status = 'Approved'
  else if (isCompleted) status = 'Completed'
  else if (hasDispatches) {
    if (countDispatchesAll === countDispatchesApproved) status = 'Ready'
    else if (countDispatchesAll > (countDispatchesApproved ?? 0)) status = 'Recon'
  } else if (payoutActual && Number(payoutActual) > 0) status = 'Ready'

  const showSetNotCompleted = ['Completed', 'Approved'].includes(status) && (!isApproved || canApprovePayout)
  const showApprove = ['Ready', 'Completed'].includes(status) && canApprovePayout
  const statusColors = STATUS_COLORS[status]
  const weekOf = row?.weekOf ?? ''
  const installerId = row?.installerId ?? ''

  const { mutate, isPending } = useSubmitInstallerPayroll()

  let noOptionsAvailable = false
  if ((status === 'Approved' && !canApprovePayout) || status === 'Recon') noOptionsAvailable = true

  return (
    <StatusButtonWrapper id='status-button-wrapper' $statusColors={statusColors}>
      <SplitButton spacing='compact' isDisabled={noOptionsAvailable || isPending}>
        <Button shouldFitContainer={true} isDisabled={noOptionsAvailable || isPending} isLoading={isPending}>
          {isHidden ? 'Hidden' : status === 'Recon' ? (percentApproved ?? 'None') : status}
          {status === 'Ready' && (countDispatchesAll ?? 0) === 0 ? ' *' : ''}
        </Button>

        <DropdownMenu<HTMLButtonElement>
          trigger={({ triggerRef, ...triggerProps }) => (
            <IconButton
              spacing='compact'
              ref={triggerRef}
              {...triggerProps}
              icon={ShowMoreHorizontalIcon}
              label='More link issue options'
            />
          )}
          placement='bottom-end'
        >
          <DropdownItemGroup>
            {status !== 'Ready' ? null : (
              <DropdownItem onClick={() => handleStatusChange({ attribute: 'isCompleted', value: 'true' })}>Complete</DropdownItem>
            )}
            {!showApprove ? null : (
              <DropdownItem onClick={() => handleStatusChange({ attribute: 'isApproved', value: 'true' })}>Approve</DropdownItem>
            )}
            {/* Reverse */}
            {!showSetNotCompleted ? null : (
              <DropdownItem onClick={() => handleStatusChange({ attribute: 'isCompleted', value: 'false' })}>
                Set Not Completed
              </DropdownItem>
            )}
            {status !== 'Approved' || !canApprovePayout ? null : (
              <DropdownItem onClick={() => handleStatusChange({ attribute: 'isApproved', value: 'false' })}>Reverse Approval</DropdownItem>
            )}
            {!isStillBlank ? null : (
              <DropdownItem onClick={() => handleStatusChange({ attribute: 'isHidden', value: 'true' })}>Remove Timecard</DropdownItem>
            )}
          </DropdownItemGroup>
        </DropdownMenu>
      </SplitButton>
    </StatusButtonWrapper>
  )

  function handleStatusChange({ attribute, value }: { attribute: string; value: string }) {
    mutate({ weekOf, installerId, attribute, value })
  }
}

export default StatusCell

type StatusColors = {
  background: TokenArg | null
  text: TokenArg | null
  hovered: TokenArg | null
  pressed: TokenArg | null
}

const STATUS_COLORS: Record<StatusColorsOptions, StatusColors | null> = {
  None: {
    background: 'color.background.accent.gray.subtler',
    text: 'color.text.disabled',
    hovered: 'color.background.accent.gray.subtler.hovered',
    pressed: 'color.background.accent.gray.subtler.pressed',
  },
  Recon: {
    background: 'color.background.warning',
    text: 'color.text.warning',
    hovered: 'color.background.warning.hovered',
    pressed: 'color.background.warning.pressed',
  },
  Ready: {
    background: 'color.background.information.bold',
    text: 'color.text.inverse',
    hovered: 'color.background.information.bold.hovered',
    pressed: 'color.background.information.bold.pressed',
  },
  Completed: {
    background: 'color.background.discovery.bold',
    text: 'color.text.inverse',
    hovered: 'color.background.discovery.bold.hovered',
    pressed: 'color.background.discovery.bold.pressed',
  },
  Approved: {
    background: 'color.background.success.bold',
    text: 'color.text.inverse',
    hovered: 'color.background.success.bold.hovered',
    pressed: 'color.background.success.bold.pressed',
  },
}

const StatusButtonWrapper = styled.div<{ $statusColors: StatusColors | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0px;
  padding: 0;

  * {
    box-sizing: border-box;
  }

  > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    /* border: 0.5px solid ${token('elevation.surface.raised.pressed')}; */

    > div {
      height: 100%;

      &:nth-child(1) {
        flex: 1 1 calc(100% - 25px);
        max-width: calc(100% - 25px);
        z-index: 1;
        > button {
          padding: 0 4px;
          height: 100%;
          border-radius: 0;
          color: ${({ $statusColors }) => (!$statusColors?.text ? 'inherit' : token($statusColors.text))};
          background-color: ${({ $statusColors }) => (!$statusColors?.background ? 'inherit' : token($statusColors.background))};
          align-items: center;
          cursor: default;
          * {
            text-overflow: clip;
          }
        }
      }

      &:nth-child(2) {
        z-index: 2;
        flex: 0 0 none;
        opacity: 0.6;
        background-color: ${({ $statusColors }) => (!$statusColors?.background ? 'inherit' : token($statusColors.background))};
      }

      &:nth-child(3) {
        flex: 0 0 none;
        height: 100%;
        > button {
          height: 100%;
          border-radius: 0;
          color: ${({ $statusColors }) => (!$statusColors?.text ? 'inherit' : token($statusColors.text))};
          background-color: ${({ $statusColors }) => (!$statusColors?.background ? 'inherit' : token($statusColors.background))};
          &:hover {
            background-color: ${({ $statusColors }) => (!$statusColors?.hovered ? 'inherit' : token($statusColors.hovered))};
          }
          &:active {
            background-color: ${({ $statusColors }) => (!$statusColors?.pressed ? 'inherit' : token($statusColors.pressed))};
          }

          > span {
            opacity: 0.5;
            transform-origin: center;
            transform: scale(0.8);
          }
        }
      }
    }
  }
`
