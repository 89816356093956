import { graphql } from '@/gql'

const SUBMIT_JOB_MUTATION = graphql(/* GraphQL */ `
  mutation SubmitJob(
    $title: String!
    $status: String!
    $customerId: UUID!
    $jobId: UUID
    $projectSiteName: String
    $projectSiteId: UUID
    $projectSiteCoordinates: CoordinatesInput
    $placesData: [PlaceInput!]
    $notesForPrint: String
  ) {
    submitJob(
      title: $title
      status: $status
      customerId: $customerId
      jobId: $jobId
      projectSiteName: $projectSiteName
      projectSiteId: $projectSiteId
      projectSiteCoordinates: $projectSiteCoordinates
      placesData: $placesData
      notesForPrint: $notesForPrint
    ) {
      success
      message
      job {
        id
        sid
        title
        status
        createdAt
        modifiedAt
        projectSite {
          id
          sid
          name
          pinDrop {
            id
            latitude
            longitude
            createdAt
          }
        }
        createdBy {
          id
          sid
          fullName
        }
      }
    }
  }
`)

export default SUBMIT_JOB_MUTATION
