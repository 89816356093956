import { toast } from 'sonner'

import AbsoluteTooltip from '@/components/AbsoluteTooltip'
import NumberCell from '@/components/NumberCell'
import TableCellNumberTextField from '@/components/TableCellNumberTextField'

import { INSTALLER_CELL_PAD } from '../constants'

import { InstallersTableRowRenderArgs } from './columnRenderDefs'

const CellPayoutPieceRate = ({
  shouldLockPieceRate,
  dispatchEnteredPieceRate,
  setInstallerAmounts,
  mutate,
  handleReturn,
  handleFocus,
  installerDispatch,
}: InstallersTableRowRenderArgs) => {
  const {
    calculatedPayoutPieceRate,
    isHourlyPayout,
    isDavisBacon,
    isPercentPieceRateLocked,
    isCompleted,
    isApproved,
    id: installerDispatchId,
    isPayrollLocked,
  } = installerDispatch

  let strikethrough = false
  let reason = ''
  if (isHourlyPayout === true || isDavisBacon === true) {
    strikethrough = true
    reason = 'Hourly mode does not receive piece rate payout. '
  }

  let isDisabled = false

  if (isPayrollLocked) {
    isDisabled = true
    reason += 'Payroll has been completed for the week.'
  } else if (isPercentPieceRateLocked) {
    isDisabled = true
    reason += 'This has been locked via the checkbox to the right.'
  } else if (shouldLockPieceRate) {
    isDisabled = true
    reason += 'At least two installers must have their piece rate % unlocked to change one.'
  } else if (isCompleted || isApproved) {
    isDisabled = true
    reason += 'This line has been ' + (isCompleted ? 'completed.' : 'approved.')
  }

  if (isDisabled)
    return (
      <>
        <NumberCell padding={INSTALLER_CELL_PAD} readOnly value={calculatedPayoutPieceRate} prefix='$' strikethrough={strikethrough} />
        {strikethrough && <AbsoluteTooltip content={reason} position='right' />}
      </>
    )

  return (
    <TableCellNumberTextField
      isCompact
      isDisabled={isDisabled}
      value={calculatedPayoutPieceRate ?? undefined}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setInstallerAmounts({ key: 'calculatedPayoutPieceRate', value: e.target.value })
      }
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        const targetValue = e.target.value
        let numberValue: number = targetValue === '' ? Number(0) : Number(targetValue)
        if (numberValue > Number(dispatchEnteredPieceRate)) {
          toast.warning(`Value exceeds maximum of ${dispatchEnteredPieceRate}. Setting to that max value.`)
          numberValue = Number(dispatchEnteredPieceRate)
        }
        const value = numberValue.toFixed(2)
        setInstallerAmounts({ key: 'calculatedPayoutPieceRate', value })
        mutate({ installerDispatchId, attribute: 'calculatedPayoutPieceRate', value })
      }}
      onKeyDown={handleReturn}
      onFocus={handleFocus}
      maxValue={Number(dispatchEnteredPieceRate)}
      prefix='$'
    />
  )
}

export default CellPayoutPieceRate
