/// <reference types="@types/google.maps" />

import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api'
import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import Textfield from '@atlaskit/textfield'
import { getTokenValue } from '@atlaskit/tokens'

import { useIsDarkMode } from '@/utils/useIsDarkMode'

const CEDAR_RAPIDS = {
  lat: 41.9779,
  lng: -91.6656,
}

const CONTINENTAL_US_BOUNDS = {
  north: 49.384358, // Northern border with Canada
  south: 24.396308, // Southern tip of Florida
  east: -66.93457, // Eastern tip of Maine
  west: -124.848974, // Western tip of Washington state
}

// Zoom levels for different types of locations
const ZOOM_LEVELS = {
  address: 19, // House level
  establishment: 18, // Building level
  default: 15, // Neighborhood level
}

interface MapPinModalProps {
  onSelect: (coordinates: { lat: number; lng: number }) => void
  onClose: () => void
  initialLocation?: { lat: number; lng: number }
}

const MapPinModal = ({ onSelect, onClose, initialLocation }: MapPinModalProps) => {
  const [selectedLocation, setSelectedLocation] = useState<google.maps.LatLng | null>(
    initialLocation ? new google.maps.LatLng(initialLocation.lat, initialLocation.lng) : null
  )
  const [mapCenter, setMapCenter] = useState(initialLocation || CEDAR_RAPIDS)
  const [mapZoom, setMapZoom] = useState(initialLocation ? 15 : 12)
  const mapRef = useRef<google.maps.Map | null>(null)
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)
  const { isDarkMode } = useIsDarkMode()

  // Dark mode styles for the map
  const backgroundColor = getTokenValue('elevation.surface.sunken', '#242f3e')
  const labelText = getTokenValue('color.text.accent.orange', '#d59563')
  console.log('backgroundColor', backgroundColor)
  const darkModeStyles = [
    { elementType: 'geometry', stylers: [{ color: backgroundColor }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: labelText }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: labelText }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: getTokenValue('color.background.accent.green.subtlest', '#263c3f') }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: getTokenValue('color.text.accent.green', '#6b9a76') }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#38414e' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#212a37' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9ca5b3' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#746855' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#1f2835' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#f3d19c' }],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#2f3948' }],
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#17263c' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#515c6d' }],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#17263c' }],
    },
  ]

  const handleMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      setSelectedLocation(event.latLng)
      console.log('Pin Drop Data:', {
        latLng: event.latLng,
        url: event.latLng.toUrlValue(15),
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
        domEvent: event.domEvent,
      })
    }
  }, [])

  const handleConfirm = useCallback(() => {
    if (selectedLocation) {
      onSelect({
        lat: selectedLocation.lat(),
        lng: selectedLocation.lng(),
      })
    }
  }, [onSelect, selectedLocation])

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current?.getPlace()
    console.log('Raw Place Data:', place) // Log the raw place data first

    if (place?.geometry) {
      const { location, viewport } = place.geometry

      if (location) {
        setMapCenter({ lat: location.lat(), lng: location.lng() })
        setSelectedLocation(location)

        // Log the processed place data
        console.log('Processed Place Selection Data:', {
          place,
          geometry: place.geometry,
          location: {
            lat: location.lat(),
            lng: location.lng(),
          },
          viewport,
          types: place.types,
          addressComponents: place.address_components,
          formattedAddress: place.formatted_address,
          name: place.name,
          placeId: place.place_id,
          rating: place.rating,
          userRatingsTotal: place.user_ratings_total,
          photos: place.photos,
          openingHours: place.opening_hours,
          website: place.website,
          priceLevel: place.price_level,
          vicinity: place.vicinity,
        })

        if (mapRef.current) {
          // If the place has a viewport (like cities, neighborhoods), use it
          if (viewport) {
            mapRef.current.fitBounds(viewport)
          } else {
            // For specific locations, use appropriate zoom level based on type
            const zoomLevel = place.types?.some(type => type === 'street_address' || type === 'premise')
              ? ZOOM_LEVELS.address
              : place.types?.some(type => type === 'establishment')
                ? ZOOM_LEVELS.establishment
                : ZOOM_LEVELS.default

            mapRef.current.panTo(location)
            mapRef.current.setZoom(zoomLevel)
            setMapZoom(zoomLevel)
          }
        }
      }
    } else {
      console.log('No geometry found for place:', place)
    }
  }

  return (
    <Modal width='x-large' onClose={onClose}>
      <ModalHeader>
        <ModalTitle>Drop a Pin on the Map</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <SearchBarContainer>
          <Autocomplete
            onLoad={autocomplete => {
              console.log('Autocomplete loaded')
              autocompleteRef.current = autocomplete
              autocomplete.setComponentRestrictions({ country: 'us' })
              autocomplete.setBounds(CONTINENTAL_US_BOUNDS)
            }}
            onPlaceChanged={() => {
              console.log('Place changed event fired')
              handlePlaceSelect()
            }}
          >
            <Textfield
              placeholder='Search for a location'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => console.log('Input changed:', e.currentTarget.value)}
            />
          </Autocomplete>
        </SearchBarContainer>

        <MapContainer>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '600px' }}
            center={mapCenter}
            zoom={mapZoom}
            onClick={handleMapClick}
            onLoad={map => {
              mapRef.current = map
            }}
            options={{
              restriction: {
                latLngBounds: CONTINENTAL_US_BOUNDS,
                strictBounds: true,
              },
              styles: isDarkMode ? darkModeStyles : undefined,
            }}
          >
            {selectedLocation && <Marker position={selectedLocation} />}
          </GoogleMap>
        </MapContainer>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={onClose}>
          Cancel
        </Button>

        <Button appearance='primary' onClick={handleConfirm} isDisabled={!selectedLocation}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const MapContainer = styled.div`
  width: 100%;
  height: 600px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 16px;
`

const SearchBarContainer = styled.div`
  width: 100%;
`

export default MapPinModal
