import { Temporal } from '@js-temporal/polyfill'
import { QueryStatus, useQuery } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { toast } from 'sonner'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { installerPayrollWithDateKeysByIdAtom } from '../reconPayrollAtoms'
import {
  GET_ALL_INSTALLER_PAYROLL,
  GET_ALL_INSTALLER_PAYROLL_QUERY_KEY,
  InstallerDatesLookupKey,
  InstallerWeeklyPayrollType,
  InstallerWeeklyPayrollWithDateKeys,
  InstallerWeeklyPayrollWithDateKeysById,
} from '../reconPayrollTypes'

export const useReconPayrollQuery = (weekOfDate: Temporal.PlainDate, staffQueryStatus: QueryStatus) => {
  const graphqlClient = useGraphQLClient()
  const setPayrollByInstallerId = useSetAtom(installerPayrollWithDateKeysByIdAtom)

  const weekNumber = weekOfDate.weekOfYear
  const weekYear = weekOfDate.yearOfWeek
  const { isLoading, error } = useQuery({
    enabled: staffQueryStatus === 'success',
    queryKey: [GET_ALL_INSTALLER_PAYROLL_QUERY_KEY, { weekNumber, weekYear }],
    queryFn: async () => {
      const response = await graphqlClient.request(GET_ALL_INSTALLER_PAYROLL, { weekNumber, weekYear })
      if (response.allInstallerPayroll?.length > 0) {
        const installerPayrollWithDateKeysById: InstallerWeeklyPayrollWithDateKeysById = {}
        response.allInstallerPayroll.forEach(row => {
          installerPayrollWithDateKeysById[row.installerId] = parseInstallerWeeklyPayroll(row)
        })
        setPayrollByInstallerId(installerPayrollWithDateKeysById)
      } else {
        toast.warning('No data found for this week')
      }
      return response
    },
  })

  return { isLoading, error }
}

export function parseInstallerWeeklyPayroll(row: InstallerWeeklyPayrollType): InstallerWeeklyPayrollWithDateKeys {
  const { installerDates, ...rest } = row

  const lookups: InstallerWeeklyPayrollWithDateKeys = {
    ...rest,
    '1': null,
    '2': null,
    '3': null,
    '4': null,
    '5': null,
    '6': null,
    '7': null,
  }

  if (installerDates) {
    installerDates.forEach(installerDate => {
      const dayOfWeek = Temporal.PlainDate.from(installerDate.date).dayOfWeek
      const key: InstallerDatesLookupKey = dayOfWeek.toString() as InstallerDatesLookupKey
      lookups[key] = installerDate
    })
  }
  return lookups
}
