import TableColumnAddRightIcon from '@atlaskit/icon/core/table-column-add-right'
import TableColumnDeleteIcon from '@atlaskit/icon/core/table-column-delete'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import styled from 'styled-components'

import { IconButton } from '@atlaskit/button/new'
import { token } from '@atlaskit/tokens'

import { useGraphQLClient } from '@/utils/useGraphQLClient'
import { CardColorOptions } from '@/utils/utilities'

import { graphql } from '@/gql'
import { ToggleDispatchAddonsSeparatedMutationVariables } from '@/gql/graphql'

import { DispatchPayoutType, GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY } from '../useDispatchPayoutQuery'

const AddonsModeToggle = ({ dispatch, color = 'gray' }: { dispatch: DispatchPayoutType; color?: CardColorOptions }) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const dispatchId = dispatch.id
  const workOrderId = dispatch.workOrderId
  const isAddonsSeparated = dispatch.isAddonsSeparated

  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: ToggleDispatchAddonsSeparatedMutationVariables) => {
      if (!dispatch) throw new Error('Dispatch is required')
      const response = await graphQLClient.request(TOGGLE_DISPATCH_ADDONS_SEPARATED, variables)
      console.log('response', response)
      if (!response.toggleDispatchAddonsSeparated?.success)
        throw new Error(response.toggleDispatchAddonsSeparated?.message ?? 'Failed to recalculate payout')
      return response.toggleDispatchAddonsSeparated
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY, workOrderId] })
    },
  })

  return (
    <StyledRecalcButtonWrapper>
      <IconButton
        icon={iconProps =>
          isAddonsSeparated ? (
            <TableColumnDeleteIcon color={token(`color.icon.accent.${color}`)} {...iconProps} />
          ) : (
            <TableColumnAddRightIcon color={token(`color.icon.accent.${color}`)} {...iconProps} />
          )
        }
        label={isAddonsSeparated ? 'Toggle Separating Addons from PR %' : 'Toggle to PR % Only'}
        isTooltipDisabled={false}
        isDisabled={!dispatchId || isPending}
        isLoading={isPending}
        appearance='subtle'
        onClick={() => mutate({ dispatchId })}
      />
    </StyledRecalcButtonWrapper>
  )
}

export default AddonsModeToggle

const TOGGLE_DISPATCH_ADDONS_SEPARATED = graphql(/* GraphQL */ `
  mutation ToggleDispatchAddonsSeparated($dispatchId: ID!) {
    toggleDispatchAddonsSeparated(dispatchId: $dispatchId) {
      success
      message
    }
  }
`)

const StyledRecalcButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  opacity: 0.5;

  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`
