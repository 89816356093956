import { useQuery } from '@tanstack/react-query'

import Button from '@atlaskit/button/new'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import SectionMessage from '@atlaskit/section-message'
import Spinner from '@atlaskit/spinner'

import { useGraphQLClient } from '@/utils/useGraphQLClient'

import { graphql } from '@/gql'

interface PrintPaystubsFormProps {
  weekOf: string | null
  closeModal: () => void
  installerId?: string | null
}

export default function PrintPaystubsForm({ weekOf, closeModal, installerId }: PrintPaystubsFormProps) {
  const graphqlClient = useGraphQLClient()
  const { isPending, error } = useQuery({
    queryKey: [GET_INSTALLER_PAYSTUBS_PDF_QUERY_KEY, weekOf, installerId],
    queryFn: async () => {
      if (!weekOf) throw new Error('No week of provided')
      const result = await graphqlClient.request(GET_INSTALLER_PAYSTUBS_PDF, { weekOf, installerId })
      if (!result?.getInstallerPaystubs?.success)
        throw new Error('Failed to generate paystubs. Error message: ' + result?.getInstallerPaystubs?.message)
      const downloadUrl = result?.getInstallerPaystubs?.pdfUrl
      if (!downloadUrl) throw new Error('No download link found')

      console.log('downloadUrl: ', downloadUrl)

      // Create a temporary anchor element
      const link = document.createElement('a')
      link.href = downloadUrl
      link.target = '_blank' // Open in a new tab/window
      const filename = downloadUrl.split('/').pop()
      link.download = filename ?? 'paystubs.pdf' // Suggest a filename

      // Programmatically click the link to trigger the download
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      closeModal()
      return downloadUrl
    },
  })

  return (
    <>
      <ModalHeader>
        <ModalTitle>
          Generating Paystubs for {weekOf ?? '(no date)'} {!error ? <Spinner size='small' /> : null}
        </ModalTitle>
      </ModalHeader>

      <ModalBody>
        <div style={{ paddingBottom: 100 }}>
          {isPending ? 'Generating Paystubs for week of: ' + weekOf : null}
          {error ? (
            <SectionMessage title='Error generating paystubs' appearance='error'>
              <p>
                The server gave the following error message. Copy the message and your current URL and send it to Evan:
                <br />
                {error.message}
              </p>
            </SectionMessage>
          ) : null}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={closeModal} isDisabled={isPending} isLoading={isPending}>
          Close
        </Button>
      </ModalFooter>
    </>
  )
}

const GET_INSTALLER_PAYSTUBS_PDF_QUERY_KEY = 'GetInstallerPaystubs'
const GET_INSTALLER_PAYSTUBS_PDF = graphql(/* GraphQL */ `
  query GetInstallerPaystubs($weekOf: Date!, $installerId: ID) {
    getInstallerPaystubs(weekOf: $weekOf, installerId: $installerId) {
      success
      message
      pdfUrl
    }
  }
`)
