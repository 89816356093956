import Button from '@atlaskit/button/new'
import { ModalFooter } from '@atlaskit/modal-dialog'

interface FormFooterProps {
  closeModal: () => void
  isPending: boolean
  isDisabled: boolean
}

export default function FormFooter({ closeModal, isPending, isDisabled }: FormFooterProps) {
  return (
    <ModalFooter>
      <Button appearance='subtle' onClick={closeModal}>
        Close
      </Button>

      <Button isLoading={isPending} appearance='primary' type='submit' isDisabled={isDisabled}>
        Create
      </Button>
    </ModalFooter>
  )
}
