import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import AddonsModeToggle from './AddonsModeToggle'
import { INSTALLER_COLUMNS } from './constants'
import { FOOTER_DEFS } from './footerDefs'
import InstallersTableRow from './InstallersTableRow'
import TopHeaderCell from './TopHeaderCell'
import { useInstallersTableData } from './useInstallersTableData'

import type { DispatchPayoutType } from '../useDispatchPayoutQuery'
import type { ColumnOrderItem } from './useInstallerColumnDefs'

interface InstallerTableProps {
  dispatch?: DispatchPayoutType
  isDavisBacon: boolean
}

const InstallerTable = ({ dispatch, isDavisBacon }: InstallerTableProps) => {
  const {
    installerDispatches,
    shouldLockPieceRate,
    shouldLockAddons,
    orderedColumns,
    columnGroups,
    workOrderId,
    tbodyHeight,
    dispatchEnteredPieceRate,
    dispatchEnteredAddons,
  } = useInstallersTableData({ dispatch, isDavisBacon })

  console.log('InstallerTable() dispatches', dispatch?.installerDispatches)

  return (
    <TableWrapper>
      <StyledTable $cols={orderedColumns}>
        <thead>
          <tr>
            {columnGroups.map(group => {
              const groupWidth = group.columns.reduce((acc, colKey) => acc + INSTALLER_COLUMNS[colKey].width, 0)
              const style: React.CSSProperties =
                group.key === 'assignment' ? { textAlign: 'left', paddingLeft: 8, color: token('color.text.subtlest'), fontSize: 15 } : {}

              if (!group.columns.length) return null
              return (
                <th key={group.key} colSpan={group.columns.length} style={{ width: groupWidth, ...style }}>
                  {!group.color ? group.label : <TopHeaderCell group={group} />}
                  {group.key === 'total' && dispatch ? <AddonsModeToggle dispatch={dispatch} color={group.color} /> : null}
                </th>
              )
            })}
          </tr>

          <tr>
            {columnGroups.map((group, groupIndex) =>
              group.columns.map((colKey, indexWithinGroup) => {
                const colDef = INSTALLER_COLUMNS[colKey]
                const isFirstOfGroup = indexWithinGroup === 0
                const isEditable = colDef.editable ?? false
                const style: React.CSSProperties = { borderLeftWidth: undefined }
                if (isFirstOfGroup) {
                  if (groupIndex > 1 && groupIndex < columnGroups.length - 1) style.borderLeftWidth = 3
                }
                if (!isEditable) style.color = token('color.text.disabled')
                let className = colDef.className
                if (colDef.left !== undefined) className += ' sticky-left'
                if (colDef.right !== undefined) className += ' sticky-right'
                // if (group.color) {
                //   style.borderTopColor = token(`color.border.accent.${group.color}`)
                // }
                return (
                  <th key={colKey} className={className} style={style}>
                    {colDef.header}
                  </th>
                )
              })
            )}
          </tr>
        </thead>

        <tbody style={{ minHeight: tbodyHeight }}>
          <>
            {installerDispatches.length > 0 ? (
              installerDispatches.map((installerDispatch, index) => {
                const isLastOfVehicle =
                  index === installerDispatches.length - 1 || installerDispatch.vehicleName !== installerDispatches[index + 1].vehicleName
                const isFirstOfVehicle = index === 0 || installerDispatch.vehicleName !== installerDispatches[index - 1].vehicleName

                return (
                  <InstallersTableRow
                    key={installerDispatch.id}
                    installerDispatchId={installerDispatch.id}
                    shouldLockPieceRate={shouldLockPieceRate}
                    shouldLockAddons={shouldLockAddons}
                    isLastOfVehicle={isLastOfVehicle}
                    isFirstOfVehicle={isFirstOfVehicle}
                    vehicleInstallerCount={installerDispatches.filter(a => a.vehicleName === installerDispatch.vehicleName).length}
                    columnGroups={columnGroups}
                    workOrderId={workOrderId}
                    dispatchEnteredPieceRate={dispatchEnteredPieceRate}
                    dispatchEnteredAddons={dispatchEnteredAddons}
                  />
                )
              })
            ) : (
              <>
                <tr className='no-installers-row'>
                  <td colSpan={3} rowSpan={2} className='sticky-left' />
                  <td colSpan={orderedColumns.length - 6} rowSpan={2} style={{ textAlign: 'center', zIndex: 1 }}>
                    No Installers
                  </td>
                  <td colSpan={3} className='sticky-right' />
                </tr>
                <tr className='no-installers-row'>
                  <td colSpan={3} className='sticky-right' />
                </tr>
              </>
            )}
          </>
        </tbody>

        <tfoot>
          <tr>
            {columnGroups.map((group, groupIndex) =>
              group.columns.map((colKey, ind) => {
                const isFirstInGroup = ind === 0
                const footerDef = FOOTER_DEFS?.[colKey]
                const colDef = INSTALLER_COLUMNS[colKey]
                let className = colDef.className
                if (colDef.left !== undefined) className += ' sticky-left'
                if (colDef.right !== undefined) className += ' sticky-right'
                if (isFirstInGroup && groupIndex !== 0 && groupIndex !== 1 && groupIndex !== columnGroups.length - 1)
                  className += ' thick-left-border'

                if (footerDef)
                  return (
                    <td key={colKey} className={className}>
                      {footerDef(dispatch)}
                    </td>
                  )
                return <td key={colKey} className={'footer-cell-unused ' + className} />
              })
            )}
          </tr>
        </tfoot>
      </StyledTable>
    </TableWrapper>
  )
}

export default InstallerTable

const TableWrapper = styled.div`
  align-self: stretch;
  max-width: 100%;
  overflow-x: auto;
  position: relative;
  min-width: 700px;
  flex: 0 0 auto;
  /* Reserve space for scrollbars so that sticky footer stays flush */
  scrollbar-gutter: stable;

  /* Optional: Style scrollbars on WebKit browsers for a slimmer look */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border: 1px solid ${token('color.border')};
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    background-color: ${token('elevation.surface.sunken')};
    transition: background-color 0.3s ease;
    &:hover {
      background-color: ${token('color.border')};
    }
  }
  &::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${token('color.border')};

    transition: background-color 0.3s ease;
    background-color: ${token('color.border')};
    &:hover {
      background-color: ${token('color.icon.disabled')};
    }
  }
`

interface StyledTableProps {
  $cols: ColumnOrderItem[]
}
const StyledTable = styled.table<StyledTableProps>`
  width: fit-content;
  min-width: 700px;
  * {
    box-sizing: border-box;
  }

  /* ALL CELLS */
  td,
  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    position: relative;
    border-color: ${token('elevation.surface.raised.pressed')};

    &.col-vehicle {
      border-left-width: 0px !important;
    }

    &.thick-left-border {
      border-left-width: 3px !important;
    }
  }

  td {
    height: 32px;
  }
  th {
    border-top-width: 0px;
    border-bottom-width: 0px;
  }

  tr.no-installers-row {
    background: ${token('color.background.accent.gray.subtlest')} !important;
    border: 0px !important;
    td {
      border: 0px !important;
      background: ${token('color.background.accent.gray.subtlest')} !important;
      font-size: 18px;
      font-weight: 700;
      color: ${token('color.text.disabled')};
      text-align: left !important;
      &.sticky-left {
        z-index: 4;
      }
      &.sticky-right {
        z-index: 4;
      }
    }
  }

  /* TOP HEADER ROW */
  thead > tr:first-child {
    background: ${token('color.background.neutral')};
    color: ${token('color.text.disabled')};

    > th {
      text-align: center;
      z-index: 3;

      /* Top left corner cell */
      &:first-child {
        background: ${token('elevation.surface')};
        position: sticky;
        left: 0px;
        z-index: 4;

        border-right-width: 0px;
        border-left-width: 0px;

        &::after {
          content: '';
          position: absolute;
          right: 0px;
          top: 0;
          bottom: 0;
          width: 1.5px;
          background: ${token('elevation.surface.raised.pressed')};
          pointer-events: none;
        }
      }

      /* All gray top header cells */
      &:not(:first-child):not(:last-child) {
        z-index: 2;
        box-shadow: inset 0 1px 0 ${token('elevation.surface.raised.pressed')};

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 1.5px;
          background: ${token('elevation.surface.raised.pressed')};
          pointer-events: none;
          border: 0;
        }
        &::before {
          border: 0;
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 1.5px;
          background: ${token('elevation.surface.raised.pressed')};
          pointer-events: none;
        }
      }

      /* Top right corner cell */
      &:last-child {
        background: ${token('elevation.surface')};
        position: sticky;
        right: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        z-index: 4;
        &::before {
          content: '';
          position: absolute;
          left: 0px;
          top: 0;
          bottom: 0;
          border-left: 1.5px solid ${token('elevation.surface.raised.pressed')};
          pointer-events: none;
        }
      }
    }
  }

  /* BOTTOM HEADER ROW */
  thead > tr:last-child > th {
    background: ${token('elevation.surface.hovered')};
    text-align: right;
    padding-right: 8px;
    padding-left: 8px;
    border-top: 1px solid ${token('elevation.surface.raised.pressed')};
  }

  /* Every Other Row */
  tr:nth-child(even) {
    background-color: ${token('elevation.surface.sunken')};
  }
  tr:nth-child(even) > td:nth-child(-n + 3),
  tr:nth-child(even) > td:nth-child(n + ${({ $cols }) => $cols.length - 4}) {
    background-color: ${token('elevation.surface.sunken')};
  }
  tr:nth-child(odd) > td:nth-child(-n + 3),
  tr:nth-child(odd) > td:nth-child(n + ${({ $cols }) => $cols.length - 4}) {
    background-color: ${token('color.background.input')};
  }

  /* ALL CELLS NOT IN TOP HEADER ROW */
  td,
  thead > tr:last-child > th {
    z-index: 1;
    border-left: 1px solid ${token('elevation.surface.raised.pressed')};

    &:first-child {
      box-shadow: inset 1px 0px 0 ${token('elevation.surface.raised.pressed')};
      border-left-width: 0px;
    }

    &:last-child {
      box-shadow: inset -1px 0px 0 ${token('elevation.surface.raised.pressed')};
    }

    /* Sticky Left Cells */
    &.sticky-left {
      padding-left: 8px;
      padding-right: 8px;
      font-family: ${token('font.family.body')};
      border-color: ${token('elevation.surface.raised.pressed')};
      border-right-width: 0px;
      border-left-width: 0px;
      position: sticky;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: ${token('elevation.surface.raised.pressed')};
        pointer-events: none;
      }

      &.col-payout-mode-toggle::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: ${token('elevation.surface.raised.pressed')};
        pointer-events: none;
      }
    }

    /* Sticky Right Cells */
    &.sticky-right {
      z-index: 4;
      position: sticky;
      border-right-width: 0px;
      border-left-width: 0px;

      &.col-minutes-actual {
        box-shadow: inset 1px 0 0 0 ${token('elevation.surface.raised.pressed')};
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: ${token('elevation.surface.raised.pressed')};
      }
    }

    /* Column Definitions for all but top header row*/
    ${({ $cols }) =>
      $cols.map(col => {
        const { className, width, left, right, align } = col
        const leftText = left === undefined ? undefined : 'z-index: 4;left: ' + left + 'px;'
        const rightText = right === undefined ? undefined : 'z-index: 4;right: ' + right + 'px;'
        const isSticky = right !== undefined || left !== undefined

        return `
      &.${className} {
        /* On Site */
        width: ${width}px;
        min-width: ${width}px;
        max-width: ${width}px;
        text-align: ${align ?? 'right'};
        position: ${isSticky ? 'sticky' : 'relative'};
        ${leftText}
        ${rightText}
      }
      `
      })}
  }

  tbody > tr > td {
    border-bottom: 1px solid ${token('elevation.surface.raised.pressed')};
    padding: 0;
    > label {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    /* Column Definitions for all body cells*/
    ${({ $cols }) =>
      $cols.map(col => {
        const { className, left, right } = col

        const isSticky = right !== undefined || left !== undefined
        const nonStickyStyle = `
          font-family: ${MONOSPACE_FONT_FAMILY};
          padding: 0;
        `
        return `
      &.${className} {
        ${isSticky ? '' : nonStickyStyle}
      }
      `
      })}
  }

  /* NUMERIC CELLS */
  tr > td:not(:nth-child(-n + 3)):not(:nth-child(n + ${({ $cols }) => $cols.length - 2})) {
    font-family: ${MONOSPACE_FONT_FAMILY};
    padding: 0;
    text-align: right;
  }

  /* FOOTER ROW */
  tfoot > tr > td {
    font-weight: 600;
    padding: 0;

    &.col-installer {
      /* Installer */
      color: ${token('color.text.subtlest')};
    }
    div.footer-cell-unused,
    &.footer-cell-unused {
      background: ${token('color.background.neutral')};
    }
  }

  /* Making Inputs fill cells */
  td:not(:nth-child(-n + 3)) > div > div {
    max-width: 120px;
    display: flex;
    justify-content: flex-end;
    border-radius: 0;
    /* border-color: ${token('elevation.surface.raised.pressed')}; */

    &:not(:focus-within) {
      background: transparent;
    }
    > span.table-cell-number-text-field-prefix {
      color: ${token('color.text.disabled')};
    }

    input {
      /* max-width: 120px; */
      font-family: ${MONOSPACE_FONT_FAMILY};
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  /* .row-header {
    font-family: ${token('font.family.body')};
    font-weight: 500;
    padding: 0 8px;
  } */
  .checkbox-wrapper {
    padding: 0 8px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > label {
      transform-origin: center;
      transform: scale(1.33);
    }
  }
`
