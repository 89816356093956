import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { DispatchAssignmentAction as Actions } from '@/gql/graphql'

import { DispatchDropZone, pendingMutationsAtom } from '../../dispatchTypes'
import { useIsDropZoneHovered } from '../hooks/useIsDropZoneHovered'

const zone: DispatchDropZone = 'VEHICLE_DISPATCH_INSTALLERS'

export const useCombinedInstallerIds = (assignedInstallerIds: string[], vehicleDispatchId: string, vehicleId: string) => {
  const { itemPickedUp } = useIsDropZoneHovered({ zone, vehicleDispatchId, vehicleId })
  const selectedPendingAtom = useMemo(
    () =>
      selectAtom(pendingMutationsAtom, pendingMutations => {
        let adding = null
        let removing = null
        if (pendingMutations.installerId) {
          if (pendingMutations.action === Actions.CreateInstallerAssignmentAll)
            adding = vehicleId === pendingMutations.targetVehicleId ? pendingMutations.installerId : null
          if (
            pendingMutations.action &&
            [(Actions.CreateInstallerAssignment, Actions.CopyInstallerAssignment, Actions.MoveInstallerAssignment)].includes(
              pendingMutations.action
            )
          )
            adding = vehicleDispatchId === pendingMutations.targetVehicleDispatchId ? pendingMutations.installerId : null
          if (pendingMutations.action === Actions.RemoveInstallerAssignment)
            removing = vehicleDispatchId === pendingMutations.originVehicleDispatchId ? pendingMutations.installerId : null
        }
        return { addingInstallerId: adding, removingInstallerId: removing }
      }),
    [vehicleDispatchId, vehicleId]
  )

  const { addingInstallerId, removingInstallerId } = useAtomValue(selectedPendingAtom)

  return useMemo(() => {
    const pickedUpInstallerId = itemPickedUp?.installerId
    const idToAdd = addingInstallerId ?? pickedUpInstallerId ?? null
    const alreadyAssigned = idToAdd === null ? false : assignedInstallerIds.includes(idToAdd)
    const allIds = [...assignedInstallerIds]
    if (idToAdd !== null && !alreadyAssigned) allIds.push(idToAdd)
    if (removingInstallerId) allIds.splice(allIds.indexOf(removingInstallerId), 1)
    return { combinedInstallerIds: allIds, pendingInstallerId: idToAdd, alreadyAssigned }
  }, [assignedInstallerIds, itemPickedUp, addingInstallerId, removingInstallerId])
}
