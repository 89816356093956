import React, { useState } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'

import { useProjectSiteData } from './hooks/useProjectSiteData'
import { useProjectSiteSubmit } from './hooks/useProjectSiteSubmit'
import PlacesManager from './PlacesManager'
import ProjectSiteForm from './ProjectSiteForm'
import { FormDataType } from './types'

interface EditProjectSiteModalProps {
  jobId: string
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  queriesToRefetchOnUpdate?: string[]
}

const EditProjectSiteModal: React.FC<EditProjectSiteModalProps> = ({ jobId, isOpen, onClose, children, queriesToRefetchOnUpdate = [] }) => {
  const { data, isLoading } = useProjectSiteData(jobId, isOpen)
  const { submit, isPending } = useProjectSiteSubmit(jobId, queriesToRefetchOnUpdate, onClose)
  const [formData, setFormData] = useState<FormDataType>({
    projectSite: {
      name: '',
      streetAddress: '',
      city: '',
      state: '',
      zipCode: '',
    },
    places: [],
    primaryPlaceId: null,
    deletedPlaceIds: [],
    coordinates: null,
    isPinMode: false,
  })

  // Initialize form data when data is loaded
  React.useEffect(() => {
    if (data?.job?.projectSite) {
      const projectSite = data.job.projectSite
      const primaryPlace = projectSite.primaryPlace

      // Determine if we're in pin mode by checking if there are no places
      const hasPinLocation = primaryPlace && !primaryPlace.googlePlaceId && primaryPlace.latitude && primaryPlace.longitude
      const isPinMode = hasPinLocation && (!projectSite.places || projectSite.places.length <= 1)

      setFormData({
        projectSite: {
          name: projectSite.name || '',
          streetAddress: projectSite.streetAddress || '',
          city: projectSite.city || '',
          state: projectSite.state || '',
          zipCode: projectSite.zipCode || '',
        },
        places: isPinMode ? [] : projectSite.places || [],
        primaryPlaceId: isPinMode ? null : projectSite.primaryPlace?.id || null,
        deletedPlaceIds: [],
        coordinates: isPinMode && primaryPlace ? { lat: Number(primaryPlace.latitude), lng: Number(primaryPlace.longitude) } : null,
        isPinMode: isPinMode,
      })
    }
  }, [data])

  const handleProjectSiteChange = (projectSiteData: FormDataType['projectSite']) => {
    setFormData(prev => ({
      ...prev,
      projectSite: projectSiteData,
    }))
  }

  const handlePlacesChange = (placesData: {
    places: FormDataType['places']
    primaryPlaceId: FormDataType['primaryPlaceId']
    deletedPlaceIds: FormDataType['deletedPlaceIds']
    coordinates: FormDataType['coordinates']
    isPinMode: FormDataType['isPinMode']
  }) => {
    setFormData(prev => ({
      ...prev,
      places: placesData.places,
      primaryPlaceId: placesData.primaryPlaceId,
      deletedPlaceIds: placesData.deletedPlaceIds,
      coordinates: placesData.coordinates,
      isPinMode: placesData.isPinMode,
    }))
  }

  const handleSubmit = () => {
    submit(formData)
  }

  return (
    <>
      {children &&
        React.cloneElement(children as React.ReactElement, {
          onClick: () => (isOpen ? onClose() : null),
        })}

      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle>Edit Project Site</ModalTitle>
          </ModalHeader>

          <ModalBody>
            <ContentContainer>
              {isLoading ? (
                <div>Loading project site data...</div>
              ) : (
                <>
                  <ProjectSiteForm data={formData.projectSite} onChange={handleProjectSiteChange} />

                  <SectionDivider />

                  <PlacesManager
                    jobId={jobId}
                    places={formData.places}
                    primaryPlaceId={formData.primaryPlaceId}
                    deletedPlaceIds={formData.deletedPlaceIds}
                    coordinates={formData.coordinates}
                    isPinMode={formData.isPinMode}
                    onChange={handlePlacesChange}
                  />
                </>
              )}
            </ContentContainer>
          </ModalBody>

          <ModalFooter>
            <Button appearance='subtle' onClick={onClose}>
              Cancel
            </Button>
            <Button appearance='primary' isLoading={isPending} onClick={handleSubmit}>
              Save Changes
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

const ContentContainer = styled.div`
  padding: 8px 0;
  max-height: 70vh;
  overflow-y: auto;
`

const SectionDivider = styled.hr`
  margin: 24px 0;
  border: none;
  border-top: 1px solid #e9eaee;
`

export default EditProjectSiteModal
